import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import { ServicesURL } from "@utils/utilsApi";
import ToastCst from "@components/ToastCst";
import { LOType, PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import ButtonBackPage from "@components/ButtonBackPage";
import useSWRMutation from "swr/mutation";
import { MethodType } from "src/services/type";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { useFetchDetail } from "@hooks/useFetchDetail";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { ServicesTypes } from "../utils/types";
import { ColumnsType } from "src/interfaces/Common";

const VersioningTaggingAssociation: React.FC<{
  type: ServicesTypes;
  page: PagesID;
}> = ({ type, page }) => {
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    key?: string | number;
  }>();
  const { idService } = useParams();
  const [taggingAssociation, setTaggingAssociation] = useState<any>(undefined);
  const [serviceUrl, setServiceUrl] = useState<string>("");
  const { t } = useTranslation([
    "sw-tagging-association-page",
    "toast-message",
  ]);

  useEffect(() => {
    switch (type) {
      case ServicesTypes.FE_DISTRIBUTIONS:
        setServiceUrl(
          `${ServicesURL.getVersioning}?type=${type}&versioningId=${idService}`
        );
        break;
      case ServicesTypes.LAMBDA:
        setServiceUrl(
          `${ServicesURL.getVersioning}?type=${type}&versioningId=${idService}`
        );
        break;
      case ServicesTypes.MICROSERVICES_BE4FE:
        setServiceUrl(
          `${ServicesURL.getVersioning}?type=${type}&versioningId=${idService}`
        );
        break;
      case ServicesTypes.MICROSERVICES_CORE:
        setServiceUrl(
          `${ServicesURL.getVersioning}?type=${type}&versioningId=${idService}`
        );
        break;
    }
  }, []);

  const { dataTable: dataDetail, mutate } = useFetchTable(serviceUrl, page);

  const { trigger: triggerCorporateAssociation } = useSWRMutation(
    ServicesURL.patchVersioningTaggingAssociation,
    (url: string, { arg }: { arg: any }) => {
      return fetcher<any>(
        `${url}?type=${type}&versioningId=${idService}`,
        MethodType.PATCH,
        arg
      );
    }
  );

  const { trigger: triggerCorporateDisassociation } = useSWRMutation(
    ServicesURL.patchVersioningTaggingDeassociation,
    (url: string, { arg }: { arg: any }) => {
      return fetcher<any>(
        `${url}?type=${type}&versioningId=${idService}`,
        MethodType.PATCH,
        arg
      );
    }
  );

  const { columns, dataTable, handleFetchData, actionForRow } = useFetchTable(
    ServicesURL.getVersioningTagging,
    PagesID["sw-tagging"]
  );

  const { dataTable: dataAllVersioning } = useFetchTable(
    `${ServicesURL.getVersioning}`,
    PagesID["sw-micro-services"]
  );

  const configurationColumnOptions: ColumnsType[] | undefined =
    React.useMemo(() => {
      if (dataAllVersioning && dataAllVersioning?.response) {
        let allServices = dataAllVersioning?.response.map((response: any) => {
          return { label: response.name, value: response.versioningId };
        });
        allServices = allServices.sort((a: any, b: any) => {
          if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
          if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
          return 0;
        });
        return columns?.map((column: any) => {
          switch (column.accessorKey) {
            case "associatedServices": {
              if (allServices.length > 0) {
                return {
                  ...column,
                  options: allServices,
                };
              } else {
                return {
                  ...column,
                  hasFilter: false,
                };
              }
            }
            default:
              return column;
          }
        });
      } else {
        return columns;
      }
    }, [columns, dataTable]);

  const handleFilters = (filters: any) => {
    let newFilters = { ...filters };
    Object.keys(filters).forEach((filter: any) => {
      switch (filter) {
        case "associatedServices":
          const servicesFilter = filters[filter]?.map((type: any) => {
            return (type?.value).toString().toUpperCase();
          });
          newFilters.associatedServices = servicesFilter;
          break;
      }
    });
    handleFetchData(newFilters);
  };

  useEffect(() => {
    if (
      dataDetail?.response &&
      dataDetail?.response[0] &&
      dataDetail?.response[0].tags &&
      dataDetail?.response[0].tags?.length > 0
    ) {
      let obj = {};
      dataDetail?.response[0].tags?.forEach((element: any) => {
        if (element.id > 0) {
          obj = {
            ...obj,
            [element.id]: true,
          };
        }
      });
      setTaggingAssociation(obj);
    }
  }, [dataDetail]);

  const handleRowSelection = async (tag: any) => {
    const associationIds =
      (taggingAssociation !== undefined && Object.keys(taggingAssociation)) ||
      [];
    const tagsIds = Object.keys(tag);
    if (associationIds?.length > tagsIds?.length) {
      const elementDeleted = associationIds.find(
        (elem: any) => !tagsIds.includes(elem)
      );
      const postObject: any = {
        tagId: elementDeleted,
      };
      console.info("remove " + elementDeleted);
      try {
        await triggerCorporateDisassociation(postObject);
        setOpenToast({
          s: StatusEnum.OK,
          message: t("removed", { ns: "toast-message" }),
          key: elementDeleted,
        });
        mutate && mutate();
        setTaggingAssociation(tag);
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    } else if (associationIds?.length < tagsIds?.length) {
      const elementAdded = tagsIds.find(
        (elem: any) => !associationIds.includes(elem)
      );

      const postObject: any = {
        tagId: Number(elementAdded),
      };
      try {
        await triggerCorporateAssociation(postObject);
        setOpenToast({
          s: StatusEnum.OK,
          message: t("added", { ns: "toast-message" }),
          key: elementAdded,
        });
        mutate && mutate();
        setTaggingAssociation(tag);
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    }
  };

  const handleCloseToastMessage = () => {
    setOpenToast(undefined);
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-activities.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={`${t("hero-title")}`} description={""} />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id={"sw-tagging-association"}
              data={dataTable?.response}
              columns={configurationColumnOptions || []}
              onChangeFilterForm={handleFilters}
              configurationTopToolbar={[]}
              enableRowSelection={true}
              enableRowActions={false}
              selectedRows={taggingAssociation}
              onRowSelectionChange={(corporates: any) => {
                handleRowSelection(corporates);
              }}
              configurationActionForRow={actionForRow}
              getRowId={(originalRow: any) => originalRow?.tagId}
              totalPages={dataTable?.metadata?.total_elements}
            />
          </Box>
        )}
      </Container>
      <ToastCst
        key={(openToast && openToast.key) || ""}
        message={(openToast && openToast.message) || ""}
        open={!!openToast}
        handleClose={handleCloseToastMessage}
      />
    </AnimationFadeIn>
  );
};

export default VersioningTaggingAssociation;
