import React, { FC } from "react";
import { Box, Checkbox, Container, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";

import { useParams } from "react-router-dom";
import { useFetchTable } from "../../../../../../../../hooks/useFetchDataTable";
import { ServicesURL } from "../../../../../../../../utils/utilsApi";
import ButtonBackPage from "../../../../../../../../components/ButtonBackPage";
import CustomRowAction from "../../../../../../../../components/Table/components/CustomRowAction";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";

interface Props {
  isClosed?: boolean;
  isDetailLearner?: boolean;
}

const DetailUserSkills: FC<Props> = ({ isClosed, isDetailLearner }) => {
  const { t } = useTranslation(["training-needs-details-user"]);
  const { id, idCampaign, idDetail, idDetailUser, idDetailKC } = useParams();

  const getKnowledge = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idCampaign ?? idDetail}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `KNOWLEDGE`) +
      `&campaign_user_id=${
        idDetailUser ?? idDetailKC ?? idDetail
      }&confirmed=TRUE`,
    PagesID[
      isClosed
        ? isDetailLearner
          ? "corporate.campaign.detail.gap.recap"
          : "corporate.campaign.detail.gap"
        : "corporate.campaign.detail.gap.user"
    ]
  );

  const getCapacity = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idCampaign ?? idDetail}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `CAPACITY`) +
      `&campaign_user_id=${
        idDetailUser ?? idDetailKC ?? idDetail
      }&confirmed=TRUE`,
    PagesID[
      isClosed
        ? isDetailLearner
          ? "corporate.campaign.detail.gap.recap"
          : "corporate.campaign.detail.gap"
        : "corporate.campaign.detail.gap.user"
    ]
  );

  let allKnowledge: any[] = [];
  if (
    getKnowledge &&
    getKnowledge?.dataTable?.output &&
    getCapacity &&
    getCapacity?.dataTable?.output
  ) {
    allKnowledge =
      [...getKnowledge?.dataTable?.output, ...getCapacity?.dataTable?.output] ||
      [];
  }

  const configurationActionForRow = React.useMemo(() => {
    return (row: any) => {
      return getKnowledge.actionForRow.filter((el: any) => {
        if (isClosed) return el.type != "visibility";
        return el;
      });
    };
  }, [getKnowledge.actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew = configurationActionForRow(row) && [
      ...configurationActionForRow(row),
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={`Skill`} description={t("hero-description")} />
        <ButtonBackPage goBack={2} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            enableRowActions={true}
            id={
              PagesID[
                isClosed
                  ? "corporate.campaign.detail.gap"
                  : "corporate.campaign.detail.gap.user"
              ]
            }
            data={allKnowledge}
            columns={getKnowledge.columns || []}
            renderRowActions={customRenderRowActions}
            onChangeFilterForm={getKnowledge.handleFetchData}
            configurationTopToolbar={getKnowledge.actionsTopToolbar}
            totalPages={getKnowledge.dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
            renderActionIcon={
              isClosed ? null : (
                <div
                  style={{
                    width: "170px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <FormControlLabel
                    style={{ margin: "0 2px" }}
                    labelPlacement="top"
                    label={""}
                    control={
                      <Checkbox
                        sx={{
                          background: "#84293c",
                          border: "1px solid #84293c",
                          borderRadius: 0,
                          padding: 0,
                        }}
                        disabled={true}
                        checkedIcon={
                          <ThumbUpOutlinedIcon
                            sx={{
                              fill: "#151515 !important",
                              background: "#f43e63",
                            }}
                          />
                        }
                        icon={
                          <ThumbUpOutlinedIcon
                            sx={{
                              fill: "#151515 !important",
                            }}
                          />
                        }
                        onChange={() => {}}
                      />
                    }
                  />
                  <FormControlLabel
                    style={{ margin: "0 2px" }}
                    labelPlacement="top"
                    label={""}
                    control={
                      <Checkbox
                        sx={{
                          border: "1px solid #84293c",
                          borderRadius: 0,
                          padding: 0,
                        }}
                        disabled={true}
                        checkedIcon={
                          <ThumbDownOutlinedIcon
                            sx={{
                              fill: "#f43e63 !important",
                            }}
                          />
                        }
                        icon={
                          <ThumbDownOutlinedIcon
                            sx={{
                              fill: "#84293c !important",
                            }}
                          />
                        }
                        onChange={() => {}}
                      />
                    }
                  />
                </div>
              )
            }
          />
        </Box>
      </Container>
    </AnimationFadeIn>
  );
};

export default DetailUserSkills;
