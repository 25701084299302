import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ButtonBackPage from "@components/ButtonBackPage";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { ActionsType } from "src/interfaces/Common";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import useSWRMutation from "swr/mutation";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { useAuth } from "@context/auth";

const GroupsEnrollmentStatus: React.FC<{
  type?: "static" | undefined;
}> = ({ type }) => {
  const { t } = useTranslation(["user-of-groups-page", "breadcrumb"]);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const navigate = useNavigate();
  const location = useLocation();
  const isDynamic = location.pathname.includes("groups-dynamic");
  const { user } = useAuth();
  const { id = 1, idGroup } = useParams();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable<any[]>(
    ServicesURL.getEnrollmentMassive + `?corporateId=${id}&&groupId=${idGroup}`,
    PagesID["corporate.groups-enrollment-status"]
  );

  const { trigger: triggerDeleteEnrollment } = useSWRMutation(
    `${ServicesURL.deleteGroupEnrollmentMassive}/?corporateId=${id}&&enrollmentId=${idToDelete}&&userId=${user?.id_user}`,
    (url: string) => {
      return fetcher<any>(url, MethodType.DELETE);
    }
  );

  const { dataDetail: groupDetail } = useFetchDetail(
    isDynamic
      ? ServicesURL.dynamicGroups.replace(":idCorporate", `${id}`)
      : ServicesURL["static-groups"],
    idGroup || "",
    isDynamic
      ? PagesID["corporate.groups-dynamic"]
      : PagesID["corporate.groups"]
  );

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "delete": {
          return {
            ...action,
            callBack: (id: number) => {
              setIdToDelete(id);
            },
          };
        }
        case "link": {
          return {
            ...action,
            callBack: (row: any) => {
              navigate(`${row.id}/lo-associated`);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    if (row.original.status !== "TODO") {
      const filteredArray = configRowNew.filter(
        (obj: any) => obj.type !== "delete"
      );
      configRowNew = filteredArray;
    }

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const handleDelete = React.useCallback(async () => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    try {
      await triggerDeleteEnrollment();
      // const newData = [
      //   ...dataTable.output.filter((el: any) => el.id !== idBadgeDeleted),
      // ];
      mutate && mutate();
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setIdToDelete(null);
      setOpenToast(undefined);
      console.error(error);
    }
  }, []);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-groups.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${groupDetail?.name} - ${t("enrollment-status", {
            ns: "breadcrumb",
          })}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.groups-enrollment-status"]}
            data={dataTable?.output}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!idToDelete}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setIdToDelete(null);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default GroupsEnrollmentStatus;
