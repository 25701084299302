// Import
import { Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  InputCst,
  SelectCst,
  AutocompleteCst,
  MultiSelectCst,
  RadioCst,
  DataPickerCst,
  SingleCheckboxCst,
  InputAssociationChipsCst,
  MultiTextCst,
} from "@components/InputCst";
import { ColumnInterface } from "@components/Table/types";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { defaultValidation } from "@utils/utilsValidators";
import ButtonCst from "@components/ButtonCst";
import DownloaderUploaderCst from "@components/DownloaderUploaderCst";
import { useNavigate } from "react-router-dom";
import InputChipsCst from "@components/InputCst/InpuctChipsCst";
import AssociationLibraryCst from "@components/AssociationLibraryCst";
import AssociationTableCst from "@components/AssociationTableCst";
import { PagesID } from "@utils/utilsConfigurations";
import TimePickerCst from "@components/InputCst/TimePickerCst";
import { TextEditorCst } from "@components/TextEditorCst";
import { Association } from "src/interfaces/Common";
import SectionFormNewCampaign from "../SectionForm/SectionFormNewCampaign";
import SectionFormOneChoice from "../SectionForm/SectionFormOneChoice";

// Interface

interface AddEditFormCstInterface {
  row?: any;
  columns: ColumnInterface[];
  additionalColumns?: ColumnInterface[];
  actionsForForms?: ColumnInterface[];
  onSubmit: (
    inputForm: any,
    fileNotificationsAsset: any,
    fileTemplateAsset: any,
    participationsAsset: any,
    uploadTheme: any,
    imagePath: any,
    pdfTemplatePath: any,
    image: any,
    document: any,
    fileUploaded: { [x: string]: any },
  ) => void;
  onReset?: () => void;
  formActionType?: string[];
  dragDrop?: boolean;
  inputsException?: (col: any, inputForm: any) => any;
  backButton?: () => any;
  getValues?: (accessorKey: string) => any;
  handleDelete?: (accessorKey: string, element: any) => void;
  handleAssociation?: (accessorKey: string, value: any) => void;
  setOpenAssociation?: (accessorKey: string, open: boolean) => void;
  association?: boolean | Association;
  isFaculties?: boolean;
  disabledConfig?: { view: string; accessoryKey: string };
  readOnly?: boolean;
  isCorporate?: boolean;
  deleteDisabled?: boolean;
  options?: any;
  LoCounter?: boolean;
  LoCountValue?: number;
  LoCountMaxSelection?: number;
  optionsSelect?: any;
  getOptionValue?: any;
}

// eslint-disable-next-line no-empty-pattern
const AddEditFormCst: React.FC<AddEditFormCstInterface> = ({
  row,
  columns,
  actionsForForms = [],
  dragDrop,
  inputsException,
  onSubmit,
  onReset,
  backButton,
  getValues,
  handleDelete,
  handleAssociation,
  setOpenAssociation,
  association,
  readOnly,
  options,
  LoCounter,
  LoCountValue,
  LoCountMaxSelection,
  optionsSelect,
  getOptionValue,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(["form"]);
  const navigate = useNavigate();
  const [inputForm, setInputForm] = React.useState<any>({});
  const [errorInputForm, setErrorInputForm] = React.useState<any>({});
  const [fileUploaded, setFileUploaded] = React.useState<any>(null);
  const [isBlocked, setIsBlocked] = useState(false);

  const getInitialData = () => {
    let initialState: any = {};
    columns?.forEach(({ accessorKey }) => {
      initialState = {
        ...initialState,
        [accessorKey]: row[accessorKey],
      };
    });
    return initialState;
  };

  const resetInputForm = () => {
    let initialState = {};
    onReset && onReset();

    if (row) {
      setInputForm(getInitialData());
    } else {
      setInputForm(initialState);
    }
    setIsBlocked(false);
  };

  useEffect(() => {
    if (
      row &&
      Object.keys(row).length > 0 &&
      columns?.length > 0 &&
      Object.keys(inputForm).length === 0
    ) {
      const initialState = getInitialData();
      setInputForm(initialState);

      // Disable all columns if readOnly=true prop is passed
      if (readOnly) {
        columns?.forEach((column) => (column.disabled = true));
      }
    }
  }, [row, columns]);

  //TODO: refactor validation v.1
  const onConfirm = () => {
    // FIXME: defaultValidation non effettua validazione de i campi upload (es. upload image certificati anche se required non viene validato).
    // fix predisposta in branch fix/actionsForFormsValidation
    const { canBeSubmit, showError } = defaultValidation(columns, inputForm);

    // if both fields is empty shows a error message
    if (
      !(!inputForm["link_title"] && !inputForm["link_URL"]) &&
      (!inputForm["link_title"] || !inputForm["link_URL"])
    ) {
      setErrorInputForm({
        ...showError,
        link_title: {
          msg: "invalid-label-value-editorial-text",
        },
        link_URL: {
          msg: "invalid-label-value-editorial-text",
        },
      });
      return;
    }

    if (inputForm.campaign_name) {
      const hasValidSections =
        Object.values(inputForm).some((value: any) => value.enabled === true) &&
        Object.values(inputForm)
          .filter((value: any) => value.enabled === true) // Considera solo le sezioni abilitate
          .every((section: any) => {
            const isLearner = section === inputForm.section_learner;
            const isHRorManager =
              section === inputForm.section_hr ||
              section === inputForm.section_manager;

            if (isLearner) {
              return section?.selectedGroups.length > 0 || section.all_users;
            }

            if (isHRorManager) {
              return (
                section.selectedLevels &&
                section.selectedLevels.length > 0 &&
                (section?.selectedGroups.length > 0 || section.all_users)
              );
            }

            return false;
          });

      if (!hasValidSections) {
        setErrorInputForm({
          section_data: "error",
          ...showError,
          link_title: {
            msg: "mandatory-section",
          },
          link_URL: {
            msg: "mandatory-section",
          },
        });
        return;
      } else setErrorInputForm({});
    }

    // if both fields is empty shows a error message
    if (
      !(
        !inputForm["brightcove_title"] &&
        !inputForm["brightcove"] &&
        !inputForm["brightcove_time"]
      ) &&
      (!inputForm["brightcove_title"] ||
        !inputForm["brightcove"] ||
        !inputForm["brightcove_time"])
    ) {
      setErrorInputForm({
        ...showError,
        brightcove_title: {
          msg: "invalid-label-value-editorial-text",
        },
        brightcove: {
          msg: "invalid-label-value-editorial-text",
        },
        brightcove_time: {
          msg: "invalid-label-value-editorial-text",
        },
      });
      return;
    }

    setErrorInputForm(showError);
    const corporateDomainExist = columns.find(
      (item) => item.accessorKey === "corporate_domain",
    );

    if (corporateDomainExist != undefined) {
      if (
        inputForm["corporate_domain"] === "" ||
        inputForm["corporate_domain"] === undefined
      ) {
        inputForm["corporate_domain"] = null;
      }
    }

    if (canBeSubmit) {
      setIsBlocked(false);
      onSubmit(
        inputForm,
        fileUploaded && fileUploaded["uploadNotification"],
        fileUploaded && fileUploaded["uploadTemplate"],
        fileUploaded && fileUploaded["participations"],
        fileUploaded && fileUploaded["uploadTheme"],
        fileUploaded && fileUploaded["imagePath"],
        fileUploaded && fileUploaded["pdfTemplatePath"],
        fileUploaded && fileUploaded["image"],
        fileUploaded && fileUploaded["document"],
        fileUploaded,
      );
    }
  };

  const getErrMsg = (accessorKey: string): string => {
    return errorInputForm[accessorKey]?.msg || errorInputForm[accessorKey];
  };

  const minLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const maxLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const getInputsList = (col: any, inputForm: any) => {
    if (inputsException) {
      col = inputsException(col, inputForm);
    }

    const enrollmentDisabledFields = [
      "isExpirationFixed",
      "expirationDate",
      "isExpirationHard",
      "daysToComplete",
    ];
    if (!inputForm.expiration_date_flag) {
      if (enrollmentDisabledFields.includes(col.accessorKey)) {
        col.disabled = true;
      }
    }
    if (col.visibilityInForm == false) return;

    const loFields = ["activities", "courses", "paths"];

    if (
      LoCounter &&
      LoCountValue &&
      LoCountMaxSelection &&
      LoCountValue >= LoCountMaxSelection
    ) {
      if (loFields.includes(col.accessorKey)) {
        col.disableAssociationButton = true;
      }
    }

    switch (col.type) {
      case "association": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={col.fullWidth ? 12 : 6}>
            <InputAssociationChipsCst
              key={col.accessorKey}
              id={col.accessorKey}
              label={col.header}
              value={getValues && getValues(col.accessorKey)}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              noAssociationButton={col.disableAssociationButton}
              disabled={col.disabled}
              textTooltip={col.tooltip}
              required={col.required}
              onDelete={(e: any) => {
                setIsBlocked(true);
                handleDelete && handleDelete(col.accessorKey, e);
              }}
              onRedirect={() => {
                setIsBlocked(true);
                setOpenAssociation && setOpenAssociation(col.accessorKey, true);
              }}
              labelKey={col.labelKey}
            />
          </Grid>
        );
      }
      case "singleCheckbox": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <SingleCheckboxCst
              key={col.accessorKey}
              id={col.accessorKey}
              value={inputForm[col.accessorKey]}
              label={col.header}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({
                  ...inputForm,
                  ...{ [col.accessorKey]: e.target.checked },
                });
              }}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
            />
          </Grid>
        );
      }
      case "text": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <InputCst
              key={col.accessorKey}
              id={col.accessorKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              required={col.required}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({
                  ...inputForm,
                  ...{ [col.accessorKey]: e.target.value },
                });
              }}
              maxValue={col?.rules?.max?.value}
              multiline={col.multiline}
              rows={col.rows}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
              textTooltip={col.tooltip}
              type={col.inpuType || "text"}
            />
          </Grid>
        );
      }
      case "chips": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <InputChipsCst
              label={col.header}
              id={col.accessorKey}
              value={inputForm[col.accessorKey]}
              required={col.required}
              maxLength={col?.rules?.max?.value}
              disabled={col.disabled}
              textTooltip={col.textTooltip}
              validationType={col.validationType}
              error={errorInputForm[col.accessorKey] ? true : false}
              onAdd={(list: any[]) => {
                setIsBlocked(true);
                setInputForm({
                  ...inputForm,
                  ...{ [col.accessorKey]: list },
                });
              }}
              deleteDisabled={col.deleteDisabled}
              undeletableValues={col.undeletableValues}
            />
          </Grid>
        );
      }
      case "dynamicUOGSelect": {
        let error = false;
        const buildLevels = (data: any) => {
          const levels: any = {};

          const rootItem = data.find((el: any) => el.parent_code == "NULL");

          function getLevelItems(parentCode: string, level: number) {
            if (!levels[level]) levels[level] = [];
            const items = data.filter(
              (item: any) => item.parent_code === parentCode,
            );

            if (items.length === 0) return;

            levels[level].push(...items);

            items.forEach((item: any) => getLevelItems(item.code, level + 1));
          }

          if (rootItem) {
            error = false;
            getLevelItems(rootItem.code, 1);
          } else {
            error = true;
          }

          const fullLevels = {
            "0": [rootItem],
            ...levels,
          };

          const options = Object.keys(fullLevels)
            .filter((levelIndex) => fullLevels[levelIndex].length > 0)
            .map((levelIndex) => ({
              name: `${levelIndex}`,
              value: levelIndex,
              label: `${levelIndex}`,
            }));

          return options;
        };

        const optionChoice = buildLevels(optionsSelect);

        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <SelectCst
              key={col.accessorKey}
              id={col.accessorKey}
              label={col.header}
              optionsToDisable={col.optionsToDisable}
              value={inputForm[col.accessorKey]}
              service={col.service}
              required={col.required}
              onChange={(selected: any) => {
                setIsBlocked(true);
                if (col.callBack) {
                  setInputForm(col.callBack(selected, row));
                } else {
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: selected },
                  });
                }
              }}
              options={optionChoice}
              textTooltip={col.tooltip}
              error={!!errorInputForm[col.accessorKey] || !!error}
              errorMessage={
                error
                  ? t("error.field.uog-hierarchy") || ""
                  : t("error.field.required") || ""
              }
              disabled={error || col.disabled}
            />
          </Grid>
        );
      }
      case "select": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <SelectCst
              key={col.accessorKey}
              id={col.accessorKey}
              optionsKey={col.optionsKey}
              label={col.header}
              optionsToDisable={col.optionsToDisable}
              value={inputForm[col.accessorKey]} //REFACTOR temp to show right value on select
              service={col.service}
              required={col.required}
              onChange={(e: any) => {
                setIsBlocked(true);
                if (col.callBack) {
                  setInputForm(col.callBack(e, row));
                } else {
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }
              }}
              options={col?.options}
              textTooltip={col.tooltip}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
            />
          </Grid>
        );
      }
      case "select-checkbox": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <MultiSelectCst
              id={col.accessorKey}
              label={col.header}
              optionsKey={col.optionsKey}
              value={inputForm[col.accessorKey]}
              optionsToDisable={col.optionsToDisable}
              disabled={col.disabled}
              onChange={(e: any) => {
                setIsBlocked(true);
                let newValue = JSON.parse(JSON.stringify(e));
                let newInputForm = JSON.parse(JSON.stringify(inputForm));
                setInputForm({
                  ...newInputForm,
                  ...{ [col.accessorKey]: newValue },
                });
              }}
              options={col.options}
              required={col.required}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
            />
          </Grid>
        );
      }
      case "autocomplete": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <AutocompleteCst
              key={col.accessorKey}
              id={col.accessorKey}
              optionsKey={col.optionsKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              required={col.required}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
              }}
              options={col?.options}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
            />
          </Grid>
        );
      }
      case "multiCheckbox": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <MultiSelectCst
              key={col.accessorKey}
              id={col.accessorKey}
              optionsKey={col.optionsKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              textTooltip={col.tooltip}
              required={col.required}
              service={col.service}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
              }}
              options={col?.options}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
            />
          </Grid>
        );
      }
      case "radio": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <RadioCst
              key={col.accessorKey}
              id={col.accessorKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
              }}
              options={col.options}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
            />
          </Grid>
        );
      }
      case "dateTime": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <DataPickerCst
              id={col.accessorKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              required={col.required}
              minDate={col.minDate}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
              }}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
            />
          </Grid>
        );
      }
      case "dateTimeYear": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <DataPickerCst
              views={["year"]}
              id={col.accessorKey}
              label={col.header}
              value={inputForm[col.accessorKey]}
              required={col.required}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
              }}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
              disabled={col.disabled}
            />
          </Grid>
        );
      }
      case "time": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <TimePickerCst
              id={col.accessorKey}
              value={inputForm[col.accessorKey]}
              error={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`) || "Error"
              }
              required={col.required}
              label={col.header}
              disabled={col.disabled}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
              }}
            />
          </Grid>
        );
      }
      case "multi-text": {
        return (
          <MultiTextCst
            label={col.header}
            fields={col.fields}
            value={inputForm[col.accessorKey]}
            disabled={col.disabled}
            onChange={(e: any) => {
              setIsBlocked(true);
              setInputForm({
                ...inputForm,
                ...{ [col.accessorKey]: e },
              });
            }}
          />
        );
      }
      case "editor": {
        return (
          <TextEditorCst
            options={col.editorOptions}
            value={inputForm[col.accessorKey]}
            onChange={(e: any) => {
              setIsBlocked(true);
              setInputForm((prev: any) => {
                return {
                  ...prev,
                  [col.accessorKey]: e,
                };
              });
            }}
          />
        );
      }
      case "upload": {
        return (
          <Grid key={col.accessorKey} item xs={12} sm={6}>
            <DownloaderUploaderCst
              name={col.header}
              accept={col?.accept}
              url={col.service}
              type={col.upload_type}
              onChange={(e: any) => {
                setInputForm({
                  ...inputForm,
                  [col.accessorKey]: {
                    e,
                  },
                });
                setFileUploaded({
                  ...fileUploaded,
                  [col.accessorKey]: {
                    file: e,
                  },
                });
              }}
              fileName={!!row?.originalFileName && row.originalFileName}
              downloadDisabled={!!col.downloadDisabled}
              onDownload={col.downloadCallback && col.downloadCallback}
              onTemplateDownload={col.templateCallback && col.templateCallback}
              onDelete={
                col.deleteCallback &&
                (() => {
                  setFileUploaded(null);
                  return col.deleteCallback && col.deleteCallback();
                })
              }
              disabled={col.disabled}
              required={col?.required}
              showError={errorInputForm[col.accessorKey] ? true : false}
              errorMessage={
                t(`error.field.${getErrMsg(col.accessorKey)}`, {
                  min: minLength(col.accessorKey),
                  max: maxLength(col.accessorKey),
                }) || "Error"
              }
            />
          </Grid>
        );
      }
      case "sectionFormNewCampaign": {
        let error = false;

        const buildLevels = (data: any) => {
          const levels: any = {};
          const rootItem = data.find((el: any) => el.parent_code === "NULL");

          function getLevelItems(parentCode: string, level: number) {
            if (!levels[level]) levels[level] = [];
            const items = data.filter(
              (item: any) => item.parent_code === parentCode,
            );

            if (items.length === 0) return;

            levels[level].push(...items);

            items.forEach((item: any) => getLevelItems(item.code, level + 1));
          }

          if (rootItem) {
            getLevelItems(rootItem.code, 1);
          } else {
            error = true;
          }

          const fullLevels = {
            "0": [rootItem],
            ...levels,
          };

          const options = Object.keys(fullLevels)
            .filter((levelIndex) => fullLevels[levelIndex]?.length > 0)
            .map((levelIndex) => ({
              name: `${levelIndex}`,
              value: levelIndex,
              label: `${levelIndex}`,
            }));

          return options;
        };

        const optionLevel = buildLevels(optionsSelect);

        return (
          <SectionFormNewCampaign
            error={error}
            errorMessage={t("error.field.uog-hierarchy") || ""}
            col={col}
            options={options}
            optionsLevels={optionLevel} // Passa le opzioni costruite dalla funzione
            setInputForm={setInputForm}
            inputForm={inputForm}
          />
        );
      }
      case "sectionFormOneChoise": {
        return (
          <SectionFormOneChoice
            col={col}
            options={options}
            setInputForm={setInputForm}
            inputForm={inputForm}
            oneChoise={true}
            getOptionValue={getOptionValue}
          />
        );
      }
      default:
        return <></>;
    }
  };

  const getBase64 = (file: any, callBack: any) => {
    let reader = new FileReader();
    if (!file) return;
    reader.readAsDataURL(file);
    reader.onload = function () {
      callBack(reader.result);
    };
    reader.onerror = function (error) {
      console.error("getBase64 error: ", error);
    };
  };

  const getInputField = useMemo(() => {
    return columns?.map((col: any) => {
      if (!!col.knowledge) {
        return false;
      }
      return getInputsList(col, inputForm);
    });
  }, [
    columns,
    inputForm,
    errorInputForm,
    t,
    getErrMsg,
    getValues,
    handleDelete,
    maxLength,
    minLength,
    setOpenAssociation,
  ]);

  const showFormAction = (field: ColumnInterface) => {
    return (
      field?.type?.includes("upload") ||
      field?.type?.includes("download") ||
      field?.type?.includes("template")
    );
  };

  return (
    <>
      {/* <BackButtonWarning isBlocked={isBlocked} /> */}
      {!association && (
        <Grid container>
          <Grid item xs={0} lg={4} xl={3}>
            <BackgroundImageCst
              image="section-internal-form.jpg"
              disableGradient
            />
          </Grid>
          <Grid item xs={12} lg={8} xl={9}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                padding: "32px",
                minHeight: "495px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {LoCounter && (
                <Typography variant="h5" mb={2}>
                  Associa Oggetti Formativi ({LoCountValue}/
                  {LoCountMaxSelection})
                </Typography>
              )}
              <Grid container spacing={3}>
                {getInputField}
                {actionsForForms?.map((field) => {
                  if (field.accessorKey === "uploadNotification") {
                    const isNotificationUploaded =
                      field.accessorKey === "uploadNotification" &&
                      row?.notificationName;
                    field.downloadDisabled = !isNotificationUploaded;
                    field.fileName = row?.notificationName;
                    field.disabled =
                      !(
                        fileUploaded?.uploadTemplate &&
                        fileUploaded?.uploadTemplateWeb &&
                        fileUploaded?.uploadTemplatePush
                      ) &&
                      !(
                        row?.templateMailName &&
                        row?.templateWebappName &&
                        row?.templatePushName
                      );
                  } else if (field.accessorKey === "maintenancePage") {
                    const isTemplateMaintenancePageUploaded =
                      field.accessorKey === "maintenancePage" &&
                      row?.templatemaintenancePage;
                    field.downloadDisabled = !isTemplateMaintenancePageUploaded;
                    field.fileName = row?.templatemaintenancePage;
                  } else if (field.accessorKey === "uploadTemplate") {
                    const isTemplateUploaded =
                      field.accessorKey === "uploadTemplate" &&
                      row?.templateMailName;
                    field.downloadDisabled = !isTemplateUploaded;
                    field.fileName = row?.templateMailName;
                  } else if (field.accessorKey === "uploadTemplateWeb") {
                    const isTemplateWebUploaded =
                      field.accessorKey === "uploadTemplateWeb" &&
                      row?.templateWebappName;
                    field.downloadDisabled = !isTemplateWebUploaded;
                    field.fileName = row?.templateWebappName;
                  } else if (field.accessorKey === "uploadTemplatePush") {
                    const isTemplatePushUploaded =
                      field.accessorKey === "uploadTemplatePush" &&
                      row?.templatePushName;
                    field.downloadDisabled = !isTemplatePushUploaded;
                    field.fileName = row?.templatePushName;
                  } else if (field.accessorKey === "uploadTheme") {
                    const isThemeUploaded =
                      field.accessorKey === "uploadTheme" &&
                      row?.link_admin_theme;
                    field.downloadDisabled = !isThemeUploaded;
                    let themeFileName: string | any = "";
                    if (isThemeUploaded) {
                      // File name is the last pathname after /
                      const themeLinkPathArray = new URL(
                        row?.link_admin_theme,
                      ).pathname?.split("/");
                      if (themeLinkPathArray.length > 0) {
                        themeFileName =
                          themeLinkPathArray[themeLinkPathArray.length - 1];
                      }
                    }
                    field.fileName = themeFileName;
                  } else if (field.accessorKey === "participations") {
                    const isParticipationsUploaded =
                      field.accessorKey === "participations" &&
                      !!row?.participations;
                    field.downloadDisabled = !isParticipationsUploaded;
                    field.fileName = row?.participations;
                  } else if (field.accessorKey === "imagePath") {
                    field.fileName = row?.sourceImageName;
                  } else if (field.accessorKey === "image") {
                    field.fileName = row?.sourceImageName || row?.image;
                  } else if (field.accessorKey === "pdfTemplatePath") {
                    field.fileName = row?.templateName;
                  } else if (field.accessorKey === "document") {
                    field.fileName = row?.document;
                  } else if (field.accessorKey === "uploadMultilanguageWeb") {
                    const isUploadMultilanguageWebUploaded =
                      field.accessorKey === "uploadMultilanguageWeb" &&
                      row?.labelTranslationWebName;
                    field.downloadDisabled = !isUploadMultilanguageWebUploaded;
                    field.fileName = row?.labelTranslationWebName;
                  } else if (
                    field.accessorKey === "uploadMultilanguageMobile"
                  ) {
                    const isUploadMultilanguageMobileUploaded =
                      field.accessorKey === "uploadMultilanguageMobile" &&
                      row?.labelTranslationMobileName;
                    field.downloadDisabled =
                      !isUploadMultilanguageMobileUploaded;
                    field.fileName = row?.labelTranslationMobileName;
                  }
                  if (inputForm.videoconference_tool === "TEAMS_DIG") {
                    if (field.accessorKey === "participations") {
                      field.disabled = true;
                    }
                  } else {
                    if (field.accessorKey === "participations") {
                      field.disabled = false;
                    }
                  }

                  return (
                    <Grid key={field.accessorKey} item xs={12} sm={6}>
                      {showFormAction(field) && (
                        <DownloaderUploaderCst
                          disabled={field.disabled}
                          name={field.header}
                          accept={field?.accept}
                          maxSize={
                            field?.maxSize ||
                            10 /* 10Mb: API GATEWAY payload limit */
                          }
                          required={field?.required}
                          url={field.service}
                          dragDrop={dragDrop}
                          fileName={field.fileName}
                          type={field?.type || []}
                          downloadDisabled={field.downloadDisabled}
                          onChange={(e: any) => {
                            if (field?.uploadCallback) {
                              if (!e) return;
                              const formData = new FormData();
                              formData.append("file", e);
                              setFileUploaded({
                                ...fileUploaded,
                                [field.accessorKey]: {
                                  file: formData,
                                },
                              });
                            } else {
                              getBase64(e, (result: any) => {
                                setFileUploaded({
                                  ...fileUploaded,
                                  [field.accessorKey]: {
                                    file: e,
                                    base64: result,
                                  },
                                });
                              });
                            }
                          }}
                          onDownload={
                            field.downloadCallback && field.downloadCallback
                          }
                          onTemplateDownload={
                            field.templateCallback && field.templateCallback
                          }
                          onDelete={
                            field.deleteCallback &&
                            (() => {
                              setFileUploaded(null);
                              return (
                                field.deleteCallback && field.deleteCallback()
                              );
                            })
                          }
                          textTooltip={field?.tooltip}
                          tooltipFile={field?.tooltipFile}
                          tooltipFileIta={field?.tooltipFileIta}
                          tooltipFileEn={field?.tooltipFileEn}
                          showError={
                            errorInputForm[field.accessorKey] ? true : false
                          }
                          errorMessage={
                            t(`error.field.${getErrMsg(field.accessorKey)}`) ||
                            "Error"
                          }
                        />
                      )}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: "32px",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "space-between",
                    [theme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <ButtonCst
                    id={"back-button"}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      mr: "auto",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={() => {
                      if (backButton) {
                        backButton();
                      } else {
                        navigate(-1);
                      }
                    }}
                  >
                    {t("backButton")}
                  </ButtonCst>
                  <ButtonCst
                    id={"reset-button"}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={resetInputForm}
                  >
                    {t("resetButton")}
                  </ButtonCst>
                  <ButtonCst
                    id={"save-button"}
                    variant={"contained"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={() => onConfirm()}
                    disabled={!!readOnly}
                  >
                    {t("saveButton")}
                  </ButtonCst>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {(association as { type: string })?.type === "library" && (
        <AssociationLibraryCst
          association={
            getValues && getValues((association as Association).accessorKey)
          }
          setAssociation={(value: any) => {
            handleAssociation &&
              handleAssociation(
                (association as Association).accessorKey,
                value,
              );
          }}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation &&
            setOpenAssociation((association as Association).accessorKey, value)
          }
          filters={[
            {
              field: "type",
              value: (association as Association).mediaType,
            },
          ]}
          multiple
          title={t((association as Association).titleKey)}
        />
      )}
      {(association as { type: string })?.type === "table" && (
        <AssociationTableCst
          association={
            getValues && getValues((association as Association).accessorKey)
          }
          setAssociation={(value: any) => {
            handleAssociation &&
              handleAssociation(
                (association as Association).accessorKey,
                value,
              );
            setInputForm({
              ...inputForm,
              ...{ [(association as Association).accessorKey]: value },
            });
          }}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation &&
            setOpenAssociation((association as Association).accessorKey, value)
          }
          title={t((association as Association).titleKey)}
          service={(association as { service: string }).service}
          pageID={(association as { pageId: PagesID }).pageId}
          filter={(association as Association).accessorKey}
          defaultFilters={(association as Association).defaultFilters}
          manageConfigs={(association as Association).manageConfigs}
          enableMultiRowSelection={(association as Association).multiChoice}
          labelKey={(association as Association).labelKey}
        />
      )}
    </>
  );
};

export default AddEditFormCst;
