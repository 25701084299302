import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";
import ButtonBackPage from "../../../../../../components/ButtonBackPage";
import ModalCst from "../../../../../../components/ModalCst";
import { DetailUserKnowledge } from "../../../../../../components/DetailUserKnowledge/Index";
import { ServicesURL } from "../../../../../../utils/utilsApi";
import { useFetchTable } from "../../../../../../hooks/useFetchDataTable";
import ModalConfirmsCst from "../../../../../../components/ModalConfirmsCst";
import { StatusEnum } from "../../../../../../components/ModalConfirmsCst/types";
import useSWRMutation from "swr/mutation";
import { axiosInstance } from "../../../../../../services/config";
import { useParams } from "react-router-dom";
import { TableTabs } from "../../../../../../components/TableTabs/Index";
import CustomRowAction from "../../../../../../components/Table/components/CustomRowAction";
import { useFetchDataDetail } from "../../../../../../hooks/useFetchDetail";

const ViewPreferences: React.FC = () => {
  const { t } = useTranslation(["training-needs-details-user"]);
  const { t: tCompletation } = useTranslation(["training-needs-create-page"]);
  const { t: tModal } = useTranslation(["modal"]);
  const [actionData, setActionData] = useState<any>({});
  const [reminderModal, setReminderModal] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();
  const [modalConfirm, setModalConfirm] = useState(false);

  useEffect(() => {
    if (actionData.type == "reminder") setModalConfirm(true);
  }, [actionData]);

  const { id, idCampaign } = useParams();

  const detailCampaign = useFetchDataDetail(
    `${ServicesURL.digitedCampaignReport
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{corporateId}", `${id}`)}`
  );

  const isCompletedHrState = detailCampaign.dataDetail.hr_state == "COMPLETED";

  const isCompletationCampaignFlux = window.location.href.includes(
    "completation-campaign"
  );

  const learnerData = useFetchTable(
    `${ServicesURL.digitedUsersCampaign.replace(
      "{campaignId}",
      `${idCampaign}`
    )}?role=LEARNER${isCompletedHrState ? `&status=COMPLETED` : ""}`,
    PagesID["corporate.campaign.visualize.learner"]
  );
  const hrData = useFetchTable(
    `${ServicesURL.digitedUsersCampaign.replace(
      "{campaignId}",
      `${idCampaign}`
    )}?role=HR${isCompletedHrState ? `&status=COMPLETED` : ""}`,
    PagesID["corporate.campaign.visualize.learner"]
  );
  const managerData = useFetchTable(
    `${ServicesURL.digitedUsersCampaign.replace(
      "{campaignId}",
      `${idCampaign}`
    )}?role=MANAGER${isCompletedHrState ? `&status=COMPLETED` : ""}`,
    PagesID["corporate.campaign.visualize.learner"]
  );

  const { trigger: reminderUser } = useSWRMutation(
    ServicesURL.reminderUsersCampaign +
      `?campaign_id=${idCampaign}&user_id=${actionData.user_id}`,
    (url: string) => {
      return () => axiosInstance.post(`${url}`);
    }
  );

  const handleReminderUser = async () => {
    setReminderModal({ s: StatusEnum.LOADING, message: "loading" });

    try {
      await reminderUser();
      setReminderModal({
        s: StatusEnum.OK,
        message: tModal("successfully-reminder-user"),
      });
    } catch (error) {
      setReminderModal(undefined);
      console.error(error);
    }
  };

  useResetFilters();

  const configurationActionForRow = (row: any) => {
    return learnerData.actionForRow
      ?.filter((action: any) => {
        if (action.type === "reminder") {
          return !isCompletationCampaignFlux;
        }
        return true;
      })
      .map((action: any) => {
        if (action.type === "reminder") {
          const disabled = row.original.status === "COMPLETED";
          return {
            ...action,
            disabled: disabled,
          };
        }
        return action;
      });
  };

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow(row) && [
      ...configurationActionForRow(row),
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        setActionData={setActionData}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const tabs = [
    {
      learner: (
        <Table
          id={PagesID["corporate.campaign.visualize.learner"]}
          data={
            (learnerData?.dataTable?.output &&
              learnerData?.dataTable?.output) ||
            []
          }
          columns={learnerData.columns || []}
          setActionData={setActionData}
          enableRowActions={true}
          renderRowActions={customRenderRowActions}
          onChangeFilterForm={learnerData.handleFetchData}
          configurationTopToolbar={learnerData.actionsTopToolbar}
          totalPages={learnerData.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
        />
      ),
    },
    {
      manager: (
        <Table
          id={PagesID["corporate.campaign.visualize.manager"]}
          data={managerData?.dataTable?.output || []}
          columns={managerData.columns || []}
          setActionData={setActionData}
          renderRowActions={customRenderRowActions}
          onChangeFilterForm={managerData.handleFetchData}
          configurationTopToolbar={managerData.actionsTopToolbar}
          totalPages={managerData.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
        />
      ),
    },
    {
      hr: (
        <Table
          id={PagesID["corporate.campaign.visualize.hr"]}
          data={hrData?.dataTable?.output || []}
          columns={hrData.columns || []}
          setActionData={setActionData}
          renderRowActions={customRenderRowActions}
          onChangeFilterForm={hrData.handleFetchData}
          configurationTopToolbar={hrData.actionsTopToolbar}
          totalPages={hrData.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
        />
      ),
    },
  ];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={
            isCompletationCampaignFlux
              ? `${tCompletation("hero-title")}`
              : `${t("hero-title")}`
          }
          description={t("hero-description")}
        />
        <ButtonBackPage goBack={2} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <TableTabs tabs={tabs} />
        </Box>
      </Container>
      <ModalCst
        open={actionData && actionData.type == "visibility"}
        setOpen={setActionData}
      >
        <TableTabs
          tabs={[
            {
              [t("knowledge")]: (
                <DetailUserKnowledge tab={"knowledge"} data={actionData} />
              ),
            },
            {
              [t("capacity")]: (
                <DetailUserKnowledge tab={"capacity"} data={actionData} />
              ),
            },
          ]}
        />
      </ModalCst>

      <ModalConfirmsCst
        open={modalConfirm}
        routeToSamePage={true}
        title={t("modal-confirm-reminder")}
        description={""}
        onActionConfirmed={() => handleReminderUser()}
        onCallBackClosed={() => {
          setReminderModal(undefined);
          setModalConfirm(false);
        }}
        status={reminderModal}
      />
    </AnimationFadeIn>
  );
};

export default ViewPreferences;
