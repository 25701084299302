import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { downloadFile, ServicesURL, uploadFile } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import { ActionsType } from "src/interfaces/Common";
import { ActionUploadDownloadIterface } from "@components/ModalDownloadUploadCst/types";
import { KeyURL, MethodType } from "src/services/type";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { Group } from "src/interfaces/Group";
import useSWRMutation from "swr/mutation";
import { fetcher } from "src/services/config";
import ButtonBackPage from "@components/ButtonBackPage";
import AnimationFadeIn from "@components/AnimationFadeIn";

const AllGroupsEnrolled: React.FC = () => {
  const navigate = useNavigate();
  const { id, idLORow } = useParams();
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
  } = useFetchTable<Group[]>(
    `${ServicesURL.getGroupsEnrolledToLO}?corporateId=${id}&loId=${idLORow}`,
    PagesID["corporate.groups.all-groups-enrolled"]
  );
  const { t } = useTranslation(["all-groups-enrolled-page", "modal"]);

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID.corporate
  );

  const actionsRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.key) {
        case "users-group": {
          return {
            ...action,
            callBack: (row: any) =>
              navigate(`user-of-group`, {
                state: { row: row },
              }),
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-groups.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.name || ""} - ${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id="groups"
            data={dataTable?.response || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            configurationActionForRow={actionsRow}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>
    </AnimationFadeIn>
  );
};

export default AllGroupsEnrolled;
