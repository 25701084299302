import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useSWRMutation from "swr/mutation";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import ButtonCst from "@components/ButtonCst";
import { RadioCst } from "@components/InputCst";
import ModalCst from "@components/ModalCst";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { ServicesURL } from "@utils/utilsApi";
import ModalConfirmsCst from "@components/ModalConfirmsCst";

export default function ModalMaintenancePage({
  openMantainancePage,
  setOpenMantainancePage,
  modalTitle,
}: {
  openMantainancePage: boolean;
  setOpenMantainancePage: (arg: boolean) => void;
  modalTitle: string;
}) {
  const { data } = useSWR<{
    showMaintenancePage: boolean;
    updateDate: "string";
  }>(`GET-${ServicesURL.getSmartConfiguratorMaintenancePage}`, () =>
    fetcher(ServicesURL.getSmartConfiguratorMaintenancePage, MethodType.GET)
  );

  const [isOpenMaintenancePageWarning, setIsOpenMaintenancePageWarning] =
    useState(false);

  const { trigger } = useSWRMutation(
    `POST-${ServicesURL.postSmartConfiguratorMaintenancePage}`,
    (
      url,
      params: { arg: { showMaintenancePage: boolean; updateDate: string } }
    ) =>
      fetcher(
        ServicesURL.postSmartConfiguratorMaintenancePage,
        MethodType.POST,
        params.arg
      )
  );

  const { t } = useTranslation(["common", "maintenance-page"]);
  const [inputRadio, setInputRadio] = useState(!!data?.showMaintenancePage);

  const onConfirm = async () => {
    // Crea una nuova data (puoi specificare la data che vuoi)
    const date = new Date();

    // Usa il metodo toISOString() e poi rimuovi i millisecondi e il fuso orario
    const formattedDate = date.toISOString().substring(0, 19);

    await trigger({
      showMaintenancePage: inputRadio,
      updateDate: formattedDate,
    });
    setOpenMantainancePage(false);
  };

  const onReset = () => {
    setOpenMantainancePage(false);
  };

  useEffect(() => {
    setInputRadio(!!data?.showMaintenancePage);
  }, [data]);

  return (
    <ModalCst open={openMantainancePage} setOpen={setOpenMantainancePage}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ margin: "0" }} id="parent-modal-title">
          {modalTitle}
        </h2>
        <Button
          onClick={() => setOpenMantainancePage(false)}
          sx={{
            borderRadius: "50%",
            minWidth: "0",
            padding: "0 3px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            span: {
              width: "0",
            },
          }}
        >
          <CloseIcon fontSize="large" />
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <RadioCst
          key={"radio-global-deactivate"}
          id={"radio-global-deactivate"}
          value={inputRadio.toString() === "true" ? true : false}
          onChange={(e: any) => {
            setIsOpenMaintenancePageWarning(true);
          }}
          options={[
            {
              value: false,
              label: t("radio-global-deactivate", {
                ns: "maintenance-page",
              }),
            },
            {
              value: true,
              label: t("radio-global-activate", {
                ns: "maintenance-page",
              }),
            },
          ]}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ButtonCst
          id={"reset-button"}
          variant={"outlined"}
          size={"medium"}
          onClick={onReset}
        >
          {t("resetButton", {
            ns: "maintenance-page",
          })}
        </ButtonCst>
        <ButtonCst
          id={"save-button"}
          variant={"contained"}
          size={"medium"}
          onClick={onConfirm}
        >
          {t("saveButton", {
            ns: "maintenance-page",
          })}
        </ButtonCst>
      </Box>
      <ModalConfirmsCst
        open={!!isOpenMaintenancePageWarning}
        title={"Maintenance page"}
        description={
          !inputRadio
            ? t("warning-activate", {
                ns: "maintenance-page",
              })
            : t("warning-deactivate", {
                ns: "maintenance-page",
              })
        }
        routeToSamePage={true}
        onCallBackClosed={() => {
          setIsOpenMaintenancePageWarning(false);
        }}
        onActionConfirmed={() => {
          setInputRadio(!inputRadio);
          setIsOpenMaintenancePageWarning(false);
        }}
      />
    </ModalCst>
  );
}
