import { PagesID, configurations } from "@utils/utilsConfigurations";
import {
  translateHeaders,
  translateActions,
} from "@utils/utilsTranslateHeaders";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnsType, ConfigPageType } from "src/interfaces/Common";

//TOD: Da eliminare dopo integrazione dei servizi
export function useFetchMock<T = any>(idPage: PagesID) {
  const { i18n } = useTranslation();

  const [data, setData] = useState<T | undefined>(undefined);

  useEffect(() => {
    import(`src/mock_data/${idPage}`).then((res) => {
      setData(res.default);
    });
  }, [idPage]);

  const dataConfig: ConfigPageType = idPage && configurations[idPage];

  const getColumns = useCallback(() => {
    return translateHeaders(
      dataConfig?.columns.filter((element: ColumnsType) => {
        if (
          idPage.includes("add") || idPage.includes("edit")
            ? element.hasOwnProperty("visibilityInDetail") &&
              element.visibilityInDetail === false
            : element.hasOwnProperty("visibilityInTable") &&
              element.visibilityInTable === false
        ) {
          return false;
        } else {
          return true;
        }
      }),
      i18n.language
    );
  }, [dataConfig, i18n.language]);

  const getActionsToolbar = useCallback(() => {
    return translateActions(dataConfig.actionsTopToolbar, i18n.language);
  }, [dataConfig, i18n.language]);

  const getActionsRow = useCallback(() => {
    return translateActions(dataConfig.actionForRow, i18n.language);
  }, [dataConfig, i18n.language]);

  return {
    columns: getColumns(),
    dataTable: {output:data ,metadata: {
      total_elements: 134,
      total_pages: 27
  }},
    dataDetail:data,
    actionsTopToolbar: getActionsToolbar(),
    handleFetchData: () => null,
    actionsForForms: dataConfig.actionsForForms || [],
    actionForRow: getActionsRow(),
  };
}
