import React, { useEffect, useMemo, useState } from "react";
import { Box, Container } from "@mui/material";
import dayjs from "dayjs";
import { useFetchDetail, usePutDetail } from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ColumnInterface } from "@components/Table/types";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalCst from "@components/ModalCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { ColumnsType } from "src/interfaces/Common";

import { useAddEditPhysicalEdition } from "../hooks/useAddEditPhysicalEdition";
import AnimationFadeIn from "@components/AnimationFadeIn";

const EditPhysicalEditions: React.FC = () => {
  const { t } = useTranslation(["physical-editions-page"]);
  const { idEdition, id } = useParams();
  const [editColumns, setEditColumns] = useState<ColumnsType | any>();
  const { columns, dataDetail, actionsForForms, mutate } = useFetchDetail(
    ServicesURL.getEditions + `/physical`,
    idEdition + `?corporateId=${id}` || "",
    PagesID["corporate.physical-editions"]
  );
  const navigate = useNavigate();

  const getInitialOutcome = () => {
    return {
      edition: {
        code: undefined,
        message: t("edit.outcome.edition.messageLoading"),
        title: t("edit.outcome.edition.title"),
      },
      uploadParticipants: {
        code: undefined,
        message: t("edit.outcome.upload-participations.messageLoading"),
        title: t("edit.outcome.upload-participations.title"),
      },
    };
  };

  const { trigger } = usePutDetail(
    ServicesURL.getEditions + `/physical/${idEdition}?corporateId=${id}`
  );

  const {
    onSubmit,
    onDownload,
    outcome,
    openOutcome,
    setOpenOutcome,
    openConfirmStatus,
    setOpenConfirmStatus,
    handleAssociation,
    setOpenAssociation,
    handleDelete,
    getValues,
    openAssociations,
    participationsData,
    siteAssociation,
  } = useAddEditPhysicalEdition(
    "edit",
    trigger,
    getInitialOutcome,
    id,
    idEdition,
    dataDetail,
    mutate
  );

  const inputsException = (col: any, inputForm: any) => {
    if (Object.keys(dataDetail).length > 0) {
      if (
        dataDetail &&
        Object.keys(dataDetail).length > 0 &&
        columns?.length > 0 &&
        dayjs().isAfter(dayjs(dataDetail["publish_date"]))
      ) {
        if (
          ![
            "edition_code",
            "highlight_agenda",
            "faculties",
            "edition_name",
          ].includes(col.accessorKey)
        ) {
          col.disabled = true;
        }
      } else {
        if (
          ![
            "edition_code",
            "highlight_agenda",
            "faculties",
            "edition_name",
          ].includes(col.accessorKey)
        ) {
          col.disabled = false;
        }
        if (siteAssociation && siteAssociation.length === 0) {
          if (["rooms"].includes(col.accessorKey)) {
            col.disabled = true;
          }
        } else {
          if (["rooms"].includes(col.accessorKey)) {
            col.disabled = false;
          }
        }
      }
    }
    return col;
  };

  const fullData = useMemo(() => {
    if (dataDetail && Object.keys(dataDetail).length > 0) {
      return { ...dataDetail, participations: participationsData };
    } else {
      return {};
    }
  }, [dataDetail, participationsData]);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-editions-physical.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {columns && Object.keys(fullData).length > 0 && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={fullData}
              columns={columns}
              onSubmit={onSubmit}
              inputsException={inputsException}
              actionsForForms={
                actionsForForms &&
                actionsForForms.map((el: ColumnInterface) => {
                  if (el.type?.includes("download")) {
                    el.downloadCallback = () =>
                      onDownload("downloadParticipants");
                  }
                  if (el.type?.includes("template")) {
                    el.templateCallback = () =>
                      onDownload("downloadTemplateParticipants");
                  }
                  return el;
                })
              }
              formActionType={["download"]}
              backButton={() => {
                navigate(-1);
              }}
              setOpenAssociation={(accessorKey, open) =>
                setOpenAssociation(accessorKey, open)
              }
              handleDelete={(accessorKey, el) => handleDelete(accessorKey, el)}
              handleAssociation={(accessorKey, value) =>
                handleAssociation(accessorKey, value)
              }
              getValues={(accessorKey) => getValues(accessorKey)}
              association={openAssociations}
            />
          </Box>
        )}
        <ModalConfirmsCst
          open={!!openConfirmStatus}
          title={t("outcome-item", { ns: "modal" })}
          description={t("outcome-item", { ns: "modal" })}
          onCallBackClosed={() => setOpenConfirmStatus(undefined)}
          status={openConfirmStatus}
        />
        <ModalCst
          open={openOutcome}
          setOpen={setOpenOutcome}
          typeOfModal={"outcome"}
          data={{
            outcome: outcome,
            callBackSubmit: () => {
              setOpenOutcome(!openOutcome);
              navigate(`/corporate/${id}/detail/physical-editions`);
            },
          }}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default EditPhysicalEditions;
