import React from "react";
import { Box, Link, Typography, useTheme } from "@mui/material";
import { useNavigate, useMatches, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowCircleLeft } from "@mui/icons-material";

interface PROPS {
  customRedirect?: any;
  goBack?: number;
}

const ButtonBackPage: React.FC<PROPS> = ({ customRedirect, goBack = 1 }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMatches();
  const location = useLocation();
  const { t } = useTranslation(["common"]);
  const findPathIndex = matches.findIndex(
    (el) => el.pathname === location.pathname
  );

  return (
    <Box>
      <Link
        component="button"
        sx={{
          color: "white",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          mb: "24px",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        onClick={() =>
          navigate(
            customRedirect
              ? customRedirect
              : matches[findPathIndex - goBack].pathname
          )
        }
      >
        <ArrowCircleLeft sx={{ fontSize: "32px" }} />
        <Typography sx={{ fontWeight: "600" }}>{t("backButton")}</Typography>
      </Link>
    </Box>
  );
};

export default ButtonBackPage;
