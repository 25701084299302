import { Grid, Container } from "@mui/material";
import BackgroundImage from "@components/BackgroundImageCst";
import CardHomeCst from "@components/CardHomeCst";
import { useTranslation } from "react-i18next";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import { useAuth } from "@context/auth";
import { useNavigaionRoutes } from "@hooks/useNavigation";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";
import { useFilteredBadge } from "../../../../hooks/useFilteredBadge";

const DetailCorporate: React.FC = () => {
  const { t } = useTranslation(["corporate-detail-page", "common"]);
  const { id } = useParams();
  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID.corporate
  );
  const { user } = useAuth();
  const navigation = useNavigaionRoutes();

  // https://yem2kwxkwk.execute-api.eu-central-1.amazonaws.com/test/nonymous-embed?user=${user?.id_user}&corporate=${user?.organization_id}

  useResetFilters();

  let menuItem = [];
  menuItem = navigation
    .find((el) => el.to === "corporate")
    ?.children?.filter((el: { section: string; to: string }) => {
      return (
        user?.permissions?.includes(el.section) &&
        (el.to !== "selfsignup" ||
          (dataDetail &&
            (dataDetail["self_signup"] === "LEARNER" ||
              dataDetail["self_signup"] === "MANAGER")))
      );
    });

  menuItem = useFilteredBadge(
    menuItem,
    dataDetail,
    ["enable_training_needs", "enable_budget"],
    ["usermgmt.corporates.training_needs", "usermgmt.corporates.budget"]
  );

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corporate.jpg"
        position={"absolute"}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${
            dataDetail?.name
              ? `${dataDetail?.name} - ${t("hero-title")}`
              : t("hero-title")
          }`}
          description={t("hero-description")}
        />
        <Grid container spacing={3}>
          {menuItem &&
            menuItem.map(
              (
                {
                  text,
                  section,
                  external,
                  image,
                  to,
                  link,
                  description,
                  buttonLabel,
                  disabled,
                  settingsLink,
                  permissionSettingsLink,
                }: any,
                index: React.Key | null | undefined
              ) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <CardHomeCst
                    image={image}
                    title={text}
                    description={description}
                    secondaryBtnLabel={buttonLabel}
                    to={to ? to : link}
                    externalLink={external}
                    disabled={disabled}
                    settingsLink={
                      user?.permissions.find(
                        (permission) => permission === permissionSettingsLink
                      )
                        ? settingsLink
                        : null
                    }
                  />
                </Grid>
              )
            )}
        </Grid>
      </Container>
    </AnimationFadeIn>
  );
};

export default DetailCorporate;
