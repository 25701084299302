import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
  Box,
  RadioGroup,
  Radio,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

// MockData.ts
export interface Option {
  obsolete: boolean;
  id: number;
  name: string;
  description: string;
  identifier: string;
  corporate_id: number;
  creation_date: string;
  delete_date: string;
  update_date: string;
  created_by: number;
  updated_by: number;
  deleted_by: number;
  deleted_by_name: string;
  created_by_name: string;
  updated_by_name: string;
}

interface SectionFormProps {
  col: {
    accessorKey: string;
    sectionData: {
      label: string;
      groups?: boolean;
      customSelect: { label: string }[];
    };
    role: string; // Assuming role can be 'manager' or 'hr'
  };
  inputForm: any; // Adjust type according to your form structure
  oneChoise?: boolean;
  options?: any;
  setInputForm: React.Dispatch<React.SetStateAction<any>>; // Adjust type according to your form structure
  getOptionValue?: any;
}

const SectionFormNewCampaign: React.FC<SectionFormProps> = ({
  col,
  inputForm,
  setInputForm,
  oneChoise = false,
  options,
  getOptionValue,
}) => {
  const { t } = useTranslation(["training-needs-campaign-page"]);
  const { label, customSelect } = col.sectionData;
  const sectionState = inputForm[col.accessorKey] || [];
  const enabled = sectionState.enabled || oneChoise || false;

  const [selectedOption, setSelectedOption] = useState<string>(
    sectionState.selectedOption ||
      (customSelect.length > 0 ? customSelect[0].label : "")
  );
  const [selectedGroups, setSelectedGroups] = useState<Option | any>(
    sectionState.selectedGroups || []
  );

  useEffect(() => {
    // Ensure one option is always selected at the beginning
    if (!sectionState.selectedOption || !sectionState.selectedGroups) {
      const initialGroups = selectedOption === "Knowledge" ? [] : [];
      setInputForm({
        ...inputForm,
        [col.accessorKey]: {
          ...sectionState,
          selectedOption:
            selectedOption ||
            (customSelect.length > 0 ? customSelect[0].label : ""),
          selectedGroups: initialGroups,
        },
      });
    }
  }, [
    col.accessorKey,
    inputForm,
    sectionState,
    setInputForm,
    selectedOption,
    customSelect,
  ]);

  const handleGroupChange = (index: number, value: any) => {
    const updatedGroups = [...selectedGroups];
    updatedGroups[index] = value; // Ensure only one selection per Select
    setSelectedGroups(updatedGroups);
    getOptionValue && getOptionValue(updatedGroups);
    setInputForm({
      ...inputForm,
      [col.accessorKey]: {
        ...sectionState,
        selectedGroups: updatedGroups,
      },
    });
  };

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    const newGroups = value === "Knowledge" ? [] : [];
    setSelectedGroups(newGroups.map(() => ""));
    setInputForm({
      ...inputForm,
      [col.accessorKey]: {
        ...sectionState,
        selectedOption: value,
        selectedGroups: newGroups.map(() => ""),
      },
    });
  };

  const getOptions = (type: string) => {
    switch (type) {
      case "knowledgeArea":
        return options.slice(1);
      case "capacity":
        return [options[0]];
      default:
        return [];
    }
  };

  const renderGroups = () => {
    const groupType =
      selectedOption === "Knowledge" ? "knowledgeArea" : "capacity";
    const groups = getOptions(groupType);

    return (
      <>
        {selectedOption === "Knowledge" ? (
          groups && groups.length ? (
            groups.map((knowledge: any, index: number) => (
              <Grid
                key={index}
                item
                xs={6}
                sm={6}
                paddingBottom={2}
                spacing={2}
              >
                <FormControl fullWidth>
                  <Select
                    id={knowledge.id}
                    labelId={`${knowledge.name}-groups-label-${knowledge.id}`}
                    value={selectedGroups[index] || ""}
                    onChange={(e) => handleGroupChange(index, e.target.value)}
                    displayEmpty
                    disabled={index > 0 && !selectedGroups[index - 1]} // Disabilita la seconda select se la prima non è selezionata
                  >
                    <MenuItem disabled value="">
                      {index === 0
                        ? t("add.knowledge-type")
                        : t("add.knowledge-domain")}
                    </MenuItem>
                    {knowledge.map((option: Option) => (
                      //@ts-ignore
                      <MenuItem key={option.id} value={option}>
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ))
          ) : (
            <h4>No Options</h4>
          )
        ) : (
          groups.map((group: Option, index: number) => (
            <Grid key={index} item xs={6} sm={6} paddingBottom={2} spacing={2}>
              <FormControl fullWidth>
                <Select
                  id={group.id ? group.id.toString() : "default"}
                  labelId={`${group.name}-groups-label-${group.id}`}
                  value={selectedGroups[index] || ""}
                  onChange={(e) => handleGroupChange(index, e.target.value)}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    {t("add.capabilities")}
                  </MenuItem>
                  {groups[0].map((option: Option | any) => (
                    <MenuItem key={option.id} value={option}>
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))
        )}
      </>
    );
  };

  return (
    <Grid
      key={col.accessorKey}
      item
      xs={12}
      sm={12}
      paddingLeft={2}
      paddingBottom={6}
    >
      <Grid
        item
        xs={12}
        sm={12}
        paddingBottom={2}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" component="h6">
          {label} *
        </Typography>
        {!oneChoise && (
          <FormControlLabel
            control={
              <Switch
                checked={enabled}
                onChange={() => {
                  const newEnabled = !enabled;
                  setInputForm({
                    ...inputForm,
                    [col.accessorKey]: {
                      ...sectionState,
                      enabled: newEnabled,
                      selectedOption: newEnabled ? selectedOption : "",
                      selectedGroups: newEnabled
                        ? selectedGroups
                        : (selectedOption === "Knowledge" ? [] : []).map(
                            () => ""
                          ),
                    },
                  });
                }}
                required
              />
            }
            label={`${t("add.activate-switch")} ${label}`}
          />
        )}
      </Grid>

      <Box display="flex" gap={2}>
        {customSelect &&
          customSelect.map((el, index) => (
            <Grid key={index} container xs={12} sm={6} spacing={2}>
              <Grid item xs={12} sm={12} spacing={2} paddingBottom={2}>
                <Box
                  border={1}
                  borderRadius={0}
                  paddingLeft={2}
                  borderColor="rgba(255, 255, 255, 0.23)"
                >
                  <FormControl component="fieldset" disabled={!enabled}>
                    <RadioGroup
                      aria-label={`${label}-options-all`}
                      name={`${label}-options-all`}
                      value={selectedOption}
                      onChange={(e) => handleOptionChange(e.target.value)}
                    >
                      <FormControlLabel
                        value={el.label}
                        control={<Radio />}
                        label={t(`add.${el.label.toLowerCase()}`)}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          ))}
      </Box>

      <Box display={"flex"} gap={2}>
        <Grid container xs={12} sm={12} spacing={2}>
          {col.sectionData.groups && renderGroups()}
        </Grid>
      </Box>
    </Grid>
  );
};

export default SectionFormNewCampaign;
