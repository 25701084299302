import { ColumnInterface, Rules } from "@components/Table/types";

const VATRegex =
  /^((AT)?(U\d{8})|(BE)?(0\d{9})|(BG)?(\d{9,10})|(CY)?(\d{8}[LX])|(CZ)?(\d{8,10})|(DE)?(\d{9})|(DK)?(\d{8})|(EE)?(\d{9})|(EL|GR)?(\d{9})|(ES)?([\dA-Z]\d{7}[\dA-Z])|(FI)?(\d{8})|(FR)?([\dA-Z]{2}\d{9})|(HU)?(\d{8})|(IE)?(\d{7}[A-Z]{2})|(IT)?(\d{11})|(LT)?(\d{9}|\d{12})|(LU)?(\d{8})|(LV)?(\d{11})|(MT)?(\d{8})|(NL)?(\d{9}(B\d{2}|BO2))|(PL)?(\d{10})|(PT)?(\d{9})|(RO)?(\d{2,10})|(SE)?(\d{12})|(SI)?(\d{8})|(SK)?(\d{10}))$/;
const FiscalCodeRegex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const numberRegex = /^\d+$/;
const phoneNumberRegex = /^(\+)?\d*$/;
const atecoRegex = /[a-zA-Z]\d{20}|^$/;
const URLRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
const urlDomainRegex =
  /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/;
export const corporateDomainRegex =
  /^[a-zA-Z0-9\-]{2,}(\.[a-zA-Z0-9\-]{2,})(\.[a-zA-Z0-9\-]{2,})?(\.[a-zA-Z0-9\-]{2,})?/gm;
const percentageRegex = /^([0-9]|([1-9][0-9])|100)$/;
const stringRegex = /^[a-zA-Z0-9]/;

const isEmpty = (value: any) => {
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    value?.toString().trim() === ""
  );
};

export const isTrueValue = (value: string) => {
  if (typeof value === "string") {
    return value?.toLowerCase?.() === "true";
  } else {
    return value;
  }
};

export const defaultValidation = (
  columns: ColumnInterface[],
  inputForm: any
) => {
  let canBeSubmit = true;
  let showError: any = {};
  let hasValidSections = true;
  columns.forEach((column) => {
    const { rules, required } = column;
    const lengthFail =
      rules && checkLength(inputForm[column.accessorKey], rules);

    const isLearnerFluxCampaign = window.location.pathname.includes(
      "create-learner-campaign"
    );

    if (inputForm.campaign_name) {
      hasValidSections =
        Object.values(inputForm).some((value: any) => value.enabled === true) && // Verifica che almeno una sezione sia abilitata
        Object.values(inputForm)
          .filter((value: any) => value.enabled === true) // Considera solo le sezioni abilitate
          .every((section: any) => {
            const isLearner = section === inputForm.section_learner;
            const isHRorManager =
              section === inputForm.section_hr ||
              section === inputForm.section_manager;

            // Se è la sezione 'section_learner', deve avere solo i gruppi
            if (isLearner) {
              return (
                (section?.selectedGroups &&
                  section?.selectedGroups.length > 0) ||
                section.all_users
              );
            }

            // Se è 'section_hr' o 'section_manager', deve avere sia gruppi che livelli
            if (isHRorManager) {
              if (isLearnerFluxCampaign) {
                return (
                  (section?.selectedGroups &&
                    section?.selectedGroups.length > 0) ||
                  section.all_users
                );
              }
              return (
                section.selectedLevels &&
                section.selectedLevels.length > 0 &&
                ((section?.selectedGroups &&
                  section?.selectedGroups.length > 0) ||
                  section.all_users)
              );
            }

            return false;
          });
    }

    const emailFail = rules && checkEmail(inputForm[column.accessorKey], rules);
    const fiscalCodeFail =
      rules && checkFiscalCode(inputForm[column.accessorKey], rules);
    const phoneNumberFail =
      rules && checkPhoneNumber(inputForm[column.accessorKey], rules);
    const atecoFail = rules && checkAteco(inputForm[column.accessorKey], rules);
    const numberFail =
      rules && checkNumber(inputForm[column.accessorKey], rules);
    const URLFail = rules && checkURL(inputForm[column.accessorKey], rules);
    const urlDomainFail =
      rules &&
      Object.keys(rules).includes("urlDomain") &&
      checkInitiativeDomain(inputForm[column.accessorKey], rules);
    const percentageFail =
      rules && checkPergentage(inputForm[column.accessorKey], rules);
    const stringFail =
      rules && checkString(inputForm[column.accessorKey], rules);
    let corporateDomainFail: string | boolean | undefined = false;
    if (
      rules &&
      Array.isArray(inputForm[column.accessorKey]) &&
      inputForm[column.accessorKey].length
    ) {
      inputForm[column.accessorKey].forEach((el: string) => {
        if (!corporateDomainFail && checkCorporateDomain(el, rules)) {
          corporateDomainFail = !!checkCorporateDomain(el, rules);
        }
      });
    } else {
      corporateDomainFail =
        rules && checkCorporateDomain(inputForm[column.accessorKey], rules);
    }
    const specialCharactersFail =
      rules &&
      Object.keys(rules).includes("notAllowed") &&
      checkNotAllowedSpecial(inputForm[column.accessorKey], rules);

    let numberValueFail;
    if (column.inpuType === "number") {
      numberValueFail =
        rules && checkNumberValue(inputForm[column.accessorKey], rules);
    }

    if (column?.children?.length) {
      const { canBeSubmit: canBeSubmitNested, showError: showErrorNested } =
        defaultValidation(column.children, inputForm);
      if (!canBeSubmitNested) canBeSubmit = false;
      if (showErrorNested) showError = { ...showError, ...showErrorNested };
    }

    //TODO: we need refactor this function
    if (column?.form_box?.length && inputForm[column.accessorKey] === true) {
      const { canBeSubmit: canBeSubmitNested, showError: showErrorNested } =
        defaultValidation(column.form_box, inputForm);
      if (!canBeSubmitNested) canBeSubmit = false;
      if (showErrorNested) showError = { ...showError, ...showErrorNested };
    }

    if (required && inputForm && isEmpty(inputForm[column.accessorKey])) {
      canBeSubmit = false;
      showError[column.accessorKey] = "required";
    }

    if (["text", "number"].includes(column.type || "") && lengthFail) {
      if (
        lengthFail &&
        inputForm[column.accessorKey].toString()?.length !== 0
      ) {
        canBeSubmit = false;
        showError[column.accessorKey] = lengthFail;
      }
    }

    if (emailFail) {
      if (required || !isEmpty(inputForm[column.accessorKey])) {
        canBeSubmit = false;
        showError[column.accessorKey] = emailFail || lengthFail;
      }
    }
    if (fiscalCodeFail) {
      if (required || !isEmpty(inputForm[column.accessorKey])) {
        canBeSubmit = false;
        showError[column.accessorKey] = fiscalCodeFail || lengthFail;
      }
    }
    if (phoneNumberFail) {
      if (required || !isEmpty(inputForm[column.accessorKey])) {
        canBeSubmit = false;
        showError[column.accessorKey] = phoneNumberFail || lengthFail;
      }
    }
    if (numberValueFail) {
      if (required || !isEmpty(inputForm[column.accessorKey])) {
        canBeSubmit = false;
        showError[column.accessorKey] = numberValueFail || lengthFail;
      }
    }
    if (atecoFail) {
      if (required || !isEmpty(inputForm[column.accessorKey])) {
        canBeSubmit = false;
        showError[column.accessorKey] = atecoFail || lengthFail;
      }
    }
    if (numberFail) {
      if (!isEmpty(inputForm[column.accessorKey])) {
        canBeSubmit = false;
        showError[column.accessorKey] = numberFail || lengthFail;
      }
    }
    if (URLFail) {
      if (!isEmpty(inputForm[column.accessorKey])) {
        canBeSubmit = false;
        showError[column.accessorKey] = URLFail || lengthFail;
      }
    }
    if (urlDomainFail) {
      if (!isEmpty(inputForm[column.accessorKey])) {
        canBeSubmit = false;
        showError[column.accessorKey] = urlDomainFail || lengthFail;
      }
    }
    if (percentageFail) {
      if (!isEmpty(inputForm[column.accessorKey])) {
        canBeSubmit = false;
        showError[column.accessorKey] = percentageFail || lengthFail;
      }
    }
    if (stringFail) {
      if (!isEmpty(inputForm[column.accessorKey])) {
        canBeSubmit = false;
        showError[column.accessorKey] = stringFail || lengthFail;
      }
    }
    if (corporateDomainFail) {
      if (!isEmpty(inputForm[column.accessorKey])) {
        canBeSubmit = false;
        showError[column.accessorKey] = corporateDomainFail || lengthFail;
      }
    }
    if (specialCharactersFail) {
      if (required || !isEmpty(inputForm[column.accessorKey])) {
        canBeSubmit = false;
        showError[column.accessorKey] = specialCharactersFail || lengthFail;
      }
    }
    if (
      inputForm.campaign_name &&
      !hasValidSections &&
      columns.find(
        (el) =>
          el.accessorKey === "section_hr" ||
          el.accessorKey === "section_learner" ||
          el.accessorKey === "section_manager"
      )
    ) {
      canBeSubmit = false;
    }
  });
  return { canBeSubmit, showError };
};

export const checkLength = (
  input: string | number,
  rules: Rules
): { value: number | boolean; msg: string } | boolean => {
  const inpLength = input?.toString()?.length;
  const { min, max } = rules;
  if (min && max) {
    if (inpLength < (min.value as number)) {
      return min;
    } else if (inpLength > (max.value as number)) {
      return max;
    }
  } else if (max && !min) {
    if (inpLength > (max.value as number)) {
      return max;
    }
  }
  return false;
};

export const checkNotAllowedSpecial = (value: string, rules: any) => {
  const { notAllowed } = rules;
  if (
    value &&
    notAllowed &&
    value.split("").some((letter: any) => notAllowed.value.includes(letter))
  ) {
    return notAllowed.msg;
  }
  return false;
};

export const checkEmail = (value: string, rules: Rules) => {
  const { email } = rules;
  if (email && !emailRegex.test(value)) {
    return email.msg;
  }
  return false;
};

export const checkURL = (value: string, rules: Rules) => {
  const { URL } = rules;
  if (URL && !URLRegex.test(value)) {
    return URL.msg;
  }
  return false;
};

export const checkInitiativeDomain = (value: string, rules: Rules) => {
  const { urlDomain, string } = rules;

  const hasPoint = value?.includes(".");

  if (!!hasPoint) {
    if (urlDomain && !urlDomainRegex.test(value)) {
      return urlDomain.msg;
    }
  } else {
    if (string && !stringRegex.test(value)) {
      return string.msg;
    }
  }

  return false;
};

export const checkFiscalCode = (value: string, rules: Rules) => {
  const { fiscalCode } = rules;
  if (fiscalCode && !VATRegex.test(value) && !FiscalCodeRegex.test(value)) {
    return fiscalCode.msg;
  }
  return false;
};

export const checkNumber = (value: string, rules: Rules) => {
  const { number } = rules;
  if (number && !numberRegex.test(value)) {
    return number.msg;
  }
  return false;
};

export const checkNumberValue = (value: string, rules: any) => {
  const { minValue, maxValue } = rules;
  if (minValue) {
    if (minValue.value > Number(value)) {
      return minValue.msg;
    }
  }
  if (maxValue) {
    if (maxValue.value < Number(value)) {
      return maxValue.msg;
    }
  }
  return false;
};

export const checkPergentage = (value: string, rules: Rules) => {
  const { percentage } = rules;
  if (percentage && !percentageRegex.test(value)) {
    return percentage.msg;
  }
  return false;
};

export const checkString = (value: string, rules: Rules) => {
  const { string } = rules;
  if (string && !stringRegex.test(value)) {
    return string.msg;
  }
  return false;
};

export const checkCorporateDomain = (
  value: string,
  rules: Rules
): string | boolean => {
  const { domainCorporate } = rules;

  if (domainCorporate && value && !value.match(corporateDomainRegex)) {
    return domainCorporate.msg;
  }
  return false;
};

export const checkPhoneNumber = (value: string, rules: Rules) => {
  const { phoneNumber } = rules;
  if (phoneNumber && !phoneNumberRegex.test(value)) {
    return phoneNumber.msg;
  }
  return false;
};

export const checkAteco = (value: string, rules: Rules) => {
  const { ateco } = rules;
  if (ateco && !atecoRegex.test(value)) {
    return ateco.msg;
  }
  return false;
};
