export const UseIconButtonStyles = (
  icon: string,
  status?: string,
  disabled?: boolean
) => {
  const getIconColor = () => {
    if (disabled) return "#696969";
    switch (icon) {
      case "delete":
        switch (status?.toLowerCase()) {
          case "delete":
            return "#F88BA1";
          default:
            return "#ffffff";
        }

      case "AssignmentTurnedIn":
        switch (status?.toLowerCase()) {
          case "completed":
            return "#A6FF03";
          case "pending":
            return "#0000FF";
          default:
            return "#ffffff";
        }

      default:
        return "#ffffff";
    }
  };

  return {
    ".MuiSvgIcon-root": {
      fill: `${getIconColor()} !important`,
    },
  };
};
