import ProtectedRoute from "@components/ProtectedRoute";
import { LOType, PagesID } from "@utils/utilsConfigurations";
import { useIsInclude } from "src/router/RoutersCst";

import PhysicalEditions from "@pages/Contents/Corporate/EditionsPhysical";
import EditPhysicalEditions from "@pages/Contents/Corporate/EditionsPhysical/EditEdition";
import AddPhysicalEditions from "@pages/Contents/Corporate/EditionsPhysical/AddEdition";

import VirtualEditions from "@pages/Contents/Corporate/EditionsVirtual";
import EditVirtualEditions from "@pages/Contents/Corporate/EditionsVirtual/EditEdition";
import AddVirtualEditions from "@pages/Contents/Corporate/EditionsVirtual/AddEdition";

import AssociationLODomainUsers from "@pages/Contents/Corporate/Catalog/sharedComponents/AssociationDomainUsers";
import AssociationLOGroup from "@pages/Contents/Corporate/Catalog/sharedComponents/AssociationGroups";
import AssociationLOUser from "@pages/Contents/Corporate/Catalog/sharedComponents/AssociationEditionUser";
import LOSubscribedUsers from "@pages/Contents/Corporate/Catalog/sharedComponents/SubscribedUsers";
import Enrollments from "@pages/Contents/Corporate/Catalog/sharedComponents/EnrollmentsForm";
import UsersWating from "@pages/Contents/Corporate/Catalog/sharedComponents/UsersWating";
import EditionTOJ from "@pages/Contents/Corporate/EditionTOJ";
import EditEditionTOJ from "@pages/Contents/Corporate/EditionTOJ/Edit";
import AddEditionTOJ from "@pages/Contents/Corporate/EditionTOJ/Add";
import ManageTOJ from "@pages/Contents/Corporate/EditionTOJ/ManageTOJ";
import UserSubscribedTOJ from "@pages/Contents/Corporate/EditionTOJ/UserSubscribed";
import ManageSubscribedTOJ from "@pages/Contents/Corporate/EditionTOJ/UserSubscribed/ManageSubscribedTOJ/ManageSubscribedTOJ";
import EditionTOJEnrollUsers from "@pages/Contents/Corporate/EditionTOJ/Enrollment/EditionTOJEnrollUsers/EditionTOJEnrollUsers";

const useCorporateEditions = () => {
  const { isInclude } = useIsInclude();

  return [
    {
      path: "physical-editions",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&corporate.physical-editions")}
            >
              <PhysicalEditions />
            </ProtectedRoute>
          ),
        },
        {
          path: "add",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&editions.physical.create")}
            >
              <AddPhysicalEditions />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit/:idEdition",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&editions.physical.update")}
            >
              <EditPhysicalEditions />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idEdition/:idEditionLOActivity/domain-users-association",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&learning.enrollment.read")}
            >
              <AssociationLODomainUsers type={LOType.ACTIVITIES} />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idEdition/:idEditionLOActivity/group-association",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&learning.enrollment.read")}
            >
              <AssociationLOGroup type={LOType.ACTIVITIES} />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idEdition/:idEditionLOActivity/users-association",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&learning.enrollment.read")}
            >
              <AssociationLOUser type={LOType.ACTIVITIES} />
            </ProtectedRoute>
          ),
        },
        // {
        //   path: ":idEdition/:idEditionLOActivity/users-association/:userId/enrollment",
        //   element: (
        //     <ProtectedRoute
        //       isAllowed={isInclude(
        //         "corporate&&learning.enrollment.read"
        //       )}
        //     >
        //       <Enrollments/>
        //     </ProtectedRoute>
        //   ),
        // }
      ],
    },
    {
      path: "physical-editions/:idEdition/subscribed",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("catalog&&learning.enrollment.read")}
            >
              <LOSubscribedUsers
                page={PagesID["corporate.physical-editions.subscribed"]}
              />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "physical-editions/:idEdition/:idLO/users-waiting",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("catalog&&learning.enrollment.read")}
            >
              <UsersWating />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "virtual-editions",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&corporate.virtual-editions")}
            >
              <VirtualEditions />
            </ProtectedRoute>
          ),
        },
        {
          path: "add",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&editions.virtual.create")}
            >
              <AddVirtualEditions />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit/:idEdition",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&editions.virtual.update")}
            >
              <EditVirtualEditions />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idEdition/:idEditionLOActivity/domain-users-association",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&learning.enrollment.read")}
            >
              <AssociationLODomainUsers type={LOType.ACTIVITIES} />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idEdition/:idEditionLOActivity/group-association",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&learning.enrollment.read")}
            >
              <AssociationLOGroup type={LOType.ACTIVITIES} />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idEdition/:idEditionLOActivity/users-association",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&learning.enrollment.read")}
            >
              <AssociationLOUser type={LOType.ACTIVITIES} />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idEdition/:idEditionLOActivity/users-association/:userId/enrollment",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&learning.enrollment.read")}
            >
              <Enrollments />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "virtual-editions/:idEdition/subscribed",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("catalog&&learning.enrollment.read")}
            >
              <LOSubscribedUsers
                page={PagesID["corporate.virtual-editions.subscribed"]}
              />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "virtual-editions/:idEdition/:idLO/users-waiting",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("catalog&&learning.enrollment.read")}
            >
              <UsersWating />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "toj-edition",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute isAllowed={isInclude("corporate")}>
              <EditionTOJ />
            </ProtectedRoute>
          ),
        },
        {
          path: "add",
          element: (
            <ProtectedRoute isAllowed={isInclude("corporate")}>
              <AddEditionTOJ />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit/:idEdition",
          element: (
            <ProtectedRoute isAllowed={isInclude("corporate")}>
              <EditEditionTOJ />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idEdition/manage-toj-edition",
          element: (
            <ProtectedRoute isAllowed={isInclude("corporate")}>
              <ManageTOJ />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idEdition/:idEditionLOActivity/users-association",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&learning.enrollment.read")}
            >
              <AssociationLOUser type={LOType.ACTIVITIES} isToj={true} />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idEdition/subscribed",
          children: [
            {
              index: true,
              path: "",
              element: (
                <ProtectedRoute isAllowed={isInclude("corporate")}>
                  <UserSubscribedTOJ />
                </ProtectedRoute>
              ),
            },
            {
              path: ":idUser/:idLO/:idEdition/manage-user-toj-edition",
              element: (
                <ProtectedRoute isAllowed={isInclude("corporate")}>
                  <ManageSubscribedTOJ />
                </ProtectedRoute>
              ),
            },
          ],
        },
      ],
    },
  ];
};

export default useCorporateEditions;
