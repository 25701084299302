import * as React from "react";
import {
  RequestProps,
  SkillBookCreate,
  SkillBookGlobalStateInterface,
} from "@pages/Contents/BadgeAndCertificates/types";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { MethodType } from "../../../../services/type";

export const getOptionsYears = () => {
  const startYear = 2010;
  const currentYear = new Date().getFullYear();
  const yearsArray = [];

  for (let year = startYear; year <= currentYear; year++) {
    yearsArray.push({ label: year, value: year });
  }

  return yearsArray;
};

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export async function approveRequest(
  approve: string,
  data: RequestProps[],
  trigger: any
) {
  if (!data) return;

  let output;
  const ids = data.map((item) => item.id);
  const payload = {
    ids: ids,
    status: approve,
  };

  if (payload) {
    const response = await trigger(payload);
    output = {
      message: "success",
      response: response,
    };
    return output;
  }
  return null;
}

export async function submitCreation(
  state: SkillBookGlobalStateInterface,
  corporateId: string | number
) {
  let output;
  let knowledgeStructure;
  let capability;

  if (state.area_domain) {
    knowledgeStructure = state.area_domain.map((area: any) => ({
      id: area.knowledge_area.value,
      label: area.knowledge_area.label,
      domain: area.knowledge_domain
        ? [
            {
              id: area.knowledge_domain.value as string,
              label: area.knowledge_domain.label,
            },
          ]
        : [],
    }));
  }

  if (state.capability) {
    capability = state.capability.map((e: any) => ({
      id: Number(e.value),
      label: e.label,
    }));
  }

  const data: SkillBookCreate = {
    corporateId: corporateId,
    uploadByAdmin: true,
    learnerId: state.user_id,
    title: state.title,
    duration: state.duration,
    description: state.description,
    courseStartDate: state.start_date,
    courseEndDate: state.end_date,
    link: state.link,
    certificateStartValidityDate: state.validation_start_date,
    certificateEndValidityDate: state.validation_end_date,
    code: state.code,
    notes: state.notes,
    area: knowledgeStructure,
    trainingType: state.courseType,
    capability: capability,
    sourceId: state.source_id,
    source: state.source,
  };

  let formData = new FormData();
  formData.append(
    "certificate",
    !!state.certificate ? state.certificate : null
  );
  formData.append("data", JSON.stringify(data));

  if (data) {
    const response = await uploadFile(
      `${ServicesURL.postSkillBook}`,
      formData,
      MethodType.POST,
      "multipart/form-data"
    );
    output = {
      message: "success",
      response: response,
    };
    return output;
  }
  return null;
}

export const mapOptionsToColumns = (
  columns: any[],
  optionsMap: {
    [key: string]: any;
  }
) => {
  return columns.map((column) => {
    const key = column.optionsKey;
    if (optionsMap[column.optionsKey]) {
      return {
        ...column,
        options: optionsMap[key].map((item: any) => ({
          label: item.name || item.label,
          value: item.id || item.value,
        })),
        loading: optionsMap[key].loading,
        error: optionsMap[key].error,
      };
    }
    return column;
  });
};

export const mapOptionsToData = (
  data: any[],
  optionsMap: {
    [key: string]: any;
  }
) => {
  data.map((e, index) => {
    if (e.typeId) {
      data[index]["courseType"] = optionsMap.getCourseTypes.find(
        (item: any) => item.value === e.typeId
      );
    }
    if (e.capability.length > 0 && e.capability[0]?.label) {
      const capabilities = optionsMap.getCapabilities.filter(
        (capability: any) =>
          e.capability.some((item: any) => item.id === capability.id)
      );
      data[index]["capability"] = capabilities.map((item: any) => item.name);
    }
    if (e.area.length > 0 && e.area[0].domain) {
      data[index]["domains"] = e.area.flatMap((area: any) =>
        area.domain.map((domain: any) => domain.label)
      );
      data[index]["area"] = e.area.map((area: any) => area.label);
    }
  });
  return data;
};

export const getBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const handleFileChange = async (
  event: React.ChangeEvent<HTMLInputElement>,
  accessorKey: string,
  dispatch: any,
  maxFileSize: number,
  minFileSize: number
) => {
  //UPLOAD FILE VALUE ON STATE
  if (event.target.files) {
    try {
      const file = event.target.files[0];

      // Check file type
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        console.error(
          "Invalid file type. Only PDF, JPG, JPEG, and PNG are allowed."
        );
        dispatch({
          type: "SET_INPUT_VALUES",
          payload: {
            key: "error_file",
            value: "file_type_error",
          },
        });
        return;
      }

      // Check file size
      if (file.size < minFileSize || file.size > maxFileSize) {
        console.error(
          `File size must be between ${minFileSize} bytes and ${maxFileSize} bytes.`
        );
        dispatch({
          type: "SET_INPUT_VALUES",
          payload: {
            key: "error_file",
            value: "file_size_error",
          },
        });
        return;
      }

      // Convert file to base64
      const base64 = await getBase64(file);

      dispatch({
        type: "SET_INPUT_VALUES",
        payload: {
          key: "error_file",
          value: null,
        },
      });
      dispatch({
        type: "SET_INPUT_VALUES",
        payload: {
          key: accessorKey,
          value: file,
        },
      });
      dispatch({
        type: "SET_INPUT_VALUES",
        payload: {
          key: "file_name",
          value: file.name,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const bytesToMB = (bytes: number) => {
  return (bytes / (1024 * 1024)).toFixed(2);
};
