import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { ActionsType } from "src/interfaces/Common";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ButtonBackPage from "@components/ButtonBackPage";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useFetchMock } from "src/mock_data/useMockData";
import { useFetchTable } from "@hooks/useFetchDataTable";

const GroupsDynamic: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message?: string;
  }>();
  const [deletedId, setDeleteId] = useState<any>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.dynamicGroups.replace(":idCorporate", `${id ? id : 1}`)}`,
    PagesID["corporate.groups-dynamic"]
  );

  const { t } = useTranslation(["groups-dynamic-page", "modal"]);

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID.corporate
  );

  // const { trigger: triggerDelete } = useSWRMutation(
  //   deletedId ? `${ServicesURL.staticGroups}/${deletedId}?id_corporate=${id}` : null,
  //   (url, params) => {
  //     return fetcher<any>(url, MethodType.DELETE, params.arg);
  //   }
  // );

  const actionsRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "link": {
          if (action.key === "learning-objects") {
            return {
              ...action,
              callBack: (row: any) =>
                navigate(`${row.id}/learning-objects`, {
                  state: { row: { ...row, groupType: "DYNAMIC" } },
                }),
            };
          } else if (action.key === "enrollment") {
            return {
              ...action,
              callBack: (row: any) =>
                navigate(`${row.id}/enrollment`, {
                  state: { row: { ...row, groupType: "DYNAMIC" } },
                }),
            };
          }
          {
            return { ...action };
          }
        }
        case "delete": {
          return {
            ...action,
            callBack: (id: string) => {
              setDeleteId(id);
              setOpen(true);
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const handleDelete = React.useCallback(async () => {
    // setOpenToast({ s: StatusEnum.LOADING });
    // let deleteResponse = undefined;
    // try {
    //   deleteResponse = await triggerDelete();
    // } catch (error) {
    //   setOpenToast(undefined);
    // }
    // if (deleteResponse) {
    //   await mutate(ServicesURL.staticGroups + `/static?id_corporate=${id}`);
    //   setOpenToast({ s: StatusEnum.OK });
    // }
  }, [deletedId]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-groups.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.name || ""} - ${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id="groups"
            data={dataTable?.output || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            configurationActionForRow={actionsRow}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!open}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpen(false);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default GroupsDynamic;
