// Import
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  InputCst,
  SelectCst,
  AutocompleteCst,
  MultiSelectCst,
  RadioCst,
  DataPickerCst,
  SingleCheckboxCst,
  InputAssociationChipsCst,
} from "@components/InputCst";
import { ColumnInterface } from "@components/Table/types";
import DownloaderUploaderCst from "@components/DownloaderUploaderCst";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { defaultValidation } from "@utils/utilsValidators";
import ButtonCst from "@components/ButtonCst";
import TooltipInput from "@components/InputCst/TooltipInput";
import { useNavigate } from "react-router-dom";
import AssociationLibraryCst from "@components/AssociationLibraryCst";
import { MediaType } from "src/interfaces/Media";
import BackButtonWarning from "@components/BackButtonWarning";
import ModalCst from "@components/ModalCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import {
  CorporateForm,
  CorporateFormStepFieldLabel,
  emptyCorporateForm,
} from "@pages/Contents/Corporate/SelfSignup/Form/types";
import { useFetchDataDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { Options } from "@components/InputCst/SelectCst/types";
import ClearIcon from "@mui/icons-material/Clear";

export interface InitiativeLanguages {
  id: string;
  primary_language: {
    id: string;
    label: string;
  };
  secondary_languages: {
    id: string;
    label: string;
  }[];
}

// Interface
interface InitiativeFormCstInterface {
  edit?: boolean;
  row?: any;
  columns: ColumnInterface[];
  actionsForForms?: ColumnInterface[];
  additionalColumns?: any;
  onSubmit: (
    initiativePayload: any,
    smartConfigurationPayload: any,
    fileBrandingAsset: any,
    fileNotificationsAsset: any,
    fileTemplateAsset: any,
    fileUploaded: { [x: string]: any }
  ) => void;
  formActionType?: string[];
  dragDrop?: boolean;
  section?: string;
  sectionTooltip?: string;
  sectionTooltipTitle?: string;
  backButton?: () => any;
  initiativeId?: string | number;
  idCorporate?: string | number;
}

// eslint-disable-next-line no-empty-pattern
const InitiativeFormCst: React.FC<InitiativeFormCstInterface> = ({
  edit = false,
  row,
  columns,
  additionalColumns,
  actionsForForms = [],
  dragDrop,
  onSubmit,
  sectionTooltip,
  backButton,
  initiativeId,
  idCorporate,
}) => {
  const theme = useTheme();
  const { t } = useTranslation(["form"]);
  const { t: tInitiativePage } = useTranslation(["initiative-page"]);
  const navigate = useNavigate();

  const [inputForm, setInputForm] = React.useState<any>({});
  const [errorInputForm, setErrorInputForm] = React.useState<any>({});
  const [fileUploaded, setFileUploaded] = React.useState<any>(null);
  const [weigthSum, setWeigthSum] = React.useState<any>(100);

  const [openAssociationContract, setOpenAssociationContract] = useState(false);
  const [contractAssociation, setContractAssociation] = useState<any[]>([]);
  const [isBlocked, setIsBlocked] = useState(false);

  const [isOpenMaintenancePage, setIsOpenMaintenancePage] =
    useState<boolean>(false);

  const [languages, setLanguages] = useState<InitiativeLanguages>();

  const { dataDetail: languagesData } = useFetchDataDetail(
    `${ServicesURL.corporateLanguages.replace(
      ":corporateId",
      idCorporate!.toString()
    )}`
  );

  useEffect(() => {
    if (languagesData && Object.keys(languagesData).length > 0) {
      const languages = languagesData as InitiativeLanguages;
      setLanguages(languages);
    }
  }, [languagesData]);

  const availableLanguages = useMemo(() => {
    if (
      !languages ||
      !languages.primary_language ||
      !languages.secondary_languages
    )
      return [];

    let list: Options[] = [];

    if (languages.primary_language)
      list = [
        ...list,
        {
          label: languages.primary_language.label,
          value: languages.primary_language.id,
        },
      ];

    if (languages.secondary_languages)
      list = [
        ...list,
        ...languages.secondary_languages.map((lang) => ({
          label: lang.label,
          value: lang.id,
        })),
      ];

    return list;
  }, [languages]);

  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "contract_id":
        setOpenAssociationContract(value);
        break;
    }
  };

  const getValues = (accessorKey: string) => {
    switch (accessorKey) {
      case "contract_id":
        return contractAssociation;
    }
  };

  const handleDelete = (accessorKey: string, e: any) => {
    switch (accessorKey) {
      case "contract_id":
        setContractAssociation(
          contractAssociation.filter((element: any) => element.id !== e.id)
        );
        break;
    }
  };

  useEffect(() => {
    let sumWeightTemp = 0;
    if (row) {
      Object.keys(row).forEach((key: any) => {
        if (
          [
            "knowledgeDomainHRWeight",
            "knowledgeDomainManagerWeight",
            "skillsHRWeight",
            "skillsUserWeight",
            "knowledgeDomainUserWeight",
          ].includes(key)
        ) {
          sumWeightTemp = sumWeightTemp + +row[key];
        }
      });
    }
    setWeigthSum(sumWeightTemp);
  }, [row]);

  const resetInputForm = () => {
    let initialState = {};
    setInputForm(initialState);
    setIsBlocked(false);
  };

  useEffect(() => {
    if (
      row &&
      Object.keys(row).length > 0 &&
      columns?.length > 0 &&
      Object.keys(inputForm).length === 0
    ) {
      let initialState = {};
      let totalColumns = columns?.concat(additionalColumns || []);
      totalColumns?.forEach(({ accessorKey }) => {
        if (accessorKey === "contract_id") {
          if (!row[accessorKey]) return;
          setContractAssociation([row[accessorKey]]);
        } else {
          initialState = {
            ...initialState,
            [accessorKey]: row[accessorKey],
          };
        }
      });
      setInputForm(initialState);
    }
  }, [additionalColumns, row]);

  useEffect(() => {
    if (inputForm["leaderboard"] === false) {
      setInputForm({ ...inputForm, leaderboardAnonymous: false });
    }
  }, [inputForm["leaderboard"]]);

  //TODO: refactor validation v.1
  const onConfirm = () => {
    console.log(inputForm);

    let totalColumns = columns?.concat(additionalColumns || []);

    const { canBeSubmit, showError } = defaultValidation(
      totalColumns,
      inputForm
    );

    let errors = { ...showError };

    if (weigthSum !== 100) {
      errors = { ...errors, weight: "percentage-weight" };
    }

    Object.keys(inputForm).forEach((element: any) => {
      if (
        [
          "knowledgeDomainHRWeight",
          "knowledgeDomainManagerWeight",
          "skillsHRWeight",
          "skillsUserWeight",
          "knowledgeDomainUserWeight",
        ].includes(element)
      ) {
        if ([undefined, null, ""].includes(inputForm[element])) {
          errors = { ...errors, [element]: "percentage-weight-required" };
        }
      }
    });

    setErrorInputForm(errors);

    if (canBeSubmit && Object.keys(errors).length === 0) {
      // const idConfig = row && +row.idConfiguration;
      let initiativeObject = {
        name: inputForm["name"] || "",
        description: inputForm["description"] || "",
        area: inputForm["area"] || "",
        start_date:
          (inputForm["start_date"] &&
            new Date(inputForm["start_date"]).getTime()) ||
          0,
        end_date:
          (inputForm["end_date"] &&
            new Date(inputForm["end_date"]).getTime()) ||
          0,
        contract_id: contractAssociation[0]?.id || null,
        primary_language: inputForm["primary_language"] ?? null,
        secondary_languages: inputForm["secondary_languages"]
          ? inputForm["secondary_languages"].map(
              (lang: Record<string, any>) => lang.value
            )
          : [],
      };
      let smartConfigurationObject = {
        urlDomain: inputForm["urlDomain"] || "",
        urlCorporateDomain: inputForm["urlCorporateDomain"] || "",
        widgetCalendar: inputForm["widgetCalendar"] || false,
        accredible: inputForm["accredible"] || false,
        freshDesk: inputForm["freshDesk"] || false,
        idWidgetFreshdesk: Number(inputForm["idWidgetFreshdesk"]) || null,
        xapiTrackerType: inputForm["xapiTrackerType"] || "I",
        visExternalCertificate:
          inputForm["visExternalCertificate"] === "admin_learner",
        webDesktop: true, //default true inputForm["webDesktop"]
        webMobile: false, // removed inputForm["webMobile"]
        appNativa: false, // removed inputForm["appNativa"]
        api: false, //removed inputForm["api"]
        widgetContinueLearning: inputForm["widgetContinueLearning"] || false,
        widgetBadge: inputForm["widgetBadge"] || false,
        leaderboard: inputForm["leaderboard"] || false,
        sliderTopContentExplore: inputForm["sliderTopContentExplore"] || false,
        trainingMandatory: inputForm["trainingMandatory"] || false,
        visForyou: inputForm["visForyou"] || false,
        funcCommunity: inputForm["funcCommunity"] || false,
        communityDiscussion: inputForm["communityDiscussion"] || false,
        communityFreePost: inputForm["communityFreePost"] || false,
        communityShareContent: inputForm["communityShareContent"] || false,
        smartLearning: inputForm["smartLearning"] || false,
        visCarBestRating: inputForm["visCarBestRating"] || false,
        visCarTrainingByTrade: inputForm["visCarTrainingByTrade"] || false,
        visCarSuggested: inputForm["visCarSuggested"] || false,
        visCarCategory: inputForm["visCarCategory"] || false,
        visCarTrainingTransversal:
          inputForm["visCarTrainingTransversal"] || false,
        funcRating: inputForm["funcRating"] || false,
        ratingMax: Number(inputForm["ratingMax"]),
        funcFavourites: inputForm["funcFavourites"] || false,
        carConteSpeciDurata: inputForm["carConteSpeciDurata"] || false,
        lrsEsterno: false, // removed inputForm["lrsEsterno"]
        favourites: inputForm["funcFavourites"] || false,
        leaderboardAnonymous: inputForm["leaderboardAnonymous"] || false,
        widgetNews: inputForm["widgetNews"] || false,
        linkFeedRSS: inputForm["linkFeedRSS"] || null,
        idCorporate:
          (idCorporate && +idCorporate) || (row && +row.idCorporate) || "",
        initiativeId:
          (initiativeId && +initiativeId) || (row && +row.initiativeId) || 0,
        isDigitedDomain: (row && row.isDigitedDomain) || false, // field? -> \"^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\\.[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\\.[a-zA-Z]{2,}$\
        urlLogoBlack: (row && row.urlLogoBlack) || "urlLogoBlack12", //mock
        urlLogoRgb: (row && row.urlLogoRgb) || "urlLogoRgb12", //mock
        urlLogoWhite: (row && row.urlLogoWhite) || "urlLogoWhite12", //mock
        chatbot: inputForm["chatbot"] || false,
        totalForYouSlots: inputForm["totalForYouSlots"] || null,
        aiSuggestedAltSlots: inputForm["aiSuggestedAltSlots"] || null,
        mandatoryAltSlots: inputForm["mandatoryAltSlots"] || null,
        hrSuggestedAltSlots: inputForm["hrSuggestedAltSlots"] || null,
        managerSuggestedAltSlots: inputForm["managerSuggestedAltSlots"] || null,
        knowledgeDomainHRWeight: inputForm["knowledgeDomainHRWeight"] || null,
        knowledgeDomainManagerWeight:
          inputForm["knowledgeDomainManagerWeight"] || null,
        skillsHRWeight: inputForm["skillsHRWeight"] || null,
        knowledgeDomainUserWeight:
          inputForm["knowledgeDomainUserWeight"] || null,
        skillsUserWeight: inputForm["skillsUserWeight"] || null,
        showMaintenancePage: inputForm["showMaintenancePage"] || false,
      };

      onSubmit(
        initiativeObject,
        smartConfigurationObject,
        fileUploaded && fileUploaded["uploadBrandIdentity"],
        fileUploaded && fileUploaded["uploadNotification"],
        fileUploaded && fileUploaded["uploadTemplate"],
        fileUploaded
      );
      setIsBlocked(false);
    }
  };

  const getErrMsg = (accessorKey: string): string => {
    return errorInputForm[accessorKey]?.msg || errorInputForm[accessorKey];
  };

  const minLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const maxLength = (accessorKey: string): number => {
    return errorInputForm[accessorKey]?.value;
  };

  const getAdditionalInputField = useMemo(() => {
    return additionalColumns?.map((col: any) => {
      if (
        ![
          "knowledgeDomainHRWeight",
          "knowledgeDomainManagerWeight",
          "skillsHRWeight",
          "skillsUserWeight",
          "knowledgeDomainUserWeight",
        ].includes(col.accessorKey)
      ) {
        if (col.accessorKey === "leaderboardAnonymous") {
          if (inputForm["leaderboard"] && inputForm["leaderboard"] === true) {
            col.disabled = false;
          } else {
            col.disabled = true;
          }
        }

        if (col.accessorKey === "idWidgetFreshdesk") {
          if (!inputForm["freshDesk"] || inputForm["freshDesk"] === "") {
            col.disabled = true;
          } else {
            col.disabled = false;
          }
        }

        if (col.accessorKey === "showMaintenancePage") {
          if (
            !fileUploaded?.uploadMaintenancePage &&
            !row?.templateMaintenancePage
          ) {
            col.disabled = true;
          } else {
            col.disabled = false;
          }
        }

        switch (col.type) {
          case "singleCheckbox": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <SingleCheckboxCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  value={inputForm[col.accessorKey]}
                  label={col.header}
                  required={col.required}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    if (
                      col.accessorKey === "freshDesk" &&
                      inputForm[col.accessorKey] === true
                    ) {
                      setInputForm({
                        ...inputForm,
                        ...{
                          [col.accessorKey]: e.target.checked,
                          idWidgetFreshdesk: null,
                        },
                      });
                    } else if (col.accessorKey === "showMaintenancePage") {
                      setIsOpenMaintenancePage(true);
                    } else {
                      setInputForm({
                        ...inputForm,
                        ...{ [col.accessorKey]: e.target.checked },
                      });
                    }
                  }}
                  error={errorInputForm[col.accessorKey] ? true : false}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
          case "text": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <InputCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    setInputForm({
                      ...inputForm,
                      ...{ [col.accessorKey]: e.target.value },
                    });
                  }}
                  maxValue={col?.rules?.max?.value}
                  multiline={col.multiline}
                  rows={col.rows}
                  required={col.required}
                  textTooltip={col.tooltip}
                  error={errorInputForm[col.accessorKey] ? true : false}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                  type={col.inpuType || "text"}
                />
              </Grid>
            );
          }
          case "select": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <SelectCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  optionsKey={col.optionsKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                  }}
                  required={col.required}
                  options={col?.options}
                  textTooltip={col.tooltip}
                  error={errorInputForm[col.accessorKey] ? true : false}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
          case "autocomplete": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <AutocompleteCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  optionsKey={col.optionsKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  textTooltip={col.tooltip}
                  required={col.required}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                  }}
                  options={col?.options}
                  error={errorInputForm[col.accessorKey] ? true : false}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
          case "multiCheckbox": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <MultiSelectCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  optionsKey={col.optionsKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  textTooltip={col.tooltip}
                  required={col.required}
                  // service={col.service}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    setInputForm({
                      ...inputForm,
                      ...{ [col.accessorKey]: e.filter((value: any) => value) },
                    });
                  }}
                  options={col?.options}
                  error={errorInputForm[col.accessorKey] ? true : false}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
          case "radio": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <RadioCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  label={col.header}
                  value={inputForm[col.accessorKey] || ""}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                  }}
                  options={col.options}
                  error={errorInputForm[col.accessorKey] ? true : false}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
          case "dateTime": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <DataPickerCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  required={col.required}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                  }}
                  error={errorInputForm[col.accessorKey] ? true : false}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                />
              </Grid>
            );
          }
        }
      }
    });
  }, [
    additionalColumns,
    inputForm,
    fileUploaded,
    errorInputForm,
    t,
    getErrMsg,
    minLength,
    maxLength,
  ]);

  const getBase64 = (file: any, callBack: any) => {
    let reader = new FileReader();
    if (!file) return;
    reader.readAsDataURL(file);
    reader.onload = function () {
      callBack(reader.result);
    };
    reader.onerror = function (error) {
      console.error("getBase64 Error: ", error);
    };
  };

  const getInputField = useMemo(() => {
    return columns?.map((col: any) => {
      if (col.accessorKey === "status") {
        col.disabled = true;
      }

      switch (col.type) {
        case "association": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <InputAssociationChipsCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={getValues(col.accessorKey)}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                required={col.required}
                textTooltip={col.tooltip}
                onDelete={(e: any) => {
                  setIsBlocked(true);
                  handleDelete(col.accessorKey, e);
                }}
                onRedirect={() => {
                  setIsBlocked(true);
                  setOpenAssociation(col.accessorKey, true);
                }}
                labelKey={col.accessorKey === "contract_id" ? "label" : "name"}
              />
            </Grid>
          );
        }
        case "singleCheckbox": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <SingleCheckboxCst
                key={col.accessorKey}
                id={col.accessorKey}
                value={inputForm[col.accessorKey]}
                label={col.header}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: e.target.checked },
                  });
                }}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
              />
            </Grid>
          );
        }
        case "text": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <InputCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: e.target.value },
                  });
                }}
                textTooltip={col.tooltip}
                maxValue={col?.rules?.max?.value}
                multiline={col.multiline}
                rows={col.rows}
                required={col.required}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
                type={col.inpuType || "text"}
              />
            </Grid>
          );
        }
        case "select": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <SelectCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                optionsKey={col.optionsKey}
                value={inputForm[col.accessorKey] || ""}
                textTooltip={col.tooltip}
                required={col.required}
                endAdornment={
                  <InputAdornment sx={{ marginRight: "10px" }} position="end">
                    <IconButton
                      size={"small"}
                      onClick={() => {
                        setInputForm({
                          ...inputForm,
                          ...{ [col.accessorKey]: null },
                        });
                      }}
                      aria-label="clear"
                    >
                      <ClearIcon sx={{ height: "20px", width: "20px" }} />
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: e },
                    secondary_languages: inputForm.secondary_languages.filter(
                      (lang: { label: string; value: string }) =>
                        lang.value !== e
                    ),
                  });
                }}
                options={col?.options}
                extraOptions={
                  col && col.accessorKey === "primary_language"
                    ? availableLanguages
                    : []
                }
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "autocomplete": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <AutocompleteCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                textTooltip={col.tooltip}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col?.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "multiCheckbox": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <MultiSelectCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                textTooltip={col.tooltip}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: e.filter((value: any) => value) },
                  });
                }}
                options={
                  col && col.accessorKey === "secondary_languages"
                    ? availableLanguages?.filter(
                        (lang) => lang?.value !== inputForm.primary_language
                      )
                    : col?.options
                }
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "radio": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <RadioCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "dateTime": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <DataPickerCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
      }
    });
  }, [
    columns,
    inputForm,
    errorInputForm,
    t,
    getValues,
    getErrMsg,
    handleDelete,
    maxLength,
    minLength,
  ]);

  const getWeight = useMemo(() => {
    return additionalColumns?.map((col: any) => {
      if (
        [
          "knowledgeDomainHRWeight",
          "knowledgeDomainManagerWeight",
          "skillsHRWeight",
          "skillsUserWeight",
          "knowledgeDomainUserWeight",
        ].includes(col.accessorKey)
      ) {
        switch (col.type) {
          case "text": {
            return (
              <Grid key={col.accessorKey} item xs={12} sm={6}>
                <InputCst
                  key={col.accessorKey}
                  id={col.accessorKey}
                  label={col.header}
                  value={inputForm[col.accessorKey]}
                  onChange={(e: any) => {
                    setIsBlocked(true);
                    setWeigthSum((prev: any) => {
                      return (
                        Number(prev - inputForm[col.accessorKey]) +
                        Number(e.target.value)
                      );
                    });
                    setInputForm({
                      ...inputForm,
                      ...{ [col.accessorKey]: e.target.value },
                    });
                  }}
                  textTooltip={col.tooltip}
                  maxValue={col?.rules?.max?.value}
                  multiline={col.multiline}
                  rows={col.rows}
                  required={col.required}
                  error={errorInputForm[col.accessorKey] ? true : false}
                  errorMessage={
                    t(`error.field.${getErrMsg(col.accessorKey)}`, {
                      min: minLength(col.accessorKey),
                      max: maxLength(col.accessorKey),
                    }) || "Error"
                  }
                  disabled={col.disabled}
                  type={col.inpuType || "text"}
                />
              </Grid>
            );
          }
        }
      }
    });
  }, [
    additionalColumns,
    inputForm,
    errorInputForm,
    t,
    getValues,
    getErrMsg,
    handleDelete,
    maxLength,
    minLength,
  ]);

  const showFormAction = (field: ColumnInterface) => {
    return (
      field?.type?.includes("upload") ||
      field?.type?.includes("download") ||
      field?.type?.includes("template")
    );
  };

  return (
    <>
      {/* <BackButtonWarning isBlocked={isBlocked} /> */}
      {!openAssociationContract && (
        <Grid container>
          <Grid item xs={0} lg={4} xl={3}>
            <BackgroundImageCst
              image="section-internal-form.jpg"
              disableGradient
            />
          </Grid>
          <Grid item xs={12} lg={8} xl={9}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                padding: "32px",
                minHeight: "495px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid container spacing={3}>
                {getInputField}
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    margin: "24px 0px",
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  Smart configuration
                </Typography>
                {sectionTooltip && (
                  <TooltipInput
                    hrefInput
                    hrefIta={
                      "https://www.carniaindustrialpark.it/public/explorer/Test_Ita__s.pdf"
                    }
                    hrefEn={"https://www.africau.edu/images/default/sample.pdf"}
                  />
                )}
              </Box>
              <Grid container spacing={3}>
                {actionsForForms?.map((field) => {
                  if (field.accessorKey === "uploadBrandIdentity") {
                    const isBrandUploaded =
                      field.accessorKey === "uploadBrandIdentity" &&
                      row?.brandassetName;
                    field.downloadDisabled = !isBrandUploaded;
                    field.fileName = row?.brandassetName;
                  }
                  // else if (field.accessorKey === "uploadNotification") {
                  //   const isNotificationUploaded =
                  //     field.accessorKey === "uploadNotification" &&
                  //     row?.notificationName;
                  //   field.downloadDisabled = !isNotificationUploaded;
                  //   field.fileName = row?.notificationName;
                  //   field.disabled = !(fileUploaded?.uploadTemplate && fileUploaded?.uploadTemplateWeb && fileUploaded?.uploadTemplatePush) && !(row?.templateMailName && row?.templateWebappName && row?.templatePushName)
                  // } else if (field.accessorKey === "uploadTemplate") {
                  //   const isTemplateUploaded =
                  //     field.accessorKey === "uploadTemplate" &&
                  //     (row?.templateMailName || row?.templateWebappName);
                  //   field.downloadDisabled = !isTemplateUploaded;
                  //   if (row?.templateMailName && row?.templateWebappName) {
                  //     switch (row.lastSavedTemplateChannel) {
                  //       case "WEB":
                  //         field.fileName = row?.templateWebappName;
                  //         break;
                  //       case "EMAIL":
                  //         field.fileName = row?.templateMailName;
                  //         break;

                  //       default:
                  //         break;
                  //     }
                  //   } else {
                  //     field.fileName =
                  //       row?.templateMailName || row?.templateWebappName;
                  //   }
                  // }
                  else if (field.accessorKey === "uploadNotification") {
                    const isNotificationUploaded =
                      field.accessorKey === "uploadNotification" &&
                      row?.notificationName;
                    field.downloadDisabled = !isNotificationUploaded;
                    field.fileName = row?.notificationName;
                    field.disabled =
                      !(
                        fileUploaded?.uploadTemplate &&
                        fileUploaded?.uploadTemplateWeb &&
                        fileUploaded?.uploadTemplatePush
                      ) &&
                      !(
                        row?.templateMailName &&
                        row?.templateWebappName &&
                        row?.templatePushName
                      );
                  } else if (field.accessorKey === "uploadTemplate") {
                    const isTemplateUploaded =
                      field.accessorKey === "uploadTemplate" &&
                      row?.templateMailName;
                    field.downloadDisabled = !isTemplateUploaded;
                    field.fileName = row?.templateMailName;
                  } else if (field.accessorKey === "uploadTemplateWeb") {
                    const isTemplateWebUploaded =
                      field.accessorKey === "uploadTemplateWeb" &&
                      row?.templateWebappName;
                    field.downloadDisabled = !isTemplateWebUploaded;
                    field.fileName = row?.templateWebappName;
                  } else if (field.accessorKey === "uploadTemplatePush") {
                    const isTemplatePushUploaded =
                      field.accessorKey === "uploadTemplatePush" &&
                      row?.templatePushName;
                    field.downloadDisabled = !isTemplatePushUploaded;
                    field.fileName = row?.templatePushName;
                  } else if (field.accessorKey === "uploadMaintenancePage") {
                    const isTemplateMantenance =
                      field.accessorKey === "uploadMaintenancePage" &&
                      row?.templateMaintenancePage;
                    field.downloadDisabled = !isTemplateMantenance;
                    field.fileName = row?.templateMaintenancePage;
                  }

                  return (
                    <Grid key={field.accessorKey} item xs={12} sm={6}>
                      {showFormAction(field) && (
                        <DownloaderUploaderCst
                          name={field.header}
                          accept={field?.accept}
                          url={field.service}
                          dragDrop={dragDrop}
                          fileName={field.fileName}
                          id={field.accessorKey}
                          type={field?.type || []}
                          data={row}
                          onChange={(e: any) => {
                            getBase64(e, (result: any) => {
                              setFileUploaded({
                                ...fileUploaded,
                                [field.accessorKey]: {
                                  file: e,
                                  base64: result,
                                },
                              });
                            });
                          }}
                          onDownload={
                            field.downloadCallback &&
                            (() =>
                              field.downloadCallback &&
                              field.downloadCallback())
                          }
                          onTemplateDownload={
                            field.templateCallback &&
                            (() =>
                              field.templateCallback &&
                              field.templateCallback())
                          }
                          downloadDisabled={field.downloadDisabled}
                          onDelete={
                            field.deleteCallback &&
                            (() => {
                              setFileUploaded(null);
                              return (
                                field.deleteCallback && field.deleteCallback()
                              );
                            })
                          }
                          disabled={field.disabled}
                          textTooltip={field?.tooltip}
                          tooltipFile={field?.tooltipFile}
                          tooltipFileIta={field?.tooltipFileIta}
                          tooltipFileEn={field?.tooltipFileEn}
                        />
                      )}
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      border:
                        (weigthSum >= 0 && weigthSum < 100) || weigthSum > 100
                          ? `1px solid ${theme.palette.error.main}`
                          : `1px solid`,
                      padding: "32px 16px",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "4px",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        padding: "5px",
                        position: "absolute",
                        top: 0,
                        left: "10px",
                        backgroundColor: theme?.palette.background.paper,
                        transform: "translate(0, -50%)",
                        color:
                          (weigthSum >= 0 && weigthSum < 100) || weigthSum > 100
                            ? `${theme.palette.error.main}`
                            : ``,
                      }}
                    >
                      {t("percentage-weight").replace(":weight", weigthSum)}
                    </Box>
                    <Grid
                      container
                      spacing={"24px"}
                      sx={{ justifyContent: "flex-end" }}
                    >
                      {getWeight}
                    </Grid>
                  </Box>
                  {errorInputForm["weight"] && (
                    <FormHelperText sx={{ paddingLeft: "15px" }} error={true}>
                      {t("error.field.percentage-weight")}
                    </FormHelperText>
                  )}
                </Grid>
                {getAdditionalInputField}
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: "32px",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "space-between",
                    [theme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <ButtonCst
                    id={"add-button1"}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      mr: "auto",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={() => {
                      if (backButton) {
                        backButton();
                      } else {
                        navigate(-1);
                      }
                    }}
                  >
                    {t("backButton")}
                  </ButtonCst>
                  <ButtonCst
                    id={"add-button1"}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={resetInputForm}
                  >
                    {t("resetButton")}
                  </ButtonCst>
                  <ButtonCst
                    id={"add-button1"}
                    variant={"contained"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={() => onConfirm()}
                  >
                    {t("saveButton")}
                  </ButtonCst>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {openAssociationContract && (
        <AssociationLibraryCst
          association={contractAssociation}
          setAssociation={setContractAssociation}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("contract_id", value)
          }
          filters={[{ field: "type", value: MediaType.LEGAL_DOCUMENTATION }]}
          title={t("documentation-association")}
        />
      )}
      <ModalConfirmsCst
        open={!!isOpenMaintenancePage}
        title={"Maintenance page"}
        description={
          !inputForm["showMaintenancePage"]
            ? tInitiativePage("maintenance-page-confirm-modal-activate")
            : tInitiativePage("maintenance-page-confirm-modal-deactivate")
        }
        routeToSamePage={true}
        onCallBackClosed={() => {
          setIsOpenMaintenancePage(false);
        }}
        onActionConfirmed={() => {
          setInputForm({
            ...inputForm,
            ...{ showMaintenancePage: !inputForm["showMaintenancePage"] },
          });
          setIsOpenMaintenancePage(false);
        }}
      />
    </>
  );
};

export default InitiativeFormCst;
