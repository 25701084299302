import budgetIcon from "../../../assets/icons/gestione_budget.svg";

const IconBudget: React.FC = (props) => {
  return (
    <img
      src={budgetIcon}
      alt=""
      width="24px"
      height="24px"
      style={{ padding: "2px" }}
    />
  );
};

export default IconBudget;
