//REACT AND REACT BASED LIBRARIES
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import Table from "@components/Table";
import { Box, Container, Typography, useTheme } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import BackgroundImage from "@components/BackgroundImageCst";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { ActionsType } from "src/interfaces/Common";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { useFetchTable } from "@hooks/useFetchDataTable";
import ButtonBackPage from "@components/ButtonBackPage";
import { ErrorOutline } from "@mui/icons-material";

const SubscribedGroups: React.FC = () => {
  const { t } = useTranslation(["groups-enrolledToCatalog-page", "modal"]);
  const theme = useTheme();
  const { id, idLO } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openToastDeleted, setOpenToastDeleted] = useState<{
    s: StatusEnum;
    message?: string;
    customContent?: JSX.Element;
  }>();
  const [groupIDWarning, setGroupIDWarning] = useState<any>();
  const [enrollGroupEdit, setEnrollGroupEdit] = useState<any>();
  const [enrollGroupDeleted, setEnrollGroupDeleted] = useState<any>();

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getGroupsEnrolledWithEnrollInfo}?corporateId=${id}&objectId=${idLO}`,
    PagesID["corporate.catalog.path.subscribed.groups"]
  );

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID["corporate.initiative"]
  );

  const { trigger: triggerDelete } = useSWRMutation(
    `${ServicesURL.getCertificates}`,
    (url: string) => {
      return fetcher<any>(`${url}`, MethodType.DELETE);
    }
  );

  const { trigger: triggerDeleteGroups } = useSWRMutation(
    `${ServicesURL.postEnrollmentMassive}`,
    (url: string, { arg }: { arg: { id: any; groupType: any } }) =>
      fetcher(
        `${url}?corporateId=${id}&groupId=${arg.id}&groupType=${arg.groupType}&objectId=${idLO}`,
        MethodType.DELETE
      )
  );

  const deleteErrorCustom = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <ErrorOutline
          sx={{ fontSize: "60px", color: theme.palette.error.main }}
        />
        <Typography
          variant="h6"
          component={"p"}
          sx={{ wordWrap: "break-word", width: "100%", mb: "2rem" }}
        >
          {t("already-deleted", { ns: "modal" })}
        </Typography>
      </Box>
    );
  };

  const handleDeleteGroup = React.useCallback(async () => {
    if (enrollGroupDeleted) {
      setOpenToastDeleted({ s: StatusEnum.LOADING, message: "loading" });
      try {
        const data = await triggerDeleteGroups({
          id: enrollGroupDeleted?.id,
          groupType: enrollGroupDeleted?.groupType,
        });

        if (!!data.errorMessage) {
          setOpenToastDeleted({
            s: StatusEnum.CUSTOM,
            customContent: deleteErrorCustom(),
          });
        } else {
          mutate && mutate();
          setOpenToastDeleted({ s: StatusEnum.OK, message: "" });
        }
      } catch (e) {
        console.error(e);
        setOpenToastDeleted(undefined);
        setEnrollGroupDeleted(undefined);
      }
    }
  }, [dataTable, enrollGroupDeleted, mutate, triggerDelete]);

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.key) {
        case "group_enroll":
          return {
            ...action,
            callBack: (row: any) => {
              setEnrollGroupEdit(row);
              setGroupIDWarning(row?.id);
            },
          };
        case "delete_group_enroll":
          return {
            ...action,
            callBack: (row: any) => {
              setEnrollGroupDeleted(row);
              setGroupIDWarning(row?.id);
            },
          };
        case "subscribed":
          return {
            ...action,
            getIcon: () => <RecentActorsIcon />,
            callBack: (row: any) => {
              const LOType = () => {
                if (pathname.includes("courses")) return "courses";
                if (pathname.includes("activities")) return "activities";
                if (pathname.includes("paths")) return "paths";
              };
              navigate(
                `/corporate/${id}/detail/catalog/${LOType()}/${idLO}/subscribed`,
                {
                  state: { row: row, setGroup: "id" },
                }
              );
            },
          };
        case "subscribed-groups":
          return {
            ...action,
            callBack: (row: any) =>
              navigate(`${row.id}/${action.url}`, {
                state: { row: row },
              }),
          };

        default:
          return action;
      }
    });
  }, [actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };
  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-certificates.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.name || ""} - ${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.catalog.path.subscribed.groups"]}
            data={dataTable?.response || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            renderRowActions={customRenderRowActions}
            numberOfActions={actionForRow.length}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>

      <ModalConfirmsCst
        open={!!enrollGroupEdit}
        title={t("edit-item", { ns: "modal" })}
        description={t("warning-edit-group-enroll", { ns: "modal" }).replace(
          ":ID",
          groupIDWarning
        )}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setEnrollGroupEdit(undefined);
          setOpenToastDeleted(undefined);
        }}
        onActionConfirmed={() =>
          enrollGroupEdit &&
          navigate(
            `${enrollGroupEdit.groupType}/${enrollGroupEdit.id}/enrollment`
          )
        }
        status={openToastDeleted}
      />
      <ModalConfirmsCst
        open={!!enrollGroupDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={t("warning-delete-group-enroll", { ns: "modal" }).replace(
          ":ID",
          groupIDWarning
        )}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setEnrollGroupDeleted(undefined);
          setOpenToastDeleted(undefined);
        }}
        onActionConfirmed={() => enrollGroupDeleted && handleDeleteGroup()}
        status={openToastDeleted}
      />
    </AnimationFadeIn>
  );
};

export default SubscribedGroups;
