import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import { MethodType } from "src/services/type";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import {
  ServicesURL,
  getMessageObjectForModalEnrollementsAPI,
} from "@utils/utilsApi";
import { fetcher } from "src/services/config";
import { PagesID } from "@utils/utilsConfigurations";
import { User } from "src/interfaces/User";
import { ActionsType, ColumnsType } from "src/interfaces/Common";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import useSWRMutation from "swr/mutation";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { formatDateWithMinutes } from "@utils/utilsDate";
import ButtonBackPage from "@components/ButtonBackPage";
import {
  EnrollmentAssociationUserRequest,
  EnrollmentEditionUserPatchRequest,
} from "src/interfaces/Enrollment";
import { LOType } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { SingleCheckboxCst } from "@components/InputCst";
import { getJSXForModalCustomEnrollments } from "@utils/utilsModal";
import AnimationFadeIn from "@components/AnimationFadeIn";

const AssociationUser: React.FC<{ type: LOType }> = ({ type }) => {
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message?: string;
    customContent?: JSX.Element;
  }>();
  const { t } = useTranslation(["users-page", "modal"]);
  const { id, idLO, idEdition, idEditionLOActivity } = useParams();
  const [openSubscribeUser, setOpenSubscribeUser] = useState<any>(false);
  const [registrationMandatory, setRegistrationMandatory] =
    useState<any>(false);
  const location = useLocation();
  const { trigger: triggerInitiativeAssociation } = useSWRMutation(
    `${ServicesURL.postEnrollment}`,
    (
      url: string,
      {
        arg,
      }: {
        arg: EnrollmentAssociationUserRequest[];
      }
    ) => {
      return fetcher<any>(`${url}`, MethodType.POST, arg);
    }
  );

  const { trigger: triggerPatchAssociation } = useSWRMutation(
    `${ServicesURL.patchEnrollment}`,
    (url: string, { arg }: { arg: any[] }) =>
      fetcher(url, MethodType.PATCH, arg)
  );

  const handleCloseToastMessage = () => {
    setOpenToast(undefined);
  };

  const getObjectId = () => {
    // If it's an Edition objectId is the learning_objects id of edition
    // If it's not an Edition take idLO passed in URL path
    if (idEditionLOActivity) {
      return +idEditionLOActivity;
    } else {
      return (idLO && +idLO) || undefined;
    }
  };

  const handleSubscribe = React.useCallback(
    async (row: any) => {
      try {
        setOpenToast({ s: StatusEnum.LOADING });
        // const response = await triggerInitiativeAssociation([
        //   {
        //     enrollDate: `${formatDateWithMinutes(new Date())}`,
        //     enrollType: "TOP",
        //     editionId: (idEdition && +idEdition) || undefined,
        //     mandatory: registrationMandatory,
        //     learningObject: {
        //       corporateId: (id && +id) || undefined,
        //       objectId: getObjectId(),
        //       objectType: type,
        //     },
        //     userId: row.id_user,
        //   },
        // ]);

        // If it is user enrollment of Edition it is also required to make a PATCH after POST
        let responsePatch = null;
        // if (idEdition && response && response.numberSaved) {
        responsePatch = await triggerPatchAssociation([
          {
            corporateId: (id && +id) || undefined,
            editionId: (idEdition && +idEdition) || undefined,
            objectId: getObjectId(),
            isToj: false,
            userId: [row.userId],
            mandatory: null,
          },
        ]);
        // }

        let modalMessageObject: any = {};

        if (idEdition && responsePatch) {
          modalMessageObject = getMessageObjectForModalEnrollementsAPI(
            responsePatch,
            {
              usersAddedLabel: t("enrollment.users-added", { ns: "modal" }),
              usersNotAddedLabel: t("enrollment.users-not-added", {
                ns: "modal",
              }),
              errorsOccuredLabel: t("enrollment.errors-occured", {
                ns: "modal",
              }),
            }
          );
        }
        // else {
        //   modalMessageObject = getMessageObjectForModalEnrollementsAPI(
        //     response,
        //     {
        //       usersAddedLabel: t("enrollment.users-added", { ns: "modal" }),
        //       usersNotAddedLabel: t("enrollment.users-not-added", {
        //         ns: "modal",
        //       }),
        //       errorsOccuredLabel: t("enrollment.errors-occured", {
        //         ns: "modal",
        //       }),
        //     },
        //   );
        // }

        if (responsePatch?.errorMessage) {
          setOpenToast({
            s: StatusEnum.KO,
            message: `${responsePatch?.errorMessage || ""}`,
          });
        } else {
          setOpenToast({
            s: StatusEnum.OK,
            message: "",
          });
        }

        // setOpenSubscribeUser(false)
        setRegistrationMandatory(false);
      } catch (e) {
        setOpenSubscribeUser(false);
        setOpenToast(undefined);
        console.error(e);
      }
    },
    [
      id,
      idEdition,
      idLO,
      triggerInitiativeAssociation,
      type,
      registrationMandatory,
    ]
  );

  const { columns, dataTable, handleFetchData } = useFetchTable<User[]>(
    `${idEdition ? ServicesURL.getEnrollment : ServicesURL.getCustomer}${
      idEditionLOActivity
        ? `?corporateId=${id}&objectId=${idEditionLOActivity}&enrollType=TOP,TOP_MANDATORY`
        : `?corporate_id=${id}`
    }`,
    idEdition
      ? PagesID["enrollment.edition.user"]
      : PagesID["corporate.customers"]
  );

  const actionsRow: ActionsType[] = React.useMemo(() => {
    const customActions = [
      {
        type: "button",
        icon: "lockUnlock",
        permission: "button",
        url: "enrollment",
        labelTranslate: t("association-user.enroll-users"),
      },
    ];
    return customActions?.map((action: any) => {
      switch (action.type) {
        case "button": {
          return {
            ...action,
            callBack: (row: any) => {
              if (idEdition || idEditionLOActivity) {
                setOpenSubscribeUser(row);
              } else {
                navigate(`${row.id_user}/enrollment`);
              }
            },
            getIcon: (row: any) => {
              return <LeakAddIcon />;
            },
          };
        }
        default:
          return action;
      }
    });
  }, [columns]);

  const [columnsMerged, setColumnsMerged] = useState<ColumnsType[]>();

  React.useEffect(() => {
    if (!columnsMerged) {
      if (columns) {
        setColumnsMerged([
          ...(columns.map((el: ColumnsType) => {
            switch (el.accessorKey) {
              case "uog": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    const uog = value && value[0]?.uog;
                    return uog;
                  },
                };
              }
              case "job": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    return value && value?.label;
                  },
                };
              }
              case "uog_description": {
                return {
                  ...el,
                  Cell: ({ cell }: any) => {
                    const value = cell.getValue();
                    const uog_description = value && value[0]?.uog_description;
                    return uog_description;
                  },
                };
              }
              default:
                return el;
            }
          }) || []),
        ]);
      }
    }
  }, [columns, columnsMerged]);

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID.corporate
  );

  const getEnrollmentResponse = () => {
    const newList = dataTable?.enrollmentResponses.map((item: any) => {
      if (
        !!item.learningObject.blendedParentId &&
        item.learningObject.blendedParentId !== Number(idLO)
      ) {
        return {
          ...item,
          blendedParentId: item.learningObject.blendedParentId,
        };
      } else {
        return item;
      }
    });

    return newList;
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-courses.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${
            location.state?.row?.title || dataDetail?.business_name
          } - ${t("association-user.hero-title")}`}
          description={t("association-user.hero-description")}
        />
        <ButtonBackPage customRedirect={-1} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id="association-user"
            data={idEdition ? getEnrollmentResponse() : dataTable?.output}
            columns={columnsMerged || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={[]}
            enableRowActions={true}
            configurationActionForRow={actionsRow || []}
            totalPages={
              idEdition
                ? dataTable?.metadata?.totalElements
                : dataTable?.metadata?.total_elements
            }
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openSubscribeUser}
        title={
          openToast && openToast?.s ? "" : t("association-user.enroll-users")
        }
        description={t("association-user.confirm-enroll-users")}
        onCallBackClosed={() => {
          setOpenSubscribeUser(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => {
          openSubscribeUser && handleSubscribe(openSubscribeUser);
        }}
        status={openToast}
        routeToSamePage={true}
      />
    </AnimationFadeIn>
  );
};

export default AssociationUser;
