import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";
import TrainingNeeds from "../../../../pages/Contents/Corporate/TrainingNeeds";
import AddCampaign from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/AddCampaign/Index";
import ViewPreferences from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/ViewPreferences/Index";
import { Navigate } from "react-router-dom"; // Importa Navigate
import NextStep from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/NextStep/Index";
import CreateManagement from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/Index";
import VisualizeAndConfirm from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/VisualizeAndConfirm/Index";
import AddKnowledge from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/VisualizeAndConfirm/AddKnowledge/Index";
import ViewUserConfirm from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/VisualizeAndConfirm/ViewUser/Index";
import CompletationCampaign from "../../../../pages/Contents/Corporate/TrainingNeeds/CompletationCampaign/Index";
import AddLearnerCampaign from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/AddLearnerCampaign/Index";
import VisualizeAndConfirmLearner from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/LearnerConfirm/Index";
import ViewDetailConfirm from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/LearnerConfirm/ViewDetail/Index";
import CollectionResults from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/LearnerConfirm/CollectionResults/Index";
import AddKnowledgeLearner from "../../../../pages/Contents/Corporate/TrainingNeeds/CompletationCampaign/AddKnowledgeLearner/Index";
import { RolesDigitedEnum } from "../../../../context/auth/types";
import VisualizeAndConfirmDetailsLearner from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/LearnerConfirm/VisualizeAndConfirmDetailLearner/Index";
import DetailUserSkills from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/LearnerConfirm/ViewDetail/DetailUserSkills/Index";
import DetailSkillUsers from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/LearnerConfirm/ViewDetail/DetailSkillUsers/Index";
import VisualizeAndConfirmLearnerSkills from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/LearnerConfirm/VisualizeAndConfirmDetailLearner/VisualizeAndConfirmSkills/Index";
import TrainingGaps from "../../../../pages/Contents/Corporate/TrainingNeeds/CreateManagement/LearnerConfirm/CollectionResults/trainingGaps/Index";

const useCorporateTrainingNeeds = () => {
  const { isInclude, isIncludeRole } = useIsInclude();

  return {
    path: "training-needs",
    children: [
      {
        index: true,
        path: "",
        element: (
          <ProtectedRoute
            isAllowed={isInclude("usermgmt.corporates.training_needs")}
          >
            <TrainingNeeds />
          </ProtectedRoute>
        ),
      },
      {
        path: "create-management",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={
                  isInclude("usermgmt.corporates.training_needs") &&
                  isIncludeRole([
                    RolesDigitedEnum.BUSINESS_ADMINISTRATOR,
                    RolesDigitedEnum.BUSINESS_ADMIN,
                    RolesDigitedEnum.PRODUCT_OWNER,
                  ])
                }
              >
                <CreateManagement />
              </ProtectedRoute>
            ),
          },
          {
            path: "create-campaign",
            element: (
              <ProtectedRoute isAllowed={isInclude("usermgmt.campaign.add")}>
                <AddCampaign />
              </ProtectedRoute>
            ),
          },
          {
            path: "create-learner-campaign",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("usermgmt.campaign.add.learner")}
              >
                <AddLearnerCampaign />
              </ProtectedRoute>
            ),
          },
          {
            path: ":idCampaign",
            children: [
              {
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("usermgmt.campaign.confirm")}
                  >
                    <Navigate to="create-management" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "learner-confirm",
                children: [
                  {
                    path: "",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude("usermgmt.campaign.confirm")}
                      >
                        <VisualizeAndConfirmLearner />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ":idDetailUser",

                    children: [
                      {
                        path: "detail-user-skills",
                        element: (
                          <ProtectedRoute
                            isAllowed={isInclude("usermgmt.campaign.add")}
                          >
                            <DetailUserSkills isClosed isDetailLearner />
                          </ProtectedRoute>
                        ),
                      },
                    ],
                  },
                  {
                    path: ":idDetail",
                    children: [
                      {
                        path: "details",
                        children: [
                          {
                            path: "add",
                            element: (
                              <ProtectedRoute
                                isAllowed={isInclude(
                                  "usermgmt.campaign.confirm"
                                )}
                              >
                                <AddKnowledgeLearner />
                              </ProtectedRoute>
                            ),
                          },
                          {
                            path: "",
                            element: (
                              <ProtectedRoute
                                isAllowed={isInclude(
                                  "usermgmt.campaign.confirm"
                                )}
                              >
                                <VisualizeAndConfirmDetailsLearner />
                              </ProtectedRoute>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: ":idDetail",
            children: [
              {
                path: "detail-learner",
                children: [
                  {
                    path: "",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude("usermgmt.campaign.add")}
                      >
                        <ViewDetailConfirm />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ":idDetailUser",
                    children: [
                      {
                        path: "detail-user-skills",
                        element: (
                          <ProtectedRoute
                            isAllowed={isInclude("usermgmt.campaign.add")}
                          >
                            <DetailUserSkills isClosed isDetailLearner />
                          </ProtectedRoute>
                        ),
                      },
                    ],
                  },
                  {
                    path: ":idDetailKC",
                    children: [
                      {
                        path: "detail-skill-users",
                        element: (
                          <ProtectedRoute
                            isAllowed={isInclude("usermgmt.campaign.add")}
                          >
                            <DetailSkillUsers />
                          </ProtectedRoute>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: ":idDetail",

            children: [
              {
                path: "collection-results",

                children: [
                  {
                    path: "",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude("usermgmt.campaign.add")}
                      >
                        <CollectionResults />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ":idUser",
                    children: [
                      {
                        path: "training-gaps",

                        element: (
                          <ProtectedRoute
                            isAllowed={isInclude("usermgmt.campaign.add")}
                          >
                            <TrainingGaps />
                          </ProtectedRoute>
                        ),
                      },
                    ],
                  },
                  {
                    path: ":idDetailKC",
                    children: [
                      {
                        path: "detail-user-skills",

                        element: (
                          <ProtectedRoute
                            isAllowed={isInclude("usermgmt.campaign.add")}
                          >
                            <DetailUserSkills isClosed isDetailLearner />
                          </ProtectedRoute>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: ":idCampaign",
            children: [
              {
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("usermgmt.campaign.confirm")}
                  >
                    <Navigate to="create-management" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "visualize-and-confirm",
                children: [
                  {
                    path: "",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude("usermgmt.campaign.confirm")}
                      >
                        <VisualizeAndConfirm />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: "add",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude("usermgmt.campaign.add")}
                      >
                        <AddKnowledge />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ":idDetail",
                    children: [
                      {
                        path: "users",

                        element: (
                          <ProtectedRoute
                            isAllowed={isInclude("usermgmt.campaign.add")}
                          >
                            <ViewUserConfirm />
                          </ProtectedRoute>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: ":idCampaign",
            children: [
              {
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={
                      isInclude("usermgmt.campaign.add") &&
                      isIncludeRole([
                        RolesDigitedEnum.BUSINESS_ADMINISTRATOR,
                        RolesDigitedEnum.BUSINESS_ADMIN,
                        RolesDigitedEnum.PRODUCT_OWNER,
                      ])
                    }
                  >
                    <Navigate to="create-management" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "next-step",
                element: (
                  <ProtectedRoute
                    isAllowed={
                      isInclude("usermgmt.campaign.add") &&
                      isIncludeRole([
                        RolesDigitedEnum.BUSINESS_ADMINISTRATOR,
                        RolesDigitedEnum.BUSINESS_ADMIN,
                        RolesDigitedEnum.PRODUCT_OWNER,
                      ])
                    }
                  >
                    <NextStep />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: ":idCampaign",

            children: [
              {
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("usermgmt.campaign.add")}
                  >
                    <Navigate to="create-management" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "view-preferences",

                children: [
                  {
                    path: "",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude("usermgmt.campaign.add")}
                      >
                        <ViewPreferences />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: "next-step",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude("usermgmt.campaign.add")}
                      >
                        <NextStep />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "completation-campaign",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute
                isAllowed={isInclude("usermgmt.corporates.training_needs")}
              >
                <CompletationCampaign />
              </ProtectedRoute>
            ),
          },

          {
            path: ":idCampaign",

            children: [
              {
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("usermgmt.campaign.add")}
                  >
                    <Navigate to="create-management" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "visualize-and-confirm-learner",

                children: [
                  {
                    path: "",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude("usermgmt.campaign.add")}
                      >
                        <VisualizeAndConfirmLearner />
                      </ProtectedRoute>
                    ),
                  },

                  {
                    path: ":idDetail",

                    children: [
                      {
                        path: "details",

                        children: [
                          {
                            path: "",
                            element: (
                              <ProtectedRoute
                                isAllowed={isInclude("usermgmt.campaign.add")}
                              >
                                <VisualizeAndConfirmDetailsLearner />
                              </ProtectedRoute>
                            ),
                          },
                          {
                            path: "add",
                            element: (
                              <ProtectedRoute
                                isAllowed={isInclude("usermgmt.campaign.add")}
                              >
                                <AddKnowledgeLearner />
                              </ProtectedRoute>
                            ),
                          },
                        ],
                      },
                      {
                        path: "detail-user-skills",
                        children: [
                          {
                            path: "",
                            element: (
                              <ProtectedRoute
                                isAllowed={isInclude("usermgmt.campaign.add")}
                              >
                                <DetailUserSkills isClosed isDetailLearner />
                              </ProtectedRoute>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: ":idCampaign",

            children: [
              {
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("usermgmt.campaign.confirm")}
                  >
                    <Navigate to="create-management" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "visualize-and-confirm",

                children: [
                  {
                    path: "",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude("usermgmt.campaign.confirm")}
                      >
                        <VisualizeAndConfirm />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: "add",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude("usermgmt.campaign.add")}
                      >
                        <AddKnowledge />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ":idDetail",

                    children: [
                      {
                        path: "users",

                        element: (
                          <ProtectedRoute
                            isAllowed={isInclude("usermgmt.campaign.add")}
                          >
                            <ViewUserConfirm />
                          </ProtectedRoute>
                        ),
                      },
                    ],
                  },
                  {
                    path: ":idDetail",

                    children: [
                      {
                        path: "details",

                        element: (
                          <ProtectedRoute
                            isAllowed={isInclude("usermgmt.campaign.add")}
                          >
                            <VisualizeAndConfirm />
                          </ProtectedRoute>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: ":idCampaign",

            children: [
              {
                path: "",
                element: (
                  <ProtectedRoute
                    isAllowed={isInclude("usermgmt.campaign.confirm")}
                  >
                    <Navigate to="create-management" />
                  </ProtectedRoute>
                ),
              },
              {
                path: "detail-users",

                children: [
                  {
                    path: "",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude("usermgmt.campaign.confirm")}
                      >
                        <ViewDetailConfirm />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ":idDetailUser",

                    children: [
                      {
                        path: "detail-user-skills",

                        element: (
                          <ProtectedRoute
                            isAllowed={isInclude("usermgmt.campaign.add")}
                          >
                            <DetailUserSkills isDetailLearner />
                          </ProtectedRoute>
                        ),
                      },
                    ],
                  },
                  {
                    path: ":idDetailKC",

                    children: [
                      {
                        path: "detail-skill-users",

                        element: (
                          <ProtectedRoute
                            isAllowed={isInclude("usermgmt.campaign.add")}
                          >
                            <DetailSkillUsers />
                          </ProtectedRoute>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: ":idCampaign",
            children: [
              {
                path: "view-preferences",
                children: [
                  {
                    path: "",
                    element: (
                      <ProtectedRoute
                        isAllowed={isInclude("usermgmt.campaign.add")}
                      >
                        <ViewPreferences />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
};

export default useCorporateTrainingNeeds;
