import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OutcomesInterface } from "@components/ModalCst";
import { usePostUploadDetail } from "@hooks/useFetchDetail";
import {
  ServicesURL,
  downloadFile,
  getAllErrorsAsString,
} from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { MethodType } from "src/services/type";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { useAuth } from "@context/auth";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import useSWR from "swr";
import { fetcher } from "src/services/config";
import { isTrueValue } from "@utils/utilsValidators";

interface Association {
  accessorKey: string;
  mediaType: string;
  type: string;
  titleKey: string;
  service?: string;
  pageId?: PagesID;
  multiChoice?: boolean;
}

const initialOutcome: OutcomesInterface = {
  edition: {
    code: "LOADING",
    message: undefined,
  },
  uploadParticipants: {
    code: undefined,
    message: undefined,
  },
};

export const useAddEditEditionTOJ = (
  messageType: string,
  triggetEdition: (input: any) => any,
  id?: string,
  idEdition?: string | number,
  dataDetail?: any,
  mutate?: any
) => {
  dayjs.extend(customParseFormat);
  const { t } = useTranslation(["toj-edition-page"]);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();
  const [openAssociations, setOpenAssociations] = useState<
    boolean | Association
  >(false);
  const [coursesAssociation, setCoursesAssociation] = useState<any>([]);
  const [facultiesAssociation, setFacultiesAssociation] = useState<any>([]);

  useEffect(() => {
    if (dataDetail) {
      dataDetail?.learning_objects &&
        setCoursesAssociation([dataDetail.learning_objects]);
      dataDetail?.faculties && setFacultiesAssociation(dataDetail.faculties);
    }
  }, [dataDetail]);

  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "learning_objects":
        setOpenAssociations(
          value
            ? {
                accessorKey: "learning_objects",
                mediaType: "learning_objects",
                multiChoice: false,
                type: "table",
                titleKey: "courses-association",
                pageId: PagesID["corporate.catalog.courses"],
                service:
                  ServicesURL.getCoursesAssociation.replace(
                    ":idCorporate",
                    id || "1"
                  ) + `?toj_specialization=true`,
              }
            : value
        );
        break;
      case "faculties":
        setOpenAssociations(
          value
            ? {
                accessorKey: "faculties",
                mediaType: "faculties",
                type: "table",
                titleKey: "faculties-association",
                pageId: PagesID["faculties"],
                service: ServicesURL.faculties + `?corporate_id=${id || "1"}`,
              }
            : value
        );
        break;
    }
  };

  const getValues = (accessorKey: string) => {
    switch (accessorKey) {
      case "learning_objects":
        return coursesAssociation;
      case "faculties":
        return facultiesAssociation;
    }
  };

  const handleDelete = (accessorKey: string, e: any) => {
    switch (accessorKey) {
      case "learning_objects":
        setCoursesAssociation(
          coursesAssociation.filter((element: any) => element.id !== e.id)
        );
        break;
      case "faculties":
        setFacultiesAssociation(
          facultiesAssociation.filter((element: any) => element.id !== e.id)
        );
        break;
    }
  };

  const handleAssociation = (accessorKey: string, value: any) => {
    switch (accessorKey) {
      case "learning_objects":
        const objToActivitiesAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return coursesAssociation.find((elem: any) => elem.id === +key);
            }
          }
        );
        setCoursesAssociation(objToActivitiesAssociation);
        break;
      case "faculties":
        const objToFacultiesAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return facultiesAssociation.find((elem: any) => elem.id === +key);
            }
          }
        );
        setFacultiesAssociation(objToFacultiesAssociation);
        break;
    }
  };

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" }); //!!
    let newInputForm = {
      edition_code: inputForm.edition_code || null,
      faculties:
        facultiesAssociation &&
        facultiesAssociation.map(({ id }: any) => parseInt(id)),
      lo_id: parseInt(coursesAssociation[0].id),
      start_date: dayjs(inputForm.start_date).format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ"
      ),
      end_date: dayjs(inputForm.end_date).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      edition_name: inputForm?.edition_name || null,
    };
    try {
      await triggetEdition(newInputForm);
      setOpenToast({ s: StatusEnum.OK, message: "" }); //!!
    } catch (error) {
      setOpenToast(undefined); //!!
      console.error(error); //!!
    }
    mutate && mutate(newInputForm);
  };

  return {
    onSubmit,
    handleAssociation,
    setOpenAssociation,
    handleDelete,
    getValues,
    openAssociations,
    openToast,
    setOpenToast,
  };
};
