// Import
import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MultiSelectCst from "@components/InputCst/MultiSelectCst";
import { useTranslation } from "react-i18next";

interface SelectModalCstInterface {
  title?: string;
  description?: string;
  cancelText?: string;
  submitText?: string;
  data?: any;
  onSubmitAction?: any;
  onCancelAction: () => void;
  selectChoiceError?: boolean;
}

const SelectModalCst: React.FC<SelectModalCstInterface> = ({
  title,
  description,
  cancelText,
  submitText,
  data,
  onSubmitAction,
  onCancelAction,
  selectChoiceError,
}) => {
  const theme = useTheme();
  const [value, setValue] = useState([]);
  const { t } = useTranslation(["modal"]);

  return (
    <Box
      sx={{
        minHeight: "200px",
        alignItems: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ margin: "0" }} id="parent-modal-title">
          {title}
        </h2>
        <Button
          onClick={() => onCancelAction()}
          sx={{
            borderRadius: "50%",
            minWidth: "0",
            padding: "0 3px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            span: {
              width: "0",
            },
          }}
        >
          <CloseIcon fontSize="large" />
        </Button>
      </Box>
      <p id="parent-modal-description">{description}</p>
      <Box sx={{ mt: 6, maxWidth: "300px" }}>
        <MultiSelectCst
          id={"lo"}
          label={"Learning Object"}
          options={data}
          value={value}
          disabled={false}
          onChange={(e: any) => {
            console.log(e);
            setValue(e);
          }}
          required={true}
        />
      </Box>
      {selectChoiceError && (
        <Typography
          sx={{
            color: theme.palette.error.main,
            mt: 2,
          }}
        >
          {t("select-choice-error")}
        </Typography>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          columnGap: "20px",
          rowGap: "10px",
          // minHeight: '90px',
          mt: 10,
          alignItems: "end",
        }}
      >
        {cancelText && (
          <Button
            id={"add-button1"}
            variant={"outlined"}
            size={"medium"}
            sx={{
              minWidth: "200px",
            }}
            onClick={() => onCancelAction()}
          >
            {cancelText}
          </Button>
        )}
        {submitText && (
          <Button
            id={"add-button1"}
            variant={"contained"}
            size={"medium"}
            sx={{
              minWidth: "200px",
            }}
            onClick={() => {
              onSubmitAction(value);
            }}
          >
            {submitText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SelectModalCst;
