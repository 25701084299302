import { useAuth } from "@context/auth";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const useSoftwareVersioningNavigation = () => {
  const { t } = useTranslation(["menu", "skills-page", "common"]);
  const { t: modalT } = useTranslation(["modal"]);
  const { user } = useAuth();
  const { id } = useParams();

  let navigation: any[] = [];
  navigation = [
    {
      section: "smartconfigurator.versioning.read",
      text: t("software-versioning.distribution-fe.title"),
      to: "distribution-fe",
      description: t("software-versioning.distribution-fe.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      image: "section-catalog.jpg",
    },
    {
      section: "smartconfigurator.versioning.read",
      text: t("software-versioning.lambda.title"),
      description: t("software-versioning.lambda.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "lambda",
      image: "section-corporate.jpg",
    },
    {
      section: "smartconfigurator.versioning.read",
      text: t("software-versioning.micro-services.title"),
      description: t("software-versioning.micro-services.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "micro-services",
      image: "section-faculties.jpg",
      children: [
        {
          section: "smartconfigurator.versioning.read",
          text: t("software-versioning.micro-services.be4fe.title"),
          description: t(
            "software-versioning.micro-services.be4fe.description"
          ),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "be4fe",
          image: "section-skills.jpg",
        },
        {
          section: "smartconfigurator.versioning.read",
          text: t("software-versioning.micro-services.core.title"),
          description: t("software-versioning.micro-services.core.description"),
          buttonLabel: t("manageButton", { ns: "common" }),
          to: "core",
          image: "section-skills.jpg",
        },
      ],
    },
    {
      section: "smartconfigurator.versioning.read",
      text: t("software-versioning.tagging.title"),
      description: t("software-versioning.tagging.description"),
      buttonLabel: t("manageButton", { ns: "common" }),
      to: "tagging",
      image: "section-skills.jpg",
    },
  ];

  return navigation;
};

export default useSoftwareVersioningNavigation;
