import { useState } from "react";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { isTrueValue } from "@utils/utilsValidators";
import { useFetchDetail, usePostDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import useSWRMutation from "swr/mutation";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";

export const useAddActivity = (pageID: PagesID, idCorporate?: string) => {
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const { columns, mutate, actionsForForms } = useFetchDetail(
    `${ServicesURL.getActivities.replace(":idCorporate", `${idCorporate}`)}`,
    undefined,
    pageID
  );

  const { trigger: triggerPresignedUrl } = useSWRMutation(
    `${ServicesURL.library}/presignedurl?corporateId=${idCorporate}`,
    (url: string, arg: { arg: { fileName: string } }) =>
      fetcher(`${url}&fileName=${arg.arg.fileName}`, MethodType.GET)
  );
  const { trigger: triggerPersist } = usePostDetail(
    `${ServicesURL.library}/persist`
  );

  const { trigger: triggerAddActivity } = useSWRMutation(
    ServicesURL.postActivities.replace(":idCorporate", `${idCorporate}`),
    (url: string, arg: { arg: any }) =>
      fetcher<any>(`${url}`, MethodType.POST, { ...arg.arg })
  );

  const onSubmit = async (inputForm: any, fileUploaded: any) => {
    const fileStoryBoard = fileUploaded?.storyboard_id
      ? fileUploaded.storyboard_id.file.get("file")
      : null;
    let storyboard_id = inputForm?.storyboard_id;
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    if (fileStoryBoard) {
      try {
        const res: { url: string; fileId: string } = await triggerPresignedUrl({
          fileName: fileStoryBoard.name,
        });
        const fileBlob = new Blob([fileStoryBoard], {
          type: fileStoryBoard.type,
        });

        await uploadFile(
          res.url,
          fileBlob,
          MethodType.PUT,
          fileStoryBoard.type
        );
        const objToSend: any = {
          name: fileStoryBoard.name,
          mediaType: "STORYBOARD",
          queryParams: {
            fileId: res.fileId,
          },
        };
        const { id } = await triggerPersist(objToSend);
        storyboard_id = id;
        setOpenToast && setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        console.error(error);
      }
    }
    const type = inputForm.type;
    let objToSend = {};
    if (type === "DA_XAPI") {
      objToSend = {
        type: inputForm.type,
        status: inputForm.status,
        title: inputForm.title || "",
        description: inputForm.description || "",
        detail_description: inputForm.detail_description || "",
        note: inputForm.note || "",
        duration: +inputForm.duration || undefined,
        expected_compl_time: +inputForm.expected_compl_time || undefined,
        cover_id:
          (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
        video_id:
          (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
        is_standalone: isTrueValue(inputForm.is_standalone),
        publish_date: inputForm.publish_date || "",
        obsolescent_date: inputForm.obsolescent_date || "",
        expiration_date: inputForm.expiration_date || "",
        soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
        highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
        highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
        didactical_material:
          inputForm.didactical_material.map((el: any) => +el.id) || "",
        certificate_id: inputForm.certificate_id || null,
        badge_id: inputForm.badge_id || null,
        score: +inputForm.score || undefined,
        persona:
          inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
        xapi_package_id: +inputForm.xapi_package_id?.id,
        format: inputForm.format || null,
        fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
        useful_links: inputForm["useful_links"],
        complexity: inputForm["complexity"] || null,
        knowledge_domain: inputForm["knowledge_domain"] || null,
        learning_purpose: inputForm["learning_purpose"] || null,
        digited_code: inputForm["digited_code"] || null,
        fruition_flag: inputForm?.fruition_flag,
        storyboard_id:
          storyboard_id != null && !!storyboard_id ? storyboard_id : null,
      };
    } else if (type === "DA_SCORM") {
      objToSend = {
        type: inputForm.type,
        title: inputForm.title || "",
        description: inputForm.description || "",
        detail_description: inputForm.detail_description || "",
        note: inputForm.note || "",
        duration: +inputForm.duration || undefined,
        expected_compl_time: +inputForm.expected_compl_time || undefined,
        cover_id:
          (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
        video_id:
          (inputForm["video_id"] && +inputForm["video_id"]?.id) || undefined,
        is_standalone: isTrueValue(inputForm.is_standalone),
        publish_date: inputForm.publish_date || "",
        obsolescent_date: inputForm.obsolescent_date || "",
        expiration_date: inputForm.expiration_date || "",
        soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
        highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
        highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
        didactical_material:
          inputForm.didactical_material?.map((el: any) => +el.id) || "",
        certificate_id: inputForm.certificate_id || null,
        badge_id: inputForm.badge_id || null,
        score: +inputForm.score || undefined,
        persona:
          inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
        scorm_package_id: +inputForm.scorm_package_id?.id,
        format: inputForm.format || null,
        fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
        useful_links: inputForm["useful_links"],
        complexity: inputForm["complexity"] || null,
        knowledge_domain: inputForm["knowledge_domain"] || null,
        learning_purpose: inputForm["learning_purpose"] || null,
        digited_code: inputForm["digited_code"] || null,
        fruition_flag: inputForm?.fruition_flag,
        storyboard_id:
          storyboard_id != null && !!storyboard_id ? storyboard_id : null,
      };
    } else if (type === "DA_EXTERNAL_RES") {
      objToSend = {
        type: inputForm.type,
        title: inputForm.title || "",
        description: inputForm.description || "",
        detail_description: inputForm.detail_description || "",
        note: inputForm.note || "",
        duration: +inputForm.duration || undefined,
        expected_compl_time: +inputForm.expected_compl_time || undefined,
        cover_id:
          (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
        video_id:
          (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
        is_standalone: isTrueValue(inputForm.is_standalone),
        publish_date: inputForm.publish_date || "",
        obsolescent_date: inputForm.obsolescent_date || "",
        expiration_date: inputForm.expiration_date || "",
        soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
        highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
        highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
        didactical_material:
          inputForm.didactical_material?.map((el: any) => +el.id) || "",
        certificate_id: inputForm.certificate_id || null,
        badge_id: inputForm.badge_id || null,
        score: +inputForm.score || undefined,
        persona:
          inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
        external_file_url: inputForm?.external_file_url || "", //???
        format: inputForm.format || null,
        fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
        useful_links: inputForm["useful_links"],
        complexity: inputForm["complexity"] || null,
        knowledge_domain: inputForm["knowledge_domain"] || null,
        learning_purpose: inputForm["learning_purpose"] || null,
        digited_code: inputForm["digited_code"] || null,
        fruition_flag: inputForm?.fruition_flag,
        download_flag: inputForm["download_flag"] || null,
        storyboard_id:
          storyboard_id != null && !!storyboard_id ? storyboard_id : null,
      };
    } else if (type === "DA_YOUTUBE") {
      objToSend = {
        type: inputForm.type,
        title: inputForm.title || "",
        description: inputForm.description || "",
        detail_description: inputForm.detail_description || "",
        note: inputForm.note || "",
        duration: +inputForm.duration || undefined,
        expected_compl_time: +inputForm.expected_compl_time || undefined,
        cover_id:
          (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
        video_id:
          (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
        is_standalone: isTrueValue(inputForm.is_standalone),
        publish_date: inputForm.publish_date || "",
        obsolescent_date: inputForm.obsolescent_date || "",
        expiration_date: inputForm.expiration_date || "",
        soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
        highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
        highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
        didactical_material:
          inputForm.didactical_material?.map((el: any) => +el.id) || "",
        certificate_id: inputForm.certificate_id || null,
        badge_id: inputForm.badge_id || null,
        score: +inputForm.score || undefined,
        persona:
          inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
        youtube_video: inputForm?.youtube_video || "",
        format: inputForm.format || null,
        fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
        useful_links: inputForm["useful_links"],
        complexity: inputForm["complexity"] || null,
        knowledge_domain: inputForm["knowledge_domain"] || null,
        learning_purpose: inputForm["learning_purpose"] || null,
        digited_code: inputForm["digited_code"] || null,
        fruition_flag: inputForm?.fruition_flag,
        storyboard_id:
          storyboard_id != null && !!storyboard_id ? storyboard_id : null,
      };
    } else if (type === "DA_LINKEDIN") {
      objToSend = {
        type: inputForm.type,
        title: inputForm.title || "",
        description: inputForm.description || "",
        detail_description: inputForm.detail_description || "",
        note: inputForm.note || "",
        duration: +inputForm.duration || undefined,
        expected_compl_time: +inputForm.expected_compl_time || undefined,
        cover_id:
          (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
        video_id:
          (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
        is_standalone: isTrueValue(inputForm.is_standalone),
        publish_date: inputForm.publish_date || "",
        obsolescent_date: inputForm.obsolescent_date || "",
        expiration_date: inputForm.expiration_date || "",
        soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
        highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
        highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
        didactical_material:
          inputForm.didactical_material?.map((el: any) => +el.id) || "",
        certificate_id: inputForm.certificate_id || null,
        badge_id: inputForm.badge_id || null,
        score: +inputForm.score || undefined,
        persona:
          inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
        external_id: inputForm?.external_id || "",
        format: inputForm.format || null,
        fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
        useful_links: inputForm["useful_links"],
        complexity: inputForm["complexity"] || null,
        knowledge_domain: inputForm["knowledge_domain"] || null,
        learning_purpose: inputForm["learning_purpose"] || null,
        digited_code: inputForm["digited_code"] || null,
        fruition_flag: inputForm?.fruition_flag,
        storyboard_id:
          storyboard_id != null && !!storyboard_id ? storyboard_id : null,
      };
    } else if (type === "DA_PHYSICAL_CLASS") {
      objToSend = {
        type: inputForm.type,
        title: inputForm.title || "",
        description: inputForm.description || "",
        detail_description: inputForm.detail_description || "",
        note: inputForm.note || "",
        duration: +inputForm.duration || undefined,
        expected_compl_time: +inputForm.expected_compl_time || undefined,
        cover_id:
          (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
        video_id:
          (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
        is_standalone: isTrueValue(inputForm.is_standalone),
        publish_date: inputForm.publish_date || "",
        obsolescent_date: inputForm.obsolescent_date || "",
        expiration_date: inputForm.expiration_date || "",
        soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
        highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
        highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
        didactical_material:
          inputForm.didactical_material?.map((el: any) => +el.id) || "",
        certificate_id: inputForm.certificate_id || null,
        badge_id: inputForm.badge_id || null,
        score: +inputForm.score || undefined,
        persona:
          inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
        min_partecipant: +inputForm.min_partecipant,
        max_partecipant: +inputForm.max_partecipant,
        min_completition_perc: +inputForm.min_completition_perc,
        agenda: inputForm.agenda,
        phisical_edition: inputForm.phisical_edition || [], //???
        format: inputForm.format || null,
        fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
        useful_links: inputForm["useful_links"],
        complexity: inputForm["complexity"] || null,
        knowledge_domain: inputForm["knowledge_domain"] || null,
        learning_purpose: inputForm["learning_purpose"] || null,
        digited_code: inputForm["digited_code"] || null,
        storyboard_id:
          storyboard_id != null && !!storyboard_id ? storyboard_id : null,
        self_enrollment: isTrueValue(inputForm.self_enrollment) || false,
      };
    } else if (type === "DA_VIRTUAL_CLASS") {
      objToSend = {
        type: inputForm.type,
        title: inputForm.title || "",
        description: inputForm.description || "",
        detail_description: inputForm.detail_description || "",
        note: inputForm.note || "",
        duration: +inputForm.duration || undefined,
        expected_compl_time: +inputForm.expected_compl_time || undefined,
        cover_id:
          (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
        video_id:
          (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
        is_standalone: isTrueValue(inputForm.is_standalone),
        publish_date: inputForm.publish_date || "",
        obsolescent_date: inputForm.obsolescent_date || "",
        expiration_date: inputForm.expiration_date || "",
        soft_expiring: !(isTrueValue(inputForm.soft_expiring) || false),
        highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
        highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
        didactical_material:
          inputForm.didactical_material?.map((el: any) => +el.id) || "",
        certificate_id: inputForm.certificate_id || null,
        badge_id: inputForm.badge_id || null,
        score: +inputForm.score || undefined,
        persona:
          inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
        min_partecipant: +inputForm.min_partecipant,
        max_partecipant: +inputForm.max_partecipant,
        min_completition_perc: +inputForm.min_completition_perc,
        mandatory: isTrueValue(inputForm.mandatory) || false,
        agenda: inputForm.agenda,
        virtual_edition: inputForm.virtual_edition || [], //???
        format: inputForm.format || null,
        fixed_expiration: isTrueValue(inputForm.fixed_expiration) || false,
        useful_links: inputForm["useful_links"],
        complexity: inputForm["complexity"] || null,
        knowledge_domain: inputForm["knowledge_domain"] || null,
        learning_purpose: inputForm["learning_purpose"] || null,
        digited_code: inputForm["digited_code"] || null,
        storyboard_id:
          storyboard_id != null && !!storyboard_id ? storyboard_id : null,
        self_enrollment: isTrueValue(inputForm.self_enrollment) || false,
      };
    } else if (type === "DA_SURVEY") {
      objToSend = {
        type: inputForm.type,
        title: inputForm.title || "",
        description: inputForm.description || "",
        detail_description: inputForm.detail_description || "",
        duration: +inputForm.duration || undefined,
        cover_id:
          (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
        video_id:
          (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
        is_standalone: isTrueValue(inputForm.is_standalone) || false,
        publish_date: inputForm.publish_date || "",
        obsolescent_date: inputForm.obsolescent_date || "",
        highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
        highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
        score: +inputForm.score || undefined,
        useful_links: inputForm["useful_links"],
        knowledge_domain: inputForm["knowledge_domain"] || null,
        digited_code: inputForm["digited_code"] || null,
        fruition_flag: isTrueValue(inputForm.fruition_flag),
        survey_id: Number(inputForm["survey_id"]) || null,
        storyboard_id:
          storyboard_id != null && !!storyboard_id ? storyboard_id : null,
      };
    } else if (type === "DA_URL_RES") {
      objToSend = {
        type: inputForm.type,
        title: inputForm.title || "",
        description: inputForm.description || "",
        detail_description: inputForm.detail_description || "",
        note: inputForm.note || "",
        duration: +inputForm.duration || undefined,
        cover_id:
          (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
        video_id:
          (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
        is_standalone: isTrueValue(inputForm.is_standalone),
        publish_date: inputForm.publish_date || "",
        obsolescent_date: inputForm.obsolescent_date || "",
        highlight_for_you: isTrueValue(inputForm.highlight_for_you) || false,
        highlight_explore: isTrueValue(inputForm.highlight_explore) || false,
        didactical_material:
          inputForm.didactical_material?.map((el: any) => +el.id) || "",
        certificate_id: inputForm.certificate_id || null,
        badge_id: inputForm.badge_id || null,
        score: +inputForm.score || undefined,
        persona:
          inputForm?.persona?.map((el: any) => el.value?.toUpperCase()) || [],
        external_file_url: inputForm["external_file_url"] || "",
        format: inputForm.format || null,
        useful_links: inputForm["useful_links"],
        complexity: inputForm["complexity"] || null,
        knowledge_domain: inputForm["knowledge_domain"] || null,
        learning_purpose: inputForm["learning_purpose"] || null,
        digited_code: inputForm["digited_code"] || null,
        fruition_flag: isTrueValue(inputForm["fruition_flag"] || null),
        storyboard_id:
          storyboard_id != null && !!storyboard_id ? storyboard_id : null,
        completion_required: isTrueValue(inputForm["completion_required"]),
      };
    } else if (
      ["DA_MATERIAL", "DA_GOALS", "DA_MEETING", "DA_FINAL_BALANCE"].includes(
        type
      )
    ) {
      objToSend = {
        type: inputForm.type,
        title: inputForm.title || "",
        description: inputForm.description || "",
        detail_description: inputForm.detail_description || "",
        duration: +inputForm.duration || undefined,
        cover_id:
          (inputForm["cover_id"] && +inputForm["cover_id"]?.id) || undefined,
        video_id:
          (inputForm["video_id"] && inputForm["video_id"]?.id) || undefined,
        is_standalone: false, //BY DEFAULT
        useful_links: inputForm["useful_links"],
        digited_code: inputForm["digited_code"] || null,
        fruition_flag: inputForm["fruition_flag"] || null,
      };
    }

    let resetStandaloneItems = {
      publish_date: null,
      obsolescent_date: null,
      expiration_date: null,
      soft_expiring: null,
      highlight_for_you: null,
      highlight_explore: null,
      fixed_expiration: null,
      expected_compl_time: 1,
    };

    if (!isTrueValue(inputForm.is_standalone)) {
      if (["DA_PHYSICAL_CLASS", "DA_VIRTUAL_CLASS"].includes(inputForm.type)) {
        objToSend = {
          ...objToSend,
          ...resetStandaloneItems,
        };
      } else {
        objToSend = {
          ...objToSend,
          ...resetStandaloneItems,
        };
      }
    }

    let resetExpirationFlag = {
      expiration_date: 253402214400000, // 31/12/9999
      soft_expiring: true,
      fixed_expiration: true,
      expected_compl_time: 1,
    };

    if (!isTrueValue(inputForm.expiration_date_flag)) {
      objToSend = {
        ...objToSend,
        ...resetExpirationFlag,
      };
    }

    try {
      await triggerAddActivity(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
    }
    mutate && mutate(objToSend);
  };

  return {
    onSubmit,
    openToast,
    setOpenToast,
    columns,
    actionsForForms,
  };
};
