//REACT AND REACT BASED LIBRARIES
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import Table from "@components/Table";
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import BackgroundImage from "@components/BackgroundImageCst";
import CustomRowAction from "@components/Table/components/CustomRowAction";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { ActionsType } from "src/interfaces/Common";
import ButtonBackPage from "@components/ButtonBackPage";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { MRT_PaginationState } from "material-react-table";
import DownloadIcon from "@mui/icons-material/Download";
import { useFilters } from "@context/filters";

const UserSubscribedTOJ: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["toj-user-subscribed-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  const navigate = useNavigate();
  const { filters, setFilters } = useFilters();

  const { id, idEdition } = useParams();

  const [idDeleted, setIdDeleted] = useState<any>();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  //!! CHIAMATA GET
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getEnrolledUserTOJEdition.replace(
      ":editionId",
      idEdition || ""
    )}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    PagesID["corporate.toj-subscribed"] //!! (SOLO DI ESEMPIO) MODIFICARE PAGE ID CON QUELLO INSERITO NEL FILE DI CONFIG
  );

  // const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();

  //?? GESTIONE PER CHIAMATA DELETE (A SECONDA DELLE NECESSITÀ)
  const { trigger: triggerDelete } = useSWRMutation(
    `${ServicesURL.deleteEnrollmentTOJ.replace(":corporateId", id || "1")}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    (url: string) => {
      return fetcher<any>(
        `${url}?courseId=${idDeleted?.objectId}&editionId=${idDeleted?.tojEditionId}&userId=${idDeleted?.userId}`,
        MethodType.DELETE
      );
    }
  );

  const { trigger: triggerDownloadEnrolled } = useSWRMutation(
    `${ServicesURL.getEnrolledUserTOJEdition.replace(
      ":editionId",
      idEdition || ""
    )}/csvDetails`,
    (url: string, { arg }: { arg: string }) =>
      fetcher(`${url}${arg}`, MethodType.GET)
  );

  const handleDelete = React.useCallback(async () => {
    if (idDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDelete();
        const newData = [
          ...dataTable.usersAssociatedWithTOJEdition.filter(
            (el: any) => el.id !== idDeleted?.userId
          ),
        ];
        mutate &&
          mutate({
            ...dataTable,
            ...{ usersAssociatedWithTOJEdition: newData },
          });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setIdDeleted(undefined);
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [idDeleted, triggerDelete, dataTable, mutate]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA
  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.type) {
        case "button": {
          // if (action.icon === "delete") {
          return {
            ...action,
            callBack: (row: any) => {
              setIdDeleted(row);
            },
          };
          // }
          // return action;
        }
        case "link": {
          return {
            ...action,
            callBack: (row: any) => {
              navigate(
                `${row.userId}/${row.objectId}/${row.tojEditionId}/manage-user-toj-edition`
              );
            },
          };
        }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const downloadEnrolled = async () => {
    let indexFilter =
      Object.keys(filters).find((filter: any) =>
        filter.includes(PagesID["corporate.toj-subscribed"])
      ) || undefined;
    let filteredQueryParams = "";
    if (indexFilter) {
      for (const key in filters[indexFilter]) {
        filteredQueryParams =
          filteredQueryParams + `&${key}=${filters[indexFilter][key]}`;
      }
    }
    try {
      let response = await triggerDownloadEnrolled(
        `?editionId=${idEdition}&corporateId=${id || "1"}${filteredQueryParams}`
      );
      let fileName = `users_subscribed_page n^${pagination.pageIndex + 1}.csv`;
      const blob = new Blob([response], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-toj.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.toj-subscribed"]}
            data={dataTable?.usersAssociatedWithTOJEdition || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar?.map((action: any) => {
              if (action.accessorKey === "download_enrolled") {
                return {
                  ...action,
                  callBack:
                    idEdition &&
                    dataTable?.usersAssociatedWithTOJEdition &&
                    dataTable?.usersAssociatedWithTOJEdition.length > 0
                      ? () => downloadEnrolled()
                      : null,
                  getIcon: (row: any) => <DownloadIcon />,
                };
              } else {
                return action;
              }
            })}
            configurationActionForRow={actionForRow}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.totalElements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
            getPagination={setPagination}
          />
        </Box>
      </Container>

      <ModalConfirmsCst
        open={!!idDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setIdDeleted(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default UserSubscribedTOJ;
