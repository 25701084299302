import { useMemo } from "react";

interface MenuItem {
  section: string;
}

interface DataDetail {
  [key: string]: boolean;
}

export const useFilteredBadge = (
  menuItems: MenuItem[],
  dataDetail: DataDetail,
  valuesToCheck: string[],
  permissions: string[]
) => {
  const filteredBadge = useMemo(() => {
    let result = menuItems;

    valuesToCheck.forEach((value, index) => {
      if (dataDetail[value] === undefined || !dataDetail[value]) {
        const permission = permissions[index];
        result = result.filter((el) => el.section !== permission);
      }
    });

    return result;
  }, [menuItems, dataDetail, valuesToCheck, permissions]);

  return filteredBadge;
};
