import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";
import ButtonBackPage from "../../../../../../../components/ButtonBackPage";
import { TableTabs } from "../../../../../../../components/TableTabs/Index";
import { useFetchTable } from "../../../../../../../hooks/useFetchDataTable";
import { ServicesURL } from "../../../../../../../utils/utilsApi";
import { useParams } from "react-router-dom";

const ViewUserConfirm: React.FC = () => {
  const { t } = useTranslation(["training-needs-details-user"]);
  const { id, idCampaign, idDetail } = useParams();

  useResetFilters();

  const { columns, dataTable, handleFetchData, actionsTopToolbar } =
    useFetchTable(
      `${ServicesURL.digitedUsersCampaign}?campaign_detail_id=${idDetail}`.replace(
        "{campaignId}",
        `${idCampaign}`
      ),
      PagesID["corporate.campaign.visualize.confirm.user"]
    );

  const tabs = [
    {
      [t("knowledge")]: (
        <Table
          enableRowActions={false}
          id={PagesID["corporate.campaign.visualize.confirm.user"]}
          data={dataTable?.output}
          columns={columns || []}
          onChangeFilterForm={handleFetchData}
          configurationTopToolbar={actionsTopToolbar}
          totalPages={dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
        />
      ),
    },
  ];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage goBack={2} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <TableTabs tabs={tabs} />
        </Box>
      </Container>
    </AnimationFadeIn>
  );
};

export default ViewUserConfirm;
