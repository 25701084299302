//REACT AND REACT BASED LIBRARIES
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import { Box, Container } from "@mui/material";
import AddEditFormCst from "@components/AddEditFormCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import {
  StatusEnum,
  StatusModalConfirmTypes,
} from "@components/ModalConfirmsCst/types";
import { ServicesURL } from "@utils/utilsApi";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { isTrueValue } from "@utils/utilsValidators";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { formatDateWithMinutes } from "@utils/utilsDate";
import { Association } from "src/interfaces/Common";
import ModalCst from "@components/ModalCst";

const GroupsEnrollments: React.FC = () => {
  const { t } = useTranslation(["enrollment-page", "form", "modal", "common"]);
  const location = useLocation();
  const [openAssociations, setOpenAssociations] = useState<
    boolean | Association
  >(false);
  const [coursesAssociation, setCoursesAssociation] = useState<any>([]);
  const [pathAssociation, setPathAssociation] = useState<any>([]);
  const [activityAssociation, setActivityAssociation] = useState<any>([]);
  const LoList = [
    ...activityAssociation,
    ...coursesAssociation,
    ...pathAssociation,
  ];
  const LoMaxSelection = 100;

  const { id, userDomainId, idGroup } = useParams();

  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState<StatusModalConfirmTypes>();
  const [enrollmentExceeded, setEnrollmentExceeded] = useState<boolean>(false);
  const [enrollmentErrorMultiple, setEnrollmentErrorMultiple] =
    useState<boolean>(false);
  const [enrollmentErrorSingle, setEnrollmentErrorSingle] =
    useState<boolean>(false);
  const [duplicateEnrolledElements, setDuplicateEnrolledElements] = useState<
    { value: number; label: string }[]
  >([]);
  const [savedInputForm, setSavedInputForm] = useState<any>(null);
  const [savedEnrollmentCount, setSavedEnrollmentCount] = useState<any>(0);
  const [selectChoiceError, setSelectChoiceError] = useState(false);

  useEffect(() => {
    if (LoList.length > LoMaxSelection) {
    }
  }, [LoList]);

  const setOpenAssociation = (accessorKey: string, value: boolean) => {
    switch (accessorKey) {
      case "activities":
        setOpenAssociations(
          value
            ? {
                accessorKey: "activities",
                mediaType: "activities",
                type: "table",
                titleKey: "activities-association",
                pageId: PagesID["corporate.catalog.activities"],
                manageConfigs: {
                  is_standalone: {
                    hasFilter: false,
                  },
                },
                service: `${ServicesURL.getActivities.replace(
                  ":idCorporate",
                  id || ""
                )}?is_standalone=true`,
              }
            : value
        );
        break;
      case "courses":
        setOpenAssociations(
          value
            ? {
                accessorKey: "courses",
                mediaType: "courses",
                type: "table",
                titleKey: "course-association",
                pageId: PagesID["corporate.catalog.courses"],
                manageConfigs: {
                  is_standalone: {
                    hasFilter: false,
                  },
                },
                service: `${ServicesURL.getCoursesAssociation.replace(
                  ":idCorporate",
                  id || ""
                )}?is_standalone=true`,
              }
            : value
        );
        break;
      case "paths":
        setOpenAssociations(
          value
            ? {
                accessorKey: "paths",
                mediaType: "paths",
                type: "table",
                titleKey: "path-association",
                pageId: PagesID["corporate.catalog.path"],
                manageConfigs: {
                  is_standalone: {
                    hasFilter: false,
                  },
                },
                service: `${ServicesURL.getPaths.replace(
                  ":idCorporate",
                  id || ""
                )}?is_standalone=true`,
              }
            : value
        );
        break;
    }
  };

  const getValues = (accessorKey: string) => {
    switch (accessorKey) {
      case "activities":
        return activityAssociation;
      case "courses":
        return coursesAssociation;
      case "paths":
        return pathAssociation;
    }
  };

  const handleDelete = (accessorKey: string, e: any) => {
    switch (accessorKey) {
      case "activities":
        setActivityAssociation(
          activityAssociation.filter((element: any) => element.id !== e.id)
        );
        break;
      case "courses":
        setCoursesAssociation(
          coursesAssociation.filter((element: any) => element.id !== e.id)
        );
        break;
      case "paths":
        setPathAssociation(
          pathAssociation.filter((element: any) => element.id !== e.id)
        );
        break;
    }
  };

  const handleAssociation = (accessorKey: string, value: any) => {
    switch (accessorKey) {
      case "activities":
        const objToActivityAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return activityAssociation.find((elem: any) => elem.id === +key);
            }
          }
        );
        setActivityAssociation(objToActivityAssociation);
        break;
      case "courses":
        const objToCoursesAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return coursesAssociation.find((elem: any) => elem.id === +key);
            }
          }
        );
        setCoursesAssociation(objToCoursesAssociation);
        break;
      case "paths":
        const objToPathsAssociation: any = Object.keys(value).map(
          (key: string) => {
            if (key.includes("||")) {
              return {
                id: key.split("||")[0],
                label: key.split("||")[1],
              };
            } else {
              return pathAssociation.find((elem: any) => elem.id === +key);
            }
          }
        );
        setPathAssociation(objToPathsAssociation);
        break;
    }
  };

  const { columns, dataTable, mutate } = useFetchTable(
    null,
    PagesID["groups-enrollments"]
  );

  const { trigger: triggerPOSTMassive } = useSWRMutation(
    `${ServicesURL.postGroupEnrollmentMassive}/`,
    (
      url: string,
      {
        arg,
      }: {
        arg: any;
      }
    ) => {
      return fetcher<any>(`${url}`, MethodType.POST, arg);
    }
  );

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });

    const activityIds = activityAssociation.map((activity: any) => {
      return Number(activity.id);
    });
    const coursesIds = coursesAssociation.map((course: any) => {
      return Number(course.id);
    });
    const pathIds = pathAssociation.map((path: any) => {
      return Number(path.id);
    });

    const loSelected = activityIds.concat(coursesIds, pathIds);

    let objToSendMassive = {
      isAdmin: true,
      enrollDate: `${formatDateWithMinutes(new Date())}`,
      enrollType: inputForm.enrollType,
      domainId: (userDomainId && +userDomainId) || null,
      forcedExpiration: inputForm.forcedExpiration,
      groupType: location.state.row.groupType,
      learningObject: {
        corporateId: (id && +id) || null,
        forcedEnroll: false,
        objectId: loSelected || null,
      },
      daysToComplete: +inputForm.daysToComplete || null,
      expirationDate: inputForm.expirationDate
        ? formatDateWithMinutes(new Date(inputForm.expirationDate)).split(
            " "
          )[0]
        : null,
      isExpirationFixed: isTrueValue(inputForm.expiration_date_flag)
        ? !isTrueValue(inputForm.isExpirationFixed)
        : null,
      isExpirationHard:
        inputForm.isExpirationHard !== null
          ? isTrueValue(inputForm.isExpirationHard)
          : null,
      massiveListDomainId: (userDomainId && [+userDomainId]) || null,
      massiveListGroupId: (idGroup && [+idGroup]) || null,
    };

    if (loSelected.length > LoMaxSelection) {
      setOpenToast(undefined);
      setEnrollmentExceeded(true);
      return;
    }

    try {
      let impactedLo = null;
      let impactedUsers = null;
      let response = await triggerPOSTMassive(objToSendMassive);
      impactedLo = response?.newEnrollments;
      impactedUsers = response?.totalDistinctPart;
      if (response.elementsAlreadyEnrolled.length) {
        setOpenToast(undefined);
        const countItems = response.elementsAlreadyEnrolled.length;
        setDuplicateEnrolledElements(response.elementsAlreadyEnrolled);
        setSavedInputForm(inputForm);
        setSavedEnrollmentCount(impactedLo);
        if (countItems === 1) {
          setEnrollmentErrorSingle(true);
        } else {
          setEnrollmentErrorMultiple(true);
        }
      } else {
        setOpenToast({
          s: StatusEnum.OK,
          title: `${t("enrollment.taken-charge", { ns: "modal" })}`,
          successMessage: `${t("massiveEnrollment.impacted-lo", {
            ns: "modal",
          })} ${impactedLo}`,
          customContent: (
            <span>
              {t("massiveEnrollment.impacted-users", { ns: "modal" })}{" "}
              {impactedUsers}
            </span>
          ),
        });
      }
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    mutate && mutate();
  };

  const inputsException = (col: any, inputForm: any) => {
    let expirationToDisable = [
      "isExpirationFixed",
      "expirationDate",
      "isExpirationHard",
      "daysToComplete",
    ];

    if (col.accessorKey === "forcedExpiration") {
      if (
        inputForm.enrollType === "TOP" ||
        inputForm.enrollType === "TOP_MANDATORY"
      ) {
        if (
          inputForm?.isExpirationFixed &&
          !isTrueValue(inputForm?.isExpirationFixed)
        ) {
          col.disabled = false;
          col.required = true;
        } else {
          col.disabled = true;
          col.required = false;
          inputForm.forcedExpiration = false;
        }
      } else {
        col.disabled = true;
        col.required = false;
        inputForm.forcedExpiration = false;
      }
    }

    if (
      isTrueValue(inputForm["expiration_date_flag"]) &&
      col.accessorKey === "expiration_date_flag"
    ) {
      col.callBack = (e: any, row: any) => {
        return {
          ...inputForm,
          expiration_date_flag: e,
          isExpirationFixed: undefined,
          expirationDate: undefined,
          isExpirationHard: undefined,
          daysToComplete: undefined,
        };
      };
    }

    if (col.accessorKey === "isExpirationFixed") {
      col.callBack = (e: any, row: any) => {
        return {
          ...inputForm,
          isExpirationFixed: e,
          expirationDate: undefined,
          daysToComplete: undefined,
        };
      };
    }

    if (
      inputForm?.enrollType &&
      inputForm?.enrollType !== "TOP" &&
      col.accessorKey === "enrollType"
    ) {
      col.callBack = (e: any, row: any) => {
        return { ...inputForm, enrollType: e };
      };
    }

    if (expirationToDisable.includes(col.accessorKey)) {
      if (
        [undefined, "false", false].includes(inputForm["expiration_date_flag"])
      ) {
        col.required = false;
        col.disabled = true;
      } else {
        col.required = true;
        col.disabled = false;
      }
    }

    if (col.accessorKey === "expirationDate") {
      if (
        inputForm["isExpirationFixed"] === undefined ||
        isTrueValue(inputForm["isExpirationFixed"])
      ) {
        col.disabled = true;
        col.required = false;
      } else if (
        [undefined, "false", null, false].includes(
          inputForm["isExpirationFixed"]
        )
      ) {
        col.disabled = false;
        col.required = true;
      }
    }

    if (col.accessorKey === "daysToComplete") {
      if (
        inputForm["isExpirationFixed"] === undefined ||
        !isTrueValue(inputForm["isExpirationFixed"])
      ) {
        col.disabled = true;
        col.required = false;
      } else {
        col.disabled = false;
        col.required = true;
      }
    }

    if (col.accessorKey === "enrollType") {
      let optionsToDisable = optionBasedOnPriority();
      if (optionsToDisable) {
        optionsToDisable = [...optionsToDisable, "AUTO", "BOTTOM"];
      } else {
        optionsToDisable = ["AUTO", "BOTTOM"];
      }
      col.optionsToDisable = optionsToDisable;
    }

    return col;
  };

  const optionBasedOnPriority: any = () => {
    let priority: any = {
      TOP_MANDATORY: 0,
      TOP: 1,
      AUTO: 2,
      BOTTOM: 3,
      PRE_AUTO: 4,
      PRE_BOTTOM: 5,
    };
    if (dataTable?.enrollmentResponses) {
      if (dataTable?.enrollmentResponses[0]?.enrollType) {
        return Object.keys(priority).filter((el: any, index: any) => {
          return (
            priority[dataTable?.enrollmentResponses[0]?.enrollType] <=
            priority[el]
          );
        });
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const getExpirationValue = () => {
    const isNotSet =
      dataTable?.enrollmentResponses[0]?.isExpirationFixed === true &&
      dataTable?.enrollmentResponses[0]?.daysToComplete === null &&
      dataTable?.enrollmentResponses[0]?.expirationDate === "9999-12-31" &&
      dataTable?.enrollmentResponses[0]?.isExpirationHard === false;

    if (!dataTable?.enrollmentResponses[0]) {
      return {};
    }

    if (!!isNotSet) {
      const newObject = {
        ...dataTable.enrollmentResponses[0],
        expiration_date_flag: false,
        isExpirationFixed: undefined,
        expirationDate: undefined,
        isExpirationHard: undefined,
        daysToComplete: undefined,
      };
      return newObject;
    } else {
      return {
        ...dataTable.enrollmentResponses[0],
        isExpirationFixed: !isTrueValue(
          dataTable.enrollmentResponses[0].isExpirationFixed
        ),
        expiration_date_flag: true,
      };
    }
  };

  const overwriteEnrollments = async (data: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });

    if (data.length === 0) {
      setSelectChoiceError(true);
      setOpenToast(undefined);
      return;
    }

    const idList = data.map((item: any) => {
      return Number(item.value);
    });

    if (!savedInputForm) return;

    let objToSendMassive = {
      isAdmin: true,
      enrollDate: `${formatDateWithMinutes(new Date())}`,
      enrollType: savedInputForm.enrollType,
      domainId: (userDomainId && +userDomainId) || null,
      forcedExpiration: savedInputForm.forcedExpiration,
      groupType: location.state.row.groupType,
      learningObject: {
        corporateId: (id && +id) || null,
        forcedEnroll: true,
        objectId: idList.length && idList,
      },
      daysToComplete: +savedInputForm.daysToComplete || null,
      expirationDate: savedInputForm.expirationDate
        ? formatDateWithMinutes(new Date(savedInputForm.expirationDate)).split(
            " "
          )[0]
        : null,
      isExpirationFixed: isTrueValue(savedInputForm.expiration_date_flag)
        ? !isTrueValue(savedInputForm.isExpirationFixed)
        : null,
      isExpirationHard:
        savedInputForm.isExpirationHard !== null
          ? isTrueValue(savedInputForm.isExpirationHard)
          : null,
      massiveListDomainId: (userDomainId && [+userDomainId]) || null,
      massiveListGroupId: (idGroup && [+idGroup]) || null,
    };

    try {
      let impactedLo = null;
      let impactedUsers = null;
      let response = await triggerPOSTMassive(objToSendMassive);
      impactedLo = response?.newEnrollments;
      impactedUsers = response?.totalDistinctPart;
      setEnrollmentErrorMultiple(false);
      setOpenToast({
        s: StatusEnum.OK,
        title: `${t("enrollment.taken-charge", { ns: "modal" })}`,
        successMessage: `${t("massiveEnrollment.impacted-lo", {
          ns: "modal",
        })} ${impactedLo + savedEnrollmentCount}`,
        customContent: (
          <span>
            {t("massiveEnrollment.impacted-users", { ns: "modal" })}{" "}
            {impactedUsers}
          </span>
        ),
      });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    mutate && mutate();
  };

  const overwriteSingleEnrollment = async () => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    if (!savedInputForm) return;

    let objToSendMassive = {
      isAdmin: true,
      enrollDate: `${formatDateWithMinutes(new Date())}`,
      enrollType: savedInputForm.enrollType,
      domainId: (userDomainId && +userDomainId) || null,
      forcedExpiration: savedInputForm.forcedExpiration,
      groupType: location.state.row.groupType,
      learningObject: {
        corporateId: (id && +id) || null,
        forcedEnroll: true,
        objectId: duplicateEnrolledElements[0]?.value
          ? [duplicateEnrolledElements[0]?.value]
          : null,
      },
      daysToComplete: +savedInputForm.daysToComplete || null,
      expirationDate: savedInputForm.expirationDate
        ? formatDateWithMinutes(new Date(savedInputForm.expirationDate)).split(
            " "
          )[0]
        : null,
      isExpirationFixed: isTrueValue(savedInputForm.expiration_date_flag)
        ? !isTrueValue(savedInputForm.isExpirationFixed)
        : null,
      isExpirationHard:
        savedInputForm.isExpirationHard !== null
          ? isTrueValue(savedInputForm.isExpirationHard)
          : null,
      massiveListDomainId: (userDomainId && [+userDomainId]) || null,
      massiveListGroupId: (idGroup && [+idGroup]) || null,
    };

    try {
      let impactedLo = null;
      let impactedUsers = null;
      let response = await triggerPOSTMassive(objToSendMassive);
      impactedLo = response?.newEnrollments;
      impactedUsers = response?.totalDistinctPart;
      setEnrollmentErrorSingle(false);
      setOpenToast({
        s: StatusEnum.OK,
        title: `${t("enrollment.taken-charge", { ns: "modal" })}`,
        successMessage: `${t("massiveEnrollment.impacted-lo", {
          ns: "modal",
        })} ${impactedLo + savedEnrollmentCount}`,
        customContent: (
          <span>
            {t("massiveEnrollment.impacted-users", { ns: "modal" })}{" "}
            {impactedUsers}
          </span>
        ),
      });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    mutate && mutate();
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-certificates.jpg" //PAGINA DI SFONDO
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={getExpirationValue()}
              columns={columns}
              inputsException={inputsException}
              onSubmit={onSubmit}
              actionsForForms={[]}
              LoCounter={true}
              LoCountValue={LoList.length}
              LoCountMaxSelection={LoMaxSelection}
              backButton={() => {
                navigate(-1);
              }}
              setOpenAssociation={(accessorKey, open) =>
                setOpenAssociation(accessorKey, open)
              }
              handleDelete={(accessorKey, el) => handleDelete(accessorKey, el)}
              handleAssociation={(accessorKey, value) =>
                handleAssociation(accessorKey, value)
              }
              getValues={(accessorKey) => getValues(accessorKey)}
              association={openAssociations}
              onReset={() => {
                setActivityAssociation([]);
                setCoursesAssociation([]);
                setPathAssociation([]);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={""}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
      <ModalCst
        open={enrollmentExceeded}
        setOpen={setEnrollmentExceeded}
        typeOfModal={"enrollmentExceeded"}
        title={t("massiveEnrollment.association-error", { ns: "modal" }) || ""}
        description={t("massiveEnrollment.enrollment-exceeded", {
          ns: "modal",
        }).replace("{{maxValue}}", `${LoMaxSelection}`)}
      />
      <ModalConfirmsCst
        open={!!enrollmentErrorSingle}
        title={t("warning", { ns: "common" })}
        description={t("single-enrollment-error", { ns: "modal" }).replace(
          "{{LO}}",
          `${duplicateEnrolledElements[0]?.label || ""}`
        )}
        onCallBackClosed={() => {
          setEnrollmentErrorSingle(false);
        }}
        onActionConfirmed={() => overwriteSingleEnrollment()}
        status={openToast}
      />
      <ModalCst
        open={!!enrollmentErrorMultiple}
        setOpen={setEnrollmentErrorMultiple}
        typeOfModal={"selectChoice"}
        selectChoiceError={selectChoiceError}
        data={duplicateEnrolledElements}
        onSubmit={overwriteEnrollments}
      />
    </AnimationFadeIn>
  );
};

export default GroupsEnrollments;
