// Import
import { Box, Button } from "@mui/material";
import React from "react";

interface ConfirmModalCstInterface {
  title?: string;
  description?: string;
  cancelText?: string;
  submitText?: string;
  data?: any;
  onSubmitAction?: any;
  onCancelAction: () => void;
}

const ConfirmModalCst: React.FC<ConfirmModalCstInterface> = ({
  title,
  description,
  cancelText,
  submitText,
  data,
  onSubmitAction,
  onCancelAction,
}) => {
  return (
    <Box
      sx={{
        minHeight: "200px",
        alignItems: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem 0",
          borderBottom: "1px dashed #6d6d6d",
        }}
      >
        <h1 style={{ margin: "0" }} id="parent-modal-title">
          {title}
        </h1>
      </Box>
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem 0",
          borderBottom: "1px dashed #6d6d6d",
        }}
        id="parent-modal-description"
      >
        {description}
      </p>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          columnGap: "20px",
          rowGap: "10px",
          minHeight: "60px",
          alignItems: "end",
        }}
      >
        {cancelText && (
          <Button
            id={"add-button1"}
            variant={"text"}
            size={"medium"}
            sx={{
              minWidth: "200px",
              textDecoration: "underline",
            }}
            onClick={() => onCancelAction()}
          >
            {cancelText}
          </Button>
        )}
        {submitText && (
          <Button
            id={"add-button1"}
            variant={"contained"}
            size={"medium"}
            sx={{
              minWidth: "200px",
            }}
            onClick={() => {
              data.callBackSubmit();
              onSubmitAction();
            }}
          >
            {submitText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ConfirmModalCst;
