import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import LinkIcon from "@mui/icons-material/Link";

import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, downloadBlobFile, onErrorMessage } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ButtonBackPage from "@components/ButtonBackPage";
import { ActionsType, ColumnsType } from "src/interfaces/Common";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import { translateHeaders } from "@utils/utilsTranslateHeaders";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { MandatoryInterface } from "./types";
import { useFetchTable } from "@hooks/useFetchDataTable";
import AnimationFadeIn from "@components/AnimationFadeIn";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import { useErrorContext } from "@context/ErrorContext";
import dayjs from "dayjs";
import DownloadIcon from "@mui/icons-material/Download";
import { useAuth } from "@context/auth";
import { buildDateStr } from "@utils/utilsDate";
import { useFilters } from "@context/filters";
import { MRT_PaginationState } from "material-react-table";

const SubscribedUsers: React.FC<{
  page: PagesID;
  showIssuedCertificates?: boolean;
}> = ({ page, showIssuedCertificates }) => {
  const { t, i18n } = useTranslation([
    "customers-page",
    "toast-message",
    "modal",
  ]);
  const { user } = useAuth();
  const { filters, setFilters } = useFilters();
  const { setErrorContext, setOpenErrorContext } = useErrorContext();
  const { id = "1", idLO, idEdition, idEditionLOActivity } = useParams();
  const navigate = useNavigate();

  const [openToastDeleted, setOpenToastDeleted] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const [enrollDeleted, setEnrollDeleted] = useState<any>();
  const [enrollGroupDeleted, setEnrollGroupDeleted] = useState<any>();
  const [enrollSingleEdit, setEnrollSingleEdit] = useState<any>();
  const [enrollGroupEdit, setEnrollGroupEdit] = useState<any>();
  const location = useLocation();
  const [groupIDWarning, setGroupIDWarning] = useState<any>();

  const [editDescriptionErrorModal, setEditDescriptionErrorModal] =
    useState(false);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const {
    dataTable,
    columns,
    actionForRow,
    actionsTopToolbar,
    handleFetchData,
    mutate,
  } = useFetchTable(
    `${
      idEdition ? ServicesURL.getEnrollEditionDetail : ServicesURL.getEnrollment
    }?corporateId=${id}${idLO && !idEdition ? `&objectId=${idLO}` : ``}${
      idEdition ? `&editionId=${idEdition}` : ``
    }${
      location?.state?.setGroup && location?.state?.row
        ? `&massiveGroupId=${location?.state?.row[location?.state?.setGroup]}`
        : ``
    }${showIssuedCertificates ? "&showIssuedCertificate=true" : ""}`,
    idEdition ? PagesID["enrollment.subscribed.edition"] : page
  );

  // se nessuna riga ha un certificato disabilito il tasto download massivo
  const certificatesAvailable = useMemo(() => {
    return dataTable?.enrollmentResponses?.find(
      (el: any) => !!el.certificateId
    );
  }, [dataTable]);

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID["corporate"]
  );

  const { trigger: triggerDelete } = useSWRMutation(
    `${ServicesURL.deleteEnrollment}`,
    (url: string, { arg }: { arg: { enrollmentEId: string } }) =>
      fetcher(`${url}?enrollmentEId=${arg.enrollmentEId}`, MethodType.DELETE)
  );

  const { trigger: triggerDownloadEnrolled } = useSWRMutation(
    `${ServicesURL.downloadEnrolledEdition}`,
    (url: string, { arg }: { arg: string }) =>
      fetcher(`${url}${arg}`, MethodType.GET)
  );

  const { trigger: triggerDeleteEdition } = useSWRMutation(
    `${ServicesURL.getEnrollEdition}`,
    (url: string, { arg }: { arg: { studentEid: string; objectId: string } }) =>
      fetcher(
        `${url}?corporateId=${id}&objectId=${arg.objectId}&studentEid=${arg.studentEid}&editionId=${idEdition}`,
        MethodType.DELETE
      )
  );

  const { trigger: triggerDeleteGroups } = useSWRMutation(
    `${ServicesURL.postEnrollmentMassive}`,
    (url: string, { arg }: { arg: { groupId: any; groupType: any } }) =>
      fetcher(
        `${url}?corporateId=${id}&groupId=${arg.groupId}&groupType=${arg.groupType}&objectId=${idLO}`,
        MethodType.DELETE
      )
  );

  const configurationActionForRow: ActionsType[] | undefined =
    React.useMemo(() => {
      return actionForRow?.map((action: any) => {
        if (action.type === "button" && action.icon === "download") {
          return {
            ...action,
            callBack: async (row: any) => {
              const payload = {
                learnerId: row.userId,
                objectId: row.learningObject.objectId,
                certificateId: row.certificateId,
              };

              try {
                setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
                const url = `${ServicesURL.getIssuedCertificate.replace(
                  ":certificateId",
                  payload.certificateId
                )}?objectId=${payload.objectId}&learnerId=${payload.learnerId}`;
                await downloadBlobFile(
                  url,
                  null,
                  `issued_certificate_lo_${payload.objectId}_learner_${payload.learnerId}.pdf`,
                  MethodType.GET
                );
                //await triggerDownloadCertificate(payload);
                setOpenToast({ s: StatusEnum.OK, message: "" });
              } catch (error) {
                const message = onErrorMessage(error);
                setOpenToast({ s: StatusEnum.KO, message: message });
                console.error(error);
              }
            },
            getIcon: (row: any) => <DownloadIcon />,
          };
        } else {
          if (action.type === "button" && action.icon !== "delete") {
            if (idEdition) return {};
            return {
              ...action,
              callBack: (row: any) => {
                if (
                  row?.learningObject?.blendedParentId &&
                  row?.learningObject?.blendedParentId !== Number(idLO) &&
                  action.key !== "enroll_history"
                ) {
                  setEditDescriptionErrorModal(true);
                  setTitleError("Modifica Iscrizione");
                  setDescriptionError(
                    `L'utente è stato iscritto all'oggetto formativo tramite Percorso/Corso ID "${row.learningObject.blendedParentId}". Per poter modificare l'iscrizione dell'utente all'oggetto formativo, è necessario modificare l'iscrizione sul Percorso/Corso ID "${row.learningObject.blendedParentId}"`
                  );
                  return;
                }

                const isAfterStartEdition = dayjs().isAfter(
                  dayjs(row["editionStartDate"])
                );
                const isBeforeEndEdition = dayjs().isBefore(
                  dayjs(row["editionEndDate"])
                );

                const isEditionInProgress =
                  isAfterStartEdition && isBeforeEndEdition;

                if (
                  !!row?.editionStartDate &&
                  isEditionInProgress &&
                  action.key !== "enroll_history"
                ) {
                  setErrorContext({
                    title: "Warning",
                    content: `${t("warning-startEdition-enroll", {
                      ns: "modal",
                    })}`,
                  });
                  setOpenErrorContext(true);
                  return;
                }

                if (action.key === "group_enroll") {
                  setEnrollGroupEdit(row);
                  setGroupIDWarning(row?.massiveGroupId);
                } else if (action.key === "single_enroll") {
                  navigate(`${row.userId}/enrollment`);
                  // if (row?.blended) {
                  //   // setEnrollSingleEdit(row)
                  //   setErrorContext({
                  //     title: "Warning",
                  //     content: `${t("warning-blended-enroll", {
                  //       ns: "modal",
                  //     })}`,
                  //   });
                  //   setOpenErrorContext(true);
                  // } else {
                  //   navigate(`${row.userId}/enrollment`);
                  // }
                } else if (action.key === "enroll_history") {
                  navigate(`${row.userId}/enrollment-history`);
                }
              },
            };
          }
          if (action.type === "button" && action.icon === "delete") {
            if (action.key === "single_enroll") {
              return {
                ...action,
                callBack: (row: any) => {
                  if (
                    !!row?.learningObject?.blendedParentId &&
                    row?.learningObject?.blendedParentId !== Number(idLO)
                  ) {
                    setEditDescriptionErrorModal(true);
                    setTitleError("Elimina Iscrizione");
                    setDescriptionError(
                      `L'utente è stato iscritto all'oggetto formativo tramite Percorso/Corso ID "${row.learningObject.blendedParentId}". Per poter eliminare l'iscrizione dell'utente all'oggetto formativo, è necessario eliminare l'iscrizione sul Percorso/Corso ID "${row.learningObject.blendedParentId}"`
                    );
                    return;
                  }
                  setEnrollDeleted(row);
                  setGroupIDWarning(row?.massiveGroupId);
                  // if (row?.blended) {
                  //   setErrorContext({
                  //     title: "Warning",
                  //     content: `${t("warning-blended-enroll", {
                  //       ns: "modal",
                  //     })}`,
                  //   });
                  //   setOpenErrorContext(true);
                  // } else {
                  //   setEnrollDeleted(row);
                  //   setGroupIDWarning(row?.massiveGroupId);
                  // }
                },
                labelTranslate: (action.label as any)[i18n.language],
              };
            } else {
              if (idEdition) return {};
              return {
                ...action,
                callBack: (row: any) => {
                  if (
                    !!row?.learningObject?.blendedParentId &&
                    row?.learningObject?.blendedParentId !== Number(idLO)
                  ) {
                    setEditDescriptionErrorModal(true);
                    setTitleError("Elimina Iscrizione");
                    setDescriptionError(
                      `L'utente è stato iscritto all'oggetto formativo tramite Percorso/Corso ID "${row.learningObject.blendedParentId}". Per poter eliminare l'iscrizione dell'utente all'oggetto formativo, è necessario eliminare l'iscrizione sul Percorso/Corso ID "${row.learningObject.blendedParentId}"`
                    );
                    return;
                  }
                  setEnrollGroupDeleted(row);
                  setGroupIDWarning(row?.massiveGroupId);
                },
                labelTranslate: (action.label as any)[i18n.language],
              };
            }
          }
        }
        return {
          ...action,
          labelTranslate: (action.label as any)[i18n.language],
        };
      });
    }, [actionForRow, i18n.language]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    configRowNew.forEach((configRow: any) => {
      let fixedDisable = false;
      if (!idEdition && configRow.key === "group_enroll" && !fixedDisable) {
        if (row.original?.massiveGroupId) {
          configRow.disabled = false;
        } else {
          configRow.disabled = true;
          fixedDisable = true;
        }
      }

      if (configRow.key === "download_certificate") {
        const hasDownloadCertificatePermission = user?.permissions.find(
          (userPermission) => userPermission === configRow.permission
        );

        configRow.disabled =
          !row.original?.certificateId || !hasDownloadCertificatePermission;
      }
    });

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const configurationTopToolbar: ActionsType[] | undefined =
    React.useMemo(() => {
      return (actionsTopToolbar || []).map((action: any) => {
        if (action.type === "button" && action.icon === "download") {
          const hasDownloadCertificatePermission = user?.permissions.find(
            (userPermission) => userPermission === action.permission
          );
          if (!hasDownloadCertificatePermission) {
            return {};
          }
          return {
            ...action,
            labelTranslate: (action.label as any)[i18n.language],
            callBack: certificatesAvailable
              ? async (el: any) => {
                  try {
                    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });

                    const fileName = `certificates_${buildDateStr(
                      new Date()
                    )}.zip`;

                    const { enrollmentResponses } = dataTable;
                    const issuedCertificates = enrollmentResponses
                      .filter((certificate: any) => !!certificate.certificateId)
                      .map((row: any) => {
                        return {
                          learnerId: row.userId,
                          objectId: row.objectId,
                          issuedCertificateId: row.certificateId,
                        };
                      });

                    await downloadBlobFile(
                      `${ServicesURL.getIssuedCertificatesZip}`,
                      {
                        issuedCertificates: issuedCertificates,
                      },
                      fileName
                    );
                    setOpenToast({ s: StatusEnum.OK, message: "" });
                  } catch (error) {
                    const message = onErrorMessage(error);
                    setOpenToast({ s: StatusEnum.KO, message: message });
                    console.error(error);
                  }
                }
              : null,
            getIcon: () => <DownloadIcon />,
          };
        }
        return {
          ...action,
          labelTranslate: (action.label as any)[i18n.language],
        };
      });
    }, [actionsTopToolbar, i18n.language]);

  const handleDelete = React.useCallback(async () => {
    if (enrollDeleted) {
      setOpenToastDeleted({ s: StatusEnum.LOADING, message: "loading" });
      let newData = [];
      try {
        if (idEdition) {
          await triggerDeleteEdition({
            studentEid: enrollDeleted.idUser.toString(),
            objectId: enrollDeleted.objectId.toString(),
          });
          newData = [
            ...dataTable.users.filter(
              (el: any) => el.idUser !== enrollDeleted.idUser
            ),
          ];
        } else {
          await triggerDelete({
            enrollmentEId: enrollDeleted.enrollId.toString(),
          });
          newData = [
            ...dataTable.enrollmentResponses.filter(
              (el: any) => el.id !== enrollDeleted.enrollId
            ),
          ];
        }

        mutate && mutate({ ...dataTable, ...{ enrollmentResponses: newData } });
        setOpenToastDeleted({ s: StatusEnum.OK, message: "ok" });
      } catch (e) {
        console.error(e);
        setEnrollDeleted(undefined);
        setOpenToastDeleted(undefined);
      }
    }
  }, [dataTable, enrollDeleted, mutate, triggerDelete]);

  const handleDeleteGroup = React.useCallback(async () => {
    if (enrollGroupDeleted) {
      setOpenToastDeleted({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDeleteGroups({
          groupId: enrollGroupDeleted?.massiveGroupId,
          groupType: enrollGroupDeleted?.groupType,
        });
        const newData = [
          ...dataTable.enrollmentResponses.filter(
            (el: any) => el.massiveGroupId !== enrollGroupDeleted.massiveGroupId
          ),
        ];
        mutate && mutate({ ...dataTable, ...{ enrollmentResponses: newData } });
        setOpenToastDeleted({ s: StatusEnum.OK, message: "ok" });
      } catch (e) {
        console.error(e);
        setOpenToastDeleted(undefined);
        setEnrollGroupDeleted(undefined);
      }
    }
  }, [dataTable, enrollGroupDeleted, mutate, triggerDelete]);

  const getEnrollmentResponse = () => {
    const newList = dataTable?.enrollmentResponses.map((item: any) => {
      if (
        !!item.learningObject.blendedParentId &&
        item.learningObject.blendedParentId !== Number(idLO)
      ) {
        return {
          ...item,
          blendedParentId: item.learningObject.blendedParentId,
        };
      } else {
        return item;
      }
    });

    return newList || [];
  };

  const downloadEnrolled = async () => {
    let indexFilter =
      Object.keys(filters).find((filter: any) => filter.includes(page)) || "";
    let filteredQueryParams = "";
    for (const key in filters[indexFilter]) {
      filteredQueryParams =
        filteredQueryParams + `&${key}=${filters[indexFilter][key]}`;
    }
    try {
      let response = await triggerDownloadEnrolled(
        `?editionId=${idEdition}&corporateId=${id || "1"}${filteredQueryParams}`
      );
      let fileName = `users_subscribed_page n^${pagination.pageIndex + 1}.csv`;
      const blob = new Blob([response], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.error(e);
    }
  };

  const [columnsMerged, setColumnsMerged] = useState<ColumnsType[]>();

  useEffect(() => {
    if (!columnsMerged) {
      if (columns) {
        setColumnsMerged([
          ...(columns.map((el: ColumnsType) => {
            switch (el.accessorKey) {
              case "massiveGroupId": {
                return {
                  ...el,
                  hasFilter:
                    location && location?.state && location?.state?.setGroup
                      ? false
                      : true,
                };
              }
              default:
                return el;
            }
          }) || []),
        ]);
      }
    }
  }, [columns, columnsMerged]);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-users.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${location.state?.row?.title || dataDetail?.name} - ${t(
            "subscribed-users.hero-title"
          )}`}
          description={t("subscribed-users.hero-description")}
        />
        <ButtonBackPage customRedirect={-1} />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id={page}
              data={
                idEdition
                  ? dataTable?.users || []
                  : getEnrollmentResponse() || []
              }
              columns={
                columnsMerged
                  ? translateHeaders(columnsMerged, i18n.language)
                  : []
              }
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={configurationTopToolbar.map(
                (action: any) => {
                  if (action.accessorKey === "download_enrolled") {
                    return {
                      ...action,
                      callBack:
                        idEdition &&
                        dataTable?.users &&
                        dataTable?.users.length > 0
                          ? () => downloadEnrolled()
                          : null,
                    };
                  } else {
                    return action;
                  }
                }
              )}
              configurationActionForRow={configurationActionForRow}
              renderRowActions={customRenderRowActions}
              totalPages={dataTable?.metadata?.totalElements}
              modalTitle={t("hero-title")?.replace("-", "") || ""}
              getPagination={setPagination}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!enrollGroupEdit}
        title={t("edit-item", { ns: "modal" })}
        description={
          groupIDWarning && !idEdition
            ? t("warning-edit-group-enroll", { ns: "modal" }).replace(
                ":ID",
                groupIDWarning
              )
            : t("confirm-edit", { ns: "modal" })
        }
        routeToSamePage={true}
        onCallBackClosed={() => {
          setEnrollGroupEdit(undefined);
          setOpenToastDeleted(undefined);
        }}
        onActionConfirmed={() =>
          enrollGroupEdit &&
          navigate(
            `${enrollGroupEdit.groupType}/${enrollGroupEdit.massiveGroupId}/enrollment`
          )
        }
        status={openToastDeleted}
      />
      <ModalConfirmsCst
        open={!!enrollDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={
          groupIDWarning && !idEdition
            ? t("warning-delete-single-enroll", { ns: "modal" }).replace(
                ":ID",
                groupIDWarning
              )
            : t("confirm-delete", { ns: "modal" })
        }
        routeToSamePage={true}
        onCallBackClosed={() => {
          setEnrollDeleted(undefined);
          setOpenToastDeleted(undefined);
        }}
        onActionConfirmed={() => enrollDeleted && handleDelete()}
        status={openToastDeleted}
      />
      <ModalConfirmsCst
        open={!!enrollGroupDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={
          groupIDWarning && !idEdition
            ? t("warning-delete-group-enroll", { ns: "modal" }).replace(
                ":ID",
                groupIDWarning
              )
            : t("confirm-delete", { ns: "modal" })
        }
        routeToSamePage={true}
        onCallBackClosed={() => {
          setEnrollGroupDeleted(undefined);
          setOpenToastDeleted(undefined);
        }}
        onActionConfirmed={() => enrollGroupDeleted && handleDeleteGroup()}
        status={openToastDeleted}
      />
      <ModalConfirmsCst
        open={editDescriptionErrorModal}
        title={titleError}
        description={descriptionError}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setEditDescriptionErrorModal(false);
          setTitleError("");
          setDescriptionError("");
        }}
        removeButtons={true}
      />
      <ModalConfirmsCst
        open={!!openToast}
        title={t("download-certificates.title", { ns: "modal" })}
        description={""}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        removeButtons={true}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default SubscribedUsers;
