import React, { useState } from "react";
import { Box, Checkbox, Container, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";
import ButtonBackPage from "../../../../../../components/ButtonBackPage";
import { TableTabs } from "../../../../../../components/TableTabs/Index";
import CustomRowAction from "../../../../../../components/Table/components/CustomRowAction";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate, useParams } from "react-router-dom";
import ButtonCst from "../../../../../../components/ButtonCst";
import { ServicesURL } from "../../../../../../utils/utilsApi";
import { useFetchTable } from "../../../../../../hooks/useFetchDataTable";
import { StatusEnum } from "../../../../../../components/ModalConfirmsCst/types";
import useSWRMutation from "swr/mutation";
import { fetcher } from "../../../../../../services/config";
import { MethodType } from "../../../../../../services/type";
import ModalCst from "../../../../../../components/ModalCst";
import { useAuth } from "../../../../../../context/auth";

const VisualizeAndConfirmLearner: React.FC = () => {
  const { t } = useTranslation(["training-needs-details-user"]);
  const { t: tModal } = useTranslation(["modal"]);
  const [checkBoxData, setCheckBoxData] = useState<any>([]);
  const [checkBoxDataDelete, setCheckBoxDataDelete] = useState<any>([]);
  const [confirmModal, setConfirmModal] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModal3, setOpenConfirmModal3] = useState(false);
  const [goToFinalTab, setGoToFinalTab] = useState<any>(false);
  const { user } = useAuth();

  const { id, idCampaign } = useParams();
  const isLearnerState =
    window.location.href.includes("visualize-and-confirm-learner") ||
    window.location.href.includes("learner-confirm");

  const isCompletationFlux = window.location.href.includes(
    "completation-campaign"
  );

  useResetFilters();

  const navigate = useNavigate();

  const detailCorporate = useFetchTable(
    `${ServicesURL.digitedTipologyCampaign}`
      .replace("{campaignType}", "LEARNER")
      .replace("{corporateId}", `${id}`),
    PagesID["corporate.campaign"]
  );

  const { trigger: triggerConfirmGap } = useSWRMutation(
    `${ServicesURL.digitedDeleteDetailCampaign}`,
    (url: string, { arg }) => {
      return fetcher<any>(
        `${url.replace("{campaignId}", `${idCampaign}`)}`,
        MethodType.PUT,
        arg
      );
    }
  );

  const detailCampaign = useFetchTable(
    `${ServicesURL.digitedUsersCampaign}`.replace(
      "{campaignId}",
      `${idCampaign}`,
    )+ `${isCompletationFlux  ?  user ? `?manager=${user.external_id}&role=LEARNER`: '' : user ? `?role=LEARNER`: ''}`,
    PagesID["corporate.learner.campaign.visualize.confirm"],
  );

  const handleConfirmGap = async (row: any, complete: string) => {
    setConfirmModal({ s: StatusEnum.LOADING, message: "loading" });

    const listIdUser = checkBoxData.map((el: any) => el.id);

    const payloadConfirmGap = {
      is_closed: complete == "complete" ? true : false,
      campaign_user_ids: listIdUser,
    };

    try {
      setOpenConfirmModal(false);
      //@ts-ignore
      const confirmGap = await triggerConfirmGap(payloadConfirmGap);
      detailCampaign.mutate({
        ...detailCampaign.dataTable,
        output: confirmGap,
      });
      setOpenConfirmModal3(false);

      if (complete == "complete") navigate(-1);
    } catch (error) {
      setConfirmModal({
        s: StatusEnum.KO,
        message: "",
      });
      setOpenConfirmModal(false);
      setOpenConfirmModal3(false);
      console.error(error);
    }
  };

  const handleCheckBox = (row: any, type: string) => {
    let dataBox = type === "checkbox" ? checkBoxData : checkBoxDataDelete;
    let setData = type === "checkbox" ? setCheckBoxData : setCheckBoxDataDelete;
    if (dataBox && dataBox.some((el: any) => el.id === row.id)) {
      const removeData = dataBox.filter((el: any) => el.id !== row.id);
      setData(removeData);
    } else {
      setData([...dataBox, row]);
    }
  };

  const handleAllCheckBox = (data: any, type: string) => {
    let dataBox = type === "checkbox" ? checkBoxData : checkBoxDataDelete;
    let setData = type === "checkbox" ? setCheckBoxData : setCheckBoxDataDelete;

    const dataBoxFiltered = dataBox.filter(
      (el: any) => el.status != "COMPLETED"
    );

    const dataFiltered = data.dataTable.output.filter(
      (el: any) => el.status != "COMPLETED"
    );

    const differentDataBox =
      type === "checkbox" ? checkBoxDataDelete : checkBoxData;
    const updateData = dataFiltered.filter(
      (el: any) => !differentDataBox.some((user: any) => user.id === el.id)
    );

    if (dataBox && dataFiltered && dataBoxFiltered.length < updateData.length) {
      return setData(updateData);
    }

    return setData([]);
  };

  const configurationActionForRow = React.useMemo(() => {
    return (row: any) => {
      let completedUser = row.original.status == "COMPLETED";
      return detailCampaign.actionForRow
        ?.filter((el: any) => {
          if (completedUser) return el.type != "checkbox";
          return el;
        })
        .map((action: any) => {
          switch (action.type) {
            case "visibility": {
              if (completedUser) {
                return {
                  ...action,
                  url: "detail-user-skills",
                  useId: row.original.id,
                };
              } else {
                return {
                  ...action,
                  useId: row.original.id,
                };
              }
            }
            case "checkbox": {
              const isExist = checkBoxData.some(
                (el: any) => el.id == row.original.id
              );
              const isExistDelete = checkBoxDataDelete.some(
                (el: any) => el.id == row.original.id
              );

              return {
                ...action,
                disabled: isExist
                  ? "confirmed"
                  : isExistDelete
                  ? "delete"
                  : null,
              };
            }

            default:
              return action;
          }
        });
    };
  }, [detailCampaign.actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew = configurationActionForRow(row) && [
      ...configurationActionForRow(row),
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
        setCheckBoxData={handleCheckBox}
        checkBoxData={[checkBoxData, checkBoxDataDelete]}
      />,
    ];
  };

  const rowData =
    detailCorporate &&
    detailCorporate.dataTable &&
    detailCorporate.dataTable.output &&
    detailCorporate.dataTable.output.find(
      (el: any) => el.campaign_id == idCampaign
    );

  const dataKnowledge =
    detailCampaign &&
    detailCampaign.columns &&
    detailCampaign.columns.filter((column) => column.accessorKey != "capacity");
  const dataCapacity =
    detailCampaign &&
    detailCampaign.columns &&
    detailCampaign.columns.filter(
      (column) =>
        column.accessorKey !== "knowledge_area" &&
        column.accessorKey !== "knowledge_domain"
    );

  const isCompletedState =
    rowData && rowData.hr_state.toLowerCase() == "completed";

  const tabs = [
    {
      [t("knowledge")]: detailCampaign && (
        <Table
          id={PagesID["corporate.campaign.visualize.confirm"]}
          data={detailCampaign.dataTable?.output}
          columns={detailCampaign.columns || []}
          onChangeFilterForm={detailCampaign.handleFetchData}
          renderRowActions={customRenderRowActions}
          configurationTopToolbar={detailCampaign.actionsTopToolbar}
          totalPages={detailCampaign.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          renderActionIcon={
            <>
              <FormControlLabel
                control={
                  <CheckBoxIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label="Tutti"
              />
              <FormControlLabel
                control={
                  <DeleteIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label="Tutti"
              />
            </>
          }
          renderActionIconBottomToolbar={
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxData.length}
                    checkedIcon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() =>
                      handleAllCheckBox(detailCampaign, "checkbox")
                    }
                  />
                }
                label=""
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxDataDelete.length}
                    checkedIcon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox(detailCampaign, "delete")}
                  />
                }
                label=""
              />
            </>
          }
          renderActionUnderBottomToolbar={
            <ButtonCst
              onClick={() => setGoToFinalTab(true)}
              sx={{ cursor: "pointer" }}
              disabled={!checkBoxData.length}
              variant="contained"
              id={"continueButton"}
            >
              {tModal("confirm-gap-modal")}
            </ButtonCst>
          }
        />
      ),
    },
    {
      [t("capacity")]: detailCampaign && (
        <Table
          id={PagesID["corporate.campaign.visualize.confirm.capacity"]}
          data={detailCampaign.dataTable?.output}
          columns={dataCapacity || []}
          onChangeFilterForm={detailCampaign.handleFetchData}
          renderRowActions={customRenderRowActions}
          configurationTopToolbar={detailCampaign.actionsTopToolbar}
          totalPages={detailCampaign.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          renderActionIcon={
            <>
              <FormControlLabel
                control={
                  <CheckBoxIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label="Tutti"
              />
              <FormControlLabel
                control={
                  <DeleteIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label="Tutti"
              />
            </>
          }
          renderActionIconBottomToolbar={
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxData.length}
                    checkedIcon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => setOpenConfirmModal(true)}
                  />
                }
                label=""
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxDataDelete.length}
                    checkedIcon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox(detailCampaign, "delete")}
                  />
                }
                label=""
              />
            </>
          }
          renderActionUnderBottomToolbar={
            <ButtonCst
              onClick={() => setOpenConfirmModal(true)}
              sx={{ cursor: "pointer" }}
              disabled={!checkBoxData.length}
              variant="contained"
              id={"buttonConfirm"}
            >
              {tModal("confirm-gap-modal")}
            </ButtonCst>
          }
        />
      ),
    },
  ];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("validation-campaign-learner")}
          description={t("hero-description")}
        />
        <ButtonBackPage goBack={2} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          {isLearnerState ? (
            <Table
              id={PagesID["corporate.campaign.visualize.confirm"]}
              data={detailCampaign?.dataTable?.output}
              columns={dataKnowledge || []}
              onChangeFilterForm={detailCampaign.handleFetchData}
              renderRowActions={customRenderRowActions}
              configurationTopToolbar={detailCampaign.actionsTopToolbar}
              totalPages={detailCampaign.dataTable?.metadata?.total_elements}
              modalTitle={t("hero-title")?.replace("-", "") || ""}
              renderActionIcon={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "3px",
                    padding: "0 20px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <CheckBoxIcon
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    label="Tutti"
                  />
                  <FormControlLabel
                    control={
                      <DeleteIcon
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    label="Tutti"
                  />
                </div>
              }
              renderActionIconBottomToolbar={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    padding: "0 6px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkBoxData.length}
                        checkedIcon={
                          <CheckBoxIcon
                            fontSize="large"
                            sx={{
                              fill: "#f43e63 !important",
                            }}
                          />
                        }
                        icon={
                          <CheckBoxIcon
                            fontSize="large"
                            sx={{
                              fill: "#84293c !important",
                            }}
                          />
                        }
                        onChange={() =>
                          handleAllCheckBox(detailCampaign, "checkbox")
                        }
                      />
                    }
                    label=""
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkBoxDataDelete.length}
                        checkedIcon={
                          <DeleteIcon
                            fontSize="large"
                            sx={{
                              fill: "#f43e63 !important",
                            }}
                          />
                        }
                        icon={
                          <DeleteIcon
                            fontSize="large"
                            sx={{
                              fill: "#84293c !important",
                            }}
                          />
                        }
                        onChange={() =>
                          handleAllCheckBox(detailCampaign, "delete")
                        }
                      />
                    }
                    label=""
                  />
                </div>
              }
              renderActionUnderBottomToolbar={
                <ButtonCst
                  onClick={() => setOpenConfirmModal(true)}
                  sx={{ cursor: "pointer" }}
                  disabled={!checkBoxData.length}
                  variant="contained"
                  id={"continueButton"}
                >
                  {tModal("confirm-gap-modal")}
                </ButtonCst>
              }
            />
          ) : (
            <TableTabs
              tabs={isCompletedState ? tabs : tabs}
              goToFinalTab={goToFinalTab}
              setGoToFinalTab={setGoToFinalTab}
            />
          )}
        </Box>
        <ModalCst
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          title={tModal("confirm-gap-modal") || ""}
          description={tModal("confirm-gap-modal-description") || ""}
          typeOfModal={"confirm"}
          data={{
            callBackSubmit: async () => {
              if (isCompletationFlux)
                await handleConfirmGap(rowData, "stayActive");
              else setOpenConfirmModal3(true);
            },
          }}
          messageToast={tModal("confirm-success") || ""}
          typeToast={"success"}
        />
        <ModalCst
          open={openConfirmModal3}
          setOpen={setOpenConfirmModal3}
          submitText={tModal("confirm-closed-campaign") || ""}
          cancelText={tModal("stay-active-campaign") || ""}
          title={tModal("closed-campaign") || ""}
          description={tModal("confirm-closed-campaign-description") || ""}
          typeOfModal={"confirm"}
          messageToast={tModal("closed-campaign-success") || ""}
          typeToast="success"
          data={{
            onClose: async () => {
              await handleConfirmGap(rowData, "stayActive");
            },
            callBackSubmit: async () => {
              await handleConfirmGap(rowData, "complete");
            },
          }}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default VisualizeAndConfirmLearner;
