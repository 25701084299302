import { ListNotificationType } from "../../../../../interfaces/Common";
import {
  EventInterface,
  MergedParameter,
  NotificationCreate,
  NotificationGlobalStateInterface,
  ValuesInterface,
} from "@pages/Contents/Corporate/Notifications/types";
import { Options } from "@components/InputCst/SelectCst/types";

export const getFlagKey = (type: ListNotificationType | string) => {
  const parts = type.split("_");

  const result = parts
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");

  return `flag${result}`; //EXAMPLE KEY: flagEmail || flagUsersAll
};

export const getUploadKey = (type: ListNotificationType) =>
  `uploadedFiles${type.charAt(0).toUpperCase() + type.slice(1)}`; //EXAMPLE KEY: uploadedFilesEmail

export const getBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export function daysOfWeek(t: any) {
  return Array.from({ length: 7 }, (_, index) => {
    return {
      label: t(`options.days_of_week.${index + 1}`),
      value: index + 1,
    };
  });
}

export function daysOfMonth() {
  return Array.from({ length: 31 }, (_, index) => {
    return {
      label: (index + 1).toString(),
      value: index + 1,
    };
  });
}

export function hoursOfDay() {
  return Array.from({ length: 24 }, (_, index) => {
    const hour = index.toString().padStart(2, "0");
    return {
      label: `${hour}:00`,
      value: Number(hour),
    };
  });
}

export const isArrayOfNumbers = (array: []) => {
  return array.every((item) => typeof item === "number");
};

export const isArrayOfObjects = (array: []) => {
  return array.every(
    (item) =>
      typeof item === "object" &&
      item !== null &&
      "label" in item &&
      "value" in item
  );
};

export function mergeParameters(
  parameters: EventInterface[],
  id?: string | number
): MergedParameter[] {
  if (!id) return parameters as MergedParameter[];
  const mergedParams = parameters.reduce(
    (acc: { [key: string]: MergedParameter }, param: EventInterface) => {
      if (!acc[param.name]) {
        //CREATE ANEW ENTRY WITH VALUES ARRAY
        acc[param.name] = { ...param, values: [param.value] };
      } else {
        //ADD VALUE TO EXISTING ENTRY IF NOT ALREADY INCLUDED
        if (!acc[param.name].values.includes(param.value)) {
          acc[param.name].values.push(param.value);
        }
      }
      return acc;
    },
    {}
  );

  //CONVERT THE MERGED PARAMETERS OBJECT TO AN ARRAY
  return Object.values(mergedParams);
}

export const getSelectedOptions = (ids: [], options: Options[]) => {
  //FIND THE SELECTED VALUES AS OPTIONS
  if (!ids) return [];
  return ids
    .map((id) => options.find((option) => option.value === id))
    .filter((option) => option);
};

export function convertDateTimeToDMYHM(dateTime: string) {
  const date = new Date(dateTime);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${month}/${day}/${year} ${hours}:${minutes}`;
}

export async function submitCreation(
  state: NotificationGlobalStateInterface,
  trigger: any
) {
  let output;

  const relationship_identification_logic =
    state.relationship_identification_logic
      ? state.relationship_identification_logic.map((item: ValuesInterface) => {
          return {
            name: "RELATIONSHIP_IDENTIFICATION_LOGIC",
            value: item.value,
          };
        })
      : [];

  const enroll_status = state.enroll_status
    ? state.enroll_status.map((item: ValuesInterface) => {
        return {
          name: "ENROLL_STATUS",
          value: item.value,
        };
      })
    : [];

  const hourOfDay =
    state.everyUnit === "DAY"
      ? state.hourOfDay.map((item: ValuesInterface) => Number(item.value))
      : [Number(state.hourOfDay)];
  const dayOfWeek = state.dayOfWeek
    ? state.dayOfWeek.map((item: ValuesInterface) => Number(item.value))
    : [];
  const dayOfMonth = state.dayOfMonth
    ? state.dayOfMonth.map((item: ValuesInterface) => item.value)
    : [];

  let specificDate;

  if (state.specificDate) {
    const date = new Date(state.specificDate);
    date.setHours(date.getHours() + 2);
    const isoDate = date.toISOString();
    specificDate = isoDate;
  }

  let uploadedFile = state.uploadedFilesUsers_excluded || null;
  if (state.uploadedFilesUsers_excluded) {
    uploadedFile = await getBase64(state.uploadedFilesUsers_excluded);
  }

  const parameters = () => {
    switch (state.event) {
      case "TEAMMONITORING":
        return [
          {
            name: "ONLY_MANDATORY",
            value: state.flagOnlyMandatory.toString(),
          },
          {
            name: "ONLY_EXPIRING_COURSES",
            value: state.flagOnlyExpiringCourses.toString(),
          },
          {
            name: "ENROLLED_DAYS_AGO",
            value: !!state.flagEnrolledDaysAgo
              ? state.enrolled_days_ago ||
                state.parameters.ENROLLED_DAYS_AGO.value
              : null,
          },
          ...enroll_status,
          ...relationship_identification_logic,
        ];
      default:
        return [];
    }
  };

  const payload: NotificationCreate = {
    notificationName: state.notificationName,
    notificationCode: state.notificationCode,
    enabled: true,
    event: {
      name: state.event,
      parameters: parameters(),
    },
    templates: {
      flagEmail: !!state.flagEmail,
      flagApplicative: !!state.flagApplicative,
      flagPushMobile: !!state.flagPushMobile,
    },
    catalogue: {
      initiatives: null,
      learningObjects: state.learningObjects ? state.learningObjects : null,
    },
    users: {
      flagUsersAll: !!state.flagUsersAll,
      usersGroups: state.usersGroups || [],
      flagUsersExcluded: !!state.flagUsersExcluded,
      uploadedFilesUsersExcluded: uploadedFile,
    },
    schedule: {
      scheduleType: state.scheduleType || "MANUAL",
      everyUnit: state.everyUnit || null,
      hourOfDay: hourOfDay,
      dayOfWeek: dayOfWeek,
      dayOfMonth: dayOfMonth,
      specificDate: state.specificDate ? specificDate : null,
    },
  };

  if (payload) {
    const response = await trigger(payload);
    output = {
      message: "success",
      response: response,
    };
    return output;
  }
  return null;
}
