import React, { useState } from "react";
import { Box, Modal, useTheme } from "@mui/material";
import OutcomeModalCst from "@components/ModalCst/OutcomeModalCst";
import DeleteModalCst from "@components/ModalCst/DeleteModalCst";
import ToastMessageCst from "@components/ToastMessageCst";
import DownloadUploadModalCst from "./DownloadUploadCst";
import { useTranslation } from "react-i18next";
import { DefaultTFuncReturn } from "i18next";
import MultiTextDetailModalCst from "./MultiTextDetailModalCst";
import ConfirmModalCst from "./ConfirmModalCst";
import SelectModalCst from "./SelectModalCst";
import EnrollmentExceededModalCst from "./EnrollmentExceededModalCst";

export interface OutcomesInterface {
  [x: string]: {
    code: string | undefined;
    message: string | undefined | DefaultTFuncReturn;
    title?: string | DefaultTFuncReturn;
  };
}

interface ModalCstInterface {
  typeOfModal?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  data?: any;
  title?: string;
  titleAdditional?: string;
  description?: string;
  messageToast?: string;
  typeToast?: "error" | "info" | "warning" | "success";
  children?: React.ReactNode;
  onSubmit?: (data: any) => void;
  selectChoiceError?: boolean;
  cancelText?: string;
  submitText?: string;
}

const ModalCst: React.FC<ModalCstInterface> = ({
  typeOfModal,
  open,
  setOpen,
  data,
  title,
  titleAdditional,
  description,
  children,
  messageToast,
  typeToast,
  onSubmit,
  selectChoiceError,
  cancelText,
  submitText,
}) => {
  const [actionCompleted, setActionCompleted] = useState(false);
  const hideToastTime = 5000;
  const theme = useTheme();
  const { t } = useTranslation(["modal", "common"]);

  const handleClose = () => {
    data && data?.onClose && data.onClose();
    setOpen(!open);
  };

  const handleSubmit = () => {
    setActionCompleted(true);

    setTimeout(() => {
      setActionCompleted(false);
    }, hideToastTime);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: `2px solid ${theme.palette.divider}`,
            pt: 2,
            px: 4,
            pb: 3,
            width: "95vw",
            minHeight: "240px",
            [theme.breakpoints.up("md")]: {
              width: "65vw",
            },
            [theme.breakpoints.up("lg")]: {
              width: "50vw",
            },
          }}
        >
          {children}
          {typeOfModal === "outcome" && (
            <OutcomeModalCst
              data={data}
              title={t("outcome-item") || ""}
              description={t("confirm-delete") || ""}
              cancelText={
                cancelText ? cancelText : t("cancel-outcome-button") || ""
              }
              submitText={
                submitText ? submitText : t("submit-outcome-button") || ""
              }
              onCancelAction={() => handleClose()}
              onSubmitAction={data.callBackSubmit}
            />
          )}
          {typeOfModal === "confirm" && (
            <ConfirmModalCst
              data={data}
              title={`${title || ""}`}
              description={`${description || ""}`}
              cancelText={cancelText ? cancelText : t("cancel") || ""}
              submitText={submitText ? submitText : t("confirm") || ""}
              onCancelAction={() => handleClose()}
              onSubmitAction={handleSubmit}
            />
          )}
          {typeOfModal === "delete" && (
            <DeleteModalCst
              data={data}
              title={t("delete-item") || ""}
              description={t("confirm-delete") || ""}
              cancelText={cancelText ? cancelText : t("cancel") || ""}
              submitText={submitText ? submitText : t("yes-delete") || ""}
              onCancelAction={() => handleClose()}
              onSubmitAction={handleSubmit}
            />
          )}
          {typeOfModal === "uploadDownload" && (
            <DownloadUploadModalCst
              title={`${t("upload")} ${(title || "").toLowerCase()}`}
              data={data}
              onCancelAction={() => handleClose()}
              adaptDouble={!!titleAdditional}
            />
          )}
          {typeOfModal === "uploadDownload" && titleAdditional && (
            <DownloadUploadModalCst
              title={`${t("upload")} ${(titleAdditional || "")?.toLowerCase()}`}
              data={data}
              onCancelAction={() => handleClose()}
              isAdditional={true}
              adaptDouble={!!titleAdditional}
            />
          )}
          {typeOfModal === "multiTextDetail" && (
            <MultiTextDetailModalCst
              title={title}
              data={data}
              onCancelAction={() => handleClose()}
            />
          )}
          {typeOfModal === "selectChoice" && (
            <SelectModalCst
              data={data}
              title={t("warning", { ns: "common" }) || ""}
              description={
                t("multiple-enrollment-error", { ns: "modal" }) || ""
              }
              selectChoiceError={selectChoiceError}
              cancelText={t("cancel") || ""}
              submitText={t("confirm") || ""}
              onCancelAction={() => handleClose()}
              onSubmitAction={(value: any) => {
                onSubmit && onSubmit(value);
              }}
            />
          )}
          {typeOfModal === "enrollmentExceeded" && (
            <EnrollmentExceededModalCst
              title={`${title || ""}`}
              description={`${description || ""}`}
              onCancelAction={() => handleClose()}
            />
          )}
        </Box>
      </Modal>
      <ToastMessageCst
        hideTiming={hideToastTime}
        show={actionCompleted}
        message={messageToast ? messageToast : t("deleted-message")}
        type={typeToast ? typeToast : "success"}
      />
    </>
  );
};

export default ModalCst;
