import React, { useMemo, useState, useEffect } from "react";
import { Box, Collapse, IconButton, useTheme } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
} from "material-react-table";
import Add from "@mui/icons-material/Add";
import FilterBox from "@components/Table/components/FilterBox";
import { CustomTopToolbarInterface } from "./types";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModalCst from "@components/ModalCst";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import MobiledataOffIcon from "@mui/icons-material/MobiledataOff";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useFilters } from "@context/filters";
import { useAuth } from "@context/auth";
import IconActionRow from "../IconAcitionRow";
import { SwapCalls } from "@mui/icons-material";

const CustomTopToolbar: React.FC<CustomTopToolbarInterface> = ({
  id,
  configurationTopToolbar,
  columns,
  onChangeFilterForm,
  table,
  setHideHeader,
  counterElements = false,
  onToolbarChange,
  modalTitle,
  noSummarySelected = false,
  hiddenFilters = false,
}) => {
  const { t } = useTranslation(["table"]);
  const { t: modalT } = useTranslation(["modal"]);

  const { user } = useAuth();
  const filterId = !["", null].includes(id) ? `${user?.id_user}_${id}` : "";
  const themeTable = useTheme();
  const { filters, setFilters } = useFilters();
  const [openFilter, setOpenFilter] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalTitleLocal, setModalTitleLocal] = useState<string | null>();
  const navigate = useNavigate();
  const params = useParams();
  const numberOfElements =
    table.getState() &&
    table.getState().rowSelection &&
    Object.keys(table.getState().rowSelection);
  const toolbarRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const filter = filters[filterId];

    if (filter) {
      const filterArrayKeys = Object.keys(filter);

      if (filterArrayKeys.length > 2) {
        setOpenFilter(true);
      }
    }
  }, [filters[filterId]]);

  useEffect(() => {
    const handleResize = () => {
      const container = toolbarRef.current;
      onToolbarChange(container?.clientHeight || 40);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [onToolbarChange, openFilter]);

  useEffect(() => {
    if (filters && Object.keys(filters)?.length > 0) {
      const filtersValue = filters && filters[id as keyof typeof filters];
      if (filtersValue && Object.keys(filtersValue)?.length > 0) {
        onChangeFilterForm && onChangeFilterForm(filtersValue);
      }
    }
  }, []);

  const renderActions = useMemo(
    () => () =>
      configurationTopToolbar &&
      configurationTopToolbar.map(
        (
          {
            type,
            url,
            labelTranslate,
            callBack,
            getIcon,
            icon,
            disabled = false,
          },
          index
        ) => {
          switch (type) {
            case "add":
              return (
                <div key={index}>
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      if (callBack) {
                        callBack();
                        return;
                      }
                      url && navigate(url);
                    }}
                  >
                    <Tooltip title={labelTranslate}>
                      <AddIcon />
                    </Tooltip>
                  </IconButton>
                </div>
              );
            case "upload":
              return (
                <div key={index}>
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      if (callBack) {
                        callBack();
                        return;
                      }
                      setModalTitleLocal(null);
                      setOpenModal(!openModal);
                    }}
                  >
                    <Tooltip title={labelTranslate}>
                      <MobiledataOffIcon />
                    </Tooltip>
                  </IconButton>
                </div>
              );
            case "visibility":
              return (
                <div key={index}>
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      if (callBack) {
                        callBack();
                        return;
                      }
                      setModalTitleLocal(modalT("visibility-cones"));
                      setOpenModal(!openModal);
                    }}
                  >
                    <Tooltip title={labelTranslate}>
                      <RemoveRedEyeOutlined />
                    </Tooltip>
                  </IconButton>
                </div>
              );
            case "link":
              return (
                <IconButton
                  disabled={disabled}
                  key={index}
                  onClick={() => url && navigate(url)}
                >
                  <Tooltip title={labelTranslate}>
                    {icon === "solicit" ? (
                      <CampaignIcon />
                    ) : icon ? (
                      <IconActionRow
                        icon={icon}
                        labelTranslate={labelTranslate!}
                      />
                    ) : (
                      <ArrowForwardIcon />
                    )}
                  </Tooltip>
                </IconButton>
              );
            case "association":
              return (
                <IconButton
                  disabled={disabled}
                  key={index}
                  onClick={() => url && navigate(url)}
                >
                  <Tooltip title={labelTranslate}>
                    <SubdirectoryArrowRightIcon />
                  </Tooltip>
                </IconButton>
              );
            case "button":
              return (
                <IconButton
                  disabled={!callBack}
                  sx={{
                    opacity: !callBack ? "0.3" : "unset",
                  }}
                  size="small"
                  key={index}
                  onClick={() => callBack && callBack()}
                >
                  <Tooltip title={labelTranslate}>
                    {getIcon ? getIcon() : <Add />}
                  </Tooltip>
                </IconButton>
              );
            case "nextStep":
              return (
                <IconButton
                  size="small"
                  key={index}
                  onClick={() => {
                    if (url) navigate(url);
                  }}
                >
                  <Tooltip title={labelTranslate}>
                    <SwapCalls />
                  </Tooltip>
                </IconButton>
              );
          }
          return <div key={index}></div>;
        }
      ),
    [configurationTopToolbar, navigate]
  );

  return (
    <>
      <Box ref={toolbarRef}>
        <Collapse in={openFilter}>
          {!hiddenFilters && (
            <FilterBox
              id={id}
              columns={columns}
              onChangeFilterForm={onChangeFilterForm}
            />
          )}
        </Collapse>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: themeTable.table?.backgroundTable || "",
            padding: "10px",
          }}
        >
          <Box>
            {numberOfElements &&
              !noSummarySelected &&
              counterElements &&
              t(`selectionLabel`).replace("%n", `${numberOfElements?.length}`)}
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            {columns.length > 0 && (
              <IconButton
                aria-label="Apri/Chiudi filtro"
                onClick={() => {
                  setOpenFilter(!openFilter);
                }}
              >
                <Tooltip title="Apri/Chiudi filtro">
                  {!!openFilter ? <FilterListOffIcon /> : <FilterListIcon />}
                </Tooltip>
              </IconButton>
            )}
            {renderActions()}
            {/* eslint-disable-next-line react/jsx-pascal-case*/}
            <MRT_ShowHideColumnsButton table={table} />
            <div aria-hidden="true">
              {/* eslint-disable-next-line react/jsx-pascal-case*/}
              <MRT_FullScreenToggleButton table={table} />
            </div>
          </Box>
        </Box>
      </Box>
      <ModalCst
        open={openModal}
        setOpen={setOpenModal}
        title={modalTitleLocal || modalTitle}
        typeOfModal={"uploadDownload"}
        data={{
          configurationTopToolbar,
          callBackSubmit: () => {
            setOpenModal(!openModal);
          },
        }}
      />
    </>
  );
};

export default CustomTopToolbar;
