import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import { usePostDetail } from "@hooks/useFetchDetail";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../../../context/auth";
import { useFetchMock } from "../../../../../../mock_data/useMockData";
import { useFetchTable } from "../../../../../../hooks/useFetchDataTable";
import ButtonBackPage from "../../../../../../components/ButtonBackPage";

const useKnowledgeDomains = (id: any, idArea: any, jobId: any) => {
  const { dataTable: knowledgeOptionsDomain } = useFetchTable(
    `${ServicesURL.getKnowledgeDomainsId
      .replace(":idCorporate", `${id}`)
      .replace(":idArea", `${idArea}`)}` +
      (jobId != null ? `?job=${jobId}` : ""),
    PagesID["skills.knowledge-domains"]
  );

  return knowledgeOptionsDomain;
};
interface TCapabilities {
  obsolete: boolean;
  id: string;
  name: string;
  description: string;
  identifier: string;
  type: string;
  corporate_id: string;
  creation_date: string;
  delete_date: string;
  update_date: string;
  created_by: string;
  updated_by: string;
  deleted_by: string;
  deleted_by_name: string;
  created_by_name: string;
  updated_by_name: string;
}

interface TKnowledge {
  obsolete: boolean;
  id: string;
  name: string;
  description: string;
  identifier: string;
  type: string;
  corporate_id: string;
  creation_date: string;
  delete_date: string;
  update_date: string;
  created_by: string;
  updated_by: string;
  deleted_by: string;
  deleted_by_name: string;
  created_by_name: string;
  updated_by_name: string;
  industries: [0];
}

const AddKnowledgeLearner: React.FC = () => {
  const { t } = useTranslation(["training-needs-campaign-page"]);
  const { id, idCampaign, idDetail } = useParams();
  const { t: tModal } = useTranslation(["modal"]);

  const [optionsCapacity, setOptionsCapacity] = useState<TCapabilities | any>(
    []
  );
  const [optionsKnowledge, setOptionsKnowledge] = useState<TKnowledge | any>(
    []
  );
  const [optionsKnowledgeDomain, setOptionsKnowledgeDomain] = useState<
    TKnowledge | any
  >([]);
  const { columns } = useFetchMock(
    PagesID["corporate.campaign.visualize.confirm.learner.add"]
  );

  const [optionAreaSelected, setOptionAreaSelected] = useState<any>();

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { trigger: triggerAddKnowledge } = usePostDetail(
    `${ServicesURL.digitedDetailCampaignLearner.replace(
      "{campaignId}",
      `${idCampaign}`
    )}`
  );

  const detailUser = useFetchTable(
    `${ServicesURL.digitedUsersCampaign}`.replace(
      "{campaignId}",
      `${idCampaign}`
    ) + `?id=${idDetail}`,
    PagesID["corporate.learner.campaign.visualize.confirm"]
  );

  const jobId =
    (detailUser &&
      detailUser.dataTable &&
      detailUser.dataTable.output &&
      detailUser.dataTable.output[0].job_id) ||
    null;

  const { dataTable: capabilitiesOptions } = useFetchTable(
    `${ServicesURL.getCapabilities.replace(":idCorporate", `${id}`)}`,
    PagesID["skills.capabilities"]
  );
  const { dataTable: knowledgeOptions } = useFetchTable(
    `${ServicesURL.getKnowledgeAreas.replace(":idCorporate", `${id}`)}` +
      (jobId != null ? `?job=${jobId}` : ""),
    PagesID["skills.knowledge-areas"]
  );

  const knowledgeOptionsDomain = useKnowledgeDomains(
    id,
    optionAreaSelected ? optionAreaSelected[0].id : 0,
    jobId
  );

  useEffect(() => {
    if (optionAreaSelected && optionAreaSelected[0].id) {
      if (knowledgeOptionsDomain)
        setOptionsKnowledgeDomain(knowledgeOptionsDomain.output);
    }
  }, [knowledgeOptionsDomain, optionAreaSelected, jobId]);

  useEffect(() => {
    setOptionsKnowledge((knowledgeOptions && knowledgeOptions.output) || []);
    setOptionsCapacity(
      (capabilitiesOptions && capabilitiesOptions.output) || []
    );
  }, [knowledgeOptions, capabilitiesOptions]);

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });

    const objToSend: any = {
      desired_level: inputForm.desired_level.toUpperCase(),
      actual_level: inputForm.actual_level.toUpperCase(),
      description: inputForm.description,
      campaign_user_id: idDetail,
      ...(inputForm.added_new_knowledge.selectedOption === "Knowledge" && {
        knowledge_area:
          {
            id: inputForm.added_new_knowledge.selectedGroups?.[0]?.id,
            name: inputForm.added_new_knowledge.selectedGroups?.[0]?.name,
          } || null, // Controlla se esiste il primo elemento e poi accedi a 'id'
        knowledge_domain:
          {
            id: inputForm.added_new_knowledge.selectedGroups?.[1]?.id,
            name: inputForm.added_new_knowledge.selectedGroups?.[0]?.name,
          } || null, // Controlla il secondo elemento (se esiste)
      }),
      ...(inputForm.added_new_knowledge.selectedOption === "Capacity" && {
        capacity:
          {
            id: inputForm.added_new_knowledge.selectedGroups?.[0]?.id,
            name: inputForm.added_new_knowledge.selectedGroups?.[0]?.name,
          } || null, // Controlla se esiste il primo elemento e poi accedi a 'id'
      }),
    };

    try {
      await triggerAddKnowledge(objToSend);
      setOpenToast({
        s: StatusEnum.OK,
        message: tModal("successfully-create-skill", {
          campaign: inputForm["campaign_name"] || "",
        }),
      });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("add.gap")} description={t("hero-description")} />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              columns={columns}
              onSubmit={onSubmit}
              getValues={(accessorKey) => accessorKey}
              getOptionValue={setOptionAreaSelected}
              options={[
                optionsCapacity,
                optionsKnowledge,
                optionsKnowledgeDomain,
              ]}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AddKnowledgeLearner;
