import React, { useCallback, useMemo } from "react";

import {
  InputCst,
  SelectCst,
  AutocompleteCst,
  RadioCst,
  DataPickerCst,
  MultiSelectCst,
} from "@components/InputCst";
import { FilterBoxInterface } from "./types";
import ButtonCst from "@components/ButtonCst";
import { Box, Grid, useTheme } from "@mui/material";
import { useFilters } from "@context/filters";
import { useEffect } from "react";
import CheckboxCst from "@components/InputCst/CheckboxCst";
import { useTranslation } from "react-i18next";
import TimePickerCst from "@components/InputCst/TimePickerCst";
import dayjs from "dayjs";
import { Refresh, Search } from "@mui/icons-material";
import { useAuth } from "@context/auth";

const FilterBox: React.FC<FilterBoxInterface> = (props) => {
  const { t } = useTranslation(["form"]);

  const {
    id,
    columns,
    onChangeFilterForm,
    cancelText = t("resetButton"),
    submitText = t("saveButton"),
  } = props;

  const { user } = useAuth();
  const { filters, setFilters } = useFilters();
  const [filterForm, setFilterForm] = React.useState<any>({});
  const theme = useTheme();
  const filterId = !["", null].includes(id) ? `${user?.id_user}_${id}` : "";

  const resetFilterForm = () => {
    const resetForm = { pageIndex: 0, pageSize: 20 };
    setFilterForm({});
    setFilters && setFilters(filterId, resetForm);
    onChangeFilterForm && onChangeFilterForm(resetForm);
  };

  const callBackChangeFilters = useCallback(
    () => onChangeFilterForm && onChangeFilterForm(filterForm),
    [onChangeFilterForm, filterForm]
  );

  useEffect(() => {
    if (filters && Object.keys(filters)?.length > 0) {
      const filtersValue = filters && filters[filterId as keyof typeof filters];
      if (filtersValue && Object.keys(filtersValue)?.length > 0) {
        setFilterForm(filtersValue);
        onChangeFilterForm && onChangeFilterForm(filtersValue);
      }
    }
  }, []);

  const getFilter = useMemo(() => {
    return columns.map((filter: any, index) => {
      switch (filter.type) {
        case "autoIncrement": {
          return (
            <Grid key={filter.accessorKey} item xs={12} sm={6} md={4} lg={3}>
              <InputCst
                id={filter.accessorKey}
                label={filter.header}
                value={filterForm[filter.accessorKey]}
                maxValue={filter?.rules?.max?.value}
                multiline={filter.multiline}
                type={filter.inpuType || "text"}
                onChange={(e: any) =>
                  setFilterForm({
                    ...filterForm,
                    ...{ [filter.accessorKey]: e.target.value },
                  })
                }
                isFilterActive={!!filterForm[filter.accessorKey]}
              />
            </Grid>
          );
        }
        case "text": {
          return (
            <Grid key={filter.accessorKey} item xs={12} sm={6} md={4} lg={3}>
              <InputCst
                id={filter.accessorKey}
                label={filter.header}
                value={decodeURIComponent(filterForm[filter.accessorKey] || "")}
                maxValue={filter?.rules?.max?.value}
                multiline={filter.multiline}
                type={filter.inpuType || "text"}
                onChange={(e: any) =>
                  setFilterForm({
                    ...filterForm,
                    ...{
                      [filter.accessorKey]: encodeURIComponent(e.target.value),
                    },
                  })
                }
                isFilterActive={!!filterForm[filter.accessorKey]}
              />
            </Grid>
          );
        }
        case "select-checkbox":
        case "iconText":
        case "select": {
          return (
            <Grid key={filter.accessorKey} item xs={12} sm={6} md={4} lg={3}>
              <SelectCst
                id={filter.accessorKey}
                label={filter.header}
                optionsKey={filter.optionsKey}
                value={filterForm[filter.accessorKey]}
                optionsToDisable={filter.optionsToDisable}
                onChange={(e: any) => {
                  setFilterForm({
                    ...filterForm,
                    ...{ [filter.accessorKey]: e },
                  });
                }}
                options={filter.options || []}
                isFilterActive={!!filterForm[filter.accessorKey]}
              />
            </Grid>
          );
        }
        case "multiCheckbox": {
          return (
            <Grid key={filter.accessorKey} item xs={12} sm={6} md={4} lg={3}>
              <MultiSelectCst
                id={filter.accessorKey}
                label={filter.header}
                optionsKey={filter.optionsKey}
                value={filterForm[filter.accessorKey]}
                onChange={(e: any) => {
                  console.log("e :>> ", e);
                  setFilterForm({
                    ...filterForm,
                    ...{ [filter.accessorKey]: e },
                  });
                }}
                options={filter.options || []}
                isFilterActive={!!filterForm[filter.accessorKey]}
              />
            </Grid>
          );
        }
        case "autocomplete": {
          return (
            <Grid key={filter.accessorKey} item xs={12} sm={6} md={4} lg={3}>
              <AutocompleteCst
                id={filter.accessorKey}
                label={filter.header}
                value={filterForm[filter.accessorKey]}
                onChange={(e: any) =>
                  setFilterForm({
                    ...filterForm,
                    ...{ [filter.accessorKey]: e },
                  })
                }
                options={filter.options}
                isFilterActive={!!filterForm[filter.accessorKey]}
              />
            </Grid>
          );
        }
        case "radio": {
          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <RadioCst
                key={filter.accessorKey}
                id={filter.accessorKey}
                label={filter.header}
                value={filterForm[filter.accessorKey]}
                onChange={(e: any) =>
                  setFilterForm({
                    ...filterForm,
                    ...{ [filter.accessorKey]: e },
                  })
                }
                options={[
                  { value: "10", label: "Flower" },
                  { value: "20", label: "Engineer" },
                ]}
              />
            </Grid>
          );
        }
        case "checkbox": {
          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <CheckboxCst
                key={filter.accessorKey}
                id={filter.accessorKey}
                label={filter.header}
                value={filterForm[filter.accessorKey]}
                onChange={(e: any) =>
                  setFilterForm({
                    ...filterForm,
                    ...{ [filter.accessorKey]: e },
                  })
                }
                options={[
                  { value: "10", label: "Flower" },
                  { value: "20", label: "Engineer" },
                ]}
              />
            </Grid>
          );
        }
        case "switch": {
          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              switch
            </Grid>
          );
        }
        case "dateTimeYear": {
          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <DataPickerCst
                views={["year"]}
                key={filter.accessorKey}
                id={filter.accessorKey}
                label={filter.header}
                value={filterForm[filter.accessorKey]}
                onChange={(e) => {
                  const date = new Date(e);
                  const year = date.getFullYear();
                  setFilterForm({
                    ...filterForm,
                    ...{
                      [filter.accessorKey]: year.toString(),
                    },
                  });
                }}
                required={false}
                error={false}
                disabled={false}
                isFilterActive={!!filterForm[filter.accessorKey]}
              />
            </Grid>
          );
        }
        case "dateTime": {
          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <DataPickerCst
                key={filter.accessorKey}
                id={filter.accessorKey}
                label={filter.header}
                value={filterForm[filter.accessorKey]}
                onChange={(e: any) => {
                  const date = new Date(e);
                  const dateUTC = Date.UTC(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    0,
                    0,
                    0
                  );
                  setFilterForm({
                    ...filterForm,
                    ...{
                      [filter.accessorKey]: new Date(dateUTC).toISOString(),
                    },
                  });
                }}
                required={false}
                error={false}
                disabled={false}
                isFilterActive={!!filterForm[filter.accessorKey]}
              />
            </Grid>
          );
        }
        case "time": {
          return (
            <Grid key={filter.accessorKey} item xs={12} sm={6} md={4} lg={3}>
              <TimePickerCst
                id={filter.accessorKey}
                value={filterForm[filter.accessorKey]}
                label={filter.header}
                onChange={(e: any) =>
                  setFilterForm({
                    ...filterForm,
                    ...{
                      [filter.accessorKey]: dayjs(e).format(
                        "YYYY-MM-DDTHH:mm:ss.SSS"
                      ),
                    },
                  })
                }
              />
            </Grid>
          );
        }
      }
    });
  }, [columns, filterForm]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setFilters &&
          setFilters(filterId, {
            ...filterForm,
            ...{ pageIndex: 0, pageSize: 20 },
          });
        callBackChangeFilters();
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{ p: "16px 0 48px 24px", mt: 0, width: "100%" }}
      >
        {getFilter}
        <Grid item xs={12} sm={6} md={4} lg={3} sx={{ ml: "auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "16px",
            }}
          >
            <ButtonCst
              id={"reset-button"}
              variant={"outlined"}
              onClick={() => {
                // setFilters && setFilters(`${user?.id_user}_${id}`, {});
                resetFilterForm();
              }}
              size={"medium"}
              title="Reset"
              sx={{
                minWidth: "40px",
                padding: "0 15px",
              }}
            >
              <Refresh />
            </ButtonCst>
            <ButtonCst
              id={"apply-button"}
              variant={"contained"}
              size={"medium"}
              type="submit"
              title="Filtra"
              sx={{
                minWidth: "40px",
                padding: "0 15px",
              }}
            >
              <Search />
            </ButtonCst>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default FilterBox;
