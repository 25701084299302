import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import { usePostDetail } from "@hooks/useFetchDetail";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useFetchMock } from "../../../../../../../mock_data/useMockData";
import ButtonBackPage from "../../../../../../../components/ButtonBackPage";
import { useParams } from "react-router-dom";
import { useFetchTable } from "../../../../../../../hooks/useFetchDataTable";

const useKnowledgeDomains = (id: any, idArea: any) => {
  const { dataTable: knowledgeOptionsDomain } = useFetchTable(
    `${ServicesURL.getKnowledgeDomainsId
      .replace(":idCorporate", `${id}`)
      .replace(":idArea", `${idArea}`)}`,
    PagesID["skills.knowledge-domains"]
  );

  return knowledgeOptionsDomain;
};

interface TCapabilities {
  obsolete: boolean;
  id: string;
  name: string;
  description: string;
  identifier: string;
  type: string;
  corporate_id: string;
  creation_date: string;
  delete_date: string;
  update_date: string;
  created_by: string;
  updated_by: string;
  deleted_by: string;
  deleted_by_name: string;
  created_by_name: string;
  updated_by_name: string;
}

interface TKnowledge {
  obsolete: boolean;
  id: string;
  name: string;
  description: string;
  identifier: string;
  type: string;
  corporate_id: string;
  creation_date: string;
  delete_date: string;
  update_date: string;
  created_by: string;
  updated_by: string;
  deleted_by: string;
  deleted_by_name: string;
  created_by_name: string;
  updated_by_name: string;
  industries: [0];
}

const AddKnowledge: React.FC = () => {
  const { t } = useTranslation(["training-needs-campaign-page"]);
  const { id, idCampaign } = useParams();

  const [optionsCapacity, setOptionsCapacity] = useState<TCapabilities | any>(
    []
  );
  const [optionsKnowledge, setOptionsKnowledge] = useState<TKnowledge | any>(
    []
  );
  const [optionsKnowledgeDomain, setOptionsKnowledgeDomain] = useState<
    TKnowledge | any
  >([]);

  const [optionAreaSelected, setOptionAreaSelected] = useState<any>();

  const { columns } = useFetchMock(
    PagesID["corporate.campaign.visualize.confirm.add"]
  );

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { trigger: triggerAddKnowledge } = usePostDetail(
    `${ServicesURL.digitedDetailCampaignAdd.replace(
      "{campaignId}",
      `${idCampaign}`
    )}`
  );

  const { dataTable: capabilitiesOptions } = useFetchTable(
    `${ServicesURL.getCapabilities.replace(":idCorporate", `${id}`)}`,
    PagesID["skills.capabilities"]
  );
  const { dataTable: knowledgeOptions } = useFetchTable(
    `${ServicesURL.getKnowledgeAreas.replace(":idCorporate", `${id}`)}`,
    PagesID["skills.knowledge-areas"]
  );

  const knowledgeOptionsDomain = useKnowledgeDomains(
    id,
    optionAreaSelected ? optionAreaSelected[0].id : 0
  );

  useEffect(() => {
    if (optionAreaSelected && optionAreaSelected[0].id) {
      if (knowledgeOptionsDomain)
        setOptionsKnowledgeDomain(knowledgeOptionsDomain.output);
    }
  }, [knowledgeOptionsDomain, optionAreaSelected]);

  useEffect(() => {
    setOptionsKnowledge((knowledgeOptions && knowledgeOptions.output) || []);
    setOptionsCapacity(
      (capabilitiesOptions && capabilitiesOptions.output) || []
    );
  }, [knowledgeOptions, capabilitiesOptions]);

  const onSubmit = async (inputForm: any) => {
    const knowledgeArea =
      (inputForm.added_new_knowledge.selectedOption.toLowerCase() ===
        "knowledge" &&
        inputForm.added_new_knowledge.selectedGroups[0]) ||
      null;
    const knowledgeDomain =
      (inputForm.added_new_knowledge.selectedOption.toLowerCase() ===
        "knowledge" &&
        inputForm.added_new_knowledge.selectedGroups[1]) ||
      null;
    const capacity =
      (inputForm.added_new_knowledge.selectedOption.toLowerCase() ===
        "capacity" &&
        inputForm.added_new_knowledge.selectedGroups[0]) ||
      null;

    // Controllo se è selezionata "knowledge" e i due campi knowledgeArea e knowledgeDomain sono pieni
    if (
      inputForm.added_new_knowledge.selectedOption.toLowerCase() === "knowledge"
    ) {
      if (!knowledgeArea || !knowledgeDomain) {
        setOpenToast({
          s: StatusEnum.KO,
          message:
            "Se hai selezionato 'Conoscenza', devi riempire sia l'area di conoscenza che il dominio.",
        });
        return;
      }
    }

    // Controllo se almeno uno dei campi è pieno (knowledge o capacity)
    if (!knowledgeArea && !knowledgeDomain && !capacity) {
      setOpenToast({
        s: StatusEnum.KO,
        message: "Devi riempire almeno uno tra conoscenza, capacità o dominio.",
      });
      return;
    }

    const objToSend: any = {
      //ANCHOR - bisogna capire da dove prendere il dato
      uog: "uog1",
      ...(capacity && { capacity: { id: capacity.id, name: capacity.name } }),
      ...(knowledgeArea && {
        knowledge_area: { id: knowledgeArea.id, name: knowledgeArea.name },
      }),
      ...(knowledgeDomain && {
        knowledge_domain: {
          id: knowledgeDomain.id,
          name: knowledgeDomain.name,
        },
      }),
    };

    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });

    try {
      await triggerAddKnowledge(objToSend);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              columns={columns}
              onSubmit={onSubmit}
              getValues={(accessorKey) => accessorKey}
              getOptionValue={setOptionAreaSelected}
              options={[
                optionsCapacity,
                optionsKnowledge,
                optionsKnowledgeDomain,
              ]}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AddKnowledge;
