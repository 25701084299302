import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";

const LearnerBadge = styled(Typography)({
  backgroundColor: "#000",
  color: "#fff",
  border: "1px solid #fff",
  padding: "1px 12px",
  borderRadius: "4px",
  fontSize: "14px",
  display: "inline-block",
});

function BadgeRole({ role }: any) {
  return (
    <div>
      <LearnerBadge variant="h6">{role.toUpperCase()}</LearnerBadge>
    </div>
  );
}

export default BadgeRole;
