export const UseLabelTableStyles = (label: string, status?: any) => {
  const getLabelColor = () => {
    switch (label.toLowerCase()) {
      case "status_campaign_data":
      case "manager_state_data":
      case "hr_state_data":
        switch (status.toLowerCase()) {
          case "completed":
            return "#A6FF03";
          case "blocked":
            return "#FF6B00";
          case "in_progress":
            return "#0FD9FF";
          case "canceled":
            return "red";
          default:
            return "#ffffff";
        }
      case "status_detail_user":
        switch (status.toLowerCase()) {
          case "completed":
            return "#A6FF03";
          case "not_completed":
            return "#FF6B00";
          default:
            return "#ffffff";
        }
      default:
        return "#ffffff";
    }
  };

  return {
    color: `${getLabelColor()}`,
  };
};
