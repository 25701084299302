import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useLocation, useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ButtonBackPage from "@components/ButtonBackPage";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useFetchMock } from "src/mock_data/useMockData";
import { StatusEnum } from "@components/ModalConfirmsCst/types";

const GroupsEnrollmentStatusDetail: React.FC = () => {
  const { t } = useTranslation(["user-of-groups-page", "breadcrumb"]);
  const { id = 1, idGroup, enrollmentId } = useParams();
  const location = useLocation();
  const isDynamic = location.pathname.includes("groups-dynamic");
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
  } = useFetchTable<any[]>(
    `${ServicesURL.getEnrollmentGroupLo.replace(
      ":idCorporate",
      `${id}`
    )}?enrollmentId=${enrollmentId}`,
    PagesID["corporate.groups-enrollment-status-detail"]
  );

  const { dataDetail: groupDetail } = useFetchDetail(
    isDynamic
      ? ServicesURL.dynamicGroups.replace(":idCorporate", `${id}`)
      : ServicesURL["static-groups"],
    idGroup || "",
    isDynamic
      ? PagesID["corporate.groups-dynamic"]
      : PagesID["corporate.groups"]
  );

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-groups.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${groupDetail?.name} - ${t("enrollment-status", {
            ns: "breadcrumb",
          })} - ${t("lo-associated", { ns: "breadcrumb" })}`}
          description={t("hero-description")}
        />
        <ButtonBackPage
          customRedirect={`/corporate/${id}/detail/${
            isDynamic ? "groups-dynamic" : "groups"
          }/${idGroup}/enrollment-status`}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.groups-enrollment-status-detail"]}
            data={dataTable?.output}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            totalPages={dataTable?.metadata?.total_elements}
            enableRowActions={false}
          />
        </Box>
      </Container>
    </AnimationFadeIn>
  );
};

export default GroupsEnrollmentStatusDetail;
