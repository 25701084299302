//REACT AND REACT BASED LIBRARIES
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import { Box, Container } from "@mui/material";
import AddEditFormCst from "@components/AddEditFormCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";

const EditTagging: React.FC = () => {
  const { t } = useTranslation(["tagging-page"]);
  const { idTag } = useParams();
  const navigate = useNavigate();

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { columns, dataDetail } = useFetchDetail(
    ServicesURL.getVersioningTagging,
    idTag,
    PagesID["sw-tagging.edit"]
  );

  const { trigger } = useSWRMutation(
    `${ServicesURL.patchVersioningTagging}`,
    (url: string, arg: { arg: any }) => {
      return fetcher<any>(`${url}?tagId=${idTag}`, MethodType.PATCH, {
        ...arg.arg,
      });
    }
  );

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    const newInputForm = {
      tagName: inputForm.tagName.trim(),
    };
    try {
      await trigger(newInputForm);
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
    // mutate && mutate(inputForm) //REFRESH DEI DATI GET
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-certificates.jpg" //PAGINA DI SFONDO
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("edit.hero-title")}
          description={t("edit.hero-description")}
        />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={dataDetail || []}
              columns={columns}
              onSubmit={onSubmit}
              actionsForForms={[]}
              backButton={() => {
                navigate(-1);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default EditTagging;
