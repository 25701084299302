//REACT AND REACT BASED LIBRARIES
import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
//COMPONENTS
import Table from "@components/Table";
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import BackgroundImage from "@components/BackgroundImageCst";
//CUSTOM HOOKS AND UTILS
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useFetchTable } from "@hooks/useFetchDataTable";
import ButtonBackPage from "@components/ButtonBackPage";

const AllServicesVersioning: React.FC = () => {
  const { t } = useTranslation(["tagging-all-services-page"]);
  const { idTag } = useParams();

  const { columns, dataTable, handleFetchData, actionsTopToolbar } =
    useFetchTable(
      `${ServicesURL.getVersioning}?tags=${idTag}`,
      PagesID["sw-all-services"]
    );

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-certificates.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["sw-all-services"]}
            data={dataTable?.response || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            configurationActionForRow={[]}
            enableRowActions={false}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>
    </AnimationFadeIn>
  );
};

export default AllServicesVersioning;
