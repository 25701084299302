// Import
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

interface ConfirmModalCstInterface {
  title?: string;
  description?: string;
  onCancelAction: () => void;
}

const EnrollmentExceededModalCst: React.FC<ConfirmModalCstInterface> = ({
  title,
  description,
  onCancelAction,
}) => {
  return (
    <Box
      sx={{
        minHeight: "200px",
        alignItems: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ margin: "0" }} id="parent-modal-title">
          {title}
        </h2>
        <Button
          onClick={() => onCancelAction()}
          sx={{
            borderRadius: "50%",
            minWidth: "0",
            padding: "0 3px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            span: {
              width: "0",
            },
          }}
        >
          <CloseIcon fontSize="large" />
        </Button>
      </Box>
      <Typography
        id="parent-modal-description"
        variant="body2"
        sx={{
          mt: 7,
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default EnrollmentExceededModalCst;
