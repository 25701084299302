import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import { ActionUploadDownloadIterface } from "@components/ModalDownloadUploadCst/types";
import { downloadFile, uploadFile } from "@utils/utilsApi";
import { axiosInstance, fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import useSWRMutation from "swr/mutation";
import AnimationFadeIn from "@components/AnimationFadeIn";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import { useHeader } from "@context/header";
import ButtonBackPage from "../../../../../components/ButtonBackPage";
import { useFetchTable } from "../../../../../hooks/useFetchDataTable";
import { Campaign } from "./types";
import { TableTabs } from "../../../../../components/TableTabs/Index";
import { useParams } from "react-router-dom";

const CreateManagement: React.FC = () => {
  const { t } = useTranslation(["training-needs-details-user"]);
  const { t: tModal } = useTranslation(["modal"]);
  const { setHideHeader } = useHeader();
  const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();
  const [campaignDeleted, setCampaignDeleted] = useState<Campaign>();
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalStartCampaign, setModalStartCampaign] = useState(false);
  const [modalConfirmCampaign, setModalConfirmCampaign] = useState(false);
  const [actionData, setActionData] = useState<any>({});
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  useEffect(() => {
    if (actionData.type == "reminder") setModalConfirm(true);
  }, [actionData]);

  const [selectedTab, setSelectedTab] = useState(0);
  const { id, idampaign } = useParams();

  const dataCampaign = useFetchTable(
    `${ServicesURL.digitedTipologyCampaign}`
      .replace("{campaignType}", "UOG")
      .replace("{corporateId}", `${id}`),
    PagesID["corporate.campaign.table"]
  );

  const dataCampaignLearner = useFetchTable(
    `${ServicesURL.digitedTipologyCampaign}`
      .replace("{campaignType}", "LEARNER")
      .replace("{corporateId}", `${id}`),
    PagesID["corporate.campaign.table.learner"]
  );

  const tabSelected = selectedTab == 1 ? dataCampaignLearner : dataCampaign;

  const { trigger: triggerDeleteCampaign } = useSWRMutation(
    `${ServicesURL.digitedCampaign}`,
    (url: string, { arg }: { arg: { users_id: any } }) => {
      return fetcher<any>(
        `${url}/${arg.users_id.campaign_id}`,
        MethodType.DELETE
      );
    }
  );

  const { trigger: triggerStartCampaign } = useSWRMutation(
    `${ServicesURL.digitedCampaign}`,
    (url: string, { arg: campaign_id }) => {
      return fetcher<any>(`${url}/${campaign_id}`, MethodType.PUT, {
        campaign_state: "IN_PROGRESS",
      });
    }
  );

  const { trigger: triggerCloseCampaign } = useSWRMutation(
    `${ServicesURL.digitedCampaign}`,
    (url: string, { arg: campaign_id }) => {
      return fetcher<any>(`${url}/${campaign_id}`, MethodType.PUT, {
        hr_state: "COMPLETED",
      });
    }
  );

  const { trigger: reminderUser } = useSWRMutation(
    ServicesURL.reminderUsersCampaign,
    (url: string, { arg }: { arg: any }) => {
      const fullUrl = `${url}?corporate_id=${id}&campaign_id=${arg.campaign_id}`;
      return axiosInstance.post(fullUrl);
    }
  );

  const handleStartCampaign = async () => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    if (actionData) {
      try {
        await triggerStartCampaign(actionData.campaign_id);
        setOpenToast({
          s: StatusEnum.OK,
          message: t("success-start-campaign"),
        });
        const newData = [...tabSelected.dataTable.output];
        tabSelected.mutate &&
          tabSelected.mutate({
            ...tabSelected.dataTable,
            ...{ output: newData },
          });
      } catch (error) {
        setModalStartCampaign(false);
        setOpenToast(undefined);
        console.error(error);
      }
    } else setOpenToast({ s: StatusEnum.KO, message: "No Data Row" });
  };

  const handleConfirmCampaign = async () => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    if (actionData) {
      try {
        await triggerCloseCampaign(actionData.campaign_id);
        setOpenToast({
          s: StatusEnum.OK,
          message: t("success-confirm-campaign"),
        });
        const newData = [...tabSelected.dataTable.output];
        tabSelected.mutate &&
          tabSelected.mutate({
            ...tabSelected.dataTable,
            ...{ output: newData },
          });
      } catch (error) {
        setModalConfirmCampaign(false);
        setOpenToast(undefined);
        console.error(error);
      }
    } else setOpenToast({ s: StatusEnum.KO, message: "No Data Row" });
  };

  const handleReminderUser = async () => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    if (actionData) {
      try {
        await reminderUser({ campaign_id: actionData.campaign_id });
        setOpenToast({
          s: StatusEnum.OK,
          message: tModal("successfully-reminder-user"),
        });
      } catch (error) {
        setModalConfirm(false);
        setOpenToast(undefined);
        console.error(error);
      }
    } else setOpenToast({ s: StatusEnum.KO, message: "No User" });
  };

  const handleDelete = React.useCallback(async () => {
    if (campaignDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDeleteCampaign({
          users_id: campaignDeleted,
        });
        const newData = [
          ...tabSelected.dataTable.output.filter(
            (el: any) => el.campaign_id !== campaignDeleted.campaign_id
          ),
        ];
        tabSelected.mutate &&
          tabSelected.mutate({
            ...tabSelected.dataTable,
            ...{ output: newData },
          });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [tabSelected.dataTable, campaignDeleted, triggerDeleteCampaign]);

  const configurationActionForRow = React.useMemo(() => {
    return (row: any) => {
      let disabled = false;
      let switchIcon = false;
      let dynamicIconColor = "";

      return tabSelected.actionForRow
        ?.filter((action: any) => {
          switch (action.type) {
            case "startCampaign":
              return !(
                selectedTab == 1 && row.original.campaign_state != "BLOCKED"
              );
            case "confirm":
              return !(
                selectedTab == 1 && row.original.hr_state == "COMPLETED"
              );
            case "collectionResults":
              return !(
                selectedTab == 1 && row.original.hr_state != "COMPLETED"
              );
            case "visibility":
              return !(
                selectedTab == 1 && row.original.hr_state != "COMPLETED"
              );
            case "endCampaign":
              return (
                selectedTab == 1 &&
                row.original.campaign_state == "IN_PROGRESS" &&
                row.original.hr_state != "COMPLETED"
              );
            default:
              return true;
          }
        })
        .map((action: any) => {
          switch (action.type) {
            case "delete": {
              disabled =
                row.original.campaign_state &&
                row.original.campaign_state == "COMPLETED";

              return {
                ...action,
                disabled: disabled,
                callBack: (data: Campaign) => setCampaignDeleted(data),
              };
            }
            case "startCampaign": {
              return {
                ...action,
                disabled: false,
                callBack: (row: any) => setModalStartCampaign(true),
              };
            }
            case "endCampaign": {
              return {
                disabled: false,
                ...action,
                callBack: (row: any) => setModalConfirmCampaign(true),
              };
            }
            case "upload": {
              return {
                ...action,
                callBack: (currentElement: any) => {
                  const upload = {
                    ...action,
                    types: ["upload", "download"],
                    onDownload: (el: string) => {
                      try {
                        downloadFile(
                          `${ServicesURL.library}/userprofile/${currentElement.id_user}/image`,
                          true
                        );
                      } catch (error) {
                        console.error(error);
                      }
                    },
                    onUpload: (formData: FormData) => {
                      return uploadFile(
                        `${ServicesURL.library}/userprofile/image?userId=${currentElement.id_user}`,
                        formData
                      );
                    },
                  };
                  setOpenUpload(upload);
                },
              };
            }
            case "nextStep": {
              switchIcon = row.original.manager_state == "COMPLETED";

              return {
                ...action,
                switchIcon: switchIcon,
              };
            }
            case "confirm": {
              dynamicIconColor = row.original.hr_state;
              return {
                ...action,
                dynamicIconColor,
              };
            }
            case "reminder": {
              disabled =
                selectedTab == 1
                  ? row.original.hr_state == "COMPLETED" ||
                    row.original.campaign_state == "BLOCKED"
                  : row.original.campaign_state == "COMPLETED";

              return {
                ...action,
                disabled: disabled,
              };
            }
            case "collectionResults": {
              disabled =
                selectedTab == 1 && row.original.campaign_state == "BLOCKED";
              return {
                ...action,
                disabled: disabled,
              };
            }
            default:
              return action;
          }
        });
    };
  }, [tabSelected.actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow(row) && [
      ...configurationActionForRow(row),
    ];

    if (
      row.original.status === "active" ||
      row.original.status === "suspended"
    ) {
      return [
        <CustomRowAction
          key={row.id}
          row={row}
          setActionData={setActionData}
          configurationActionForRow={[
            ...configRowNew.filter(
              (el: any) => ["activate"].findIndex((key) => key === el.key) < 0
            ),
          ]}
          setHideHeader={setHideHeader}
        />,
      ];
    }

    if (row.original.status === "terminated") {
      configRowNew = configRowNew.map((action: any) => {
        if (!action?.key && !["delete", "activate"].includes(action?.key)) {
          return { ...action, disabled: true };
        } else {
          return { ...action };
        }
      });
      return [
        <CustomRowAction
          key={row.id}
          row={row}
          setActionData={setActionData}
          configurationActionForRow={[
            ...configRowNew.filter(
              (el: any) => ["delete"].findIndex((key) => key === el.key) < 0
            ),
          ]}
          setHideHeader={setHideHeader}
        />,
      ];
    }

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        setActionData={setActionData}
        configurationActionForRow={[
          ...configRowNew.filter(
            (el: any) =>
              ["delete", "activate"].findIndex((key) => key === el.key) < 0
          ),
        ]}
      />,
    ];
  };

  const tabs = [
    {
      "Per Unità Organizzativa": (
        <Table
          id={PagesID["corporate.campaign.table"]}
          data={tabSelected.dataTable?.output}
          columns={tabSelected.columns || []}
          onChangeFilterForm={tabSelected.handleFetchData}
          configurationActionForRow={tabSelected.actionForRow}
          configurationTopToolbar={tabSelected.actionsTopToolbar}
          renderRowActions={customRenderRowActions}
          totalPages={tabSelected.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
        />
      ),
    },
    {
      "Per Learner": (
        <Table
          id={PagesID["corporate.campaign.table.learner"]}
          data={tabSelected.dataTable?.output}
          columns={tabSelected.columns || []}
          onChangeFilterForm={tabSelected.handleFetchData}
          configurationActionForRow={tabSelected.actionForRow}
          configurationTopToolbar={tabSelected.actionsTopToolbar}
          renderRowActions={customRenderRowActions}
          totalPages={tabSelected.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
        />
      ),
    },
  ];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          // title={`${dataDetail?.name} - ${t("hero-title")}`}
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <TableTabs setExternalSelectedTab={setSelectedTab} tabs={tabs} />
      </Container>
      <ModalDownloadUploadCst
        open={!!openUpload}
        setOpen={() => {
          setOpenUpload(undefined);
        }}
        title={openUpload?.labelTranslate || ""}
        data={openUpload}
      />

      <ModalConfirmsCst
        open={!!campaignDeleted}
        title={`${t("delete-campaign", { ns: "modal" })}`}
        description={`${t("confirm-delete-campaign", { ns: "modal" })}: ${
          campaignDeleted && campaignDeleted?.campaign_name
        }`}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setCampaignDeleted(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => campaignDeleted && handleDelete()}
        status={openToast}
      />
      <ModalConfirmsCst
        open={modalConfirm}
        routeToSamePage={true}
        title={t("modal-confirm-reminder-all")}
        description={""}
        onActionConfirmed={handleReminderUser}
        onCallBackClosed={() => {
          setOpenToast(undefined);
          setModalConfirm(false);
        }}
        status={openToast}
      />
      <ModalConfirmsCst
        open={modalStartCampaign}
        routeToSamePage={true}
        title={t("start-campaign-title")}
        description={t("start-campaign-description")}
        onActionConfirmed={handleStartCampaign}
        onCallBackClosed={() => {
          setOpenToast(undefined);
          setModalStartCampaign(false);
        }}
        status={openToast}
      />
      <ModalConfirmsCst
        open={modalConfirmCampaign}
        routeToSamePage={true}
        title={t("confirm-campaign-title")}
        description={t("confirm-campaign-description")}
        onActionConfirmed={handleConfirmCampaign}
        onCallBackClosed={() => {
          setOpenToast(undefined);
          setModalConfirmCampaign(false);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default CreateManagement;
