import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { Initiative as InitiativeInterface } from "src/interfaces/Initiative";
import ButtonBackPage from "@components/ButtonBackPage";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import useSWRMutation from "swr/mutation";
import AnimationFadeIn from "@components/AnimationFadeIn";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import { disableRowBasedOnDisableIf } from "@utils/utilsTable";
import { ActionsType } from "src/interfaces/Common";
import { useAuth } from "@context/auth";

const Initiative: React.FC = () => {
  const [idInitiativeDeleted, setIdInitiativeDeleted] = useState<
    string | number | undefined
  >(undefined);
  const { id } = useParams();
  const { user } = useAuth();
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable<InitiativeInterface[]>(
    ServicesURL.initiative + `/${id}/initiatives`,
    PagesID["corporate.initiative"],
    { status: "ACTIVE" }
  );
  const { t } = useTranslation(["initiative-page", "modal"]);
  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID["corporate.initiative"]
  );
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    successMessage?: string | null;
  }>();

  const [userAssociation, setUserAssociation] = useState("");
  const [initiativeId, setInitiativeId] = useState("");

  const { trigger: triggerAssociationUser } = useSWRMutation(
    `${
      ServicesURL.initiative +
      `/initiatives/${initiativeId}/users?corporateId=${id}`
    }`,
    (
      url: string,
      {
        arg,
      }: {
        arg: {
          all_users: boolean;
          static_group: number;
          dynamic_group: number;
          external_id: any[];
        };
      }
    ) => {
      return fetcher<any>(url, MethodType.PUT, {
        all_users: arg.all_users,
        static_group: arg.static_group,
        dynamic_group: arg.dynamic_group,
        external_id: arg.external_id,
      });
    }
  );

  const { trigger: triggerDeleteInitiative } = useSWRMutation(
    `${ServicesURL.initiative + `/${id}/initiatives/${idInitiativeDeleted}`}`,
    (url: string) => {
      return fetcher<any>(url, MethodType.DELETE);
    }
  );

  const handleDelete = React.useCallback(async () => {
    if (idInitiativeDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDeleteInitiative();
        const newData = [
          ...dataTable.output.filter(
            (el: any) => el.id !== idInitiativeDeleted
          ),
        ];
        mutate({ ...dataTable, ...{ output: newData } });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [triggerDeleteInitiative, idInitiativeDeleted, mutate, dataTable]);

  const handleUsersAssociation = React.useCallback(async () => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    let objToSend: any = {
      all_users: true,
      static_group: null,
      dynamic_group: null,
      external_id: [],
    };
    try {
      const response = await triggerAssociationUser(objToSend);
      const numberOfUsers = !response.associated_users
        ? 0
        : response.associated_users;
      setOpenToast({
        s: StatusEnum.OK,
        message: "",
        successMessage: `${t("success-message", {
          ns: "toast-message",
        })} ${numberOfUsers}`,
      });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
  }, [triggerAssociationUser, userAssociation]);

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      if (
        action.accessorKey === "users-association" &&
        action.type === "button"
      ) {
        return {
          ...action,
          callBack: (row: any) => setInitiativeId(row.id),
          getIcon: () => <PersonAddIcon />,
        };
      }
      if (action.icon === "community" && action.type === "link") {
        if (dataDetail?.enable_community_flag) {
          return action;
        } else {
          return {};
        }
      }
      if (action.type === "delete") {
        return {
          ...action,
          callBack: (row: any) => setIdInitiativeDeleted(row),
        };
      }
      return action;
    });
  }, [actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    console.log("PRE :>> ", configRowNew);

    configRowNew = disableRowBasedOnDisableIf(configRowNew, row, {
      corporateID: id,
      user: user,
    });

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-initiatives.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.name || ""} - ${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.initiative"]}
            data={dataTable?.output}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
            numberOfActions={configurationActionForRow?.length}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!idInitiativeDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenToast(undefined);
          setIdInitiativeDeleted(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
      <ModalConfirmsCst
        open={!!initiativeId}
        title={t("associate-users.title", { ns: "modal" })}
        description={t("associate-users.description", { ns: "modal" })}
        onCallBackClosed={() => {
          setUserAssociation("");
          setOpenToast(undefined);
          setInitiativeId("");
        }}
        routeToSamePage={true}
        onActionConfirmed={() => handleUsersAssociation()}
        status={openToast}
      />
      {/* <ToastCst message={openToast.message} open={openToast.open} /> */}
    </AnimationFadeIn>
  );
};

export default Initiative;
