import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import { ActionUploadDownloadIterface } from "@components/ModalDownloadUploadCst/types";
import { GroupUser } from "src/interfaces/Group";
import ButtonBackPage from "@components/ButtonBackPage";
import AnimationFadeIn from "@components/AnimationFadeIn";

const UsersGroup: React.FC = () => {
  const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();
  const { id, idGroup } = useParams();
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
  } = useFetchTable<GroupUser[]>(
    ServicesURL.staticGroups + `/v3/${id}/static/${idGroup}/users`,
    PagesID["corporate.groups.user"]
  );
  const { t } = useTranslation(["user-of-groups-page"]);

  const { dataDetail: groupDetail } = useFetchDetail(
    ServicesURL["static-groups"],
    idGroup || "",
    PagesID["corporate.groups"]
  );

  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "",
    PagesID.corporate
  );

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-corp-groups.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataDetail?.name} - ${t("hero-title")} ${
            groupDetail?.name
          } `}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.groups.user"]}
            data={dataTable?.response || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            configurationActionForRow={actionForRow}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
            enableRowActions={false}
          />
        </Box>
      </Container>
      <ModalDownloadUploadCst
        open={!!openUpload}
        setOpen={() => {
          setOpenUpload(undefined);
        }}
        title={openUpload?.labelTranslate || ""}
        data={openUpload}
      />
    </AnimationFadeIn>
  );
};

export default UsersGroup;
