//REACT AND REACT BASED LIBRARIES
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import Table from "@components/Table";
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import BackgroundImage from "@components/BackgroundImageCst";
import CustomRowAction from "@components/Table/components/CustomRowAction";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { ActionsType } from "src/interfaces/Common";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { useFetchTable } from "@hooks/useFetchDataTable";
import ButtonBackPage from "@components/ButtonBackPage";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const GroupViewLO: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["los-enrolledToGroups-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  //?? HOOK PER PARAMETRI DA URL (IL NOME è LO STESSO DI QUELLO INSERITO IN ROUTER es. /:idLO)
  const { id, idLO, idGroup } = useParams();
  const { pathname } = useLocation();

  //?? HOOK PER NAVIGAER IN ALTRE PAGINE
  const navigate = useNavigate();
  const location = useLocation();

  //?? STATI PER GESTIRE LA CANCELLAZIONE e MODALE
  // const [idNomeMockatoDeleted, setIdNomeMockatoDeleted] = useState<string>();
  //const [openModalDelete, setOpenModalDelete] = useState(false);

  //!! STATO PER GESTIONE MODALE DI CONFERMA/RISPOSTA/ERRORE
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();
  const [openToastDeleted, setOpenToastDeleted] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const [groupIDWarning, setGroupIDWarning] = useState<any>();
  const [enrollGroupEdit, setEnrollGroupEdit] = useState<any>();
  const [enrollGroupDeleted, setEnrollGroupDeleted] = useState<any>();

  //!! CHIAMATA GET
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
    mutate,
  } = useFetchTable(
    `${ServicesURL.getLOEnrolledWithEnrollInfo
      .replace(":corporateId", id || "1")
      .replace(":groupId", idGroup || "1")}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    PagesID["corporate.groups.view-lo"] //!! (SOLO DI ESEMPIO) MODIFICARE PAGE ID CON QUELLO INSERITO NEL FILE DI CONFIG
  );

  const { dataDetail: dataGroup } = useFetchDetail(
    location?.state?.row?.groupType === "STATIC"
      ? `${ServicesURL.staticGroups}/static`
      : `${ServicesURL.dynamicGroups.replace(
          ":idCorporate",
          `${id ? id : 1}`
        )}`,
    idGroup || undefined,
    PagesID["corporate.groups"]
  );

  //?? GESTIONE PER CHIAMATA DELETE (A SECONDA DELLE NECESSITÀ)
  const { trigger: triggerDelete } = useSWRMutation(
    `${ServicesURL.getCertificates}`, //!! (SOLO DI ESEMPIO) MODIFICARE SERVICE URL CON QUELLO INSERITO NEL FILE DI CONFIG
    (url: string) => {
      return fetcher<any>(`${url}`, MethodType.DELETE);
    }
  );

  const { trigger: triggerDeleteGroups } = useSWRMutation(
    `${ServicesURL.postEnrollmentMassive}`,
    (
      url: string,
      { arg }: { arg: { LOID: any; groupId: any; groupType: any } }
    ) =>
      fetcher(
        `${url}?corporateId=${id}&groupId=${arg.groupId}&groupType=${arg.groupType}&objectId=${arg.LOID}`,
        MethodType.DELETE
      )
  );

  const handleDeleteGroup = React.useCallback(async () => {
    const groupType = location?.state?.row?.groupType;
    const groupID = idGroup;
    if (enrollGroupDeleted) {
      setOpenToastDeleted({ s: StatusEnum.LOADING, message: "" });
      try {
        await triggerDeleteGroups({
          LOID: enrollGroupDeleted?.id,
          groupId: groupID,
          groupType: groupType,
        });

        mutate && mutate();
        setOpenToastDeleted({ s: StatusEnum.OK, message: "" });
      } catch (e) {
        console.error(e);
        setOpenToastDeleted(undefined);
        setEnrollGroupDeleted(undefined);
      }
    }
  }, [dataTable, enrollGroupDeleted, mutate, triggerDelete]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA
  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.key) {
        case "group_enroll":
          return {
            ...action,
            callBack: (row: any) => {
              setEnrollGroupEdit(row);
              setGroupIDWarning(idGroup);
            },
          };
        case "delete_group_enroll":
          return {
            ...action,
            callBack: (row: any) => {
              setEnrollGroupDeleted(row);
              setGroupIDWarning(idGroup);
            },
          };
        case "enrolled-groups":
          return {
            ...action,
            callBack: (row: any) => {
              navigate(`${row.id}/groups-enrolled`, {
                state: { row: row },
              });
            },
          };

        default:
          return action;
      }
    });
  }, [actionForRow]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA MA IN BASE AL VALORE DEI CAMPI (ROW)
  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    //FUNZIONE PER ABILITAZIONE/ DISABILITAZIONE CAMPI IN BASE A VALORI DELLA ROW
    // configRowNew = disableRowBasedOnDisableIf(configRowNew, row);

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };
  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-certificates.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${dataGroup?.name || "ffff"} - ${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id={PagesID["corporate.groups.view-lo"]}
            data={dataTable?.output || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            renderRowActions={customRenderRowActions}
            numberOfActions={actionForRow.length}
            totalPages={dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>

      <ModalConfirmsCst
        open={!!enrollGroupEdit}
        title={t("edit-item", { ns: "modal" })}
        description={t("warning-edit-group-enroll", { ns: "modal" }).replace(
          ":ID",
          groupIDWarning
        )}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setEnrollGroupEdit(undefined);
          setOpenToastDeleted(undefined);
        }}
        onActionConfirmed={() => {
          const groupType = location?.state?.row?.groupType;
          const groupID = idGroup;
          const url = `${enrollGroupEdit?.id}/${groupType}/${groupID}/enrollment`;
          enrollGroupEdit && navigate(url);
        }}
        status={openToastDeleted}
      />
      <ModalConfirmsCst
        open={!!enrollGroupDeleted}
        title={t("delete-item", { ns: "modal" })}
        description={t("warning-delete-group-enroll", { ns: "modal" }).replace(
          ":ID",
          groupIDWarning
        )}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setEnrollGroupDeleted(undefined);
          setOpenToastDeleted(undefined);
        }}
        onActionConfirmed={() => enrollGroupDeleted && handleDeleteGroup()}
        status={openToastDeleted}
      />
    </AnimationFadeIn>
  );
};

export default GroupViewLO;
