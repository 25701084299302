import puzzleIcon from "../../../assets/icons/puzzle-solid.svg";

const IconPuzzle: React.FC = (props) => {
  return (
    <img
      src={puzzleIcon}
      alt=""
      width="24px"
      height="24px"
      style={{ padding: "2px" }}
    />
  );
};

export default IconPuzzle;
