import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row } from "../Table/style";

import IconCst from "@components/IconCst";
import { SwapCalls } from "@mui/icons-material";
import AvTimerSharpIcon from "@mui/icons-material/AvTimerSharp";
import CheckIcon from "@mui/icons-material/Check";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { UseLabelTableStyles } from "../../hooks/useLabelTableStyles";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IconLabelProps {
  icon: string;
  value: any;
  row: any;
}

export const IconLabel: React.FC<IconLabelProps> = ({ icon, value, row }) => {
  const levelState = row.level_state;

  const { t: optionsTranslate } = useTranslation("input");
  const [status, setStatus] = useState("");
  const displayLabel = typeof value === "object" ? value.label : value;

  const renderIcon = React.useMemo(() => {
    switch (icon) {
      case "completed":
        return <SwapCalls />;
      case "in_progress":
        return <SwapCalls />;
      default:
        return <IconCst icon={icon || ""} />;
    }
  }, [icon, value]);

  const iconActivate = () => {
    switch (icon) {
      case "learner_state_data":
        const [first, second] = value.split("/").map(Number);
        if (first === second) {
          return <CheckIcon sx={{ color: "#A6FF03" }} />;
        } else if (first < second) {
          return <AvTimerSharpIcon sx={{ color: "#0FD9FF" }} />;
        }
        return null;

      case "status_nextStep":
        if (levelState === "COMPLETED") {
          return <CheckIcon sx={{ color: "#A6FF03" }} />;
        } else if (levelState === "IN_PROGRESS") {
          return <AvTimerSharpIcon sx={{ color: "#0FD9FF" }} />;
        } else {
          return <LockOutlinedIcon sx={{ color: "#cf5a05" }} />;
        }

      default:
        return renderIcon;
    }
  };

  useEffect(() => {
    switch (levelState) {
      case "COMPLETED":
        setStatus("completed");
        break;
      case "IN_PROGRESS":
        setStatus("in_progress");
        break;
      default:
        setStatus("blocked");
    }
  }, [levelState, value]);

  const translateLabel = optionsTranslate(`options.status_campaign.${status}`);

  return (
    <Wrapper>
      <IconLabelStyled>
        {iconActivate()}
        {displayLabel}
      </IconLabelStyled>
      {icon === "status_nextStep" && (
        <Box sx={UseLabelTableStyles("hr_state_data", status)}>
          {translateLabel}
        </Box>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconLabelStyled = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  gap: 8px;
`;
