import React from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useParams } from "react-router-dom";
import { ServicesURL } from "../../../../../../../../utils/utilsApi";
import { useFetchTable } from "../../../../../../../../hooks/useFetchDataTable";
import ButtonBackPage from "../../../../../../../../components/ButtonBackPage";

const DetailSkillUsers: React.FC = () => {
  const { t } = useTranslation(["training-needs-details-user"]);
  const { id, idCampaign, idDetail, idDetailKC } = useParams();

  const detailCampaign = useFetchTable(
    `${ServicesURL.digitedUsersCampaign}`.replace(
      "{campaignId}",
      `${idDetail ?? idCampaign}`
    ) + `?campaign_detail_id=${idDetailKC}`,
    PagesID["corporate.learner.campaign.visualize.confirmed"]
  );

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`Visualizza Campagna per Learner`}
          description={t("hero-description")}
        />
        <ButtonBackPage goBack={2} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            enableRowActions={false}
            id={PagesID["corporate.learner.campaign.visualize.confirmed"]}
            data={detailCampaign?.dataTable?.output || []}
            columns={detailCampaign.columns || []}
            onChangeFilterForm={detailCampaign.handleFetchData}
            configurationTopToolbar={detailCampaign.actionsTopToolbar}
            totalPages={detailCampaign.dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
          />
        </Box>
      </Container>
    </AnimationFadeIn>
  );
};

export default DetailSkillUsers;
