import axios, { InternalAxiosRequestConfig } from "axios";
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import { getCookie, setCookie } from "../utils/utilsCookie";
import { MethodType, ResponseInterface } from "./type";
import { cognitoApp, parseJwt, redirectToLearner } from "@utils/utilsCognito";
import { getAllErrorsKeys } from "@utils/utilsApi";

//instance creation
export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "content-type": "application/json",
  },
});

export async function fetcher<T = any>(
  url: string,
  method: MethodType,
  body?: T,
  queryParameters?: { [x: string]: any },
) {
  const res = await customApi(url, method, body, queryParameters);
  return res.data;
}

export function customApi<T = any>(
  url: string,
  method: MethodType,
  body?: any,
  queryParameters?: { [x: string]: any },
  config?: InternalAxiosRequestConfig<any>,
): Promise<ResponseInterface<T>> {
  switch (method) {
    case MethodType.POST: {
      return axiosInstance.post(url, body, config);
    }
    case MethodType.PUT: {
      return axiosInstance.put(url, body, config);
    }
    case MethodType.PATCH: {
      return axiosInstance.patch(url, body, config);
    }
    case MethodType.DELETE: {
      return axiosInstance.delete(url, { params: body });
    }
    case MethodType.GET:
    default: {
      return axiosInstance.get(url, {
        ...config,
        ...{ params: queryParameters },
      });
    }
  }
}

/*** REFRESH ***/
export async function refreshSessionCognito(): Promise<CognitoUserSession> {
  return new Promise((resolve, reject) => {
    const cognitoUser = cognitoApp.getCognitoCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          //TODO: redirect user back to learner or return to default error page
          reject(err);
          redirectToLearner("An error occured during refreshSessionCognito");
        }
        cognitoUser?.refreshSession(
          session.getRefreshToken(),
          (error: any, session: CognitoUserSession) => {
            if (error) {
              //TODO: redirect user back to learner or return to default error page
              reject(error);
              redirectToLearner(
                "An error occured during refreshSessionCognito",
              );
            } else if (session) {
              //Refresh token success
              resolve(session);
            } else {
              //TODO: redirect user back to learner or return to default error page
              console.error("refresh cognito session: unknown error");
              reject(new Error("refresh cognito session: unknown error"));
              redirectToLearner(
                "An error occured during refreshSessionCognito",
              );
            }
          },
        );
      });
    }
  });
}

export async function refreshSessionSSO() {
  const userpoolId = cognitoApp.getUserpool()?.getUserPoolId();
  const { data: userPoolInfo } = await customApi<any>(
    `https://cognito-idp.eu-south-1.amazonaws.com/${userpoolId}/.well-known/openid-configuration`,
    MethodType.GET,
  );
  return new Promise((resolve, reject) => {
    cognitoApp
      .getCognitoCurrentUser()
      ?.getSession(async (error: any, sesion: CognitoUserSession) => {
        if (error) {
          reject(error);
          redirectToLearner("An error occured during refreshSessionSSO");
        }
        try {
          const sessionId = getCookie("sessionId");
          const { data } = await axios.create().post(
            userPoolInfo.token_endpoint,
            {
              client_id: cognitoApp.getUserpool()?.getClientId(),
              grant_type: "refresh_token",
              refresh_token: sesion.getRefreshToken().getToken(),
            },
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            },
          );
          // TO-DO forse va rifatto refreshSession come per refreshSessionCognito
          new CognitoUserSession({
            AccessToken: new CognitoAccessToken({
              AccessToken: data.access_token,
            }),
            IdToken: new CognitoIdToken({ IdToken: data.id_token }),
            RefreshToken: new CognitoRefreshToken({
              RefreshToken: data.refresh_token,
            }),
          });
          resolve(data);
        } catch (error) {
          console.error(error);
          redirectToLearner("An error occured during refreshSessionSSO");
        }
      });
  });
}

const refreshToken = async () => {
  const cognitoUser = cognitoApp.getCognitoCurrentUser();
  if (cognitoUser) {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession(async (err: any, session: CognitoUserSession) => {
        if (err) {
          //TODO: redirect user back to learner or return to default error page
          reject(err);
          redirectToLearner("An error occured during refreshSessionCognito");
        }
        const tokenParse = parseJwt(session.getIdToken().getJwtToken());
        if (
          Object.keys(tokenParse).findIndex((el) => el === "identities") >= 0
        ) {
          resolve(refreshSessionSSO());
        } else {
          resolve(refreshSessionCognito());
        }
      });
    });
  } else {
    // Refresh token not possible, redirect to corporate domain
    redirectToLearner(
      "It was not possible to refreshToken because cognitoUser is not present",
    );
  }
};

axiosInstance.interceptors.request.use(async (config) => {
  if (process.env.REACT_APP_BASE_URL && !config.url?.includes("https://")) {
    // if(config.url?.includes("/skills")){
    //   config.baseURL = "https://web-learning-professions-and-skills-v1.openlearning-svil.digitedacademy.net/";
    // }
    const sessionId = getCookie("sessionId") || "";
    let authToken = getCookie("authToken") || "";
    // console.info("***authToken***", authToken);
    // console.info("***config***", config);
    const cognitoUser = cognitoApp.getCognitoCurrentUser();
    if (cognitoUser) {
      await cognitoUser.getSession(
        async (err: any, session: CognitoUserSession) => {
          if (err) {
            await refreshSessionCognito();
            authToken = session.getAccessToken().getJwtToken();
          } else {
            authToken = session.getAccessToken().getJwtToken();
          }
        },
      );
    }
    // Don't pass Authorization header to GET /cognito/
    if (authToken && !config.url?.includes("/cognito/")) {
      config.headers["authorization"] = authToken;
    }
    if (sessionId) {
      config.headers["x-ada-session-token"] = sessionId;
    }
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (sessionStorage.getItem("retryAPICallAfterRefresh")) {
      sessionStorage.removeItem("retryAPICallAfterRefresh");
    }
    return response;
  },
  async (error: any) => {
    //refresh token if status code is 401
    if (error.toJSON().status === 401) {
      // Check if already refreshed once, if already refresh and still 401 redirect to Corporate Login
      if (sessionStorage.getItem("retryAPICallAfterRefresh") !== "last") {
        // Make refresh token
        if (sessionStorage.getItem("retryAPICallAfterRefresh") === "first") {
          sessionStorage.setItem("retryAPICallAfterRefresh", "last");
        } else {
          // Refresh cognito token only the first time API goes to 401 not at retry
          await refreshToken();
          sessionStorage.setItem("retryAPICallAfterRefresh", "first");
        }

        // Check if error is about session expired "sessionmanager.unauthorized" or "session.not.found"
        const errorsKeys = getAllErrorsKeys(error);
        // if (
        //   errorsKeys.includes("sessionmanager.unauthorized") ||
        //   errorsKeys.includes("session.not.found")
        // ) {
        // refresh session and retry (token already refresh if possible)
        let idToken = "";
        let authToken = "";
        let tokenRefresh = "";
        const cognitoUser = cognitoApp.getCognitoCurrentUser();
        if (cognitoUser) {
          cognitoUser.getSession((err: any, session: CognitoUserSession) => {
            if (err) {
              redirectToLearner(
                "An error occured during refresh sessionId -> it was not possible to call POST /sessions because cognito user is not set",
              );
            } else {
              idToken = session.getIdToken().getJwtToken();
              authToken = session.getAccessToken().getJwtToken();
              tokenRefresh = session.getRefreshToken().getToken();
            }
          });
          if (idToken && authToken && tokenRefresh) {
            try {
              const sessionsPostResponse = await fetch(
                `${process.env.REACT_APP_BASE_URL}sessions`,
                {
                  method: "POST",
                  cache: "no-cache",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: idToken,
                    AuthToken: authToken,
                    RefreshToken: tokenRefresh,
                  },
                },
              );
              const sessionsPostData = await sessionsPostResponse.json();
              if (sessionsPostData.sessionId) {
                // Update new sessionId of new session created as cookie
                setCookie(
                  "sessionId",
                  sessionsPostData.sessionId,
                  new Date().getTime(),
                );
                // retry api call
                return axiosInstance.request(error.config);
              } else {
                redirectToLearner(
                  "An error occured during refresh sessionId -> response of POST /sessions didn't retrieve sessionId",
                );
              }
            } catch (err) {
              redirectToLearner(err);
            }
          } else {
            redirectToLearner(
              "An error occured during refresh sessionId -> idToken, authToken or refreshToken is missing in cognito user",
            );
          }
        } else {
          redirectToLearner(
            "An error occured during refresh sessionId -> cognito user is not set",
          );
        }
        // } else {
        //   // retry (token already refresh if possible)
        //   return axiosInstance.request(error.config);
        // }
      } else {
        redirectToLearner(
          "API call status again for 2 times in 401 after trying to refresh token or session",
        );
      }
    } else {
      throw error;
    }
  },
);
