import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import { MethodType } from "src/services/type";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFetchDetail } from "@hooks/useFetchDetail";
import {
  ServicesURL,
  getMessageObjectForModalEnrollementsAPI,
} from "@utils/utilsApi";
import { fetcher } from "src/services/config";
import { PagesID } from "@utils/utilsConfigurations";
import { User } from "src/interfaces/User";
import { ActionsType, ColumnsType } from "src/interfaces/Common";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import useSWRMutation from "swr/mutation";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { formatDateWithMinutes } from "@utils/utilsDate";
import ButtonBackPage from "@components/ButtonBackPage";
import {
  EnrollmentAssociationUserRequest,
  EnrollmentEditionUserPatchRequest,
} from "src/interfaces/Enrollment";
import { LOType } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { SingleCheckboxCst } from "@components/InputCst";
import { getJSXForModalCustomEnrollments } from "@utils/utilsModal";
import AnimationFadeIn from "@components/AnimationFadeIn";
import ButtonCst from "@components/ButtonCst";
import { useTheme } from "@mui/material";
import CustomRowAction from "@components/Table/components/CustomRowAction";

const AssociationEditionUser: React.FC<{ type: LOType; isToj?: boolean }> = ({
  type,
  isToj = false,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message?: string;
    customContent?: JSX.Element;
  }>();
  const { t } = useTranslation(["users-page", "modal"]);
  const { id, idLO, idEdition, idEditionLOActivity } = useParams();
  // const [openSubscribeUser, setOpenSubscribeUser] = useState<any>(false);
  // const [registrationMandatory, setRegistrationMandatory] =
  //   useState<any>(false);

  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
  } = useFetchTable<User[]>(
    `${ServicesURL.getEnrollment}?corporateId=${
      id || "1"
    }&objectId=${idEditionLOActivity}&enrollType=TOP,TOP_MANDATORY&editionToEnroll=${idEdition}${
      isToj ? "&isToj=true" : ""
    }`,
    PagesID["enrollment.edition.user"]
  );

  //CORPORATE DATA
  const { dataDetail } = useFetchDetail(
    ServicesURL.corporate,
    id || "1",
    PagesID.corporate
  );

  const [userAlreadySelected, setUserAlreadySelected] = useState<number[]>([]);
  const [selected, setSelected] = useState<any>({});
  const [openBatchStatus, setOpenBatchStatus] = useState(false);
  const [batchStatusMessage, setBatchStatusMessage] = useState("");
  const location = useLocation();

  const { trigger: triggerPatchAssociation } = useSWRMutation(
    `${ServicesURL.patchEnrollment}`,
    (url: string, { arg }: { arg: any[] }) =>
      fetcher(url, MethodType.PATCH, arg)
  );

  // const handleCloseToastMessage = () => {
  //   setOpenToast(undefined);
  // };

  // const getObjectId = () => {
  //   // If it's an Edition objectId is the learning_objects id of edition
  //   // If it's not an Edition take idLO passed in URL path
  //   if (idEditionLOActivity) {
  //     return +idEditionLOActivity;
  //   } else {
  //     return (idLO && +idLO) || undefined;
  //   }
  // };

  const handleSubscribe = React.useCallback(async () => {
    let listOfUser = Object.keys(selected)
      .map((key: string) => {
        if (key.includes("||")) {
          return +key.split("||")[0];
        }
      })
      .filter((user: any) => {
        return !userAlreadySelected.includes(user);
      });
    try {
      setOpenToast({ s: StatusEnum.LOADING });
      let responsePatch = null;
      responsePatch = await triggerPatchAssociation([
        {
          corporateId: (id && +id) || undefined,
          editionId: (idEdition && +idEdition) || undefined,
          objectId: idEditionLOActivity && +idEditionLOActivity,
          isToj: isToj || false,
          userId: listOfUser,
          mandatory: null,
        },
      ]);

      setOpenToast({
        s: StatusEnum.OK,
        message: "",
      });
    } catch (e) {
      setOpenToast(undefined);
      console.error(e);
    }
  }, [id, idEdition, idLO, type, selected]);

  const getEnrollmentResponse = () => {
    let response = dataTable?.enrollmentResponses || dataTable?.output || [];
    const newList = response.map((item: any) => {
      if (
        !!item.learningObject.blendedParentId &&
        item.learningObject.blendedParentId !== Number(idLO)
      ) {
        return {
          ...item,
          blendedParentId: item.learningObject.blendedParentId,
        };
      } else {
        return item;
      }
    });

    return newList;
  };

  useEffect(() => {
    let alreadySelectedElements = { ...selected };
    let response = dataTable?.enrollmentResponses || dataTable?.output || [];
    response.forEach((item: any) => {
      if (
        item.alreadyEnrolled ||
        (!item.alreadyEnrolled && item?.batchStatus === "IN_PROGRESS")
      ) {
        alreadySelectedElements = {
          ...alreadySelectedElements,
          [getKeysIdTitle(item)]: true,
        };
      }
    });
    setSelected(alreadySelectedElements);
  }, [dataTable]);

  function getKeysIdTitle(originalRow: any) {
    return `${originalRow?.userId}||${originalRow?.enrollId}`;
  }

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.key) {
        case "decompression":
          if (action.icon === "success") {
            return action;
          } else {
            return {
              ...action,
              callBack: (row: any) => {
                getSingleEnroll(row.enrollId);
              },
            };
          }
        default:
          return action;
      }
    });
  }, [actionForRow]);

  //FUNZIONE NECESSARIA PER APPORTARE MODIFICHE AI CAMPI DELLE AZIONI DI RIGA MA IN BASE AL VALORE DEI CAMPI (ROW)
  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];

    configRowNew = configRowNew.map((actionRow: any) => {
      if (actionRow.key === "decompression") {
        switch (row?.original?.batchStatus) {
          case "COMPLETE":
            return {
              ...actionRow,
              icon:
                row?.original?.editionId.toString() === idEdition
                  ? "success"
                  : row?.original?.editionId.toString() !== idEdition
                  ? "error"
                  : "",
            };
          case "ERROR":
            return { ...actionRow, icon: "error" };
          case "IN_PROGRESS":
            return { ...actionRow, icon: "warning" };
          default:
            if (!!row?.original?.editionId) {
              return { ...actionRow, icon: "error" };
            }
            return { ...actionRow, icon: "" };
        }
      } else {
        return actionRow;
      }
    });

    if (!row?.original?.batchStatus) {
      return [
        <CustomRowAction
          key={row.id}
          row={row}
          configurationActionForRow={[
            ...configRowNew.filter(
              (el: any) =>
                ["decompression"].findIndex((type) => type === el.key) < 0
            ),
          ]}
        />,
      ];
    }

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const getSingleEnroll = (enrollId: number) => {
    let response = dataTable?.enrollmentResponses || dataTable?.output || [];
    const enroll = response.filter(
      (item: any) => item.enrollId === enrollId
    )[0];
    const batchStatus = enroll?.batchStatus;
    switch (batchStatus) {
      case "ERROR":
        setBatchStatusMessage(enroll?.errorMessage);
        break;

      case "IN_PROGRESS":
        setBatchStatusMessage(
          `${t("association-user.batch-status.pending-message")}`
        );
        break;

      case "COMPLETE":
        if (enroll?.editionId.toString() !== idEdition) {
          setBatchStatusMessage(
            `${t("association-user.batch-status.already-enrolled").replace(
              ":idEdition",
              enroll?.editionId.toString()
            )}`
          );
        } else {
          setBatchStatusMessage(
            `${t("association-user.batch-status.confirmed-message")}`
          );
        }
        break;

      default:
        break;
    }
    setOpenBatchStatus(true);
  };

  const handleChangeSelected = (userSelected: any) => {
    // console.log("userSelected :>> ", userSelected);
    //FUNCTION USED TO AVOID BUG ON TABLE LIBRARY TO REMOVE CHECKBOX FOR ELEMENTS DISABLED
    let response = dataTable?.enrollmentResponses || dataTable?.output || [];
    let listOfUserInteractable = response
      .filter((row: any) => {
        return !(
          !!row?.editionId ||
          (row?.alreadyEnrolled && row?.batchStatus === "IN_PROGRESS")
        );
      })
      .map((user: any) => user.userId);

    let listOfUserNOTInteractable = response
      .filter((row: any) => {
        return (
          !!row?.editionId ||
          (row?.alreadyEnrolled && row?.batchStatus === "IN_PROGRESS")
        );
      })
      .map((user: any) => user.userId);

    let listOfUserJustSelected = Object.keys(userSelected).map(
      (key: string) => {
        if (key.includes("||")) {
          return +key.split("||")[0];
        }
      }
    );

    console.log("listOfUserJustSelected :>> ", listOfUserJustSelected);

    let newSelected: any = { ...selected };
    listOfUserInteractable.forEach((interactable: any) => {
      if (listOfUserJustSelected.includes(interactable)) {
        let userToAdd: any = Object.keys(userSelected).find((user: any) =>
          user.includes(interactable)
        );
        newSelected = { ...newSelected, [userToAdd]: true };
      } else {
        let userToDelete: any = Object.keys(newSelected).find((user: any) =>
          user.includes(interactable)
        );

        if (!!newSelected[userToDelete]) {
          delete newSelected[userToDelete];
        }
      }
    });

    // console.log(
    //   "newSelectedFINE :>> ",
    //   newSelected,
    //   Object.keys(newSelected).length,
    // );
    setSelected(newSelected);
    setUserAlreadySelected([
      ...userAlreadySelected,
      ...listOfUserNOTInteractable,
    ]);
    console.log("//////////////////////////////////////////////////////////");
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-courses.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${
            location.state?.row?.title || dataDetail?.business_name
          } - ${t("association-user.hero-title")}`}
          description={t("association-user.hero-description")}
        />
        <Box
          sx={{
            display: "flex",
            gap: "24px",
            flexDirection: "column",
            [theme.breakpoints.up("sm")]: {
              width: "100%",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            },
            [theme.breakpoints.up("md")]: {
              width: "auto",
            },
          }}
        >
          <ButtonBackPage customRedirect={-1} />
          <ButtonCst
            id={"save-button"}
            variant={"contained"}
            size={"medium"}
            sx={{}}
            onClick={() => {
              handleSubscribe();
            }}
          >
            {t("saveButton", { ns: "form" })}
          </ButtonCst>
        </Box>
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <Table
            id=""
            data={getEnrollmentResponse() || []}
            columns={columns || []}
            onChangeFilterForm={handleFetchData}
            configurationTopToolbar={actionsTopToolbar}
            // configurationActionForRow={actionForRow}
            renderRowActions={customRenderRowActions}
            totalPages={dataTable?.metadata?.totalElements}
            enableMultiRowSelection={true}
            enableRowSelection={(row: any) =>
              !(
                // row?.original?.alreadyEnrolled ||
                // (row?.original?.alreadyEnrolled &&
                (
                  row?.original?.batchStatus === "IN_PROGRESS" ||
                  !!row?.original?.editionId
                )
              )
            }
            enableRowActions={true}
            selectedRows={selected}
            onRowSelectionChange={(item: any) => {
              handleChangeSelected(item);
            }}
            getRowId={(originalRow: any) => {
              return getKeysIdTitle(originalRow);
            }}
            numberOfActions={1}
            enableSelectAll={true}
            renamedActionsColumn="Status"
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openBatchStatus}
        title={t("association-user.batch-status.modal-title")}
        description={batchStatusMessage || ""}
        removeButtons={true}
        onCallBackClosed={() => {
          setOpenBatchStatus(false);
        }}
        onActionConfirmed={() => {}}
      />
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AssociationEditionUser;
