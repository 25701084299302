import React, { useState } from "react";
import { Box, Checkbox, Container, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate, useParams } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { MethodType } from "../../../../../../../services/type";
import { fetcher } from "../../../../../../../services/config";
import { useFetchTable } from "../../../../../../../hooks/useFetchDataTable";
import { ServicesURL } from "../../../../../../../utils/utilsApi";
import { StatusEnum } from "../../../../../../../components/ModalConfirmsCst/types";
import ButtonCst from "../../../../../../../components/ButtonCst";
import ModalConfirmsCst from "../../../../../../../components/ModalConfirmsCst";
import CustomRowAction from "../../../../../../../components/Table/components/CustomRowAction";
import ButtonBackPage from "../../../../../../../components/ButtonBackPage";
import { TableTabs } from "../../../../../../../components/TableTabs/Index";
import ModalCst from "../../../../../../../components/ModalCst";

const VisualizeAndConfirmDetailsLearner: React.FC = () => {
  const { t } = useTranslation(["training-needs-details-user"]);
  const { t: tModal } = useTranslation(["modal"]);
  const [checkBoxData, setCheckBoxData] = useState<any>([]);
  const [checkBoxDataDelete, setCheckBoxDataDelete] = useState<any>([]);
  const [goToFinalTab, setGoToFinalTab] = useState<any>(false);
  const [confirmModal, setConfirmModal] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModalStayActive, setOpenConfirmModalStayActive] =
    useState(false);

  const { id, idCampaign, idDetail } = useParams();
  useResetFilters();

  const navigate = useNavigate();

  const detailCorporate = useFetchTable(
    `${ServicesURL.digitedCampaign}?corporate_id=${id}&id_campaign=${idCampaign}&campaign_type=UOG`,
    PagesID["corporate.campaign"]
  );

  const rowData =
    detailCorporate &&
    detailCorporate.dataTable &&
    detailCorporate.dataTable.output &&
    detailCorporate.dataTable.output.find(
      (el: any) => el.campaign_id == idCampaign
    );

  const isCompletedState = rowData && rowData.hr_state == "COMPLETED";
  const isCompletationFlux = window.location.href.includes(
    "completation-campaign"
  );

  const detailCampaignKnowledge = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `KNOWLEDGE`) +
      `&campaign_user_id=${idDetail}`,
    PagesID["corporate.campaign.visualize.confirm.learner"]
  );
  const detailCampaignCapacity = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `CAPACITY`) + `&campaign_user_id=${idDetail}`,
    PagesID["corporate.campaign.visualize.confirm.learner.capacity"]
  );

  const typeKnowledge =
    selectedTab == 0 ? detailCampaignKnowledge : detailCampaignCapacity;

  const detailConfirmCampaignKnowledge = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `KNOWLEDGE`) +
      `&campaign_user_id=${idDetail}`,
    PagesID["corporate.campaign.visualize.confirm.learner"]
  );
  const detailConfirmCampaignCapacity = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `CAPACITY`) + `&campaign_user_id=${idDetail}`,
    PagesID["corporate.campaign.visualize.confirm.learner.capacity"]
  );

  const typeKnowledgeConfirmed =
    selectedTab == 0
      ? detailConfirmCampaignKnowledge
      : detailConfirmCampaignCapacity;

  const { trigger: triggerDeleteKnowledgeUser } = useSWRMutation(
    `${ServicesURL.digitedDetailCampaignLearnerDeleteKnowledge}`,
    (url: string, { arg }: { arg: { id: any } }) => {
      return fetcher<any>(
        `${url
          .replace("{campaignId}", `${idCampaign}`)
          .replace("{campaignDetailId}", `${arg.id}`)
          .replace("{campaignUserId}", `${idDetail}`)}`,
        MethodType.DELETE
      );
    }
  );

  const { trigger: triggerConfirmGap } = useSWRMutation(
    `${ServicesURL.digitedDeleteDetailCampaign}`,
    (url: string, { arg }) => {
      return fetcher<any>(
        `${url.replace("{campaignId}", `${idCampaign}`)}`,
        MethodType.PUT,
        arg
      );
    }
  );

  const handleConfirmGap = async (row: any, complete: string) => {
    setConfirmModal({ s: StatusEnum.LOADING, message: "loading" });

    const listIdGap = checkBoxData.map((el: any) => el.id);

    const payloadConfirmGap = {
      is_closed: complete == "complete" ? true : false,
      campaign_user: {
        user_id: idDetail,
        detail_ids: listIdGap,
      },
    };

    try {
      //@ts-ignore
      await triggerConfirmGap(payloadConfirmGap);

      setConfirmModal({
        s: StatusEnum.OK,
        message: "",
      });
      setOpenConfirmModalStayActive(false);
      if (complete == "complete") navigate(-2);
      else navigate(-1);
    } catch (error) {
      setConfirmModal(undefined);
      setOpenConfirmModalStayActive(false);
      console.error(error);
    }
  };

  const isLearnerFlux = window.location.href.includes("learner-confirm");
  const isLearnerCompletationFlux = window.location.href.includes(
    "visualize-and-confirm-learner"
  );

  const handleCheckBox = (row: any, type: string) => {
    let dataBox = type === "checkbox" ? checkBoxData : checkBoxDataDelete;
    let setData = type === "checkbox" ? setCheckBoxData : setCheckBoxDataDelete;
    if (dataBox && dataBox.some((el: any) => el.id === row.id)) {
      const removeData = dataBox.filter((el: any) => el.id !== row.id);
      setData(removeData);
    } else {
      setData([...dataBox, row]);
    }
  };

  const handleAllCheckBox = (data: any, type: string) => {
    let dataBox = type === "checkbox" ? checkBoxData : checkBoxDataDelete;
    let setData = type === "checkbox" ? setCheckBoxData : setCheckBoxDataDelete;

    const dataBoxFiltered = dataBox.filter(
      (el: any) => el.status != "COMPLETED"
    );

    const dataFiltered = data.dataTable.output.filter(
      (el: any) => el.status != "COMPLETED"
    );

    const differentDataBox =
      type === "checkbox" ? checkBoxDataDelete : checkBoxData;
    const updateData = dataFiltered.filter(
      (el: any) => !differentDataBox.some((user: any) => user.id === el.id)
    );

    if (dataBox && dataFiltered && dataBoxFiltered.length < updateData.length) {
      return setData(updateData);
    }

    return setData([]);
  };

  const configurationActionForRow = React.useMemo(() => {
    return (row: any) => {
      return typeKnowledge.actionForRow
        ?.filter((action: any) => {
          if (action.type === "visibility") {
            return !(isLearnerFlux || isLearnerCompletationFlux);
          }
          return true;
        })
        .map((action: any) => {
          if (action.type === "visibility") {
            return {
              ...action,
              useId: row.original.id,
            };
          }
          if (action.type === "checkbox") {
            const isExist = checkBoxData.some(
              (el: any) => el.id == row.original.id
            );
            const isExistDelete = checkBoxDataDelete.some(
              (el: any) => el.id == row.original.id
            );

            return {
              ...action,
              disabled: isExist ? "confirmed" : isExistDelete ? "delete" : null,
              style: "only",
            };
          }
          return action;
        });
    };
  }, [typeKnowledge.actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew = configurationActionForRow(row) && [
      ...configurationActionForRow(row),
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
        setCheckBoxData={handleCheckBox}
        checkBoxData={[checkBoxData, checkBoxDataDelete]}
      />,
    ];
  };

  const tabs = [
    {
      [t("knowledge")]: typeKnowledge && (
        <Table
          id={PagesID["corporate.campaign.visualize.confirm.learner"]}
          data={typeKnowledge.dataTable?.output}
          columns={typeKnowledge.columns || []}
          onChangeFilterForm={typeKnowledge.handleFetchData}
          renderRowActions={customRenderRowActions}
          configurationTopToolbar={typeKnowledge.actionsTopToolbar}
          totalPages={typeKnowledge.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          renderActionIcon={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "3px",
                padding: "0 20px",
              }}
            >
              <FormControlLabel
                control={
                  <CheckBoxIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label={t("all")}
              />
              <FormControlLabel
                control={
                  <DeleteIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label={t("all")}
              />
            </div>
          }
          renderActionIconBottomToolbar={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                padding: "0 6px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxData.length}
                    checkedIcon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() =>
                      handleAllCheckBox(typeKnowledge, "checkbox")
                    }
                  />
                }
                label=""
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxDataDelete.length}
                    checkedIcon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox(typeKnowledge, "delete")}
                  />
                }
                label=""
              />
            </div>
          }
          renderActionUnderBottomToolbar={
            <ButtonCst
              onClick={() => setGoToFinalTab(true)}
              sx={{ cursor: "pointer" }}
              disabled={!checkBoxData.length}
              variant="contained"
              id={"continueButton"}
            >
              {tModal("continue")}
            </ButtonCst>
          }
        />
      ),
    },
    {
      [t("capacity")]: typeKnowledge && (
        <Table
          id={PagesID["corporate.campaign.visualize.confirm.learner.capacity"]}
          data={typeKnowledge.dataTable?.output}
          columns={typeKnowledge.columns || []}
          onChangeFilterForm={typeKnowledge.handleFetchData}
          renderRowActions={customRenderRowActions}
          configurationTopToolbar={typeKnowledge.actionsTopToolbar}
          totalPages={typeKnowledge.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          renderActionIcon={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "3px",
                padding: "0 20px",
              }}
            >
              <FormControlLabel
                control={
                  <CheckBoxIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label={t("all")}
              />
              <FormControlLabel
                control={
                  <DeleteIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label={t("all")}
              />
            </div>
          }
          renderActionIconBottomToolbar={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                padding: "0 6px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxData.length}
                    checkedIcon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() =>
                      handleAllCheckBox(typeKnowledge, "checkbox")
                    }
                  />
                }
                label=""
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxDataDelete.length}
                    checkedIcon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox(typeKnowledge, "delete")}
                  />
                }
                label=""
              />
            </div>
          }
          renderActionUnderBottomToolbar={
            <ButtonCst
              onClick={() => setOpenConfirmModal(true)}
              sx={{ cursor: "pointer" }}
              disabled={!checkBoxData.length}
              variant="contained"
              id={"buttonConfirm"}
            >
              {tModal("confirm")}
            </ButtonCst>
          }
        />
      ),
    },
  ];
  const tabsConfirmed = [
    {
      [t("knowledge")]: typeKnowledgeConfirmed && (
        <Table
          enableRowActions={false}
          id={PagesID["corporate.campaign.visualize.confirm.learner"]}
          data={typeKnowledgeConfirmed.dataTable}
          columns={typeKnowledgeConfirmed.columns || []}
          onChangeFilterForm={typeKnowledgeConfirmed.handleFetchData}
          configurationTopToolbar={typeKnowledgeConfirmed.actionsTopToolbar}
          totalPages={
            typeKnowledgeConfirmed.dataTable?.metadata?.total_elements
          }
          modalTitle={t("hero-title")?.replace("-", "") || ""}
        />
      ),
    },
    {
      [t("capacity")]: typeKnowledgeConfirmed && (
        <Table
          enableRowActions={false}
          id={PagesID["corporate.campaign.visualize.confirm.learner.capacity"]}
          data={typeKnowledgeConfirmed.dataTable}
          columns={typeKnowledgeConfirmed.columns || []}
          onChangeFilterForm={typeKnowledgeConfirmed.handleFetchData}
          configurationTopToolbar={typeKnowledgeConfirmed.actionsTopToolbar}
          totalPages={
            typeKnowledgeConfirmed.dataTable?.metadata?.total_elements
          }
          modalTitle={t("hero-title")?.replace("-", "") || ""}
        />
      ),
    },
  ];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage goBack={2} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <TableTabs
            tabs={isCompletedState ? tabsConfirmed : tabs}
            setExternalSelectedTab={setSelectedTab}
            goToFinalTab={goToFinalTab}
            setGoToFinalTab={setGoToFinalTab}
          />
        </Box>
        <ModalConfirmsCst
          open={openConfirmModal}
          title={`${tModal("confirm-gap")}`}
          description={`${tModal("want-confirm-gap")}`}
          routeToSamePage={true}
          onCallBackClosed={() => {
            // navigate(-3);
            setConfirmModal(undefined);
            setOpenConfirmModal(false);
          }}
          onActionConfirmed={async () => {
            if (isCompletationFlux)
              await handleConfirmGap(rowData, "stayActive");
            else setOpenConfirmModalStayActive(true);
          }}
          status={confirmModal}
        />
        <ModalCst
          open={openConfirmModalStayActive}
          setOpen={setOpenConfirmModalStayActive}
          //ANCHOR - traduzioni
          submitText="Conferma chiusura campagna"
          cancelText="Mantieni Attiva la campagna"
          title={`Chiusura Campagna`}
          description={`Hai confermato tutti i gap formativi e possiamo chiudere la campagna?`}
          typeOfModal={"confirm"}
          messageToast="Campagna Chiusa con successo"
          typeToast="success"
          data={{
            onClose: async () => {
              await handleConfirmGap(rowData, "stayActive");
            },
            callBackSubmit: async () => {
              await handleConfirmGap(rowData, "complete");
            },
          }}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default VisualizeAndConfirmDetailsLearner;
