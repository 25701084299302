import React from "react";
import { Box, Container } from "@mui/material";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { PagesID } from "@utils/utilsConfigurations";
import ActivityFormCst from "@pages/Contents/Corporate/Catalog/Activities/ActivityFormCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useAddActivity } from "@hooks/hookscatalog/Activity/useAddActivity";

const AddActivitiesDigited: React.FC = () => {
  const { t } = useTranslation(["activities-digited-page"]);
  const navigate = useNavigate();

  const { onSubmit, openToast, setOpenToast, columns } = useAddActivity(
    PagesID["catalog.activities"],
    "1"
  );

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-activities.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <ActivityFormCst
            row={{ self_enrollment: "false" }}
            columns={columns}
            onSubmit={onSubmit}
            backButton={() => {
              navigate(`/catalog/activities`);
            }}
          />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AddActivitiesDigited;
