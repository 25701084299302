// Import
import { useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  InputCst,
  SelectCst,
  AutocompleteCst,
  MultiSelectCst,
  RadioCst,
  DataPickerCst,
  InputAssociationChipsCst,
  SingleCheckboxCst,
  MultiTextCst,
} from "@components/InputCst";
import { ColumnInterface } from "@components/Table/types";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImageCst from "@components/BackgroundImageCst";
import { defaultValidation, isTrueValue } from "@utils/utilsValidators";
import ButtonCst from "@components/ButtonCst";
import InputChipsCst from "@components/InputCst/InpuctChipsCst";
import { useNavigate, useParams } from "react-router-dom";
import AssociationLibraryCst from "@components/AssociationLibraryCst";
import { LearningResourceType, MediaType } from "src/interfaces/Media";
import dayjs from "dayjs";
import AnimationFadeIn from "@components/AnimationFadeIn";
import AssociationTableCst from "@components/AssociationTableCst";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { scrollToElement } from "@utils/utilsScrollToElement";
import DownloaderUploaderCst from "@components/DownloaderUploaderCst";
import BackButtonWarning from "@components/BackButtonWarning";

// Interface
interface ActivityFormCstInterface {
  row?: any;
  columns: ColumnInterface[];
  onSubmit: (inputForm: any, fileUpload?: any) => void;
  backButton?: () => any;
  edit?: boolean;
}

// eslint-disable-next-line no-empty-pattern
const ActivityFormCst: React.FC<ActivityFormCstInterface> = ({
  row,
  columns,
  onSubmit,
  backButton,
  edit = false,
}) => {
  const { id = "1" } = useParams();
  const theme = useTheme();
  const { t, i18n } = useTranslation(["form"]);
  const navigate = useNavigate();
  const [inputForm, setInputForm] = React.useState<any>({});
  const [errorInputForm, setErrorInputForm] = React.useState<any>({});
  const [fileUploaded, setFileUploaded] = useState<any>(null);

  //ASSOCIATION'S STATES
  const [openAssociationScorm, setOpenAssociationScorm] = useState(false);
  const [openAssociationXapi, setOpenAssociationXapi] = useState(false);
  const [openAssociationCover, setOpenAssociationCover] = useState(false);
  const [openAssociationVideo, setOpenAssociationVideo] = useState(false);
  const [openAssociationMaterials, setOpenAssociationMaterials] =
    useState(false);
  const [openAssociationBadge, setOpenAssociationBadge] = useState(false);
  const [openAssociationCertificate, setOpenAssociationCertificate] =
    useState(false);
  const [openAssociationKnowledgeDomain, setOpenAssociationKnowledgeDomain] =
    useState(false);
  const [openAssociationSurvey, setOpenAssociationSurvey] = useState(false);

  const [scormAssociation, setScormAssociation] = useState([]);
  const [xapiAssociation, setXapiAssociation] = useState([]);
  const [coverAssociation, setCoverAssociation] = useState([]);
  const [videoAssociation, setVideoAssociation] = useState([]);
  const [materialsAssociation, setMaterialsAssociation] = useState([]);
  const [badgeAssociation, setBadgesAssociation] = useState<any>([]);
  const [certificatesAssociation, setCertificatesAssociation] = useState<any>(
    []
  );
  const [knowledgeDomainAssociation, setKnowledgeDomainAssociation] =
    useState<any>([]);
  const [surveyAssociation, setSurveyAssociation] = useState<any>([]);
  const [isBlocked, setIsBlocked] = useState(false);

  const getInitialData = () => {
    console.log("dajjeejejej");

    let initialState = {};
    const activityStandaloneColumns =
      (columns.find((col) => col.accessorKey === "is_standalone") as any)
        .form_box || [];
    [...columns, ...activityStandaloneColumns]?.forEach(({ accessorKey }) => {
      switch (accessorKey) {
        case "cover_id":
          let formattedCoverValue = Array.isArray(row[accessorKey])
            ? row[accessorKey]
            : [row[accessorKey]];
          row[accessorKey] && setCoverAssociation(formattedCoverValue || []);
          break;
        case "video_id":
          let formattedVideoValue = Array.isArray(row[accessorKey])
            ? row[accessorKey]
            : [row[accessorKey]];
          row[accessorKey] && setVideoAssociation(formattedVideoValue || []);
          break;
        case "xapi_package_id":
          let formattedXapiValue = Array.isArray(row[accessorKey])
            ? row[accessorKey]
            : [row[accessorKey]];
          row[accessorKey] && setXapiAssociation(formattedXapiValue);
          break;
        case "scorm_package_id":
          let formattedScormValue = Array.isArray(row[accessorKey])
            ? row[accessorKey]
            : [row[accessorKey]];
          row[accessorKey] && setScormAssociation(formattedScormValue);
          break;
        case "didactical_material":
          row[accessorKey] && setMaterialsAssociation(row[accessorKey]);
          break;
        case "badge_id":
          row[accessorKey] && setBadgesAssociation([row[accessorKey]]);
          break;
        case "certificate_id":
          row[accessorKey] && setCertificatesAssociation([row[accessorKey]]);
          break;
        case "knowledge_domain":
          row[accessorKey] && setKnowledgeDomainAssociation([row[accessorKey]]);
          break;
        case "survey_id":
          row[accessorKey] && setSurveyAssociation([row[accessorKey]]);
          break;
      }

      if (accessorKey === "expiration_date_flag") {
        if (
          row["fixed_expiration"] === true &&
          row["expiration_date"] === 253402214400000 &&
          row["soft_expiring"] === true &&
          row["expected_compl_time"] === 1
        ) {
          initialState = {
            ...initialState,
            [accessorKey]: "false",
          };
        } else {
          initialState = {
            ...initialState,
            [accessorKey]: "true",
          };
        }
      } else if (accessorKey === "badge_id") {
        if (row[accessorKey]) {
          initialState = {
            ...initialState,
            [accessorKey]: row[accessorKey]?.id || "",
          };
        }
      } else if (accessorKey === "certificate_id") {
        if (row[accessorKey]) {
          initialState = {
            ...initialState,
            [accessorKey]: row[accessorKey]?.id || "",
          };
        }
      } else {
        if (accessorKey === "soft_expiring") {
          initialState = {
            ...initialState,
            [accessorKey]: !row[accessorKey],
          };
        } else {
          initialState = {
            ...initialState,
            [accessorKey]: row[accessorKey],
          };
        }
      }
    });
    return initialState;
  };

  const resetInputForm = () => {
    let initialState = {};

    if (row) {
      setInputForm(getInitialData);
    } else {
      setXapiAssociation([]);
      setScormAssociation([]);
      setCoverAssociation([]);
      setVideoAssociation([]);
      setMaterialsAssociation([]);
      setBadgesAssociation([]);
      setCertificatesAssociation([]);
      setKnowledgeDomainAssociation([]);
      setSurveyAssociation([]);
      setInputForm(initialState);
    }
    setIsBlocked(false);
  };

  useEffect(() => {
    if (
      row &&
      Object.keys(row).length > 0 &&
      columns.length > 0 &&
      Object.keys(inputForm).length === 0
    ) {
      const initialState = getInitialData();
      setInputForm(initialState);
    }
  }, [row, columns]);

  //TODO: refactor validation v.1
  const onConfirm = () => {
    const localInputForm = { ...inputForm };
    let activityStandaloneColumns =
      (columns.find((col) => col.accessorKey === "is_standalone") as any)
        ?.form_box || [];
    const cols = [...columns].filter((col: any) => {
      const activityType = localInputForm["type"];
      const onlyForType = col["onlyfor_type"];
      if (onlyForType) {
        if (!onlyForType.includes(activityType)) {
          return false;
        }
      }
      return true;
    });
    if (localInputForm["is_standalone"] === "true") {
      activityStandaloneColumns = activityStandaloneColumns.filter(
        (form_col: any) => {
          const activityType = localInputForm["type"];
          if (form_col.onlyfor_type) {
            if (!form_col.onlyfor_type.includes(activityType)) {
              return false;
            }
          } else {
            return true;
          }
        }
      );
      cols.push(...activityStandaloneColumns);
    }
    localInputForm["didactical_material"] = materialsAssociation;
    localInputForm["cover_id"] = coverAssociation[0];
    localInputForm["video_id"] = videoAssociation[0];
    localInputForm["scorm_package_id"] = scormAssociation[0];
    localInputForm["xapi_package_id"] = xapiAssociation[0];
    localInputForm["badge_id"] =
      badgeAssociation.length > 0 ? badgeAssociation[0].id : null;
    localInputForm["certificate_id"] =
      certificatesAssociation.length > 0 ? certificatesAssociation[0].id : null;
    localInputForm["knowledge_domain"] =
      knowledgeDomainAssociation.length > 0
        ? Number(knowledgeDomainAssociation[0].id)
        : null; //CHECK
    localInputForm["survey_id"] =
      surveyAssociation.length > 0 ? surveyAssociation[0].id : null;

    const { canBeSubmit, showError } = defaultValidation(cols, localInputForm);

    // Check date validation
    if (localInputForm["expiration_date"] && localInputForm["publish_date"]) {
      const expiration_date = dayjs(localInputForm["expiration_date"]);
      const publish_date = dayjs(localInputForm["publish_date"]);
      if (
        expiration_date.isBefore(publish_date) ||
        expiration_date.isSame(publish_date)
      ) {
        setErrorInputForm({
          ...showError,
          expiration_date: { msg: "invalid-expiration-date" },
        });
        return;
      }
    }
    if (inputForm["min_partecipant"]) {
      if (
        inputForm["max_partecipant"] &&
        Number(inputForm["max_partecipant"]) <
          Number(inputForm["min_partecipant"])
      ) {
        setErrorInputForm({
          ...showError,
          max_partecipant: { msg: "participants-number-condition" },
          min_partecipant: { msg: "participants-number-condition" },
        });
        return;
      }
    }

    setErrorInputForm(showError);

    // enrollment_auto è un campo non presente nel form e avrà il valore contrario rispetto a iscrizione Top Down,
    // !TODO CHECK
    // switch (inputForm.topdown_enrollment) {
    // 	case "true":
    // 		localInputForm.enrollment_auto = false;
    // 		break;
    // 	case "false":
    // 		localInputForm.enrollment_auto = true;
    // 		break;
    // }

    if (canBeSubmit) {
      setIsBlocked(false);
      onSubmit(localInputForm, fileUploaded);
    }
  };

  const getErrMsg = useCallback(
    (accessorKey: string): string => {
      return errorInputForm[accessorKey]?.msg || errorInputForm[accessorKey];
    },
    [errorInputForm]
  );

  const minLength = useCallback(
    (accessorKey: string): number => {
      return errorInputForm[accessorKey]?.value;
    },
    [errorInputForm]
  );

  const maxLength = useCallback(
    (accessorKey: string): number => {
      return errorInputForm[accessorKey]?.value;
    },
    [errorInputForm]
  );

  const openAssociation =
    openAssociationScorm ||
    openAssociationXapi ||
    openAssociationCover ||
    openAssociationVideo ||
    openAssociationMaterials ||
    openAssociationBadge ||
    openAssociationCertificate ||
    openAssociationKnowledgeDomain ||
    openAssociationSurvey;

  const setOpenAssociation = useCallback(
    (accessorKey: string, value: boolean) => {
      switch (accessorKey) {
        case "scorm_package_id":
          setOpenAssociationScorm(value);
          setOpenAssociationXapi(value ? !value : openAssociationXapi);
          setOpenAssociationCover(value ? !value : openAssociationCover);
          setOpenAssociationVideo(value ? !value : openAssociationVideo);
          setOpenAssociationMaterials(
            value ? !value : openAssociationMaterials
          );
          setOpenAssociationBadge(value ? !value : openAssociationBadge);
          setOpenAssociationCertificate(
            value ? !value : openAssociationCertificate
          );
          setOpenAssociationKnowledgeDomain(
            value ? !value : openAssociationKnowledgeDomain
          );
          setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
          break;
        case "xapi_package_id":
          setOpenAssociationXapi(value);
          setOpenAssociationScorm(value ? !value : openAssociationScorm);
          setOpenAssociationCover(value ? !value : openAssociationCover);
          setOpenAssociationVideo(value ? !value : openAssociationVideo);
          setOpenAssociationMaterials(
            value ? !value : openAssociationMaterials
          );
          setOpenAssociationBadge(value ? !value : openAssociationBadge);
          setOpenAssociationCertificate(
            value ? !value : openAssociationCertificate
          );
          setOpenAssociationKnowledgeDomain(
            value ? !value : openAssociationKnowledgeDomain
          );
          setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
          break;
        case "cover_id":
          setOpenAssociationCover(value);
          setOpenAssociationXapi(value ? !value : openAssociationXapi);
          setOpenAssociationScorm(value ? !value : openAssociationScorm);
          setOpenAssociationVideo(value ? !value : openAssociationVideo);
          setOpenAssociationMaterials(
            value ? !value : openAssociationMaterials
          );
          setOpenAssociationBadge(value ? !value : openAssociationBadge);
          setOpenAssociationCertificate(
            value ? !value : openAssociationCertificate
          );
          setOpenAssociationKnowledgeDomain(
            value ? !value : openAssociationKnowledgeDomain
          );
          setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
          break;
        case "video_id":
          setOpenAssociationVideo(value);
          setOpenAssociationXapi(value ? !value : openAssociationXapi);
          setOpenAssociationScorm(value ? !value : openAssociationScorm);
          setOpenAssociationCover(value ? !value : openAssociationCover);
          setOpenAssociationMaterials(
            value ? !value : openAssociationMaterials
          );
          setOpenAssociationBadge(value ? !value : openAssociationBadge);
          setOpenAssociationCertificate(
            value ? !value : openAssociationCertificate
          );
          setOpenAssociationKnowledgeDomain(
            value ? !value : openAssociationKnowledgeDomain
          );
          setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
          break;
        case "didactical_material":
          setOpenAssociationMaterials(value);
          setOpenAssociationScorm(value ? !value : openAssociationScorm);
          setOpenAssociationXapi(value ? !value : openAssociationXapi);
          setOpenAssociationCover(value ? !value : openAssociationCover);
          setOpenAssociationVideo(value ? !value : openAssociationVideo);
          setOpenAssociationBadge(value ? !value : openAssociationBadge);
          setOpenAssociationCertificate(
            value ? !value : openAssociationCertificate
          );
          setOpenAssociationKnowledgeDomain(
            value ? !value : openAssociationKnowledgeDomain
          );
          setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
          break;
        case "badge_id":
          setOpenAssociationBadge(value);
          setOpenAssociationCertificate(
            value ? !value : openAssociationCertificate
          );
          setOpenAssociationScorm(value ? !value : openAssociationScorm);
          setOpenAssociationXapi(value ? !value : openAssociationXapi);
          setOpenAssociationCover(value ? !value : openAssociationCover);
          setOpenAssociationVideo(value ? !value : openAssociationVideo);
          setOpenAssociationMaterials(
            value ? !value : openAssociationMaterials
          );
          setOpenAssociationKnowledgeDomain(
            value ? !value : openAssociationKnowledgeDomain
          );
          setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
          break;
        case "certificate_id":
          setOpenAssociationCertificate(value);
          setOpenAssociationBadge(value ? !value : openAssociationBadge);
          setOpenAssociationScorm(value ? !value : openAssociationScorm);
          setOpenAssociationXapi(value ? !value : openAssociationXapi);
          setOpenAssociationCover(value ? !value : openAssociationCover);
          setOpenAssociationVideo(value ? !value : openAssociationVideo);
          setOpenAssociationMaterials(
            value ? !value : openAssociationMaterials
          );
          setOpenAssociationKnowledgeDomain(
            value ? !value : openAssociationKnowledgeDomain
          );
          setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
          break;
        case "knowledge_domain":
          setOpenAssociationKnowledgeDomain(value);
          setOpenAssociationCertificate(
            value ? !value : openAssociationCertificate
          );
          setOpenAssociationBadge(value ? !value : openAssociationBadge);
          setOpenAssociationScorm(value ? !value : openAssociationScorm);
          setOpenAssociationXapi(value ? !value : openAssociationXapi);
          setOpenAssociationCover(value ? !value : openAssociationCover);
          setOpenAssociationVideo(value ? !value : openAssociationVideo);
          setOpenAssociationMaterials(
            value ? !value : openAssociationMaterials
          );
          setOpenAssociationSurvey(value ? !value : openAssociationSurvey);
          break;
        case "survey_id":
          setOpenAssociationSurvey(value);
          setOpenAssociationKnowledgeDomain(
            value ? !value : openAssociationCertificate
          );
          setOpenAssociationCertificate(
            value ? !value : openAssociationCertificate
          );
          setOpenAssociationBadge(value ? !value : openAssociationBadge);
          setOpenAssociationScorm(value ? !value : openAssociationScorm);
          setOpenAssociationXapi(value ? !value : openAssociationXapi);
          setOpenAssociationCover(value ? !value : openAssociationCover);
          setOpenAssociationVideo(value ? !value : openAssociationVideo);
          setOpenAssociationMaterials(
            value ? !value : openAssociationMaterials
          );
          break;
      }
    },
    [
      openAssociationCover,
      openAssociationMaterials,
      openAssociationScorm,
      openAssociationVideo,
      openAssociationXapi,
      openAssociationBadge,
      openAssociationCertificate,
      openAssociationKnowledgeDomain,
      openAssociationSurvey,
    ]
  );

  const handleDelete = useCallback(
    (accessorKey: string, e: { id: string }) => {
      switch (accessorKey) {
        case "scorm_package_id":
          setScormAssociation(
            scormAssociation.filter((element: any) => element.id !== e.id)
          );
          break;
        case "xapi_package_id":
          setXapiAssociation(
            xapiAssociation.filter((element: any) => element.id !== e.id)
          );
          break;
        case "cover_id":
          setCoverAssociation(
            coverAssociation.filter((element: any) => element.id !== e.id)
          );
          break;
        case "video_id":
          setVideoAssociation(
            videoAssociation.filter((element: any) => element.id !== e.id)
          );
          break;
        case "didactical_material":
          setMaterialsAssociation(
            materialsAssociation.filter((element: any) => element.id !== e.id)
          );
          break;
        case "badge_id":
          setBadgesAssociation(
            badgeAssociation.filter((element: any) => element.id !== e.id)
          );
          break;
        case "certificate_id":
          setCertificatesAssociation(
            certificatesAssociation.filter(
              (element: any) => element.id !== e.id
            )
          );
          break;
        case "knowledge_domain":
          setKnowledgeDomainAssociation([]);
          break;
        case "survey_id":
          setSurveyAssociation([]);
          break;
      }
    },
    [
      coverAssociation,
      materialsAssociation,
      scormAssociation,
      videoAssociation,
      xapiAssociation,
      badgeAssociation,
      certificatesAssociation,
      knowledgeDomainAssociation,
    ]
  );

  const getValues = useCallback(
    (accessorKey: string) => {
      switch (accessorKey) {
        case "scorm_package_id":
          return scormAssociation;
        case "xapi_package_id":
          return xapiAssociation;
        case "cover_id":
          return coverAssociation;
        case "video_id":
          return videoAssociation;
        case "didactical_material":
          return materialsAssociation;
        case "badge_id":
          return badgeAssociation;
        case "certificate_id":
          return certificatesAssociation;
        case "knowledge_domain":
          return knowledgeDomainAssociation;
        case "survey_id":
          return surveyAssociation;
      }
    },
    [
      coverAssociation,
      materialsAssociation,
      scormAssociation,
      videoAssociation,
      xapiAssociation,
      badgeAssociation,
      certificatesAssociation,
      knowledgeDomainAssociation,
      surveyAssociation,
    ]
  );

  const getInputField = useMemo(() => {
    const activityType = inputForm["type"];
    return columns.map((col: any) => {
      const onlyForType = col["onlyfor_type"];

      if (!activityType) {
        if (col.accessorKey !== "type") {
          col.disabled = true;
        }
      } else {
        col.disabled = false;
      }
      if (onlyForType && activityType) {
        if (!onlyForType.includes(activityType)) {
          return false;
        }
      }

      if (col.accessorKey === "is_standalone") {
        col.form_box.forEach((el: any, index: any) => {
          if (el.accessorKey === "expiration_date") {
            if (!isTrueValue(inputForm["fixed_expiration"])) {
              el.disabled = true;
              el.required = false;
            } else {
              el.disabled = false;
              el.required = true;
            }
          }
        });
      }

      // FIELDS TO DISABLE BASED ON TYPE
      if (activityType) {
        let ActivityToDisable: any = {
          da_physical_class: [
            "is_standalone",
            "publish_date",
            "min_partecipant",
            "soft_expiring",
            "fixed_expiration",
            "expiration_date",
            "expiration_date_flag",
          ],
          da_virtual_class: [
            "is_standalone",
            "publish_date",
            "min_partecipant",
            "soft_expiring",
            "fixed_expiration",
            "expiration_date",
            "expiration_date_flag",
          ],
          da_xapi: [
            "is_standalone",
            "publish_date",
            "xapi_package_id",
            "soft_expiring",
            "fixed_expiration",
            "expiration_date",
            "expiration_date_flag",
          ],
          da_scorm: [
            "is_standalone",
            "publish_date",
            "scorm_package_id",
            "soft_expiring",
            "fixed_expiration",
            "expiration_date",
            "expiration_date_flag",
          ],
          da_external_res: [
            "is_standalone",
            "publish_date",
            "soft_expiring",
            "fixed_expiration",
            "expiration_date",
            "expiration_date_flag",
          ],
          da_youtube: [
            "is_standalone",
            "publish_date",
            "soft_expiring",
            "fixed_expiration",
            "expiration_date",
            "expiration_date_flag",
          ],
          da_linkedin: [
            "is_standalone",
            "publish_date",
            "soft_expiring",
            "fixed_expiration",
            "expiration_date",
            "expiration_date_flag",
          ],
          da_survey: [
            "is_standalone",
            "publish_date",
            "soft_expiring",
            "fixed_expiration",
            "expiration_date",
            "expiration_date_flag",
          ],
          da_url_res: [
            "is_standalone",
            "publish_date",
            "soft_expiring",
            "fixed_expiration",
            "expiration_date",
            "expiration_date_flag",
          ],
          da_material: [],
          da_goals: [],
          da_meeting: [],
          da_final_balance: [],
        };
        let fieldToExclude: string[] = [];
        if (activityType === "DA_SURVEY") {
          fieldToExclude = [
            "complexity",
            "learning_purpose",
            "didactical_material",
            "certificate_id",
            "badge_id",
            "format",
          ];
        }
        if (
          [
            "DA_MATERIAL",
            "DA_GOALS",
            "DA_MEETING",
            "DA_FINAL_BALANCE",
          ].includes(activityType)
        ) {
          fieldToExclude = [
            "status",
            "complexity",
            "learning_purpose",
            "publish_date",
            "obsolescent_date",
            "soft_expiring",
            "highlight_for_you",
            "highlight_explore",
            "didactical_material",
            "certificate_id",
            "badge_id",
            "score",
            "format",
            "is_standalone",
            "knowledge_domain",
          ];
        }
        if (fieldToExclude.includes(col.accessorKey)) {
          col.required = false;
          return null;
        }

        if (
          row &&
          Object.keys(row).length > 0 &&
          columns?.length > 0 &&
          dayjs().isAfter(dayjs(row["publish_date"]))
        ) {
          if (
            ActivityToDisable[activityType.toLowerCase()].includes(
              col.accessorKey
            )
          ) {
            col.disabled = true;
          }
          if (col.accessorKey === "is_standalone") {
            col.form_box.forEach((el: any) => {
              if (
                ActivityToDisable[activityType.toLowerCase()].includes(
                  el.accessorKey
                )
              ) {
                el.disabled = true;
              }
            });
          }
        } else {
          if (
            ActivityToDisable[activityType.toLowerCase()].includes(
              col.accessorKey
            )
          ) {
            col.disabled = false;
          }
          if (col.accessorKey === "is_standalone") {
            col.form_box.forEach((el: any) => {
              if (
                ActivityToDisable[activityType.toLowerCase()].includes(
                  el.accessorKey
                )
              ) {
                el.disabled = false;
              }
            });
          }
        }
      }

      switch (col.type) {
        case "association": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <InputAssociationChipsCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={getValues(col.accessorKey)}
                required={col.required}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                textTooltip={col.tooltip}
                onDelete={(e: any) => handleDelete(col.accessorKey, e)}
                onRedirect={() => {
                  setIsBlocked(true);
                  setOpenAssociation(col.accessorKey, true);
                }}
                labelKey={"label"}
                disabled={col.disabled}
                noAssociationButton={col.disabled}
              />
            </Grid>
          );
        }
        case "select-checkbox": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <MultiSelectCst
                id={col.accessorKey}
                label={col.header}
                optionsKey={col.optionsKey}
                value={inputForm[col.accessorKey]}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: e },
                  });
                }}
                options={col.options}
              />
            </Grid>
          );
        }
        case "singleCheckbox": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <SingleCheckboxCst
                key={col.accessorKey}
                id={col.accessorKey}
                value={inputForm[col.accessorKey]}
                label={col.header}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: e.target.checked },
                  });
                }}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
              />
            </Grid>
          );
        }
        case "text": {
          return (
            <Grid key={col.header} item xs={12} sm={6}>
              <InputCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: e.target.value },
                  });
                }}
                maxValue={col?.rules?.max?.value}
                multiline={col.multiline}
                rows={col.rows}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
                textTooltip={col?.tooltip}
                tooltipFile={col?.tooltipFile}
                tooltipFileIta={col?.tooltipFileIta}
                tooltipFileEn={col?.tooltipFileEn}
                type={col.inpuType || "text"}
              />
            </Grid>
          );
        }
        case "chips": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <InputChipsCst
                label={col.header}
                id={col.accessorKey}
                value={inputForm[col.accessorKey] || []}
                required={col.required}
                disabled={col.disabled}
                onAdd={(list: any[]) => {
                  setIsBlocked(true);
                  setInputForm({
                    ...inputForm,
                    ...{ [col.accessorKey]: list },
                  });
                }}
              />
            </Grid>
          );
        }
        case "select": {
          const isDisabled =
            (inputForm[col.accessorKey] &&
              col.accessorKey === "type" &&
              edit) ||
            col.disabled;
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <SelectCst
                key={col.accessorKey}
                id={col.accessorKey}
                optionsKey={col.optionsKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                service={col.service}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col?.options}
                textTooltip={col.tooltip}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={isDisabled}
              />
            </Grid>
          );
        }
        case "autocomplete": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <AutocompleteCst
                key={col.accessorKey}
                id={col.accessorKey}
                optionsKey={col.optionsKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col?.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "multiCheckbox": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <MultiSelectCst
                key={col.accessorKey}
                id={col.accessorKey}
                optionsKey={col.optionsKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                textTooltip={col.tooltip}
                required={col.required}
                service={col.service}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col?.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "radio": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <RadioCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm({ ...inputForm, ...{ [col.accessorKey]: e } });
                }}
                options={col.options}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "dateTime": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <DataPickerCst
                key={col.accessorKey}
                id={col.accessorKey}
                label={col.header}
                value={inputForm[col.accessorKey]}
                defaultValue={col.defaultValue || null}
                required={col.required}
                onChange={(e: any) => {
                  setIsBlocked(true);
                  setInputForm((prev: any) => ({
                    ...prev,
                    ...{
                      [col.accessorKey]: dayjs(e)
                        .format("YYYY-MM-DD")
                        .valueOf(),
                    },
                  }));
                }}
                error={errorInputForm[col.accessorKey] ? true : false}
                errorMessage={
                  t(`error.field.${getErrMsg(col.accessorKey)}`, {
                    min: minLength(col.accessorKey),
                    max: maxLength(col.accessorKey),
                  }) || "Error"
                }
                disabled={col.disabled}
              />
            </Grid>
          );
        }
        case "multi-text": {
          return (
            <MultiTextCst
              label={col.header}
              fields={col.fields}
              value={inputForm[col.accessorKey]}
              disabled={col.disabled}
              onChange={(e: any) => {
                setIsBlocked(true);
                setInputForm({
                  ...inputForm,
                  ...{ [col.accessorKey]: e },
                });
              }}
            />
          );
        }
        case "upload": {
          return (
            <Grid key={col.accessorKey} item xs={12} sm={6}>
              <DownloaderUploaderCst
                name={col.header}
                accept={col?.accept}
                onChange={(e: any) => {
                  if (col?.getFormData) {
                    if (!e) return;
                    const formData = new FormData();
                    formData.append("file", e);
                    setFileUploaded({
                      ...fileUploaded,
                      [col.accessorKey]: {
                        file: formData,
                      },
                    });
                  } else {
                    getBase64(e, (result: any) => {
                      setFileUploaded({
                        ...fileUploaded,
                        [col.accessorKey]: {
                          file: e,
                          base64: result,
                        },
                      });
                    });
                  }
                }}
                onDownload={col.downloadCallback && col.downloadCallback}
                onTemplateDownload={
                  col.templateCallback && col.templateCallback
                }
                type={col.upload_type}
                onDelete={
                  col.deleteCallback &&
                  (() => {
                    setFileUploaded(null);
                    return col.deleteCallback && col.deleteCallback();
                  })
                }
                disabled={col.disabled}
                fileName={inputForm?.storyboard_id?.label}
              />
            </Grid>
          );
        }
      }
    });
  }, [
    inputForm,
    columns,
    getValues,
    errorInputForm,
    t,
    getErrMsg,
    minLength,
    maxLength,
    handleDelete,
    setOpenAssociation,
    edit,
    row,
  ]);
  const getBase64 = (file: any, callBack: any) => {
    let reader = new FileReader();
    if (!file) return;
    reader.readAsDataURL(file);
    reader.onload = function () {
      callBack(reader.result);
    };
    reader.onerror = function (error) {
      console.error("getBase64 error: ", error);
    };
  };
  const standaloneActivityFields = useMemo(() => {
    if (
      columns.find((col: any) => col.accessorKey === "is_standalone") as any
    ) {
      const fields =
        (columns.find((col: any) => col.accessorKey === "is_standalone") as any)
          ?.form_box || [];
      const activityType = inputForm["type"];
      let expirationToDisable = [
        "fixed_expiration",
        "expiration_date",
        "soft_expiring",
        "expected_compl_time",
      ];

      if (
        inputForm["is_standalone"] === "true" ||
        inputForm["is_standalone"] === true
      ) {
        return fields.map((col: any, index: any) => {
          if (
            expirationToDisable.includes(col.accessorKey) &&
            [undefined, "false"].includes(inputForm["expiration_date_flag"])
          ) {
            col.required = false;
            return;
          }

          const onlyForType = col["onlyfor_type"];

          if (onlyForType && activityType) {
            if (!onlyForType.includes(activityType)) {
              inputForm[col.accessorKey] = null;
              return;
            }
          }
          switch (col.type) {
            case "association": {
              return (
                <Grid key={col.accessorKey} item xs={12} sm={6}>
                  <InputAssociationChipsCst
                    key={col.accessorKey}
                    id={col.accessorKey}
                    label={col.header}
                    value={getValues(col.accessorKey)}
                    error={errorInputForm[col.accessorKey] ? true : false}
                    errorMessage={
                      t(`error.field.${getErrMsg(col.accessorKey)}`, {
                        min: minLength(col.accessorKey),
                        max: maxLength(col.accessorKey),
                      }) || "Error"
                    }
                    textTooltip={col.tooltip}
                    onDelete={(e: any) => handleDelete(col.accessorKey, e)}
                    onRedirect={() => setOpenAssociation(col.accessorKey, true)}
                    labelKey={"label"}
                    disabled={col.disabled}
                    required={col.required}
                  />
                </Grid>
              );
            }
            case "select-checkbox": {
              return (
                <Grid key={col.accessorKey} item xs={12} sm={6}>
                  <MultiSelectCst
                    id={col.accessorKey}
                    label={col.header}
                    optionsKey={col.optionsKey}
                    value={inputForm[col.accessorKey]}
                    onChange={(e: any) => {
                      setInputForm({
                        ...inputForm,
                        ...{ [col.accessorKey]: e },
                      });
                    }}
                    options={col.options}
                  />
                </Grid>
              );
            }
            case "singleCheckbox": {
              return (
                <Grid key={col.accessorKey} item xs={12} sm={6}>
                  <SingleCheckboxCst
                    key={col.accessorKey}
                    id={col.accessorKey}
                    value={inputForm[col.accessorKey]}
                    label={col.header}
                    onChange={(e: any) => {
                      setInputForm({
                        ...inputForm,
                        ...{ [col.accessorKey]: e.target.checked },
                      });
                    }}
                    error={errorInputForm[col.accessorKey] ? true : false}
                    errorMessage={
                      t(`error.field.${getErrMsg(col.accessorKey)}`, {
                        min: minLength(col.accessorKey),
                        max: maxLength(col.accessorKey),
                      }) || "Error"
                    }
                  />
                </Grid>
              );
            }
            case "text": {
              return (
                <Grid key={col.accessorKey} item xs={12} sm={6}>
                  <InputCst
                    key={col.accessorKey}
                    id={col.accessorKey}
                    label={col.header}
                    value={inputForm[col.accessorKey]}
                    required={col.required}
                    onChange={(e: any) => {
                      setInputForm({
                        ...inputForm,
                        ...{ [col.accessorKey]: e.target.value },
                      });
                    }}
                    maxValue={col?.rules?.max?.value}
                    multiline={col.multiline}
                    rows={col.rows}
                    error={errorInputForm[col.accessorKey] ? true : false}
                    errorMessage={
                      t(`error.field.${getErrMsg(col.accessorKey)}`, {
                        min: minLength(col.accessorKey),
                        max: maxLength(col.accessorKey),
                      }) || "Error"
                    }
                    disabled={col.disabled}
                    textTooltip={col.tooltip}
                    type={col.inpuType || "text"}
                  />
                </Grid>
              );
            }
            case "chips": {
              return (
                <Grid key={col.accessorKey} item xs={12} sm={6}>
                  <InputChipsCst
                    label={col.header}
                    id={col.accessorKey}
                    value={inputForm[col.accessorKey] || []}
                    required={col.required}
                    disabled={col.disabled}
                    onAdd={(list: any[]) => {
                      setInputForm({
                        ...inputForm,
                        ...{ [col.accessorKey]: list },
                      });
                    }}
                  />
                </Grid>
              );
            }
            case "select": {
              return (
                <Grid key={col.accessorKey} item xs={12} sm={6}>
                  <SelectCst
                    key={col.accessorKey}
                    id={col.accessorKey}
                    optionsKey={col.optionsKey}
                    label={col.header}
                    value={inputForm[col.accessorKey]}
                    service={col.service}
                    required={col.required}
                    onChange={(e: any) => {
                      setInputForm({
                        ...inputForm,
                        ...{ [col.accessorKey]: e },
                      });
                    }}
                    options={col?.options}
                    textTooltip={col.tooltip}
                    error={errorInputForm[col.accessorKey] ? true : false}
                    errorMessage={
                      t(`error.field.${getErrMsg(col.accessorKey)}`, {
                        min: minLength(col.accessorKey),
                        max: maxLength(col.accessorKey),
                      }) || "Error"
                    }
                    disabled={col.disabled}
                  />
                </Grid>
              );
            }
            case "autocomplete": {
              return (
                <Grid key={col.accessorKey} item xs={12} sm={6}>
                  <AutocompleteCst
                    key={col.accessorKey}
                    id={col.accessorKey}
                    optionsKey={col.optionsKey}
                    label={col.header}
                    value={inputForm[col.accessorKey]}
                    required={col.required}
                    onChange={(e: any) => {
                      setInputForm({
                        ...inputForm,
                        ...{ [col.accessorKey]: e },
                      });
                    }}
                    options={col?.options}
                    error={errorInputForm[col.accessorKey] ? true : false}
                    errorMessage={
                      t(`error.field.${getErrMsg(col.accessorKey)}`, {
                        min: minLength(col.accessorKey),
                        max: maxLength(col.accessorKey),
                      }) || "Error"
                    }
                    disabled={col.disabled}
                  />
                </Grid>
              );
            }
            case "multiCheckbox": {
              return (
                <Grid key={col.accessorKey} item xs={12} sm={6}>
                  <MultiSelectCst
                    key={col.accessorKey}
                    id={col.accessorKey}
                    optionsKey={col.optionsKey}
                    label={col.header}
                    value={inputForm[col.accessorKey]}
                    textTooltip={col.tooltip}
                    required={col.required}
                    service={col.service}
                    onChange={(e: any) => {
                      setInputForm({
                        ...inputForm,
                        ...{ [col.accessorKey]: e },
                      });
                    }}
                    options={col?.options}
                    error={errorInputForm[col.accessorKey] ? true : false}
                    errorMessage={
                      t(`error.field.${getErrMsg(col.accessorKey)}`, {
                        min: minLength(col.accessorKey),
                        max: maxLength(col.accessorKey),
                      }) || "Error"
                    }
                    disabled={col.disabled}
                  />
                </Grid>
              );
            }
            case "radio": {
              return (
                <Grid key={col.accessorKey} item xs={12} sm={6}>
                  <RadioCst
                    key={col.accessorKey}
                    id={col.accessorKey}
                    label={col.header}
                    value={inputForm[col.accessorKey]}
                    onChange={(e: any) => {
                      setInputForm({
                        ...inputForm,
                        ...{ [col.accessorKey]: e },
                      });
                    }}
                    options={col.options}
                    error={errorInputForm[col.accessorKey] ? true : false}
                    errorMessage={
                      t(`error.field.${getErrMsg(col.accessorKey)}`, {
                        min: minLength(col.accessorKey),
                        max: maxLength(col.accessorKey),
                      }) || "Error"
                    }
                    disabled={col.disabled}
                  />
                </Grid>
              );
            }
            case "dateTime": {
              return (
                <Grid key={col.accessorKey} item xs={12} sm={6}>
                  <DataPickerCst
                    key={col.accessorKey}
                    id={col.accessorKey}
                    label={col.header}
                    value={inputForm[col.accessorKey]}
                    defaultValue={col.defaultValue || null}
                    required={col.required}
                    onChange={(e: any) => {
                      setInputForm((prev: any) => ({
                        ...prev,
                        ...{
                          [col.accessorKey]: dayjs(e)
                            .format("YYYY-MM-DD")
                            .valueOf(),
                        },
                      }));
                    }}
                    error={errorInputForm[col.accessorKey] ? true : false}
                    errorMessage={
                      t(`error.field.${getErrMsg(col.accessorKey)}`, {
                        min: minLength(col.accessorKey),
                        max: maxLength(col.accessorKey),
                      }) || "Error"
                    }
                    disabled={col.disabled}
                  />
                </Grid>
              );
            }
          }
        });
      } else {
        return <></>;
      }
    }
  }, [
    columns,
    inputForm,
    inputForm.type,
    getValues,
    errorInputForm,
    t,
    getErrMsg,
    minLength,
    maxLength,
    handleDelete,
    setOpenAssociation,
  ]);

  return (
    <AnimationFadeIn>
      {/* <BackButtonWarning isBlocked={isBlocked} /> */}
      {!openAssociation && (
        <Grid container>
          <Grid item xs={0} lg={4} xl={3}>
            <BackgroundImageCst
              image="section-internal-form.jpg"
              disableGradient
            />
          </Grid>
          <Grid item xs={12} lg={8} xl={9}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                padding: "32px",
                minHeight: "495px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Grid container spacing={3}>
                {getInputField}
                {standaloneActivityFields}
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: "32px",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "space-between",
                    [theme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <ButtonCst
                    id={"add-button1"}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      mr: "auto",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={() => {
                      if (backButton) {
                        backButton();
                      } else {
                        navigate(-1);
                      }
                    }}
                  >
                    {t("backButton")}
                  </ButtonCst>
                  <ButtonCst
                    id={"add-button1"}
                    variant={"outlined"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={resetInputForm}
                  >
                    {t("resetButton")}
                  </ButtonCst>
                  <ButtonCst
                    id={"add-button1"}
                    variant={"contained"}
                    size={"medium"}
                    sx={{
                      minWidth: "150px",
                      [theme.breakpoints.down("lg")]: {
                        minWidth: "100px",
                      },
                      [theme.breakpoints.down("sm")]: {
                        minWidth: "100%",
                      },
                    }}
                    onClick={() => onConfirm()}
                  >
                    {t("saveButton")}
                  </ButtonCst>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {openAssociationCover && (
        <AssociationLibraryCst
          association={coverAssociation}
          setAssociation={setCoverAssociation}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("cover_id", value)
          }
          filters={[{ field: "type", value: MediaType.COVER }]}
          title={t("cover-association")}
        />
      )}
      {openAssociationVideo && (
        <AssociationLibraryCst
          association={videoAssociation}
          setAssociation={setVideoAssociation}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("video_id", value)
          }
          filters={[{ field: "type", value: MediaType.VIDEO }]}
          title={t("video-association")}
        />
      )}
      {openAssociationBadge && (
        <AssociationTableCst
          association={badgeAssociation}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("badge_id", value)
          }
          setAssociation={(badge: any) => {
            delete errorInputForm?.["badge_id"] &&
              setErrorInputForm(errorInputForm);
            const objToBadgeAssociation: any = Object.keys(badge).map(
              (key: string) => {
                if (key.includes("||")) {
                  return {
                    id: key.split("||")[0],
                    label: key.split("||")[1],
                  };
                } else {
                  return badgeAssociation.find((elem: any) => elem.id === +key);
                }
              }
            );
            setBadgesAssociation(objToBadgeAssociation);
          }}
          title={t("badge-association")}
          service={`${ServicesURL.getBadges}${id ? `?corporateId=${id}` : ``}`}
          pageID={PagesID["badge"]}
          // title={t("activity-association")}
          enableMultiRowSelection={false}
        />
      )}
      {openAssociationCertificate && (
        <AssociationTableCst
          association={certificatesAssociation}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("certificate_id", value)
          }
          setAssociation={(certificate: any) => {
            delete errorInputForm?.["certificate_id"] &&
              setErrorInputForm(errorInputForm);
            const objToCertificateAssociation: any = Object.keys(
              certificate
            ).map((key: string) => {
              if (key.includes("||")) {
                return {
                  id: key.split("||")[0],
                  label: key.split("||")[1],
                };
              } else {
                return certificatesAssociation.find(
                  (elem: any) => elem.id === +key
                );
              }
            });
            setCertificatesAssociation(objToCertificateAssociation);
          }}
          title={t("certificate-association")}
          service={`${ServicesURL.getCertificates}${
            id ? `?corporateId=${id}` : ``
          }`}
          pageID={PagesID["certificates"]}
          // title={t("activity-association")}
          enableMultiRowSelection={false}
        />
      )}
      {openAssociationScorm && (
        <AssociationLibraryCst
          association={scormAssociation}
          setAssociation={setScormAssociation}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("scorm_package_id", value)
          }
          filters={[
            {
              field: "type",
              value: MediaType.LEARNING_RESOURCE,
            },
            {
              field: "learning_resource_type",
              value: LearningResourceType.SCORM,
            },
          ]}
          title={t("scorm-association")}
        />
      )}
      {openAssociationXapi && (
        <AssociationLibraryCst
          association={xapiAssociation}
          setAssociation={setXapiAssociation}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("xapi_package_id", value)
          }
          filters={[
            {
              field: "type",
              value: MediaType.LEARNING_RESOURCE,
            },
            {
              field: "learning_resource_type",
              value: LearningResourceType.XAPI,
            },
          ]}
          title={t("xapi-association")}
        />
      )}
      {openAssociationMaterials && (
        <AssociationLibraryCst
          association={materialsAssociation}
          setAssociation={setMaterialsAssociation}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("didactical_material", value)
          }
          limit={20}
          filters={[{ field: "type", value: MediaType.DIDACTICAL_MATERIAL }]}
          multiple
          title={t("teaching-materials-association")}
        />
      )}
      {openAssociationKnowledgeDomain && (
        <AssociationTableCst
          association={knowledgeDomainAssociation}
          setCloseAssociation={(value: boolean) =>
            setOpenAssociation("knowledge_domain", value)
          }
          setAssociation={(knowledge_domain: any) => {
            delete errorInputForm?.["knowledge_domain"] &&
              setErrorInputForm(errorInputForm);
            const objToknowledgeDomainAssociation: any = Object.keys(
              knowledge_domain
            ).map((key: string) => {
              if (key.includes("||")) {
                return {
                  id: key.split("||")[0],
                  label: key.split("||")[1],
                };
              } else {
                return knowledgeDomainAssociation.find(
                  (elem: any) => elem.id === +key
                );
              }
            });
            setKnowledgeDomainAssociation(objToknowledgeDomainAssociation);
          }}
          title={t("knowledge-domains-association", { ns: "breadcrumb" })}
          service={`${ServicesURL.getKnowledgeDomainsAll.replace(
            ":idCorporate",
            id ? id : "1"
          )}`}
          pageID={PagesID["skills.knowledge-domains"]}
          // title={t("activity-association")}
          enableMultiRowSelection={false}
        />
      )}
      {openAssociationSurvey && (
        <AssociationTableCst
          association={surveyAssociation}
          setAssociation={(test: any) => {
            delete errorInputForm?.["survey_id"] &&
              setErrorInputForm(errorInputForm);
            const objToActivityAssociation: any = Object.keys(test).map(
              (key: string) => {
                return {
                  id: key.split("||")[0],
                  label: key.split("||")[1],
                };
              }
            );
            setSurveyAssociation(objToActivityAssociation);
          }}
          setCloseAssociation={(value: boolean) => {
            scrollToElement("survey_id");
            setOpenAssociation("survey_id", value);
          }}
          service={`${ServicesURL.getSurvey}?corporateId=${id}`}
          pageID={PagesID["corporate.survey"]}
          title={t("survey-association")}
          enableMultiRowSelection={false}
        />
      )}
    </AnimationFadeIn>
  );
};

export default ActivityFormCst;
