import React, { useMemo, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Checkbox,
  Fade,
  IconButton,
  Popper,
  Tooltip,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";
import { CustomRowActionInterface } from "./types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CustomRowActionTooltip } from "./style";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import {
  AddRoadOutlined,
  AddToQueueOutlined,
  AssignmentTurnedIn,
  CreateNewFolder,
  Error,
  Visibility,
  Warning,
  SwapCalls,
  CheckCircle,
} from "@mui/icons-material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SportsIcon from "@mui/icons-material/Sports";
import { useOutsideAlerter } from "@hooks/useOutsideAlerter";
import IconActionRow from "../IconAcitionRow";
import { UseIconButtonStyles } from "../../../../hooks/useIconTableStyles";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CustomRowAction: React.FC<CustomRowActionInterface> = ({
  row,
  configurationActionForRow,
  setHideHeader,
  setActionData,
  setCheckBoxData,
  checkBoxData,
  setOpenModal,
}) => {
  const query = window.matchMedia("(min-width: 576px)");
  const { matches: isDesktop } = query;
  const currentRow = row?.original;
  const navigate = useNavigate();
  const myRef = useRef<any>(null);
  const theme = useTheme();
  const [openActions, setOpenActions] = useState(false);
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useOutsideAlerter(myRef, setOpenActions);

  const handlePopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper((previousOpen: boolean) => !previousOpen);
  };
  const canBeOpen = openPopper && Boolean(anchorEl);
  const popperId = canBeOpen ? "spring-popper" : undefined;

  const renderActions = useMemo(
    () => (internalConfigurationForRow?: any[]) =>
      (internalConfigurationForRow || configurationActionForRow) &&
      (internalConfigurationForRow || configurationActionForRow)?.map(
        (action, index) => {
          const {
            icon,
            type,
            url,
            callBack,
            labelTranslate,
            getIcon,
            actions,
            disabled = false,
            dynamicIconColor,
            useId,
            switchIcon =false,
            urlId,
            style,
          } = action;

          let noLink = false;
          switch (type) {
            case "startCampaign":
              return (
                <IconButton
                  size="small"
                  key={index}
                  disabled={switchIcon || disabled}
                  onClick={() => {
                    setOpenModal && setOpenModal(true);
                    setActionData && setActionData(currentRow);
                    callBack(currentRow);
                  }}
                >
                  <Tooltip title={labelTranslate}>
                    <SwapCalls />
                  </Tooltip>
                </IconButton>
              );
            case "endCampaign":
              return (
                <IconButton
                  size="small"
                  key={index}
                  disabled={switchIcon || disabled}
                  onClick={() => {
                    setOpenModal && setOpenModal(true);
                    setActionData && setActionData(currentRow);
                    callBack(currentRow);
                  }}
                >
                  <Tooltip title={labelTranslate}>
                    <CheckCircleIcon />
                  </Tooltip>
                </IconButton>
              );
            case "nextStep":
              return (
                <IconButton
                  size="small"
                  key={index}
                  disabled={switchIcon || disabled}
                  onClick={() => {
                    setOpenModal && setOpenModal(true);
                    setActionData && setActionData(currentRow);
                    callBack
                      ? callBack(currentRow)
                      : url && navigate(`${currentRow.campaign_id}/` + url);
                  }}
                >
                  <Tooltip title={labelTranslate}>
                    {switchIcon ? (
                      <CheckIcon sx={{ fill: "#a6ff04 !important" }} />
                    ) : (
                      <SwapCalls />
                    )}
                  </Tooltip>
                </IconButton>
              );
            case "confirm":
              return (
                <IconButton
                  size="small"
                  sx={UseIconButtonStyles(
                    "AssignmentTurnedIn",
                    dynamicIconColor
                  )}
                  key={index}
                  disabled={disabled}
                  onClick={() => {
                    setHideHeader && setHideHeader(false);
                    callBack
                      ? callBack(currentRow)
                      : navigate(`${currentRow.campaign_id}/` + url);
                  }}
                >
                  <Tooltip title={labelTranslate}>
                    {switchIcon ? <Visibility /> : <AssignmentTurnedIn />}
                  </Tooltip>
                </IconButton>
              );
            case "collectionResults":
              return (
                <IconButton
                  size="small"
                  key={index}
                  disabled={disabled}
                  onClick={() => {
                    setHideHeader && setHideHeader(false);
                    callBack
                      ? callBack(currentRow)
                      : urlId
                      ? navigate(`${urlId}/` + url)
                      : navigate(`${currentRow.campaign_id}/` + url);
                  }}
                >
                  <Tooltip title={labelTranslate}>
                    <EditNoteIcon />
                  </Tooltip>
                </IconButton>
              );

            case "visibility":
              return (
                <IconButton
                  size="small"
                  key={index}
                  disabled={disabled}
                  onClick={() => {
                    setHideHeader && setHideHeader(false);
                    if (url && !useId) navigate(url);
                    if (useId && url) {
                      if (typeof useId === "number")
                        navigate(`${useId}/${url}`);
                      else navigate(`${currentRow.campaign_id}/${url}`);
                    }
                    setActionData &&
                      setActionData({ ...currentRow, type: "visibility" });
                  }}
                >
                  <Tooltip title={labelTranslate}>
                    <Visibility />
                  </Tooltip>
                </IconButton>
              );
            case "reminder":
              return (
                <IconButton
                  size="small"
                  key={index}
                  disabled={switchIcon || disabled}
                  onClick={() => {
                    setHideHeader && setHideHeader(false);
                    callBack
                      ? callBack(currentRow)
                      : setActionData &&
                        setActionData({ ...currentRow, type: "reminder" });
                  }}
                >
                  <Tooltip title={labelTranslate}>
                    <SportsIcon />
                  </Tooltip>
                </IconButton>
              );
            case "edit":
              return (
                <IconButton
                  size="small"
                  key={index}
                  disabled={disabled}
                  onClick={() => {
                    setHideHeader && setHideHeader(false);
                    callBack
                      ? callBack(currentRow)
                      : navigate(
                          `${url}/${
                            currentRow?.id ||
                            currentRow?.external_ID ||
                            currentRow?.id_user ||
                            currentRow?.revision_id
                          }`
                        );
                  }}
                >
                  <Tooltip title={labelTranslate}>
                    <EditIcon />
                  </Tooltip>
                </IconButton>
              );
            case "delete":
              return (
                <IconButton
                  size="small"
                  sx={UseIconButtonStyles("delete", dynamicIconColor, disabled)}
                  key={index}
                  disabled={disabled}
                  onClick={() =>
                    callBack &&
                    callBack(currentRow.id || currentRow?.id_user || currentRow)
                  }
                >
                  <Tooltip title={labelTranslate}>
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              );
            case "decompression":
              return (
                <IconButton
                  size="small"
                  key={index}
                  disabled={disabled}
                  sx={{
                    ">.MuiSvgIcon-root": {
                      fill:
                        icon === "warning"
                          ? `${theme.palette.warning.main} !important`
                          : icon === "error"
                          ? `${theme.palette.error.main} !important`
                          : `${theme.palette.accent.main} !important`,
                    },
                  }}
                  onClick={() =>
                    callBack &&
                    callBack(currentRow.id || currentRow?.id_user || currentRow)
                  }
                >
                  <Tooltip title={labelTranslate}>
                    <>
                      {icon && icon === "success" && <CheckCircle />}
                      {icon && icon === "warning" && <Warning />}
                      {icon && icon === "error" && <Error />}
                    </>
                  </Tooltip>
                </IconButton>
              );
            case "popper":
              return (
                <div key={index}>
                  <IconButton
                    aria-describedby={popperId}
                    size="small"
                    key={index}
                    disabled={disabled}
                    onClick={handlePopper}
                  >
                    <Tooltip title={labelTranslate}>
                      <DynamicFeedIcon />
                    </Tooltip>
                  </IconButton>
                  <Popper
                    placement="top"
                    sx={{
                      zIndex: "1 ",
                    }}
                    id={popperId}
                    open={openPopper}
                    anchorEl={anchorEl}
                    transition
                  >
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps}>
                        <Box
                          sx={{
                            border: 1,
                            bgcolor: "background.paper",
                            padding: "0px 5px",
                          }}
                        >
                          {renderActions(actions)}
                        </Box>
                      </Fade>
                    )}
                  </Popper>
                </div>
              );
            case "association":
              noLink = url === "/";
              return (
                <IconButton
                  disabled={disabled || noLink}
                  size="small"
                  key={index}
                  onClick={() => {
                    setHideHeader && setHideHeader(false);
                    navigate(`${url}`);
                  }}
                >
                  <Tooltip title={labelTranslate}>
                    {icon === "CreateNewFolder" ? (
                      <CreateNewFolder />
                    ) : icon === "AddToQueueOutlined" ? (
                      <AddToQueueOutlined />
                    ) : icon === "AddRoadOutlined" ? (
                      <AddRoadOutlined />
                    ) : (
                      <Add />
                    )}
                  </Tooltip>
                </IconButton>
              );
            case "button":
              return (
                <IconButton
                  disabled={disabled || !callBack}
                  size="small"
                  key={index}
                  onClick={() => {
                    if (callBack) {
                      callBack(currentRow);
                    }
                  }}
                >
                  {getIcon ? (
                    <Tooltip title={labelTranslate || ""}>
                      {getIcon(currentRow)}
                    </Tooltip>
                  ) : (
                    <IconActionRow
                      icon={icon}
                      labelTranslate={labelTranslate}
                    />
                  )}
                </IconButton>
              );
            case "upload":
              return (
                <IconButton
                  disabled={disabled || !callBack}
                  sx={{
                    opacity: !callBack ? "0.3" : "unset",
                  }}
                  size="small"
                  key={index}
                  onClick={() => callBack && callBack(currentRow)}
                >
                  <Tooltip title={labelTranslate}>
                    {getIcon ? (
                      getIcon(currentRow)
                    ) : (
                      <IconActionRow
                        icon={icon}
                        labelTranslate={labelTranslate}
                      />
                    )}
                  </Tooltip>
                </IconButton>
              );
            case "link":
              noLink = url === "/";
              return (
                <IconButton
                  size="small"
                  key={index}
                  disabled={disabled || noLink}
                  onClick={() => {
                    if (!callBack) {
                      // If link is from a row which has edition field, it is a
                      // virtual or physical edition so link must have also
                      // lo_activity id of edition
                      if (currentRow?.edition) {
                        if (currentRow?.learning_objects?.id) {
                          // If learning objects.id is missing inside edition avoid navigate, it would go to error
                          navigate(
                            `${currentRow?.id || currentRow.external_ID}/${
                              currentRow.learning_objects.id
                            }/${url}`
                          );
                        }
                      } else {
                        navigate(
                          `${currentRow?.id || currentRow.external_ID}/${url}`
                        );
                      }
                    } else {
                      callBack(currentRow);
                    }
                    setHideHeader && setHideHeader(false);
                  }}
                >
                  {getIcon ? (
                    getIcon(currentRow)
                  ) : (
                    <IconActionRow
                      icon={icon}
                      labelTranslate={labelTranslate}
                    />
                  )}
                </IconButton>
              );
            case "checkbox":
              const isOnlyCheckbox = style && style == "only";

              if (checkBoxData && checkBoxData.length)
                return (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: isOnlyCheckbox
                        ? "flex-start"
                        : "space-between",
                      marginRight: isOnlyCheckbox ? "inherit" : "26px",
                      gap: isOnlyCheckbox ? " 25px" : "0",
                    }}
                  >
                    <Checkbox
                      disabled={disabled == "delete"}
                      checked={checkBoxData[0].some(
                        (el: any) => el.id === row.original.id
                      )}
                      onChange={() => setCheckBoxData(row.original, "checkbox")}
                    />
                    <Checkbox
                      disabled={disabled == "confirmed"}
                      checked={checkBoxData[1].some(
                        (el: any) => el.id === row.original.id
                      )}
                      onChange={() => setCheckBoxData(row.original, "delete")}
                    />
                  </div>
                );
              else return;
            case "checkboxGap":
              return (
                <>
                  <Checkbox
                    disabled={checkBoxData[1].some(
                      (el: any) => el.id === row.original.id
                    )}
                    checked={checkBoxData[0].some(
                      (el: any) => el.id === row.original.id
                    )}
                    onChange={() => setCheckBoxData(row.original, "like")}
                  />
                  <Checkbox
                    disabled={checkBoxData[0].some(
                      (el: any) => el.id === row.original.id
                    )}
                    checked={checkBoxData[1].some(
                      (el: any) => el.id === row.original.id
                    )}
                    onChange={() => setCheckBoxData(row.original, "dislike")}
                  />
                </>
              );
            case "checkboxGapRead":
              return (
                <>
                  <Checkbox
                    disabled={true}
                    checked={row.original.liked == "YES"}
                    onChange={() => {}}
                  />
                  <Checkbox
                    disabled={true}
                    checked={row.original.liked == "NO"}
                    onChange={() => {}}
                  />
                </>
              );
          }
          return <></>;
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      configurationActionForRow,
      currentRow?.id,
      navigate,
      openPopper,
      popperId,
      anchorEl,
    ]
  );

  useEffect(() => {
    const target = myRef?.current?.parentNode;
    target.style.overflow = openActions ? "visible" : "hidden";
  }, [openActions]);

  return (
    <Box
      ref={myRef}
      key={row.id}
      sx={{
        display: "flex",
        cursor: "pointer",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {isDesktop ? (
        renderActions()
      ) : (
        <>
          <MoreVertIcon onClick={(e: any) => setOpenActions(!openActions)} />
          {openActions && (
            <CustomRowActionTooltip theme={theme}>
              {renderActions()}
            </CustomRowActionTooltip>
          )}
        </>
      )}
    </Box>
  );
};

export default CustomRowAction;
