import dayjs from "dayjs";
import { StatusEnum } from "../components/ModalConfirmsCst/types";
import { onErrorMessage } from "./utilsApi";

/**
 * This function needs as param the configuration action row
 * and the actual row.
 * It will give back the configuration action row with disabled fields if matches.
 * ADDED: additional parameter in case of necessity
 */
export const disableRowBasedOnDisableIf = (
  configsRow: any,
  row: any,
  params?: {
    corporateID?: any;
    user?: any;
  }
) => {
  if (configsRow && row) {
    configsRow.forEach((configRow: any) => {
      const disableIf = configRow?.disableIf;
      if (disableIf && Array.isArray(disableIf)) {
        let rowDisabled = false;
        disableIf.forEach((condition) => {
          // If rowDisabled flag is previous set to true avoid to make other checkes
          if (!rowDisabled) {
            switch (condition) {
              case "futurePublicationDate":
                if (
                  row.original?.publish_date &&
                  dayjs(row.original?.publish_date).isAfter(dayjs())
                ) {
                  // Future publication date -> disable
                  rowDisabled = true;
                  configRow.disabled = true;
                } else if (row.original?.publish_date) {
                  // Not future publication date -> enable
                  configRow.disabled = false;
                } else {
                  // Publish date null or not coming -> disable
                  rowDisabled = true;
                  configRow.disabled = true;
                }
                break;
              case "obsolete":
                if (
                  row.original?.obsolescent_date &&
                  dayjs(row.original?.obsolescent_date).isBefore(dayjs())
                ) {
                  rowDisabled = true;
                  configRow.disabled = true;
                } else {
                  configRow.disabled = false;
                }
                break;
              case "notOwner":
                //BASED ON ROW CORPORATE ID
                if (row.original?.corporate_id?.id) {
                  if (
                    row.original?.corporate_id?.id !==
                    parseInt(params?.corporateID || "")
                  ) {
                    rowDisabled = true;
                    configRow.disabled = true;
                  } else {
                    configRow.disabled = false;
                  }
                } else {
                  //BASED ON USER CORPORATE ID
                  if (
                    params?.user?.organization_id !==
                    parseInt(params?.corporateID || "")
                  ) {
                    rowDisabled = true;
                    configRow.disabled = true;
                  } else {
                    configRow.disabled = false;
                  }
                }
                break;
              case "is_standalone":
                if (row.original?.is_standalone === false) {
                  rowDisabled = true;
                  configRow.disabled = true;
                } else {
                  configRow.disabled = false;
                }
                break;
              case "communitySuspended":
                if (
                  !row.original?.enable_initiative_community_flag ||
                  !row.original?.community_discussion
                ) {
                  rowDisabled = true;
                  configRow.disabled = true;
                } else {
                  configRow.disabled = false;
                }
                break;
              case "editable":
                if (row.original?.editable === false) {
                  rowDisabled = true;
                  configRow.disabled = true;
                } else {
                  configRow.disabled = false;
                }
                break;
              default:
                break;
            }
          }
        });
      }
    });
  }
  return configsRow;
};

export const downloadDataTable = async (dataTable: any, modal: any) => {
  try {
    modal({ s: StatusEnum.LOADING, message: "loading" });

    const allTables = () => {
      return dataTable.map((table: any) => {
        if (table?.dataTable) return table?.dataTable?.output;
        else return table;
      });
    };

    const combinedData = allTables().flat();

    const prepareCsvData = (data: any[]) => {
      if (!data || data.length === 0) return `No data available\n`;

      const allKeys = Array.from(
        new Set(data.flatMap((row) => Object.keys(flattenObject(row))))
      );

      const headers = allKeys.join(",");

      const rows = data.map((row) => {
        const flatRow = flattenObject(row);
        return allKeys
          .map((key) => `"${String(flatRow[key] || "").replace(/"/g, '""')}"`)
          .join(",");
      });

      return `${headers}\n${rows.join("\n")}\n`;
    };

    const csvContent = prepareCsvData(combinedData);

    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "dynamic_data.csv";
    link.click();

    modal({ s: StatusEnum.OK, message: "Download completed" });
  } catch (error) {
    const message = onErrorMessage(error);
    modal({ s: StatusEnum.KO, message: message });
    console.error(error);
  }
};

// Funzione per appiattire un oggetto annidato
const flattenObject = (obj: any, parentKey = "", sep = ".") => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const fullKey = parentKey ? `${parentKey}${sep}${key}` : key;
    if (value && typeof value === "object" && !Array.isArray(value)) {
      Object.assign(acc, flattenObject(value, fullKey, sep)); // Ricorsione per oggetti annidati
    } else {
      acc[fullKey] = value; // Aggiunge la chiave/valore
    }
    return acc;
  }, {} as Record<string, any>);
};
