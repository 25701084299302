import { CircularProgress } from "@mui/material";
import React from "react";
import { Container, Message } from "@components/FormLoadingStateCst/style";
import { useTranslation } from "react-i18next";

const FormLoadingStateCst = () => {
  const { t } = useTranslation("form");

  return (
    <Container gap={"20px"}>
      <CircularProgress color={"primary"} size={50} />
      <Message>{t("loading-state")}</Message>
    </Container>
  );
};

export default FormLoadingStateCst;
