import ProtectedRoute from "@components/ProtectedRoute";
import { useIsInclude } from "src/router/RoutersCst";

import Groups from "@pages/Contents/Corporate/Groups";
import AddGroups from "@pages/Contents/Corporate/Groups/AddGroups";
import EditGroups from "@pages/Contents/Corporate/Groups/EditGroups";
import UsersGroup from "@pages/Contents/Corporate/Groups/UsersGroup";
import GroupsDynamic from "@pages/Contents/Corporate/GroupsDynamic";
import AddGroupsDynamic from "@pages/Contents/Corporate/GroupsDynamic/AddGroupsDynamic";
import EditGroupsDynamic from "@pages/Contents/Corporate/GroupsDynamic/EditGroupsDynamic";
import UsersGroupDynamic from "@pages/Contents/Corporate/GroupsDynamic/UsersGroupDynamic";
import GroupViewLO from "@pages/Contents/Corporate/Groups/GroupViewLO";
import AllGroupsEnrolled from "@pages/Contents/Corporate/Catalog/sharedComponents/AllGroupsEnrolled";
import Enrollments from "@pages/Contents/Corporate/Catalog/sharedComponents/EnrollmentsForm";
import { useLocation } from "react-router-dom";
import GroupsEnrollments from "@pages/Contents/Corporate/Groups/GroupsEnrollmentForm";
import GroupsEnrollmentStatus from "@pages/Contents/Corporate/Groups/GroupsEnrollmentStatus";
import GroupsEnrollmentStatusDetail from "@pages/Contents/Corporate/Groups/GroupsEnrollmentStatus/StatusDetail";

const useCorporateGroups = () => {
  const { isInclude } = useIsInclude();

  return [
    {
      path: "groups",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&corporate.groups")}
            >
              <Groups />
            </ProtectedRoute>
          ),
        },
        {
          path: "add",
          element: (
            <ProtectedRoute
              isAllowed={isInclude(
                "corporate&&usermgmt.corporates.static_groups.write"
              )}
            >
              <AddGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit/:idGroup",
          element: (
            <ProtectedRoute
              isAllowed={isInclude(
                "corporate&&usermgmt.corporates.static_groups.write"
              )}
            >
              <EditGroups />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idGroup/user-of-group",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("corporate&&corporate.groups")}
            >
              <UsersGroup />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idGroup/learning-objects",
          children: [
            {
              index: true,
              path: "",
              element: (
                <ProtectedRoute
                  isAllowed={isInclude(
                    "corporate&&corporate.catalog&&learning.enrollment.read"
                  )}
                >
                  <GroupViewLO />
                </ProtectedRoute>
              ),
            },
            {
              path: ":idLO/:groupType/:groupId/enrollment",
              element: (
                <ProtectedRoute
                  isAllowed={isInclude(
                    "corporate&&corporate.catalog&&learning.enrollment.read"
                  )}
                >
                  <Enrollments />
                </ProtectedRoute>
              ),
            },
            {
              path: ":idLORow/groups-enrolled",
              children: [
                {
                  index: true,
                  path: "",
                  element: (
                    <ProtectedRoute
                      isAllowed={isInclude(
                        "corporate&&corporate.catalog&&learning.enrollment.read"
                      )}
                    >
                      <AllGroupsEnrolled />
                    </ProtectedRoute>
                  ),
                },
                {
                  index: true,
                  path: "user-of-group",
                  element: (
                    <ProtectedRoute
                      isAllowed={isInclude(
                        "corporate&&corporate.catalog&&learning.enrollment.read"
                      )}
                    >
                      <UsersGroup />
                    </ProtectedRoute>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: ":idGroup/enrollment",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.read")} //TODO inserire permessi giusti
            >
              <GroupsEnrollments />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idGroup/enrollment-status",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.read")} //TODO inserire permessi giusti
            >
              <GroupsEnrollmentStatus type="static" />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idGroup/enrollment-status/:enrollmentId/lo-associated",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.read")} //TODO inserire permessi giusti
            >
              <GroupsEnrollmentStatusDetail />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "groups-dynamic",
      children: [
        {
          index: true,
          path: "",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.read")}
            >
              <GroupsDynamic />
            </ProtectedRoute>
          ),
        },
        {
          path: "add",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.write")}
            >
              <AddGroupsDynamic />
            </ProtectedRoute>
          ),
        },
        {
          path: "edit/:idGroup",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.write")}
            >
              <EditGroupsDynamic />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idGroupDynamic/user-of-group",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.read")}
            >
              <UsersGroupDynamic />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idGroup/learning-objects",
          children: [
            {
              index: true,
              path: "",
              element: (
                <ProtectedRoute
                  isAllowed={isInclude(
                    "corporate&&corporate.catalog&&learning.enrollment.read"
                  )}
                >
                  <GroupViewLO />
                </ProtectedRoute>
              ),
            },
            {
              path: ":idLO/:groupType/:groupId/enrollment",
              element: (
                <ProtectedRoute
                  isAllowed={isInclude(
                    "corporate&&corporate.catalog&&learning.enrollment.read"
                  )}
                >
                  <Enrollments />
                </ProtectedRoute>
              ),
            },
            {
              path: ":idLORow/groups-enrolled",
              children: [
                {
                  index: true,
                  path: "",
                  element: (
                    <ProtectedRoute
                      isAllowed={isInclude(
                        "corporate&&corporate.catalog&&learning.enrollment.read"
                      )}
                    >
                      <AllGroupsEnrolled />
                    </ProtectedRoute>
                  ),
                },
                {
                  index: true,
                  path: "user-of-group",
                  element: (
                    <ProtectedRoute
                      isAllowed={isInclude(
                        "corporate&&corporate.catalog&&learning.enrollment.read"
                      )}
                    >
                      <UsersGroupDynamic />
                    </ProtectedRoute>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: ":idGroup/enrollment",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.read")} //TODO inserire permessi giusti
            >
              <GroupsEnrollments />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idGroup/enrollment-status",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.read")} //TODO inserire permessi giusti
            >
              <GroupsEnrollmentStatus />
            </ProtectedRoute>
          ),
        },
        {
          path: ":idGroup/enrollment-status/:enrollmentId/lo-associated",
          element: (
            <ProtectedRoute
              isAllowed={isInclude("usermgmt.corporates.dynamic_groups.read")} //TODO inserire permessi giusti
            >
              <GroupsEnrollmentStatusDetail />
            </ProtectedRoute>
          ),
        },
      ],
    },
  ];
};

export default useCorporateGroups;
