import React, { useState } from "react";
import { Box, Checkbox, Container, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";
import ButtonBackPage from "../../../../../../components/ButtonBackPage";
import { TableTabs } from "../../../../../../components/TableTabs/Index";
import CustomRowAction from "../../../../../../components/Table/components/CustomRowAction";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate, useParams } from "react-router-dom";
import ButtonCst from "../../../../../../components/ButtonCst";
import { ServicesURL } from "../../../../../../utils/utilsApi";
import { useFetchTable } from "../../../../../../hooks/useFetchDataTable";
import ModalConfirmsCst from "../../../../../../components/ModalConfirmsCst";
import { StatusEnum } from "../../../../../../components/ModalConfirmsCst/types";
import useSWRMutation from "swr/mutation";
import { fetcher } from "../../../../../../services/config";
import { MethodType } from "../../../../../../services/type";
import { downloadDataTable } from "../../../../../../utils/utilsTable";

const VisualizeAndConfirm: React.FC = () => {
  const { t } = useTranslation(["training-needs-details-user"]);
  const { t: tCompletation } = useTranslation(["training-needs-create-page"]);
  const { t: tDownload } = useTranslation(["upload-component"]);
  const { t: tModal } = useTranslation(["modal"]);
  const [checkBoxData, setCheckBoxData] = useState<any>([]);
  const [checkBoxDataDelete, setCheckBoxDataDelete] = useState<any>([]);
  const [goToFinalTab, setGoToFinalTab] = useState<any>(false);
  const [confirmModal, setConfirmModal] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { id, idCampaign } = useParams();
  useResetFilters();

  const navigate = useNavigate();

  const detailCampaignKnowledge = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `KNOWLEDGE`),
    PagesID["corporate.campaign.visualize.confirm"]
  );
  const detailCampaignCapacity = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `CAPACITY`),
    PagesID["corporate.campaign.visualize.confirm.capacity"]
  );

  const detailCorporate = useFetchTable(
    `${ServicesURL.digitedCampaign}?corporate_id=${id}&id_campaign=${idCampaign}&campaign_type=UOG`,
    PagesID["corporate.campaign"]
  );

  const rowData =
    detailCorporate &&
    detailCorporate.dataTable &&
    detailCorporate.dataTable.output &&
    detailCorporate.dataTable.output.find(
      (el: any) => el.campaign_id == idCampaign
    );

  const isCompletedState = rowData && rowData?.hr_state == "COMPLETED";

  const typeKnowledge =
    selectedTab == 0 ? detailCampaignKnowledge : detailCampaignCapacity;

  const detailConfirmCampaignKnowledge = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `KNOWLEDGE`) +
      `${isCompletedState ? "&confirmed=TRUE" : ""}`,
    PagesID["corporate.campaign.visualize.confirmed"]
  );
  const detailConfirmCampaignCapacity = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `CAPACITY`) +
      `${isCompletedState ? "&confirmed=TRUE" : ""}`,
    PagesID["corporate.campaign.visualize.confirmed.capacity"]
  );

  const typeKnowledgeConfirmed =
    selectedTab == 0
      ? detailConfirmCampaignKnowledge
      : detailConfirmCampaignCapacity;

  const { trigger: triggerDeleteCampaign } = useSWRMutation(
    `${ServicesURL.digitedDeleteDetailCampaign.replace(
      "{campaignId}",
      `${idCampaign}`
    )}`,
    (url: string, { arg }: { arg: { id: any } }) => {
      return fetcher<any>(`${url}/${arg.id}`, MethodType.DELETE);
    }
  );

  const { trigger: triggerConfirmGap } = useSWRMutation(
    `${ServicesURL.digitedCampaign}`,
    (url: string, { arg }) => {
      return fetcher<any>(`${url}/${idCampaign}/details`, MethodType.PUT, arg);
    }
  );

  const handleConfirmModal = async (row: any) => {
    setConfirmModal({ s: StatusEnum.LOADING, message: "loading" });

    const listIdKnowledge = checkBoxData.map((el: any) => el.id);

    const payloadConfirmGap = {
      is_closed: true,
      campaign_detail_ids: listIdKnowledge,
    };

    try {
      //@ts-ignore
      await triggerConfirmGap(payloadConfirmGap);
      setConfirmModal({
        s: StatusEnum.OK,
        message: "",
      });
    } catch (error) {
      setConfirmModal(undefined);
      setOpenConfirmModal(false);
      console.error(error);
    }
  };

  const isCompletationCampaignFlux = window.location.href.includes(
    "completation-campaign"
  );
  const isLearnerFlux = window.location.href.includes("learner-confirm");
  const isLearnerCompletationFlux = window.location.href.includes(
    "visualize-and-confirm-learner"
  );

  const handleDelete = async () => {
    if (checkBoxDataDelete && checkBoxDataDelete.length == 1) {
      setDeleteModal({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDeleteCampaign({ id: checkBoxDataDelete[0].id });
        const newData = [
          ...typeKnowledge.dataTable.output.filter(
            (el: any) => el.id !== checkBoxDataDelete[0].id
          ),
        ];

        typeKnowledge.mutate({ ...typeKnowledge.dataTable, output: newData });

        setDeleteModal({ s: StatusEnum.OK, message: "" });
        setCheckBoxDataDelete([]);
      } catch (error) {
        setDeleteModal(undefined);
        console.error(error);
      }
    }
    if (checkBoxDataDelete && checkBoxDataDelete.length > 1) {
      setDeleteModal({ s: StatusEnum.LOADING, message: "loading" });
      try {
        checkBoxDataDelete.map(async (elDel: any) => {
          await triggerDeleteCampaign({ id: elDel.id });
          const newData = [
            ...typeKnowledge.dataTable.output.filter(
              (el: any) => el.id !== elDel
            ),
          ];

          typeKnowledge.mutate({
            ...typeKnowledge.dataTable,
            output: newData,
          });
        });

        setDeleteModal({ s: StatusEnum.OK, message: "" });
        setCheckBoxDataDelete([]);
      } catch (error) {
        setDeleteModal(undefined);
        console.error(error);
      }
    }
  };

  const handleCheckBox = (row: any, type: string) => {
    let dataBox = type === "checkbox" ? checkBoxData : checkBoxDataDelete;
    let setData = type === "checkbox" ? setCheckBoxData : setCheckBoxDataDelete;
    if (dataBox && dataBox.some((el: any) => el.id === row.id)) {
      const removeData = dataBox.filter((el: any) => el.id !== row.id);
      setData(removeData);
    } else {
      setData([...dataBox, row]);
    }
  };

  const handleAllCheckBox = (data: any, type: string) => {
    let dataBox = type === "checkbox" ? checkBoxData : checkBoxDataDelete;
    let setData = type === "checkbox" ? setCheckBoxData : setCheckBoxDataDelete;

    const dataBoxFiltered = dataBox.filter(
      (el: any) => el.status != "COMPLETED"
    );

    const dataFiltered = data.dataTable.output.filter(
      (el: any) => el.status != "COMPLETED"
    );

    const differentDataBox =
      type === "checkbox" ? checkBoxDataDelete : checkBoxData;
    const updateData = dataFiltered.filter(
      (el: any) => !differentDataBox.some((user: any) => user.id === el.id)
    );

    if (dataBox && dataFiltered && dataBoxFiltered.length < updateData.length) {
      return setData(updateData);
    }

    return setData([]);
  };

  const configurationActionForRow = React.useMemo(() => {
    return (row: any) => {
      return typeKnowledge.actionForRow
        ?.filter((action: any) => {
          if (action.type === "visibility") {
            return !(isLearnerFlux || isLearnerCompletationFlux);
          }
          return true;
        })
        .map((action: any) => {
          if (action.type === "visibility") {
            return {
              ...action,
              useId: row.original.id,
            };
          }

          if (action.type === "checkbox") {
            const isExist = checkBoxData.some(
              (el: any) => el.id == row.original.id
            );
            const isExistDelete = checkBoxDataDelete.some(
              (el: any) => el.id == row.original.id
            );

            return {
              ...action,
              disabled: isExist ? "confirmed" : isExistDelete ? "delete" : null,
            };
          }
          return action;
        });
    };
  }, [typeKnowledge.actionForRow, isLearnerFlux, isLearnerCompletationFlux]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew = configurationActionForRow(row) && [
      ...configurationActionForRow(row),
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
        setCheckBoxData={handleCheckBox}
        checkBoxData={[checkBoxData, checkBoxDataDelete]}
      />,
    ];
  };

  const tabs = [
    {
      [t("knowledge")]: typeKnowledge && (
        <Table
          id={PagesID["corporate.campaign.visualize.confirm"]}
          data={typeKnowledge.dataTable?.output}
          columns={typeKnowledge.columns || []}
          onChangeFilterForm={typeKnowledge.handleFetchData}
          renderRowActions={customRenderRowActions}
          configurationTopToolbar={typeKnowledge.actionsTopToolbar}
          totalPages={typeKnowledge.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          renderActionIcon={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "3px",
                padding: "0 20px",
              }}
            >
              <FormControlLabel
                control={
                  <CheckBoxIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label={t("all")}
              />
              <FormControlLabel
                control={
                  <DeleteIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label={t("all")}
              />
            </div>
          }
          renderActionIconBottomToolbar={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                padding: "0 6px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxData.length}
                    checkedIcon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() =>
                      handleAllCheckBox(typeKnowledge, "checkbox")
                    }
                  />
                }
                label=""
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxDataDelete.length}
                    checkedIcon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox(typeKnowledge, "delete")}
                  />
                }
                label=""
              />
            </div>
          }
          renderActionUnderBottomToolbar={
            <ButtonCst
              onClick={() => setGoToFinalTab(true)}
              sx={{ cursor: "pointer" }}
              disabled={!checkBoxData.length}
              variant="contained"
              id={"continueButton"}
            >
              {tModal("continue")}
            </ButtonCst>
          }
        />
      ),
    },
    {
      [t("capacity")]: typeKnowledge && (
        <Table
          id={PagesID["corporate.campaign.visualize.confirm.capacity"]}
          data={typeKnowledge.dataTable?.output}
          columns={typeKnowledge.columns || []}
          onChangeFilterForm={typeKnowledge.handleFetchData}
          renderRowActions={customRenderRowActions}
          configurationTopToolbar={typeKnowledge.actionsTopToolbar}
          totalPages={typeKnowledge.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          renderActionIcon={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "3px",
                padding: "0 20px",
              }}
            >
              <FormControlLabel
                control={
                  <CheckBoxIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label={t("all")}
              />
              <FormControlLabel
                control={
                  <DeleteIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label={t("all")}
              />
            </div>
          }
          renderActionIconBottomToolbar={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                padding: "0 6px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxData.length}
                    checkedIcon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() =>
                      handleAllCheckBox(typeKnowledge, "checkbox")
                    }
                  />
                }
                label=""
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxDataDelete.length}
                    checkedIcon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox(typeKnowledge, "delete")}
                  />
                }
                label=""
              />
            </div>
          }
          renderActionUnderBottomToolbar={
            <ButtonCst
              onClick={() => setOpenConfirmModal(true)}
              sx={{ cursor: "pointer" }}
              disabled={!checkBoxData.length}
              variant="contained"
              id={"buttonConfirm"}
            >
              {tModal("confirm")}
            </ButtonCst>
          }
        />
      ),
    },
  ];
  const tabsConfirmed = [
    {
      [t("knowledge")]: typeKnowledgeConfirmed &&
        typeKnowledgeConfirmed?.dataTable?.output && (
          <Table
            enableRowActions={false}
            id={PagesID["corporate.campaign.visualize.confirmed"]}
            data={typeKnowledgeConfirmed.dataTable.output}
            columns={typeKnowledgeConfirmed.columns || []}
            onChangeFilterForm={typeKnowledgeConfirmed.handleFetchData}
            configurationTopToolbar={typeKnowledgeConfirmed.actionsTopToolbar}
            totalPages={
              typeKnowledgeConfirmed.dataTable?.metadata?.total_elements
            }
            modalTitle={t("hero-title")?.replace("-", "") || ""}
            renderActionIconBottomToolbar={<></>}
            renderActionUnderBottomToolbar={
              <ButtonCst
                onClick={() =>
                  downloadDataTable(
                    [
                      detailConfirmCampaignCapacity,
                      detailConfirmCampaignKnowledge,
                    ],
                    setOpenToast
                  )
                }
                sx={{ cursor: "pointer" }}
                variant="outlined"
                id={"download"}
              >
                {tDownload("download")}
              </ButtonCst>
            }
          />
        ),
    },
    {
      [t("capacity")]: typeKnowledgeConfirmed &&
        typeKnowledgeConfirmed?.dataTable?.output && (
          <Table
            enableRowActions={false}
            id={PagesID["corporate.campaign.visualize.confirmed.capacity"]}
            data={typeKnowledgeConfirmed.dataTable.output}
            columns={typeKnowledgeConfirmed.columns || []}
            onChangeFilterForm={typeKnowledgeConfirmed.handleFetchData}
            configurationTopToolbar={typeKnowledgeConfirmed.actionsTopToolbar}
            totalPages={
              typeKnowledgeConfirmed.dataTable?.metadata?.total_elements
            }
            modalTitle={t("hero-title")?.replace("-", "") || ""}
            renderActionIconBottomToolbar={<></>}
            renderActionUnderBottomToolbar={
              <ButtonCst
                onClick={() =>
                  downloadDataTable(
                    [
                      detailConfirmCampaignCapacity,
                      detailConfirmCampaignKnowledge,
                    ],
                    setOpenToast
                  )
                }
                sx={{ cursor: "pointer" }}
                variant="outlined"
                id={"download"}
              >
                {tDownload("download")}
              </ButtonCst>
            }
          />
        ),
    },
  ];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={
            isCompletationCampaignFlux
              ? `${tCompletation("hero-title")}`
              : `${t("hero-title")}`
          }
          description={t("hero-description")}
        />
        <ButtonBackPage goBack={2} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <TableTabs
            tabs={isCompletedState ? tabsConfirmed : tabs}
            setExternalSelectedTab={setSelectedTab}
            goToFinalTab={goToFinalTab}
            setGoToFinalTab={setGoToFinalTab}
          />
        </Box>
        <ModalConfirmsCst
          open={openConfirmModal}
          title={`${tModal("confirm-edit")}`}
          description={`${tModal("successfully-confirm-knowledge")}`}
          onCallBackClosed={() => {
            setConfirmModal(undefined);
            setOpenConfirmModal(false);
            navigate(-1);
          }}
          onActionConfirmed={() => handleConfirmModal(rowData)}
          status={confirmModal}
        />
        <ModalConfirmsCst
          open={openDeleteModal}
          title={`${tModal("confirm-delete")}`}
          description={``}
          routeToSamePage={true}
          onCallBackClosed={() => {
            setDeleteModal(undefined);
            setOpenDeleteModal(false);
          }}
          onActionConfirmed={() => handleDelete()}
          status={deleteModal}
        />
        <ModalConfirmsCst
          open={!!openToast}
          title={t("download-certificates.title", { ns: "modal" })}
          description={""}
          routeToSamePage={true}
          onCallBackClosed={() => {
            setOpenToast(undefined);
          }}
          removeButtons={true}
          status={openToast}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default VisualizeAndConfirm;
