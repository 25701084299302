import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { SWRConfig } from "swr";

import { useAuthProvider } from "@context/auth/hooks";
import { useFiltersProvider } from "@hooks/useFiltersProvider";
import { useHeaderProvider } from "@hooks/useHeaderProvider";
import { AuthContext } from "@context/auth";
import { FiltersContext } from "@context/filters";
import { HeaderContext } from "@context/header";
import { ColorModeContext } from "@context/theme";
import RoutersCst from "src/router/RoutersCst";
import { ErrorContext } from "@context/ErrorContext";
import ModalErrorServices from "@components/ModalErrorServices";
import { ErrorObj } from "@context/ErrorContext/types";
import { onErrorMessage } from "@utils/utilsApi";
import { theme } from "./theme";
import { Theme } from "@emotion/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ObsolateSessionIdPage from "@pages/Shared/obsolateSessionIdPage";
import RedirectError from "@pages/Shared/RedirectError";

declare module "@mui/material/styles" {
  interface Theme {
    table?: {
      iconColor: string;
      actionColor: string;
      backgroundTable: string;
      main: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    table?: {
      iconColor: string;
      actionColor: string;
      backgroundTable: string;
      main: string;
    };
  }
}

export default function App() {
  const { user, isLoading } = useAuthProvider();
  const { filters, setFilters, resetFilters } = useFiltersProvider();
  const { hideHeader, setHideHeader } = useHeaderProvider();

  const [mode, setMode] = React.useState<PaletteMode>("dark");
  const [errorContext, setErrorContext] = useState<ErrorObj>();
  const [openErrorContext, setOpenErrorContext] = useState<boolean>(false);
  const [appTheme, setAppTheme] = useState<Theme>();

  const colorMode = React.useCallback((newMode: PaletteMode) => {
    setMode(newMode);
  }, []);

  const themeContext: any = React.useCallback(async () => {
    if (user) {
      const resTheme = await theme(user?.organization_id.toString());
      const localTheme = createTheme(
        {
          palette: {
            mode,
          },
        },
        resTheme[mode]
      );
      setAppTheme(localTheme);
    }
  }, [mode, user]);

  React.useEffect(() => {
    themeContext();
  }, [themeContext]);

  if (appTheme) {
    return (
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
          shouldRetryOnError: false,
          onError: (error, key, config) => {
            if (
              error?.response?.status !== 401 &&
              error?.response?.status !== 406
            ) {
              const message = onErrorMessage(error);
              const detailMessage = error.response.data.message;
              setErrorContext({
                title: `Error ${error?.response?.status}`,
                content: detailMessage ? detailMessage : message,
              });
              setOpenErrorContext(true);
              // throw error;
            }
          },
        }}
      >
        <AuthContext.Provider value={{ user, isLoading }}>
          <FiltersContext.Provider
            value={{ filters, setFilters, resetFilters }}
          >
            <HeaderContext.Provider value={{ hideHeader, setHideHeader }}>
              <ErrorContext.Provider
                value={{
                  errorContext,
                  setErrorContext,
                  openErrorContext,
                  setOpenErrorContext,
                }}
              >
                <ColorModeContext.Provider
                  value={{ toggleColorMode: colorMode }}
                >
                  <ThemeProvider theme={appTheme}>
                    <CssBaseline />
                    {!!user && <RoutersCst />}
                    {errorContext && (
                      <ModalErrorServices
                        open={openErrorContext}
                        setOpen={() => setOpenErrorContext(false)}
                        title={errorContext.title}
                        content={errorContext.content}
                        btnLabel={errorContext.btnLabel}
                        callBack={errorContext?.callBack}
                      />
                    )}
                  </ThemeProvider>
                </ColorModeContext.Provider>
              </ErrorContext.Provider>
            </HeaderContext.Provider>
          </FiltersContext.Provider>
        </AuthContext.Provider>
      </SWRConfig>
    );
  }

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<></>} />
        <Route path="/ObsoletePage" element={<ObsolateSessionIdPage />} />
        <Route path="/redirectError" element={<RedirectError />} />
      </Routes>
    </BrowserRouter>
  );
}
